import React, { useState, useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import {
  Alert,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import BankData from "../../../Assets/banks.json";
import FormDispatch from "../FormDispatch";

const Account = ({ data }) => {
  const formDispatch = useContext(FormDispatch);
  const initialState = {
    formData: {
      typeOfBank: {
        name: "typeOfBank",
        value: data?.accounts[0]?.type,
        hasErrors: false,
        message: "",
      },
      bankName: {
        name: "bankName",
        value: data?.accounts[0]?.bank_name,
        hasErrors: false,
        message: "",
        bankList: [],
        bankListToShow: [],
      },
      branch: {
        name: "branch",
        value: data?.accounts[0]?.branch_name,
        hasErrors: false,
        message: "",
        branchList: [],
      },
      accountNo: {
        name: "accountNo",
        value: data?.accounts[0]?.number,
        hasErrors: false,
        message: "",
      },
    },
    errorCount: 0,
    saveCount: 0,
    demat: 2,
    meroShare: 2,
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
          }
        }
        return;
      case "bankValueChange":
        console.log("bank value change");
        draft.formData.bankName.hasErrors = false;
        draft.formData.bankName.value = action.value;

        draft.formData.branch.branchList = [];
        draft.formData.branch.branchList.push();
        let branches = draft.formData.bankName.bankList.filter((item) =>
          item.includes(action.value)
        );
        branches.map((item, i) => {
          draft.formData.branch.branchList.push(
            item.substring(item.indexOf("-") + 1, item.length)
          );
        });
        return;
      case "renderBanks":
        BankData.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              draft.formData.bankName.bankList.push(item.bankName);
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        // console.log(draft.formData.bankName.bankList.length)
        draft.formData.bankName.bankList.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              if (
                !draft.formData.bankName.bankListToShow.includes(
                  item.substring(0, item.indexOf("-"))
                )
              ) {
                draft.formData.bankName.bankListToShow.push(
                  item.substring(0, item.indexOf("-"))
                );
              }
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        return;
      case "dematFalse":
        draft.demat = 1;
        return;
      case "dematTrue":
        draft.demat = 2;
        return;
      case "meroShareFalse":
        draft.meroShare = 1;
        return;
      case "meroShareTrue":
        draft.meroShare = 2;
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "validateForm":
        console.log("validate form");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].value) {
            // console.log("no value in", draft.formData[key]);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
        }
        for (const key in draft.formData) {
          draft.formData[key].hasErrors && draft.errorCount++;
        }
        if (!draft.errorCount) {
          draft.saveCount++;
        }
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    dispatch({ type: "renderBanks" });
  }, []);

  useEffect(() => {
    if (state.saveCount) {
      console.log("ready to save");
      formDispatch({
        type: "saveAccount",
        value: {
          id: data.accounts[0]?.id,
          bank_name: state.formData.bankName.value,
          bank_code: "1101",
          branch_name: state.formData.branch.value,
          number: state.formData.accountNo.value,
          type: state.formData.typeOfBank.value,
        },
      });
    }
  }, [state.saveCount]);

  useEffect(() => {
    dispatch({ type: "validateForm" });
  }, [state.formData]);

  const [typeReject, setTypeReject] = useState(false);
  const [nameNameReject, setNameNameReject] = useState(false);
  const [branchNameReject, setBranchNameReject] = useState(false);
  const [nameReject, setNameReject] = useState(false);

  const dematCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "dematTrue" });
    } else {
      dispatch({ type: "dematFalse" });
    }
  };

  const meroShareCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "meroShareTrue" });
    } else {
      dispatch({ type: "meroShareFalse" });
    }
  };

  useEffect(() => {
    data.reasons.map((item, i) => {
      if (item.type === "account") {
        if (item.title === "type_of_bank_account") {
          setTypeReject(true);
        }
        if (item.title === "bank_name") {
          setNameNameReject(true);
        }
        if (item.title === "branch_name") {
          setBranchNameReject(true);
        }
        if (item.title === "bank_account_no") {
          setNameReject(true);
        }
      }
    });
    dispatch({
      type: "bankValueChange",
      value: state.formData.bankName.value,
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-type_of_bank_account"
              >
                Type of Bank Account
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  typeReject || state.formData.typeOfBank.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-type_of_bank_account"
                type="text"
                value={state.formData.typeOfBank.value === "current" ? 2 : 1}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "typeOfBank",
                  })
                }
              >
                <option value="">Select an account</option>
                <option value="1">Saving</option>
                <option value="2">Current</option>
              </select>
              {state.formData.typeOfBank.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.typeOfBank.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-bank_name">
                Bank Name
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  nameNameReject || state.formData.bankName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-bank_name"
                type="text"
                value={state.formData.bankName.value}
                // defaultValue={data?.accounts[0]?.bank_name}
                onChange={(e) =>
                  dispatch({
                    type: "bankValueChange",
                    value: e.target.value,
                    field: "bankName",
                  })
                }
              >
                {state.formData.bankName.bankListToShow.map((item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {state.formData.bankName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.bankName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-branch">
                Branch
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  branchNameReject || state.formData.branch.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-branch"
                type="text"
                value={state.formData.branch.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "branch",
                  })
                }
                defaultValue={data?.accounts[0]?.branch_name}
              >
                {state.formData.branch.branchList.length &&
                  state.formData.branch.branchList.map((item, i) => {
                    return (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
              {state.formData.branch.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.branch.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-bank_account_no"
              >
                Bank Account No
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative calendar form-control ${
                  nameReject || state.formData.accountNo.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-bank_account_no"
                type="text"
                placeholder="Enter Bank account no"
                value={state.formData.accountNo.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "accountNo",
                  })
                }
              />
              {state.formData.accountNo.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.accountNo.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="account-renew_my_demat"
                type="checkbox"
                onClick={meroShareCheck}
                checked={
                  data?.accounts[0]?.renew_account_automatically === "yes"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="account-renew_my_demat"
                style={{ marginLeft: "3rem" }}
              >
                Renew my Demat Account automatically
              </label>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="account-open_mero_share"
                type="checkbox"
                onClick={dematCheck}
                checked={
                  data?.accounts[0]?.open_mero_share === "yes" ? "checked" : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="account-open_mero_share"
                style={{ marginLeft: "3rem" }}
              >
                Open Mero Share Account
              </label>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Container>
  );
};

export default Account;
