import React, { useEffect, useContext } from "react";
import { useHistory, Switch, Route } from "react-router";
import { useImmerReducer } from "use-immer";
import axios from "axios";

import Sidebar from "./Client/Layout/Sidebar";
import Dashboard from "./Client/Dashboard";
import Header from "./Client/Layout/Header";

import StateContext from "../StateContext";
import Footer from "./Layout/Footer";
import CustomerView from "./Client/CustomerView/CustomerView";
import Loading from "./Layout/Loading";
import Profile from "./Client/CustomerView/Profile";
import Settings from "./Handler/Settings";

function ClientDashboard() {
  const history = useHistory();
  const appState = useContext(StateContext);
  const token = appState.client.data.token;
  const id = appState.client?.data?.user?.id;

  const initialState = {
    hasRole: false,
    userRole: {},
    userLoaded: false,
    userData: {},
    userDoc: {},
    hasGuardian: false,
    hasGuardianDoc: {},
    guardianData: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadRoles":
        draft.hasRole = true;
        draft.userRole = action.value;
        return;
      case "loadUser":
        draft.userData = action.value;
        return;
      case "loadDoc":
        draft.userDoc = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasGuardian":
        draft.hasGuardian = true;
        draft.guardianData = action.value;
        return;
      case "hasGuardianDoc":
        draft.hasGuardianDoc = true;
        draft.guardianDoc = action.value;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
          {
            cancelToken: ourRequest.token,
          }
        );
        console.log(response.data.message);
        dispatch({ type: "loadRoles", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching users");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    if (state.hasRole) {
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      const ourRequest = axios.CancelToken.source();
      async function fetchCustomers() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${state.userRole.customer_id}`,
            {
              cancelToken: ourRequest.token,
            }
          );
          console.log(response.data.message);
          dispatch({ type: "loadUser", value: response.data.data });
          dispatch({ type: "userLoaded" });
          dispatch({ type: "loadDoc", value: response.data.data.documents[0] });
        } catch (e) {
          console.log(e, "there was an error fetching customer");
        }
      }
      fetchCustomers();
      return () => ourRequest.cancel();
    }
  }, [state.hasRole]);

  console.log(state.userData);

  useEffect(() => {
    if (state.userLoaded) {
      if (
        state.userData?.relationships &&
        state.userData?.relationships.length
      ) {
        state.userData?.relationships.map((item, i) => {
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardian", value: item });
            dispatch({ type: "hasGuardianDoc", value: item.documents[0] });
          }
        });
      }
    }
  }, [state.userLoaded]);

  useEffect(() => {
    if (!appState.clientLoggedIn) {
      history.push("/login");
    }
  }, [appState.clientLoggedIn]);

  if (!state.hasRole) {
    return (
      <div className="admin">
        <Loading />
      </div>
    );
  }

  return (
    <Switch>
      <div className="admin">
        <Sidebar
          id={state.userData.id}
          email={state.userData.email}
          documents={state.userDoc}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
          boid={state.BOID}
        />
        <div className="main-content">
          <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Header
              id={state.userData.id}
              email={state.userData.email}
              documents={state.userDoc}
              guardian={
                state.hasGuardianDoc === true ? state.guardianDoc : false
              }
            />
            <Route path="/dashboard" exact>
              <Dashboard data={state.userData} />
            </Route>
            <Route path="/dashboard/customers/settings/:id" exact>
              <Settings />
            </Route>
            <Route path="/dashboard/customers/profile/:id" exact>
              <Profile />
            </Route>
            <Route path="/dashboard/customers/rejected/:id" exact>
              <CustomerView />
            </Route>
            <Footer />
          </div>
        </div>
      </div>
    </Switch>
  );
}

export default ClientDashboard;
