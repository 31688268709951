import React, { useContext } from "react";
import { Table, Container } from "reactstrap";
import StateContext from "../../../../StateContext";

const Payment = (props) => {
  const appState = useContext(StateContext);
  console.log(props);
  return (
    <div>
      {/* {appState.user.data.user.roles[0]?.name === "payment_manager" && ( */}
      <Container fluid>
        <Table className="align-items-center table-flush mb-5 " responsive>
          <tbody>
            <tr>
              <td className="font-weight-bold">
                <h1>Type: {props?.payment?.type}</h1>
                <h1>Amount: Rs.{props?.payment?.amount}</h1>
              </td>
              <td>
                <a
                  href={
                    `${process.env.REACT_APP_BASE_URL}/img/` +
                    props.email +
                    "/" +
                    props?.payment?.image
                  }
                  target="_blank"
                >
                  <img
                    src={
                      `${process.env.REACT_APP_BASE_URL}/img/` +
                      props.email +
                      "/" +
                      props?.payment?.image
                    }
                    alt=""
                    style={{ height: "500px", objectFit: "contain" }}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      {/* )} */}
    </div>
  );
};

export default Payment;
