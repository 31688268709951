import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";

import {
  Alert,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import FormDispatch from "../FormDispatch";
const FamilyInformation = ({ data }) => {
  const formDispatch = useContext(FormDispatch);

  const initialState = {
    fathersName: {
      name: "fathersName",
      value: data?.families[0]?.father_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    grandFathersName: {
      name: "grandFathersName",
      value: data?.families[0]?.grand_father_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    mothersName: {
      name: "mothersName",
      value: data?.families[0]?.mother_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    spousesName: {
      name: "spousesName",
      value: data?.families[0]?.spouse,
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughterInLawsName: {
      name: "daughterInLawsName",
      value: data?.families[0]?.daughter_in_law_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    fatherInLawsName: {
      name: "fatherInLawsName",
      value: data?.families[0]?.father_in_law_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    sonsName: {
      name: "sonsName",
      value: data?.families[0]?.son_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughtersName: {
      name: "daughtersName",
      value: data?.families[0]?.daughter_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount.counter >= 1) {
      formDispatch({
        type: "saveFamily",
        value: {
          id: data.families[0]?.id,
          father_name: state.fathersName.value,
          grand_father_name: state.grandFathersName.value,
          mother_name: state.mothersName.value,
          spouse: state.spousesName.value,
          son_name: state.sonsName.value,
          daughter_name: state.daughtersName.value,
          father_in_law_name: state.fatherInLawsName.value,
          daughter_in_law_name: state.daughterInLawsName.value,
        },
      });
    }
  }, [state.submitCount.counter]);

  // useEffect(() => {
  //   if (data.nominee === "yes") {
  //     formDispatch({ type: "nomineeTrue" });
  //   } else {
  //     formDispatch({ type: "nomineeFalse" });
  //   }
  // }, [data]);

  // function handleCheck(e) {
  //   if (e.target.checked) {
  //     formDispatch({ type: "nomineeTrue" });
  //   } else {
  //     formDispatch({ type: "nomineeFalse" });
  //   }
  // }

  useEffect(() => {
    dispatch({ type: "checkCount" });
  }, [
    state.fathersName,
    state.grandFathersName,
    state.mothersName,
    state.spousesName,
    state.daughterInLawsName,
    state.fatherInLawsName,
    state.sonsName,
    state.daughtersName,
  ]);

  const [fatherReject, setFatherReject] = useState(false);
  const [grandFatherReject, setGrandFatherReject] = useState(false);
  const [motherReject, setMotherReject] = useState(false);
  const [spouseReject, setSpouseReject] = useState(false);
  const [daughterInLawReject, setDaughterInLawReject] = useState(false);
  const [fatherInLawReject, setFatherInLawReject] = useState(false);
  const [sonReject, setSonReject] = useState(false);
  const [daughterReject, setDaughterReject] = useState(false);

  useEffect(() => {
    data.reasons.map((item, i) => {
      // {
      //   item !== null
      if (item.type === "family") {
        if (item.title === "fathers_name") {
          setFatherReject(true);
        }
        if (item.title === "grandfathers_name") {
          setGrandFatherReject(true);
        }
        if (item.title === "mothers_name") {
          setMotherReject(true);
        }
        if (item.title === "spouses_name") {
          setSpouseReject(true);
        }
        if (item.title === "daughter_in_laws_name") {
          setDaughterInLawReject(true);
        }
        if (item.title === "father_in_laws_name") {
          setFatherInLawReject(true);
        }
        if (item.title === "sons_name") {
          setSonReject(true);
        }
        if (item.title === "daughters_name") {
          setDaughterReject(true);
        }
      }
      // }
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-father_name">
                Father's Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  fatherReject || state.fathersName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-father_name"
                type="text"
                placeholder="Enter your Father's Name"
                value={state.fathersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "fathersName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "fathersName",
                  })
                }
              />
              {state.fathersName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.fathersName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-grandfather_name"
              >
                Grandfather's Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  grandFatherReject || state.grandFathersName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-grandfather_name"
                type="text"
                placeholder="Enter your Grandfather's Name"
                value={state.grandFathersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "grandFathersName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "grandFathersName",
                  })
                }
              />
              {state.grandFathersName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.grandFathersName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-mother_name">
                Mother's Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  motherReject || state.mothersName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-mother_name"
                type="text"
                placeholder="Enter your Mother's Name"
                value={state.mothersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "mothersName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "mothersName",
                  })
                }
              />
              {state.mothersName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.mothersName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-spouse_name">
                Spouse Name
              </label>
              <Input
                className={`form-control-alternative ${
                  spouseReject ? "border-danger is-invalid" : ""
                }`}
                id="input-spouse_name"
                type="text"
                placeholder="Enter your Spouse's Name"
                value={state.spousesName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "spousesName",
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-daughter_in_law_name"
              >
                Daughter in Law's Name
              </label>
              <Input
                className={`form-control-alternative ${
                  daughterInLawReject ? "border-danger is-invalid" : ""
                }`}
                id="input-daughter_in_law_name"
                type="text"
                placeholder="Enter your Daughter in Law's Name"
                value={state.daughterInLawsName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "daughterInLawsName",
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-father_in_law_name"
              >
                Father's in Law's Name
              </label>
              <Input
                className={`form-control-alternative ${
                  fatherInLawReject ? "border-danger is-invalid" : ""
                }`}
                id="input-father_in_law_name"
                type="text"
                placeholder="Enter your Father in Law's Name"
                value={state.fatherInLawsName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "fatherInLawsName",
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-son_name">
                Son's Name
              </label>
              <Input
                className={`form-control-alternative ${
                  sonReject ? "border-danger is-invalid" : ""
                }`}
                id="input-son_name"
                type="text"
                placeholder="Enter your Son's Name"
                value={state.sonsName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "sonsName",
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-daughter_name"
              >
                Daughter's Name
              </label>
              <Input
                className={`form-control-alternative ${
                  daughterReject ? "border-danger is-invalid" : ""
                }`}
                id="input-daughter_name"
                type="text"
                placeholder="Enter your Daughter's Name"
                value={state.daughtersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "daughtersName",
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row className="mt-3">
          <FormGroup>
            <Input
              onChange={handleCheck}
              type="checkbox"
              className="form-input  alert-primary ml-1"
              id="family-add_nominee"
            />
            <label
              className="form-control-label"
              htmlFor="family-add_nominee"
              style={{ marginLeft: "3rem" }}
            >
              Do you want to add nominee?
            </label>
          </FormGroup>
        </Row> */}
      </CardBody>
    </Container>
  );
};

export default FamilyInformation;
