import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";

import { FormGroup, Input, Row, Col } from "reactstrap";

import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

import provinceData from "../../../../Assets/province-district.json";
import country from "../../../../Assets/country";
// import districts from "../../../../Assets/district-list-nepal";

const Address = (props) => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);

  const [savecount, setSaveCount] = useState(0);
  const intitalState = {
    temporary: {
      // type: "1",
      block_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        touched: false,
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
      },
      province: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
    permanent: {
      // type: "2",
      block_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      province: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "temporaryCountryChange":
        draft.temporary.country.hasErrors = false;
        draft.temporary.country.value = action.value;
        draft.temporary.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.temporary.province.list.push(key);
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "temporaryProvincechange":
        draft.temporary.province.hasErrors = false;
        draft.temporary.province.value = action.value;
        draft.temporary.province.touched = true;
        //empty distrct listToShow
        draft.temporary.district.listToShow = [];
        //empty distrct value
        draft.temporary.district.value = "";
        //filter the array
        let selectedProvince;
        for (const key in provinceData) {
          selectedProvince = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince.map((item, i) => {
          draft.temporary.district.listToShow.push(item.district);
        });
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          draft.temporary.district.list.push();
          provinceData[key].map((item, i) => {
            // console.log("from the array", item.district)
            draft.temporary.district.list.push(item.district);
            draft.permanent.district.list.push(item.district);
          });
        }
        draft.temporary.district.listToShow = draft.temporary.district.list;
        draft.permanent.district.listToShow = draft.permanent.district.list;
        return;
      case "temporaryDistrictChange":
        draft.temporary.district.hasErrors = false;
        draft.temporary.district.value = action.value;
        draft.temporary.district.touched = true;
        //empty municipality listToShow
        draft.temporary.municipality.listToShow = [];
        //empty municipality value
        draft.temporary.municipality.value = "";
        //filter the array
        let selectedDistrict;
        for (const key in provinceData) {
          selectedDistrict = provinceData[draft.temporary.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "temporaryMunicipalityChange":
        draft.temporary.municipality.hasErrors = false;
        draft.temporary.municipality.value = action.value;
        draft.temporary.municipality.touched = true;

        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.list.push(item);
              draft.permanent.municipality.list.push(item);
            });
          });
        }
        draft.temporary.municipality.listToShow =
          draft.temporary.municipality.list;
        draft.permanent.municipality.listToShow =
          draft.permanent.municipality.list;
        return;
      case "temporaryCityChange":
        draft.temporary.city.hasErrors = false;
        draft.temporary.city.value = action.value;
        draft.temporary.city.touched = true;
        return;
      case "temporaryLocalityChange":
        draft.temporary.locality.hasErrors = false;
        draft.temporary.locality.value = action.value;
        draft.temporary.locality.touched = true;
        return;
      case "temporaryWardChange":
        draft.temporary.ward_number.hasErrors = false;
        draft.temporary.ward_number.value = action.value;
        draft.temporary.ward_number.touched = true;
        return;
      case "temporaryBlockChange":
        draft.temporary.block_number.hasErrors = false;
        draft.temporary.block_number.value = action.value;
        draft.temporary.block_number.touched = true;
        return;
      case "temporaryPhoneChange":
        draft.temporary.phone_number.hasErrors = false;
        draft.temporary.phone_number.value = action.value;
        draft.temporary.phone_number.touched = true;
        return;
      //PERMANENT
      case "permanentCountryChange":
        draft.permanent.country.hasErrors = false;
        draft.permanent.country.value = action.value;
        draft.permanent.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.permanent.province.list.length) {
          for (const key in provinceData) {
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "permanentProvincechange":
        draft.permanent.province.hasErrors = false;
        draft.permanent.province.value = action.value;
        draft.permanent.province.touched = true;
        //empty distrct listToShow

        draft.permanent.district.listToShow = [];
        //empty distrct value
        draft.permanent.district.value = "";
        //filter the array
        let selectedProvince2;
        for (const key in provinceData) {
          selectedProvince2 = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince2.map((item, i) => {
          draft.permanent.district.listToShow.push(item.district);
        });
        return;
      case "permanentDistrictChange":
        draft.permanent.district.hasErrors = false;
        draft.permanent.district.value = action.value;
        draft.permanent.district.touched = true;
        //empty municipality listToShow
        draft.permanent.municipality.listToShow = [];
        //empty municipality value
        draft.permanent.municipality.value = "";
        //filter the array
        let selectedDistrict2;
        for (const key in provinceData) {
          selectedDistrict2 = provinceData[draft.permanent.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict2.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.permanent.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "permanentMunicipalityChange":
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.municipality.value = action.value;
        draft.permanent.municipality.touched = true;
        return;
      case "permanentCityChange":
        draft.permanent.city.hasErrors = false;
        draft.permanent.city.value = action.value;
        draft.permanent.city.touched = true;
        return;
      case "permanentLocalityChange":
        draft.permanent.locality.hasErrors = false;
        draft.permanent.locality.value = action.value;
        draft.permanent.locality.touched = true;
        return;
      case "permanentWardChange":
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.ward_number.value = action.value;
        draft.permanent.ward_number.touched = true;
        return;
      case "permanentBlockChange":
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.block_number.value = action.value;
        draft.permanent.block_number.touched = true;
        return;
      case "permanentPhoneChange":
        draft.permanent.phone_number.hasErrors = false;
        draft.permanent.phone_number.value = action.value;
        draft.permanent.phone_number.touched = true;
        return;
      case "sameAsCurrent":
        draft.permanent.country.value = draft.temporary.country.value;
        draft.permanent.country.touched = true;
        draft.permanent.province.value = draft.temporary.province.value;
        draft.permanent.province.touched = true;
        draft.permanent.district.value = draft.temporary.district.value;
        draft.permanent.district.touched = true;
        draft.permanent.municipality.value = draft.temporary.municipality.value;
        draft.permanent.municipality.touched = true;
        draft.permanent.city.value = draft.temporary.city.value;
        draft.permanent.city.touched = true;
        draft.permanent.locality.value = draft.temporary.locality.value;
        draft.permanent.locality.touched = true;
        draft.permanent.ward_number.value = draft.temporary.ward_number.value;
        draft.permanent.ward_number.touched = true;
        draft.permanent.block_number.value = draft.temporary.block_number.value;
        draft.permanent.block_number.touched = true;
        draft.permanent.phone_number.value = draft.temporary.phone_number.value;
        draft.permanent.phone_number.touched = true;
        draft.permanent.country.hasErrors = false;
        draft.permanent.province.hasErrors = false;
        draft.permanent.district.hasErrors = false;
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.city.hasErrors = false;
        draft.permanent.locality.hasErrors = false;
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.permanent.country.value = "";
        draft.permanent.province.value = "";
        draft.permanent.district.value = "";
        draft.permanent.municipality.value = "";
        draft.permanent.city.value = "";
        draft.permanent.locality.value = "";
        draft.permanent.ward_number.value = "";
        draft.permanent.block_number.value = "";
        draft.permanent.phone_number.value = "";
        draft.permanent.country.touched = false;
        draft.permanent.province.touched = false;
        draft.permanent.district.touched = false;
        draft.permanent.municipality.touched = false;
        draft.permanent.city.touched = false;
        draft.permanent.locality.touched = false;
        draft.permanent.ward_number.touched = false;
        draft.permanent.block_number.touched = false;
        draft.permanent.phone_number.touched = false;
        return;
      case "validateForm":
        let errorCount = 0;
        for (const key in draft) {
          for (const key2 in draft[key]) {
            if (!draft[key][key2].touched || draft[key][key2].value == "") {
              // console.log(draft[key][key2])
              draft[key][key2].hasErrors = true;
              draft[key][key2].message = "This field is required";
            }
          }
        }
        for (const key in draft) {
          for (const key2 in draft[key]) {
            if (draft[key][key2].hasErrors) {
              errorCount++;
            }
          }
        }
        if (draft.temporary.city.value === "") {
          errorCount--;
          draft.temporary.city.hasErrors = false;
        }
        if (draft.temporary.block_number.value === "") {
          errorCount--;
          draft.temporary.block_number.hasErrors = false;
        }
        if (draft.temporary.phone_number.value === "") {
          errorCount--;
          draft.temporary.phone_number.hasErrors = false;
        }
        if (draft.permanent.city.value === "") {
          errorCount--;
          draft.permanent.city.hasErrors = false;
        }
        if (draft.permanent.block_number.value === "") {
          errorCount--;
          draft.permanent.block_number.hasErrors = false;
        }
        if (draft.permanent.phone_number.value === "") {
          errorCount--;
          draft.permanent.phone_number.hasErrors = false;
        }
        console.log("errorCount", errorCount);
        console.log(!errorCount);
        {
          !errorCount && setSaveCount(savecount + 1);
        }
        // console.log(saveCount)
        console.log(savecount);
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, intitalState);

  // function fetchProvinceList() {
  //   "<option> some rendered province name </option>"
  // }

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  useEffect(() => {
    console.log("save count form the useeffect", savecount);
    console.log("save the the state and navigate to another page");
    if (savecount) {
      console.log("there is a savecount");
      formDispatch({
        type: "saveAddress",
        value: [
          {
            type: "1",
            block_number: state.temporary.block_number.value,
            phone_number: state.temporary.phone_number.value,
            ward_number: state.temporary.ward_number.value,
            locality: state.temporary.locality.value,
            municipality: state.temporary.municipality.value,
            district: state.temporary.district.value,
            province: state.temporary.province.value,
            country: state.temporary.country.value,
            city: state.temporary.city.value,
          },
          {
            type: "2",
            block_number: state.permanent.block_number.value,
            phone_number: state.permanent.phone_number.value,
            ward_number: state.permanent.ward_number.value,
            locality: state.permanent.locality.value,
            municipality: state.permanent.municipality.value,
            district: state.permanent.district.value,
            province: state.permanent.province.value,
            country: state.permanent.country.value,
            city: state.temporary.city.value,
          },
        ],
      });
    }

    // console.log(Object.values(state))
  }, [savecount]);

  function handleCheck(e) {
    console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    setSaveCount(savecount + 1);
  }, [state.temporary, state.permanent]);

  return (
    <>
      <h3 className="text-primary">Current Address</h3>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-country">
              Country
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.temporary.country.hasErrors && "is-invalid border-danger"
              }`}
              id="input-country"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "temporaryCountryChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.country.value}
            >
              {country.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.country.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.country.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-province">
              Province
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.temporary.province.hasErrors && "is-invalid border-danger"
              }`}
              id="input-province"
              onChange={(e) =>
                dispatch({
                  type: "temporaryProvincechange",
                  value: e.target.value,
                })
              }
              value={state.temporary.province.value}
            >
              {state.temporary.province.list.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.province.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.province.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-district">
              District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.temporary.district.hasErrors && "is-invalid border-danger"
              }`}
              id="input-district"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "temporaryDistrictChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.district.value}
            >
              {state.temporary.district.listToShow.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.district.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.district.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-city">
              City
            </label>
            <Input
              className={`form-control-alternative ${
                state.temporary.city.hasErrors && "is-invalid border-danger"
              }`}
              id="input-city"
              placeholder={` ${
                state.temporary.city.hasErrors
                  ? state.temporary.city.message
                  : "Your City Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryCityChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.city.value}
            />
            {/* {state.temporary.city.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.city.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-municipality">
              Municipality
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.temporary.municipality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-municipality"
              type="text"
              placeholder={` ${
                state.temporary.municipality.hasErrors
                  ? state.temporary.municipality.message
                  : "Your Municipality / VDC name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryMunicipalityChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.municipality.value}
            >
              {state.temporary?.municipality?.listToShow?.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.municipality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.municipality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-locality_tole">
              Locality/Tole
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.temporary.locality.hasErrors && "is-invalid border-danger"
              }`}
              id="input-locality_tole"
              placeholder={` ${
                state.temporary.locality.hasErrors
                  ? state.temporary.locality.message
                  : "Enter Your street address"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryLocalityChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.locality.value}
            />
            {/* {state.temporary.locality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.locality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-ward_no">
              Ward No
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.temporary.ward_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-ward_no"
              type="text"
              placeholder={` ${
                state.temporary.ward_number.hasErrors
                  ? state.temporary.ward_number.message
                  : "Enter Your ward number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryWardChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.ward_number.value}
            />
            {/* {state.temporary.ward_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.ward_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-block_no">
              Block No
            </label>
            <Input
              className={`form-control-alternative ${
                state.temporary.block_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              type="number"
              id="input-block_no"
              placeholder={` ${
                state.temporary.block_number.hasErrors
                  ? state.temporary.block_number.message
                  : "Enter Your block number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryBlockChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.block_number.value}
            />
            {/* {state.temporary.block_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.block_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-telephone_number"
            >
              Telephone Number
            </label>
            <Input
              className={`form-control-alternative ${
                state.temporary.phone_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-telephone_number"
              type="number"
              placeholder={` ${
                state.temporary.phone_number.hasErrors
                  ? state.temporary.phone_number.message
                  : "Enter Your phone number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "temporaryPhoneChange",
                  value: e.target.value,
                })
              }
              value={state.temporary.phone_number.value}
            />
            {/* {state.temporary.phone_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.phone_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <h3 className="text-primary">Permanent Address</h3>
      <Row className="py-3">
        <Col lg="6">
          <Input
            type="checkbox"
            className="form-control-alternative alert-primary ml-1"
            id="permanent-address"
            onClick={handleCheck}
          />
          <label
            className="form-control-label"
            htmlFor="permanent-address"
            style={{ marginLeft: "3rem" }}
          >
            Same as Current Address
          </label>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-country">
              Country
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.permanent.country.hasErrors && "is-invalid border-danger"
              }`}
              id="input-country"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentCountryChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.country.value}
            >
              {country.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.country.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.country.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-province">
              Province
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.permanent.province.hasErrors && "is-invalid border-danger"
              }`}
              id="input-province"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentProvincechange",
                  value: e.target.value,
                })
              }
              value={state.permanent.province.value}
            >
              {state.permanent.province.list.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.province.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.province.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-district">
              District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.permanent.district.hasErrors && "is-invalid border-danger"
              }`}
              id="input-district"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentDistrictChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.district.value}
            >
              {state.permanent.district.listToShow.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.district.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.district.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-city">
              City
            </label>
            <Input
              className={`form-control-alternative ${
                state.permanent.city.hasErrors && "is-invalid border-danger"
              }`}
              id="input-city"
              type="text"
              placeholder={` ${
                state.permanent.city.hasErrors
                  ? state.permanent.city.message
                  : "Your City Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentCityChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.city.value}
            />
            {/* {state.temporary.city.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.city.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-municipality">
              Municipality
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.permanent.municipality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-municipality"
              type="text"
              placeholder={` ${
                state.permanent.municipality.hasErrors
                  ? state.permanent.municipality.message
                  : "Your Municipality / VDC name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentMunicipalityChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.municipality.value}
            >
              {state.permanent?.municipality?.listToShow?.map((item, i) => {
                return (
                  <option value={item} key={i}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.temporary.municipality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.municipality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-locality_tole">
              Locality/Tole
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.permanent.locality.hasErrors && "is-invalid border-danger"
              }`}
              id="input-locality_tole"
              type="text"
              placeholder={` ${
                state.permanent.locality.hasErrors
                  ? state.permanent.locality.message
                  : "Enter Your street address"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentLocalityChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.locality.value}
            />
            {/* {state.temporary.locality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.locality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-ward_no">
              Ward No
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.permanent.ward_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-ward_no"
              type="text"
              placeholder={` ${
                state.permanent.ward_number.hasErrors
                  ? state.permanent.ward_number.message
                  : "Enter Your ward number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentWardChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.ward_number.value}
            />
            {/* {state.temporary.ward_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.ward_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-block_no">
              Block No
            </label>
            <Input
              className={`form-control-alternative ${
                state.permanent.block_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-block_no"
              type="number"
              placeholder={` ${
                state.permanent.block_number.hasErrors
                  ? state.permanent.block_number.message
                  : "Enter Your block number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentBlockChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.block_number.value}
            />
            {/* {state.temporary.block_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.block_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-telephone_number"
            >
              Telephone Number
            </label>
            <Input
              className={`form-control-alternative ${
                state.permanent.phone_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-telephone_number"
              type="number"
              placeholder={` ${
                state.permanent.phone_number.hasErrors
                  ? state.permanent.phone_number.message
                  : "Enter Your phone number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "permanentPhoneChange",
                  value: e.target.value,
                })
              }
              value={state.permanent.phone_number.value}
            />
            {/* {state.temporary.phone_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.temporary.phone_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Address;
