import React from "react";

const Page2 = (props) => {
  return (
    <div className="page">
      <div className="form-row">
        <div className="col">
          <h4>एक घर परिवारका सदस्यहरुको विवरण / Details of Family Members</h4>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="spouse-name">
                  <p>पति/पत्नीको नाम: </p>
                  <p>Spouse Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="spouse-name"
                    id="spouse-name"
                    className="form-input"
                    readOnly
                    defaultValue={props.data?.families[0]?.spouse}
                  />
                  <input
                    type="text"
                    name="spouse-name1"
                    id="spouse-name1"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="father-name">
                  <p>बुबाको नाम: </p>
                  <p>Father's Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="father-name"
                    id="father-name"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.father_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="father-name1"
                    id="father-name1"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="mother-name">
                  <p>आमाको नाम: </p>
                  <p>Mother's Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="mother-name"
                    id="mother-name"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.mother_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="mother-name1"
                    id="mother-name1"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="grandfather-name">
                  <p>हजुर बुबाको नाम: </p>
                  <p>Grandfather's Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="grandfather-name"
                    id="grandfather-name"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.grand_father_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="grandfather-name1"
                    id="grandfather-name1"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col multiple-family-members">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="son-name-1">
                  <p>छोराहरुको नाम: </p>
                  <p>Son's Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="son-name-1"
                    id="son-name-1"
                    className="form-input"
                    readOnly
                    defaultValue={props.data?.families[0]?.son_name}
                  />
                  <input
                    type="text"
                    name="son-name-2"
                    id="son-name-2"
                    className="form-input"
                    readOnly
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="son-name-3"
                    id="son-name-3"
                    className="form-input"
                    readOnly
                  />
                  <input
                    type="text"
                    name="son-name-4"
                    id="son-name-4"
                    className="form-input"
                    readOnly
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="son-name-5"
                    id="son-name-5"
                    className="form-input"
                    readOnly
                  />
                  <input
                    type="text"
                    name="son-name-6"
                    id="son-name-6"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col multiple-family-members">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group f-d-col">
                <label htmlFor="son-name-1">
                  <p>अबिबाहित छोरीहरुको नाम: </p>
                  <p>Unmarried Daughter's name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="daughters-name-1"
                    id="daughters-name-1"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.daughter_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="daughters-name-2"
                    id="daughters-name-2"
                    className="form-input"
                    readOnly
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="daughters-name-3"
                    id="daughters-name-3"
                    className="form-input"
                    readOnly
                  />
                  <input
                    type="text"
                    name="daughters-name-4"
                    id="daughters-name-4"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col multiple-family-members">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group f-d-col">
                <label htmlFor="son-name-1">
                  <p>बुहारीको नाम: </p>
                  <p>Daughter in Law's name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="daughter-in-law-name-1"
                    id="daughter-in-law-name-1"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.daughter_in_law_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="daughter-in-law-name-2"
                    id="daughter-in-law-name-2"
                    className="form-input"
                    readOnly
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="daughter-in-law-name-3"
                    id="daughter-in-law-name-3"
                    className="form-input"
                    readOnly
                  />
                  <input
                    type="text"
                    name="daughter-in-law-name-4"
                    id="daughter-in-law-name-4"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group f-d-col">
                <label htmlFor="son-name-1">
                  <p>ससुरा को नाम: </p>
                  <p>Father in Law's Name</p>
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="son-name-1"
                    id="son-name-1"
                    className="form-input"
                    defaultValue={props.data?.families[0]?.father_in_law_name}
                    readOnly
                  />
                  <input
                    type="text"
                    name="son-name-2"
                    id="son-name-2"
                    className="form-input"
                    readOnly
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="son-name-3"
                    id="son-name-3"
                    className="form-input"
                    readOnly
                  />
                  <input
                    type="text"
                    name="son-name-4"
                    id="son-name-4"
                    className="form-input"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3 mb-2">
        <div className="col">
          <h4>पेशागत विवरण (Details of Occupation)</h4>
        </div>
      </div>
      <div className="form-row radio-block">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className=" col br-1 mr-3">
                  <p> पेशा </p>
                  <p>Occupation:</p>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation"
                    id="public-private"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "service" ||
                      "public sector"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>सेवा </p>
                    <p>Service</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation"
                    id="public-private"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "private sector" ||
                      "public sector"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>सार्बजनिक/निजि क्षेत्र </p>
                    <p>Public / Private</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupatio1"
                    id="NGO-INGO"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "ngo"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>एन.जी.ओ /आइ.एन.जी.ओ </p>
                    <p>NGO/INGO</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation2"
                    id="legal-expert"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "legal expert"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>कानुनी बिज्ञ </p>
                    <p>Legal Expert</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation3"
                    id="expert"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "expert"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>विशेषज्ञ </p>
                    <p>Expert</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation4"
                    id="businessman"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "businessman"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>ब्यापारी </p>
                    <p>Businessman</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation5"
                    id="student"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "student"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>बिद्यार्थी </p>
                    <p>Student</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation6"
                    id="unemployed"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "unemployed"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>सेवा निवृत्त </p>
                    <p>Unemployed</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation7"
                    id="housewife"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "housewife"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>गृहिणी </p>
                    <p>Housewife</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="occupation8"
                    id="others"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.title === "others"
                        ? "checked"
                        : ""
                    }
                  />

                  <label htmlFor="female">
                    <p>अन्य </p>
                    <p>others</p>
                  </label>
                  <input type="text" id="other-occupation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row radio-block">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className=" col col-2 br-1 mr-3">
                  <p> व्यापारको प्रकार </p>
                  <p>Type of Business:</p>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="business-type"
                    id="production"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.type === "Production"
                        ? "checked"
                        : ""
                    }
                  />

                  <label htmlFor="female">
                    <p>उत्पादन </p>
                    <p>Production</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="business-type2"
                    id="others"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.type !== "Production"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>सेवा उन्मुख </p>
                    <p>Service Oriented</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="org-name">
                  <p>संस्थाको नाम</p>
                  <p>Organization name</p>
                </label>
                <input
                  className="form-input"
                  id="org-name"
                  type="text"
                  readOnly
                  defaultValue={props.data?.occupations[0]?.organization_name}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="org-address">
                  <p>संस्थाको ठेगाना </p>
                  <p>Organization Address</p>
                </label>
                <input
                  className="form-input"
                  id="org-address"
                  type="text"
                  readOnly
                  defaultValue={props.data?.occupations[0]?.address}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="org-deisgnation">
                  <p>पद</p>
                  <p>Deisgnation</p>
                </label>
                <input
                  className="form-input"
                  id="org-deisgnation"
                  type="text"
                  readOnly
                  defaultValue={props.data?.occupations[0]?.designation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row radio-block mb-2">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className=" col col-2 br-1 mr-3">
                  <p>आर्थिक विवरण </p>
                  <p>Financial Information</p>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="financial-info"
                    id="upto100k"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.financial_details ===
                      "UPTO RS. 1 LAKHS"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>गृहिणी </p>
                    <p>Upto Rs. 1,00,000</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="financial-info"
                    id="100k-200k"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.financial_details ===
                      "1 LAKHS TO 2 LAKHS"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>अन्य </p>
                    <p>From Rs. 1,00,001 to Rs. 2,00,000</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="financial-info"
                    id="200k-500k"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.financial_details ===
                      "2 LAKHS TO 5 LAKHS"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>अन्य </p>
                    <p>From Rs. 2,00,001 to Rs. 5,00,000</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="financial-info"
                    id="above500k"
                    readOnly
                    checked={
                      props.data?.occupations[0]?.financial_details ===
                      "5 LAKHS AND ABOVE"
                        ? "checked"
                        : ""
                    }
                  />
                  <label htmlFor="female">
                    <p>अन्य </p>
                    <p>Above Rs. 5,00,000</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-8 w-60">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>
                निछेप सदस्यले हितग्राहीको खातामा भएको स्वचालित रुपमा गराउने
                /नगराउने
              </p>
              <p>
                (The Depository participant should/should not automatically
                debit/credit beneficiary's account)
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="no-border">
                <input
                  type="radio"
                  name="depository-participant"
                  id="depository-participant-yes"
                  readOnly
                  checked={
                    props.data?.accounts[0]?.renew_account_automatically ===
                    "yes"
                      ? "checked"
                      : ""
                  }
                />
                <label htmlFor="depository-participant-yes">
                  <p>गराउने </p>
                  <p>Yes</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="no-border">
                <input
                  type="radio"
                  name="depository-participant"
                  id="depository-participant-no"
                  readOnly
                  checked={
                    props.data?.accounts[0]?.renew_account_automatically ===
                    "no"
                      ? "checked"
                      : ""
                  }
                />
                <label htmlFor="depository-participant-no">
                  <p>नगराउने </p>
                  <p>No</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row radio-block">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className=" col col-2 br-1 mr-3">
                  <p> खाताको विवरण प्राप्त गर्ने </p>
                  <p>(Account Statement to be received)</p>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="account-statement"
                    id="account-statement-daily"
                  />
                  <label htmlFor="account-statement-daily">
                    <p>दैनिक </p>
                    <p>Daily</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="account-statement"
                    id="account-statement-weekly"
                  />
                  <label htmlFor="account-statement-weekly">
                    <p>साप्ताहिक </p>
                    <p>Weekly</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="account-statement"
                    id="account-statement-forthnightly"
                  />
                  <label htmlFor="account-statement-forthnightly">
                    <p>पाक्षिक </p>
                    <p>Forthnightly</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="account-statement"
                    id="account-statement-monthly"
                    checked="checked"
                  />
                  <label htmlFor="account-statement-monthly">
                    <p>मासिक</p>
                    <p>Monthly</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row my-2">
        <div className="col">
          <p>
            म / हामीले निक्षेप सदस्य र हितग्राही करारमा, प्रचलित ऐन , नियम ,
            विनिमय र सो मा भएको संशोधन मान्न मन्जुर गर्दछु/ गर्दछौँ। माथि
            उल्लेखित बिबिरण सत्य तथ्य रहेको र सो विवरणमा कुनै फरक परे कानुन
            बमोजिम सहुँला, बुझुला र हितग्राही खाता रद गर्न मन्जुर गर्दछु
            /गर्दछौं।
          </p>
        </div>
      </div>
      <div className="form-row my-2">
        <div className="col">
          <p>
            I/ We shall accept to the terms and conditions relating to the
            agreement between Depository Participants and Beneficial Owner,
            prevalent act, regulations, bylaws and any amendments on it. I/we
            hereby acknowledge that the above disclosed details are true. I
            further hereby consent to borne any legal actions in case any false
            disclosure of information related to me/us and the Depository
            Participants reserve right to close my account. All disputes are
            subject to the jurisdiction of courts in Kathmandu, Nepal.
          </p>
        </div>
      </div>
      <div className="form-row my-3">
        <div className="col-6">
          <div className="input-group no-border mb-3 ">
            <label htmlFor="applicant-name">
              <p>निवेदकको नाम </p>
              <p>Applicant's Name</p>
            </label>
            <input
              type="text"
              className="form-input bottom-border fg-1"
              id="applicant-name"
              readOnly
              defaultValue={props.data?.full_name}
            />
          </div>
          <div className="input-group no-border align-items-center my-3">
            <label htmlFor="signature">
              <p>हस्ताछ्यर</p>
              <p>Signature</p>
            </label>
            <div className="image-wrapper fg-1">
              <div className="image-container signature-box">
                <img
                  src={
                    props.guardian !== false
                      ? `${process.env.REACT_APP_BASE_URL}/img/` +
                        props.data.email +
                        "/guardian/" +
                        props.guardian?.signature_name
                      : `${process.env.REACT_APP_BASE_URL}/img/` +
                        props.data.email +
                        "/" +
                        props.data.documents[0]?.signature_name
                  }
                  style={{ height: "100px", width: "300px" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <div className="thumb-print">
            <p>Thumb Print</p>
            <div className="thumb-print-container">
              <div className="left">
                <div className="caption">
                  <p>Right</p>
                </div>
                <div className="image-container">
                  <img
                    src={
                      props.guardian !== false
                        ? `${process.env.REACT_APP_BASE_URL}/img/` +
                          props.data.email +
                          "/guardian/" +
                          props.guardian?.signature_name
                        : `${process.env.REACT_APP_BASE_URL}/img/` +
                          props.data.email +
                          "/" +
                          props.data.documents[0]?.thumb_left_name
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="right">
                <div className="caption">
                  <p>left</p>
                </div>
                <div className="image-container">
                  <img
                    src={
                      props.guardian !== false
                        ? `${process.env.REACT_APP_BASE_URL}/img/` +
                          props.data.email +
                          "/guardian/" +
                          props.guardian?.signature_name
                        : `${process.env.REACT_APP_BASE_URL}/img/` +
                          props.data.email +
                          "/" +
                          props.data.documents[0]?.thumb_right_name
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page2;
