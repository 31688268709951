import { Link } from "react-router-dom";
import welcome from "../Assets/images/vector-art-welcome.svg";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";

function Welcome() {
  return (
    <>
      <div className="client">
        <Header />
        <div className="main-content welcome pt-0">
          <div className="container-fluid">
            <div className="row align-items-center mt-4">
              <div className="col-md-6 order-md-1 order-sm-1">
                <div className=" welcome-image-container">
                  <img src={welcome} alt="" style={{ height: "77vh" }} />
                </div>
              </div>
              <div className="col-md-6 order-md-0 order-sm-0">
                <div className="content-container welcome-text-box">
                  <p className="small">
                    Join <span className="red">15 Lakhs+</span> traders and
                    investors
                  </p>
                  <p className="big">
                    Online Demat Account in less than 60 seconds
                  </p>
                  <div className="button-container">
                    <Link to="/customers">
                      <button
                        type="button"
                        className="btn btn-sm btn-default"
                        style={{ fontSize: "16px" }}
                      >
                        Sign Up
                      </button>
                    </Link>
                  </div>
                  <p className="my-2">
                    Already have an account ?
                    <Link to="/login" className="text-primary">
                      <u>Sign in</u>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Welcome;
