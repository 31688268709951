import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import adbs from "ad-bs-converter";

import {
  Alert,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import FormDispatch from "../FormDispatch";
import districts from "../../../Assets/district-list-nepal";
import nationality from "../../../Assets/nationality";

const PersonalInfo = ({ data }) => {
  const formDispatch = useContext(FormDispatch);
  const [prevDate, setPrevDate] = useState();
  const [prevIssueDate, setPrevIssueDate] = useState();

  const initialState = {
    fullName: {
      name: "fullName",
      value: data?.full_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    mobile: {
      name: "mobile",
      value: data?.mobile,
      hasErrors: false,
      message: "",
      touched: false,
    },
    email: {
      name: "email",
      value: data?.email,
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobBs: {
      name: "dobBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobAd: {
      name: "dobAd",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dobFormatted: "",
    },
    gender: {
      name: "gender",
      value: data?.personal_information[0]?.gender,
      hasErrors: false,
      message: "",
      touched: false,
    },
    maritalStatus: {
      name: "maritalStatus",
      value: data?.personal_information[0]?.marital_status,
      hasErrors: false,
      message: "",
      touched: false,
    },
    accountType: {
      name: "accountType",
      value: data?.accounts[0]?.type,
      hasErrors: false,
      message: "",
      touched: false,
    },
    nationality: {
      name: "nationality",
      value: data?.personal_information[0]?.nationality,
      hasErrors: false,
      message: "",
      touched: false,
    },
    typeOfId: {
      name: "typeOfId",
      value: data?.personal_information[0]?.identity_card_type,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idNo: {
      name: "idNo",
      value: data?.personal_information[0]?.identity_card_number,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDistrict: {
      name: "idIssueDistrict",
      value: data?.personal_information[0]?.identity_card_issue_district,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDateBs: {
      name: "idIssueDateBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDate: {
      name: "idIssueDate",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dateFormatted: "",
    },
    pan: {
      name: "pan",
      value: data?.personal_information[0]?.pan_number,
      hasErrors: false,
      message: "",
      touched: false,
      checkCount: 0,
      checkingNow: false,
    },
    checkCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
            draft[key].value = action.value;
          }
        }
        return;
      case "fullNameChange":
        draft.fullName.touched = true;
        if (action.value.length > 50) {
          draft.fullName.hasErrors = true;
          draft.fullName.message = "Full name cannot exceed 50 characters";
          return;
        }
        draft.fullName.hasErrors = false;
        draft.fullName.value = action.value;
        return;
      case "validateFullName":
        if (action.value.length < 3) {
          draft.fullName.hasErrors = true;
          draft.fullName.message =
            "Full name must be alteast 3 chaacter's long";
        }
        return;
      case "mobileChange":
        draft.mobile.touched = true;
        draft.mobile.hasErrors = false;
        draft.mobile.value = action.value;
        return;
      case "validateMobile":
        if (action.value.length !== 10) {
          draft.mobile.hasErrors = true;
          draft.mobile.message = "Mobile number must be 10 chaacter's long";
        }
        return;
      case "emailChange":
        draft.email.touched = true;
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        return;
      case "validateEmail":
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            draft.email.value
          )
        ) {
          draft.email.hasErrors = true;
          draft.email.message = "You must provide a valid email address";
        }
        return;
      case "dobBsChange":
        draft.dobBs.touched = true;
        draft.dobBs.hasErrors = false;
        draft.dobBs.value = action.value;
        return;
      case "dobAdChange":
        console.log("dobadchanged reducer hit");
        draft.dobAd.touched = true;
        draft.dobAd.hasErrors = false;
        draft.dobAd.value = action.value;
        draft.dobAd.dobFormatted = formatDate(action.value);
        console.log(draft.dobAd.value.getFullYear());
        console.log(new Date().getFullYear() - draft.dobAd.value.getFullYear());
        const yearToday =
          new Date().getFullYear() - draft.dobAd.value.getFullYear();
        if (parseInt(yearToday) < 16) {
          console.log("year is less than 16 hyears onld");
          formDispatch({ type: "minorTrue", value: "true" });
        } else {
          formDispatch({ type: "minorFalse", value: "false" });
        }

        return;
      case "idIssueDateChange":
        console.log("id issue date change reducer hit");
        draft.idIssueDate.touched = true;
        draft.idIssueDate.hasErrors = false;
        draft.idIssueDate.value = action.value;
        draft.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "panChange":
        draft.pan.touched = true;
        draft.pan.hasErrors = false;
        draft.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number invalid";
          return;
        } else if (action.value.length > 9) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be more than 9 digits";
          return;
        }
        draft.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.pan.checkingNow = true;
        draft.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.pan.message = "PAN number available";
        return;
      case "panUnavailable":
        draft.pan.message = "PAN number already in use";
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    const date = new Date(data?.personal_information[0]?.dob);
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    setPrevDate(formattedNepaliDate);
  }, [data]);

  useEffect(() => {
    const date = new Date(
      data?.personal_information[0]?.identity_card_issue_date
    );
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    setPrevIssueDate(formattedNepaliDate);
  }, [data]);

  useEffect(() => {
    const dob = new Date(data?.personal_information[0]?.dob);
    const idIssue = new Date(
      data?.personal_information[0]?.identity_card_issue_date
    );

    dispatch({
      type: "dobBsChange",
      value: prevDate,
    });
    dispatch({
      type: "dobAdChange",
      value: dob,
    });
    dispatch({
      type: "idIssueDateChange",
      value: idIssue,
    });
    dispatch({
      type: "inputChange",
      value: prevIssueDate,
    });
  }, [prevDate, prevIssueDate]);

  const handleDate = ({ bsDate, adDate }) => {
    dispatch({ type: "dobBsChange", value: bsDate, field: "dobBs" });
    dispatch({ type: "dobAdChange", value: new Date(adDate), field: "dobAd" });
  };

  function handleAdDate(date) {
    dispatch({ type: "dobAdChange", value: date, field: "dobAd" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "dobBs",
    });
  }

  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({ type: "inputChange", value: bsDate, field: "idIssueDateBs" });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    dispatch({ type: "idIssueDateChange", value: date, field: "idIssueDate" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }

  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          console.log(response.data);
          dispatch({ type: "panAvailable" });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }

    return () => ourRequest.cancel();
  }, [state.pan.checkCount]);

  useEffect(() => {
    if (state.submitCount.counter >= 1) {
      // console.log(state.dobAd.dobFormatted);
      console.log("ready to submit the personal form");
      formDispatch({
        type: "savePersonalInfo",
        value: {
          id: data.personal_information[0]?.id,
          dob: state.dobAd.dobFormatted,
          gender: state.gender.value,
          marital_status: state.maritalStatus.value,
          nationality: state.nationality.value,
          pan_number: state.pan.value,
          identity_card_type: state.typeOfId.value,
          identity_card_number: state.idNo.value,
          identity_card_issue_district: state.idIssueDistrict.value,
          identity_card_issue_date: state.idIssueDate.dateFormatted,
        },
      });
      formDispatch({
        type: "customerUpdate",
        value: {
          id: data?.id,
          registration_number: data?.registration_number,
          email: state.email.value,
          mobile: state.mobile.value,
        },
      });
      formDispatch({ type: "saveFullName", value: state.fullName.value });
    }
  }, [state.submitCount.counter]);

  useEffect(() => {
    dispatch({ type: "checkCount" });
  }, [
    state.fullName,
    state.mobile,
    state.email,
    state.dobBs,
    state.dobAd,
    state.gender,
    state.maritalStatus,
    state.accountType,
    state.nationality,
    state.typeOfId,
    state.idNo,
    state.idIssueDistrict,
    state.idIssueDateBs,
    state.idIssueDate,
    state.pan,
  ]);

  const [fullnameReject, setFullnameReject] = useState(false);
  const [mobileReject, setMobileReject] = useState(false);
  const [emailReject, setEmailReject] = useState(false);
  const [dobReject, setDOBReject] = useState(false);
  const [genderReject, setGenderReject] = useState(false);
  const [maritalStatusReject, setMaritalStatusReject] = useState(false);
  const [accountTypeReject, setAccountTypeReject] = useState(false);
  const [nationalityReject, setNationalityReject] = useState(false);
  const [cardTypeReject, setCardTypeReject] = useState(false);
  const [cardNumberReject, setCardNumberReject] = useState(false);
  const [cardIssueDistrictReject, setCardIssueDistrictReject] = useState(false);
  const [cardIssueDateReject, setCardIssueDateReject] = useState(false);
  const [panReject, setPANReject] = useState(false);

  useEffect(() => {
    data.reasons.map((item, i) => {
      if (item.type === "personal_information") {
        if (item.title === "fullname") {
          setFullnameReject(true);
        }
        if (item.title === "mobile") {
          setMobileReject(true);
        }
        if (item.title === "email") {
          setEmailReject(true);
        }
        if (item.title === "dob") {
          setDOBReject(true);
        }
        if (item.title === "gender") {
          setGenderReject(true);
        }
        if (item.title === "marital_status") {
          setMaritalStatusReject(true);
        }
        if (item.title === "account_type") {
          setAccountTypeReject(true);
        }
        if (item.title === "nationality") {
          setNationalityReject(true);
        }
        if (item.title === "id_card_type") {
          setCardTypeReject(true);
        }
        if (item.title === "id_card_number") {
          setCardNumberReject(true);
        }
        if (item.title === "id_card_issue_district") {
          setCardIssueDistrictReject(true);
        }
        if (item.title === "id_card_issue_date") {
          setCardIssueDateReject(true);
        }
        if (item.title === "pan") {
          setPANReject(true);
        }
      }
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-fullname ">
                Full Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  fullnameReject || state.fullName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-fullname"
                placeholder="Your Full Name"
                type="text"
                onBlur={(e) =>
                  dispatch({
                    type: "validateFullName",
                    value: e.target.value,
                    field: "fullName",
                  })
                }
                onChange={(e) =>
                  dispatch({
                    type: "fullNameChange",
                    value: e.target.value,
                    field: "fullName",
                  })
                }
                value={state.fullName.value}
              />
              {state.fullName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.fullName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-mobile">
                Mobile
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  mobileReject || state.mobile.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-mobile"
                placeholder="Your 10 digit phone number"
                type="number"
                value={state.mobile.value}
                onBlur={(e) =>
                  dispatch({
                    type: "validateMobile",
                    value: e.target.value,
                    field: "monile",
                  })
                }
                onChange={(e) =>
                  dispatch({
                    type: "mobileChange",
                    value: e.target.value,
                    field: "mobilelName",
                  })
                }
                readOnly
              />
              {state.mobile.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.mobile.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  emailReject || state.email.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-email"
                placeholder="Your email address"
                type="text"
                value={state.email.value}
                onBlur={(e) =>
                  dispatch({
                    type: "validateEmail",
                    value: e.target.value,
                    field: "email",
                  })
                }
                onChange={(e) =>
                  dispatch({
                    type: "emailChange",
                    value: e.target.value,
                    field: "email",
                  })
                }
                readOnly
              />
              {state.email.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.email.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-bs">
                Date of Birth (B.S.)
              </label>
              <span className="text-danger">*</span>
              <section className="diy-calendar-section">
                <input
                  value={state.dobBs.value}
                  type="text"
                  id="personal_information-date_of_birth_bs"
                  className="form-control-alternative nepaliDate calendar"
                />
                <div className="diy-calendar">
                  <Calendar
                    className="form-control-alternative nepaliDate calendar"
                    onChange={handleDate}
                    theme="deepdark"
                  />
                </div>
              </section>
              {state.dobBs.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.dobBs.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-ad">
                Date of Birth (A.D.)
              </label>
              <span className="text-danger">*</span>
              {/* <Input
                className={`form-control-alternative englishDate calendar ${
                  dobReject || state.dobAd.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                value={state.dobAd.value}
                onChange={handleAdDate}
                disabled
              /> */}
              <DatePicker
                className={`form-control-alternative englishDate calendar ${
                  dobReject || state.dobAd.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                selected={state.dobAd.value}
                onChange={handleAdDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {state.dobAd.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.dobAd.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-gender">
                Gender
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  genderReject || state.gender.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                value={
                  state.gender.value === "decline to answer"
                    ? 1
                    : state.gender.value === "male"
                    ? 2
                    : state.gender.value === "female"
                    ? 3
                    : 1
                }
                id="input-gender"
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "gender",
                  })
                }
              >
                <option disabled>Select your gender</option>
                <option value="1">Decline To Answer</option>
                <option value="2">Male</option>
                <option value="3">Female</option>
              </select>
              {state.gender.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.gender.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-marital-status"
              >
                Marital Status
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  maritalStatusReject || state.maritalStatus.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-marital-status"
                value={state.maritalStatus.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "maritalStatus",
                  })
                }
              >
                <option disabled>Select your martial status</option>
                <option value="1">Single</option>
                <option value="2">Married</option>
              </select>
              {state.maritalStatus.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.maritalStatus.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-account-type"
              >
                Account Type
              </label>
              <span className="text-danger">*</span>
              <select
                value={state.accountType.value}
                className={`form-control-alternative calendar form-control ${
                  accountTypeReject || state.accountType.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-account-type"
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "accountType",
                  })
                }
              >
                <option disabled>Select your account type</option>
                <option value="1">Nepalese</option>
                <option value="2">NRN - Non Residental Nepali</option>
                <option value="3">Foreign</option>
              </select>
              {state.accountType.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.accountType.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nationality">
                Nationality
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  nationalityReject ||
                  (state.nationality.hasErrors
                    ? "border-danger is-invalid"
                    : "")
                }`}
                id="input-nationality"
                value={state.nationality.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "nationality",
                  })
                }
              >
                {nationality.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {state.nationality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.nationality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-type_of_identity_card"
              >
                Type of Identitiy card
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  cardTypeReject || state.typeOfId.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-type_of_identity_card"
                value={state.typeOfId.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "typeOfId",
                  })
                }
              >
                <option disabled>Choose your type of ID</option>
                <option value="1">Citizenship</option>
                <option value="2">PAN</option>
              </select>
              {state.typeOfId.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.typeOfId.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-identification_number"
              >
                Identification Number
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative calendar form-control ${
                  cardNumberReject || state.typeOfId.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-identification_number"
                value={state.idNo.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "idNo",
                  })
                }
              />
              {state.typeOfId.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.typeOfId.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-id_issued_district"
              >
                ID Issued District
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  cardIssueDistrictReject || state.idIssueDistrict.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-id_issued_district"
                value={state.idIssueDistrict.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "idIssueDistrict",
                  })
                }
              >
                {districts.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {state.idIssueDistrict.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.idIssueDistrict.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-id_issue_date_bs"
              >
                ID issue Date(B.S)
              </label>
              <span className="text-danger">*</span>
              <section className="diy-calendar-section">
                <input
                  value={state.idIssueDateBs.value}
                  type="text"
                  className="form-control-alternative calendar form-control"
                />
                <div className="diy-calendar">
                  <Calendar
                    className="form-control-alternative calendar form-control"
                    onChange={handleIdIssueDateBs}
                    theme="deepdark"
                  />
                </div>
              </section>
              {state.idIssueDateBs.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.idIssueDateBs.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-id_issued_date_ad"
              >
                ID Issue Date(A.D)
              </label>
              <span className="text-danger">*</span>
              {/* <Input
                className={`form-control-alternative calendar form-control ${
                  cardIssueDateReject || state.idIssueDate.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-id_issued_date_ad"
                value={state.idIssueDate.value}
                onChange={handleIdIssueDate}
                disabled
              /> */}
              <DatePicker
                className={`form-control-alternative calendar form-control ${
                  cardIssueDateReject || state.idIssueDate.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                selected={state.idIssueDate.value}
                onChange={handleIdIssueDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {state.idIssueDate.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.idIssueDate.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-id-pan">
                Permanent Account Number(PAN)
              </label>
              <Input
                className={`form-control-alternative ${
                  panReject || state.pan.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-id-pan"
                placeholder="Enter Your Permanent Account Number"
                value={state.pan.value}
                onBlur={(e) =>
                  dispatch({
                    type: "validatePAN",
                    value: e.target.value,
                  })
                }
                onChange={(e) =>
                  dispatch({
                    type: "panChange",
                    value: e.target.value,
                    field: "pan",
                  })
                }
              />
              {state.pan.hasErrors && (
                <Alert className="alert-danger mt-4">{state.pan.message}</Alert>
              )}
              {state.pan.checkingNow && (
                <Alert
                  className={`mt-4 ${
                    state.pan.hasErrors ? "alert-danger" : "alert-success"
                  }`}
                >
                  {state.pan.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Container>
  );
};

export default PersonalInfo;
