import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { AlertTriangle } from "./Layout/Icons";
function PageNotFound() {
  return (
    <>
      <div
        className="admin d-flex justify-content-center align-items-center text-center"
        style={{ height: "100vh" }}
      >
        <span style={{ animation: "octocat-wave 560ms ease-in-out" }}>
          <AlertTriangle props="text-primary h-100 w-100" />
          <h1 className="text-default">Page not found</h1>
          <Link to="/" className="text-primary">
            <Button className="btn-default btn-md">Home</Button>
          </Link>
        </span>
      </div>
    </>
  );
}

export default PageNotFound;
