import React, { useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { Info } from "../../Layout/Icons";
import FormDispatch from "../FormDispatch";
function Occupation(props) {
  const formDispatch = useContext(FormDispatch);
  const initialState = {
    errorCount: 0,
    submitCount: 0,
    backCount: 0,
    formData: {
      occupation: {
        name: "occupation",
        value: "",
        hasErrors: false,
        message: "",
      },
      businessType: {
        name: "businessType",
        value: "",
        hasErrors: false,
        message: "",
      },
      organizationName: {
        name: "organizationName",
        value: "",
        hasErrors: false,
        message: "",
      },
      organizationAddress: {
        name: "organizationAddress",
        value: "",
        hasErrors: false,
        message: "",
      },
      designation: {
        name: "designation",
        value: "",
        hasErrors: false,
        message: "",
      },
      incomeRange: {
        name: "incomeRage",
        value: "",
        hasErrors: false,
        message: "",
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          console.log("the reducer hit for the occupation page");
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
          }
        }
        return;
      case "setIncomeRange":
        draft.formData.incomeRange.value = action.value;
        draft.formData.incomeRange.hasErrors = false;
        return;
      case "validateForm":
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].value.length) {
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field cannot be blank";
          }
        }
        for (const key in draft.formData) {
          if (draft.formData[key].hasErrors) {
            draft.errorCount++;
          }
        }
        if (draft.formData.organizationName.value === "") {
          draft.errorCount--;
          draft.formData.organizationName.hasErrors = false;
        }
        if (draft.formData.organizationAddress.value === "") {
          draft.errorCount--;
          draft.formData.organizationAddress.hasErrors = false;
        }
        if (draft.formData.designation.value === "") {
          draft.errorCount--;
          draft.formData.designation.hasErrors = false;
        }
        // console.log("error count is ", errorCount)
        if (!draft.errorCount) {
          console.log("there are no errors");
          draft.submitCount++;
        }
        return;
      case "setBackCount":
        draft.backCount++;
        return;
      case "default":
        return;
    }
  }

  function handleSubmit() {
    dispatch({ type: "validateForm" });
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount) {
      console.log("form can be submitted for the occuption blcok");
      formDispatch({
        type: "saveOccupation",
        value: {
          title: state.formData.occupation.value,
          type: state.formData.businessType.value,
          organization_name: state.formData.organizationName.value,
          address: state.formData.organizationAddress.value,
          designation: state.formData.designation.value,
          financial_details: state.formData.incomeRange.value,
        },
      });
      // props.history.push("/customers/create/documents");
      formDispatch({ type: "activeTabs", value: "document" });
    }
  }, [state.submitCount]);

  function handleBackCount(e) {
    e.preventDefault();
    dispatch({ type: "setBackCount" });
  }

  useEffect(() => {
    if (state.backCount > 0) {
      formDispatch({ type: "activeTabs", value: "account" });
    }
  }, [state.backCount]);

  return (
    <form action="" className="form position-relative">
      <div className="form-container personal-info">
        <div className="text-container">
          <h2>Occupation details</h2>
          <span
            className="position-absolute end-0 top-0"
            onClick={() => {
              formDispatch({ type: "setInfo" });
            }}
          >
            <Info />
          </span>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.occupation.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="occupation-occupation">Occupation / पेशा</label>
              <span className="text-danger">*</span>
              <select
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "occupation",
                  })
                }
                name=""
                id="occupation-occupation"
                className="form-input"
              >
                <option value="initial" selected disabled>
                  Select your occupation
                </option>
                <option value="Business">Business</option>
                <option value="Farmer">Farmer</option>
                <option value="Government Service">Government Service</option>
                <option value="Housewife">Housewife</option>
                <option value="Others">Others</option>
                <option value="Professional">Professional</option>
                <option value="Public Sector">Public Sector</option>
                <option value="Private Sector">Private Sector</option>
                <option value="Retired">Retired</option>
                <option value="Service">Service</option>
                <option value="Student">Student</option>
              </select>
              {state.formData.occupation.hasErrors && (
                <span className="error">
                  {state.formData.occupation.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.businessType.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="occupation-type_of_business">
                Type of Business / व्यापारको प्रकार
              </label>
              <span className="text-danger">*</span>
              <select
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "businessType",
                  })
                }
                name=""
                id="occupation-type_of_business"
                className="form-input"
              >
                <option value="initial" selected disabled>
                  Select a business
                </option>
                <option value="Production">Production</option>
                <option value="Service Oriented">Service Oriented</option>
              </select>
              {state.formData.businessType.hasErrors && (
                <span className="error">
                  {state.formData.businessType.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.organizationName.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="occupation-organization_name">
                Organization's Name / संस्थाको नाम
              </label>
              {/* <span className="text-danger">*</span> */}
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "organizationName",
                  })
                }
                type="text"
                id="occupation-organization_name"
                className="form-input"
                placeholder="Enter Your Organization's Name"
              />
              {state.formData.organizationName.hasErrors && (
                <span className="error">
                  {state.formData.organizationName.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.organizationAddress.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="occupation-organization_address">
                Address / संस्थाको ठेगाना
              </label>
              {/* <span className="text-danger">*</span> */}
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "organizationAddress",
                  })
                }
                type="text"
                id="occupation-organization_address"
                className="form-input"
                placeholder="Enter Your Organization's Address"
              />
              {state.formData.organizationAddress.hasErrors && (
                <span className="error">
                  {state.formData.organizationAddress.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.designation.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="occupation-designation">Designation / पद</label>
              {/* <span className="text-danger">*</span> */}
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "designation",
                  })
                }
                type="text"
                id="occupation-designation"
                className="form-input"
                placeholder="Enter Your designation in organization"
              />
              {state.formData.designation.hasErrors && (
                <span className="error">
                  {state.formData.designation.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <h3>Select your Financial Details</h3>
        <div className="row has-radio">
          <div className="col-md-4">
            <div className={`input-group `}>
              <input
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "1" })
                }
                type="radio"
                name="income_range"
                id="occupation-financial_details1"
                className="form-input"
              />
              <label
                htmlFor="occupation-financial_details1"
                style={{ margin: "0" }}
              >
                Upto Rs. 1,00,000
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className={`input-group`}>
              <input
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "2" })
                }
                type="radio"
                name="income_range"
                id="occupation-financial_details2"
                className="form-input"
              />
              <label
                htmlFor="occupation-financial_details2"
                style={{ margin: "0" }}
              >
                From Rs. 1,00,001 to Rs. 2,00,000
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className={`input-group`}>
              <input
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "3" })
                }
                type="radio"
                name="income_range"
                id="occupation-financial_details3"
                className="form-input"
              />
              <label
                htmlFor="occupation-financial_details3"
                style={{ margin: "0" }}
              >
                From Rs. 2,00,001 to Rs. 5,00,000
              </label>
            </div>
          </div>
        </div>
        <div className="row has-radio">
          <div className="col-md-4">
            <div className={`input-group`}>
              <input
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "4" })
                }
                type="radio"
                name="income_range"
                id="occupation-financial_details4"
                className="form-input"
              />
              <label
                htmlFor="occupation-financial_details4"
                style={{ margin: "0" }}
              >
                Above Rs. 5,00,000
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className={`input-group`}>
              <input
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "5" })
                }
                type="radio"
                name="income_range"
                id="occupation-financial_details5"
                className="form-input"
              />
              <label
                htmlFor="occupation-financial_details5"
                style={{ margin: "0" }}
              >
                Not Available
              </label>
            </div>
          </div>

          {state.formData.incomeRange.hasErrors && (
            <p className="text-danger">{state.formData.incomeRange.message}</p>
          )}
        </div>

        <span
          className="btn-transparent mr-2 "
          // to="/customers/create/account"
          onClick={handleBackCount}
        >
          <span>← </span>
          Previous Page
        </span>
        <span onClick={handleSubmit} className="btn-blue">
          Next Page
          <span> →</span>
        </span>
      </div>
    </form>
  );
}

export default withRouter(Occupation);
