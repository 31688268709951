import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";

// reactstrap components
import {
  Row,
  Col,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Input,
  Alert,
} from "reactstrap";
import StateContext from "../../../../../StateContext";
import pagination from "../../../../Handler/Pagination";
import { Next, Prev, Verify } from "../../../../Layout/Icons";

const Batch = () => {
  const appState = useContext(StateContext);
  const [newData, setNewData] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntries, setShowEntries] = useState(10);
  const [importFile, setImportFile] = useState(false);

  const initialState = {
    batchData: [],
    count: 0,
    file: {
      value: "",
      hasErrors: false,
      message: "",
      file: null,
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadBatch":
        draft.batchData = action.value;
        return;
      case "fileChange":
        if (action.value == null) {
          draft.file.hasErrors = true;
        } else {
          draft.file.hasErrors = false;
          draft.file.value = action.value;
          draft.touched = true;
        }
      case "changeCount":
        draft.count++;
        return;
      case "importFile":
        draft.file.file = action.value;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchBatch() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/batches`,
          {
            cancelToken: ourRequest.token,
          }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadBatch", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching Batch");
      }
    }
    fetchBatch();
    return () => ourRequest.cancel();
  }, []);

  // Filter
  function search(e) {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
    setSearchTerm(lowerCase);
  }

  const filteredData = state.batchData?.filter((el) => {
    //if no input the return the original
    if (searchTerm === "") {
      return el;
    }
    // return the item which contains the user input
    else {
      return el.filename.toLowerCase().includes(searchTerm);
    }
  });

  // Paginate
  useEffect(() => {
    setNewData(pagination({ filteredData, showEntries }));
  }, [state.batchData, searchTerm, showEntries]);
  const nextPage = () => {
    setPage((oldPage) => {
      let nextPage = oldPage + 1;
      if (nextPage > newData.length - 1) {
        nextPage = 0;
      }
      return nextPage;
    });
  };

  const prevPage = () => {
    setPage((oldPage) => {
      let prevPage = oldPage - 1;
      if (prevPage < 0) {
        prevPage = newData.length - 1;
      }
      return prevPage;
    });
  };

  const handlePage = (index) => {
    setPage(index);
  };

  // Sort Entries
  const handleEntries = (e) => {
    e.preventDefault();
    setShowEntries(e.target.value);
  };

  useEffect(() => {
    if (state.count > 0) {
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.user.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      const ourRequest = axios.CancelToken.source();
      let formData = new FormData();
      formData.append("file", state.file.value);
      async function fetchFile() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/import`,
            formData,
            {
              cancelToken: ourRequest.token,
            }
          );
          console.log(response);
          window.location.reload();
          setImportFile(true);
        } catch (e) {
          console.log(e, "there was an error import File");
          setImportFile(false);
        }
      }
      fetchFile();
      return () => ourRequest.cancel();
    }
  }, [state.count]);

  const handleFile = (e) => {
    dispatch({
      type: "changeCount",
    });
    dispatch({
      type: "importFile",
      value: e.target.files[0],
    });
    const reader = new FileReader();
    reader?.readAsDataURL(e.target.files[0]);
    reader?.addEventListener("load", () => {
      dispatch({ type: "fileChange", value: reader.result });
    });
  };

  return (
    <>
      <hr className="my-5" />
      <Row className="mb-4">
        <Col lg="6">
          <div className="d-flex align-items-baseline">
            <label htmlFor="handleEntries" className="text-light mr-2">
              Show
            </label>
            <select
              name="handleEntries"
              id=""
              className="form-control-alternative calendar"
              style={{ width: "5rem", fontSize: "1.2rem" }}
              onChange={handleEntries}
            >
              <option value="10" selected>
                10
              </option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="text-light ml-2">entries</span>
            {/* {importFile && (
              <Alert>
                <div className="d-flex align-items-center">
                  <Verify props={"mr-2"} />
                  <span>File Uploaded Successfully</span>
                </div>
              </Alert>
            )} */}
          </div>
        </Col>
        <Col lg="6">
          <form className="d-flex justify-content-end align-items-center">
            <label htmlFor="search" className="text-light">
              Search: &nbsp;&nbsp;
            </label>
            <input
              type="text"
              name="search"
              id="search"
              className="form-control"
              style={{ width: "20rem" }}
              onChange={search}
            />
          </form>
        </Col>
      </Row>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">File Name</th>
            <th scope="col">TOTAL FAILED</th>
            <th scope="col">TOTAL SUCCESS</th>
            <th scope="col">NUMBER OF CUSTOMER</th>
            <th scope="col">ACTION</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {newData[page] ? (
            newData[page]?.reverse()?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.filename}</td>
                  <td>{item.total_failed}</td>
                  <td>{item.total_success}</td>
                  <td>{item.number_of_customer}</td>
                  <td className="text-center d-flex align-items-center">
                    <Input
                      type="file"
                      name=""
                      id=""
                      className={`btn-default btn-lg `}
                      onChange={handleFile}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10} className="text-center">
                No Data Found ...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CardFooter className="py-4">
        <Row>
          <Col lg="2">
            <span className="text-light ml-2">
              Showing 1 to {showEntries} of {filteredData?.length} entries
            </span>
          </Col>
          <Col lg="10">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem>
                  <PaginationLink onClick={prevPage}>
                    <Prev className="icon" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {newData.length > 3 ? (
                  <>
                    <PaginationItem
                      className={` ${0 === page ? "active" : null}`}
                    >
                      <PaginationLink onClick={() => handlePage(0)}>
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={` ${1 === page ? "active" : null}`}
                    >
                      <PaginationLink onClick={() => handlePage(1)}>
                        2
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={` ${2 === page ? "active" : null}`}
                    >
                      <PaginationLink onClick={() => handlePage(2)}>
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink>. . .</PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={` ${
                        newData.length - 1 === page ? "active" : null
                      }`}
                    >
                      <PaginationLink
                        onClick={() => handlePage(newData.length - 1)}
                      >
                        {newData.length - 1}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                ) : (
                  <>
                    {newData.map((item, index) => {
                      return (
                        <PaginationItem
                          key={index}
                          className={` ${index === page ? "active" : null}`}
                        >
                          <PaginationLink onClick={() => handlePage(index)}>
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                  </>
                )}

                <PaginationItem>
                  <PaginationLink onClick={nextPage}>
                    <Next className="icon" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Col>
        </Row>
      </CardFooter>
    </>
  );
};

export default Batch;
