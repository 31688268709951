import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import {
  Alert,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import FormDispatch from "../FormDispatch";
import occupation from "../../../Assets/occupation";

const Occupation = ({ data }) => {
  const formDispatch = useContext(FormDispatch);
  const initialState = {
    errorCount: 0,
    submitCount: 0,
    formData: {
      occupation: {
        name: "occupation",
        value: data?.occupations[0]?.title,
        hasErrors: false,
        message: "",
      },
      businessType: {
        name: "businessType",
        value: data?.occupations[0]?.type,
        hasErrors: false,
        message: "",
      },
      organizationName: {
        name: "organizationName",
        value: data?.occupations[0]?.organization_name,
        hasErrors: false,
        message: "",
      },
      organizationAddress: {
        name: "organizationAddress",
        value: data?.occupations[0]?.address,
        hasErrors: false,
        message: "",
      },
      designation: {
        name: "designation",
        value: data?.occupations[0]?.designation,
        hasErrors: false,
        message: "",
      },
      incomeRange: {
        name: "incomeRage",
        value: data?.occupations[0]?.financial_details,
        hasErrors: false,
        message: "",
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          console.log("the reducer hit for the occupation page");
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
            if (action.value.trim() == "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "setIncomeRange":
        draft.formData.incomeRange.value = action.value;
        draft.formData.incomeRange.hasErrors = false;
        return;
      case "validateForm":
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key]?.value?.length) {
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field cannot be blank";
          }
        }
        for (const key in draft.formData) {
          if (draft.formData[key].hasErrors) {
            draft.errorCount++;
          }
        }
        if (draft.formData.organizationName.value === "") {
          draft.errorCount--;
          draft.formData.organizationName.hasErrors = false;
        }
        if (draft.formData.organizationAddress.value === "") {
          draft.errorCount--;
          draft.formData.organizationAddress.hasErrors = false;
        }
        if (draft.formData.designation.value === "") {
          draft.errorCount--;
          draft.formData.designation.hasErrors = false;
        }
        // console.log("error count is ", errorCount)
        if (!draft.errorCount) {
          console.log("there are no errors");
          draft.submitCount++;
        }
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount) {
      console.log("form can be submitted for the occuption blcok");
      formDispatch({
        type: "saveOccupation",
        value: {
          id: data.occupations[0]?.id,
          title: state.formData.occupation.value,
          type: state.formData.businessType.value,
          organization_name: state.formData.organizationName.value,
          address: state.formData.organizationAddress.value,
          designation: state.formData.designation.value,
          financial_details: state.formData.incomeRange.value,
        },
      });
    }
  }, [state.submitCount]);

  useEffect(() => {
    dispatch({ type: "validateForm" });
  }, [state.formData]);

  const [titleReject, setTitleReject] = useState(false);
  const [typeReject, setTypeReject] = useState(false);
  const [organizationNameReject, setOrganizationNameReject] = useState(false);
  const [addressReject, setAddressReject] = useState(false);
  const [designationReject, setDesignationReject] = useState(false);
  const [financialDetailReject, setFinancialDetailReject] = useState(false);

  useEffect(() => {
    data.reasons.map((item, i) => {
      if (item.type === "occupation") {
        if (item.title === "business_title") {
          setTitleReject(true);
        }
        if (item.title === "business_type") {
          setTypeReject(true);
        }
        if (item.title === "organization_name") {
          setOrganizationNameReject(true);
        }
        if (item.title === "organization_address") {
          setAddressReject(true);
        }
        if (item.title === "designation") {
          setDesignationReject(true);
        }
        if (item.title === "income_range") {
          setFinancialDetailReject(true);
        }
      }
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-occupation">
                Occupation
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  titleReject || state.formData.occupation.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="input-occupation"
                type="text"
                value={state.formData.occupation.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "occupation",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "occupation",
                  })
                }
              >
                {occupation.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {state.formData.occupation.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.occupation.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="occupation-type_of_business"
              >
                Type of Business
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  typeReject || state.formData.businessType.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="occupation-type_of_business"
                value={state.formData.businessType.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "businessType",
                  })
                }
              >
                <option value="initial">Select a business</option>
                <option value="Production">Production</option>
                <option value="Service Oriented">Service Oriented</option>
              </select>
              {state.formData.businessType.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.businessType.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="occupation-organization_name"
              >
                Organization's Name
              </label>
              {/* <span className="text-danger">*</span> */}
              <Input
                className={`form-control-alternative calendar form-control ${
                  organizationNameReject ||
                  state.formData.organizationName.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="occupation-organization_name"
                type="text"
                placeholder="Enter Your Organization's Name"
                value={state.formData.organizationName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "organizationName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "organizationName",
                  })
                }
              />
              {state.formData.organizationName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.organizationName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="occupation-organization_address"
              >
                Address
              </label>
              {/* <span className="text-danger">*</span> */}

              <Input
                className={`form-control-alternative calendar form-control ${
                  addressReject || state.formData.organizationAddress.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="occupation-organization_address"
                placeholder="Enter Your Organization's Address"
                value={state.formData.organizationAddress.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "organizationAddress",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "organizationAddress",
                  })
                }
              />
              {state.formData.organizationAddress.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.organizationAddress.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="occupation-designation"
              >
                Designation
              </label>
              {/* <span className="text-danger">*</span> */}
              <Input
                className={`form-control-alternative calendar form-control ${
                  designationReject || state.formData.designation.hasErrors
                    ? "border-danger is-invalid"
                    : ""
                }`}
                id="occupation-designation"
                type="text"
                placeholder="Enter Your designation in organization"
                value={state.formData.designation.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "designation",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "designation",
                  })
                }
              />
              {state.formData.designation.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.designation.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardBody
        className={` ${
          financialDetailReject ? "border-danger is-invalid" : ""
        }`}
      >
        <h3 className="text-primary">
          Select your Financial Details <span className="text-danger">*</span>
        </h3>
        <Row className="my-4">
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="occupation-financial_details1"
                type="radio"
                name="income_range"
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "1" })
                }
                defaultChecked={
                  data?.occupations[0]?.financial_details === "UPTO RS. 1 LAKHS"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="occupation-financial_details1"
                style={{ marginLeft: "3rem" }}
              >
                Upto Rs. 1,00,000
              </label>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="occupation-financial_details2"
                type="radio"
                name="income_range"
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "2" })
                }
                defaultChecked={
                  data?.occupations[0]?.financial_details ===
                  "1 LAKHS TO 2 LAKHS"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="occupation-financial_details2"
                style={{ marginLeft: "3rem" }}
              >
                From Rs. 1,00,001 to Rs. 2,00,000
              </label>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="occupation-financial_details3"
                type="radio"
                name="income_range"
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "3" })
                }
                defaultChecked={
                  data?.occupations[0]?.financial_details ===
                  "2 LAKHS TO 5 LAKHS"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="occupation-financial_details3"
                style={{ marginLeft: "3rem" }}
              >
                From Rs. 2,00,001 to Rs. 5,00,000
              </label>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="occupation-financial_details4"
                type="radio"
                name="income_range"
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "4" })
                }
                defaultChecked={
                  data?.occupations[0]?.financial_details ===
                  "5 LAKHS AND ABOVE"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="occupation-financial_details4"
                style={{ marginLeft: "3rem" }}
              >
                Above Rs. 5,00,000
              </label>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="occupation-financial_details5"
                type="radio"
                name="income_range"
                onChange={(e) =>
                  dispatch({ type: "setIncomeRange", value: "5" })
                }
                defaultChecked={
                  data?.occupations[0]?.financial_details === "NOT AVAILABLE"
                    ? "checked"
                    : ""
                }
              />
              <label
                className="form-control-label"
                htmlFor="occupation-financial_details5"
                style={{ marginLeft: "3rem" }}
              >
                Not Available
              </label>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Container>
  );
};

export default Occupation;
