import React, { useContext, useState, useEffect } from "react";
import { useImmerReducer } from "use-immer";

import DisplayPicture from "../../../Handler/Documents/DisplayPicture";
import ThumbPrintLeft from "../../../Handler/Documents/ThumbprintLeft";
import ThumbprintRight from "../../../Handler/Documents/ThumbprintRight";
import CitizenshipBack from "../../../Handler/Documents/CitizenshipBack";
import CitizenshipFront from "../../../Handler/Documents/CitizenshipFront";
import BirthCertificate from "../../../Handler/Documents/BirthCertificate";
import Signature from "../../../Handler/Documents/Signature";
import MapI from "../../../Handler/MapSearch";

import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

const Documents = () => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [errorCount, setErrorCount] = useState(0);

  const initialState = {
    errorCount: 0,
    sendCount: 0,
    documents: {
      displayPicture: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      thumbLeft: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      thumbRight: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      citizenshipBack: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      citizenshipFront: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      signature: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      birthCertificate: {
        value: "",
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "setDisplayPicture":
        draft.documents.displayPicture.hasErrors = false;
        draft.documents.displayPicture.value = action.value;
        return;
      case "setDisplayPictureFile":
        draft.documents.displayPicture.file = action.value;
        return;
      case "setThumbprintLeft":
        draft.documents.thumbLeft.hasErrors = false;
        draft.documents.thumbLeft.value = action.value;
        return;
      case "setThumbprintLeftFile":
        draft.documents.thumbLeft.file = action.value;
        return;
      case "setThumbprintRight":
        draft.documents.thumbRight.hasErrors = false;
        draft.documents.thumbRight.value = action.value;
        return;
      case "setThumbprintRightFile":
        draft.documents.thumbRight.file = action.value;
        return;

      case "setCitizenshipBack":
        draft.documents.citizenshipBack.hasErrors = false;
        draft.documents.citizenshipBack.value = action.value;
        return;
      case "setCitizenshipBackFile":
        draft.documents.citizenshipBack.file = action.value;
        return;
      case "setCitizenshipFront":
        draft.documents.citizenshipFront.hasErrors = false;
        draft.documents.citizenshipFront.value = action.value;
        return;
      case "setCitizenshipFrontFile":
        draft.documents.citizenshipFront.file = action.value;
        return;
      case "setSignature":
        draft.documents.signature.hasErrors = false;
        draft.documents.signature.value = action.value;
        return;
      case "setSignatureFile":
        draft.documents.signature.file = action.value;
        return;
      case "setBirthCertificate":
        draft.documents.birthCertificate.hasErrors = false;
        draft.documents.birthCertificate.value = action.value;
        return;
      case "setBirthCertificateFile":
        draft.documents.birthCertificate.file = action.value;
        return;
      case "validateForm":
        //check for errors
        draft.errorCount = 0;
        for (const key in draft.documents) {
          if (draft.documents[key].value == "") {
            console.log("there is an errror");
            draft.errorCount++;
            console.log(draft.errorCount);
            draft.documents[key].hasErrors = true;
            draft.documents[key].message = "This field is required";
          }
          if (!draft.errorCount) {
            draft.sendCount++;
          }
        }
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "saveSubmitCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function setDisplayPicture(e) {
    dispatch({ type: "setDisplayPicture", value: e });
  }
  function setDisplayPictureFile(e) {
    dispatch({ type: "setDisplayPictureFile", value: e });
  }
  function setThumbprintLeft(e) {
    dispatch({ type: "setThumbprintLeft", value: e });
  }
  function setThumbprintLeftFile(e) {
    dispatch({ type: "setThumbprintLeftFile", value: e });
  }
  function setThumbprintRight(e) {
    dispatch({ type: "setThumbprintRight", value: e });
  }
  function setThumbprintRightFile(e) {
    dispatch({ type: "setThumbprintRightFile", value: e });
  }
  function setCitizenshipBack(e) {
    dispatch({ type: "setCitizenshipBack", value: e });
  }
  function setCitizenshipBackFile(e) {
    dispatch({ type: "setCitizenshipBackFile", value: e });
  }
  function setCitizenshipFront(e) {
    dispatch({ type: "setCitizenshipFront", value: e });
  }
  function setCitizenshipFrontFile(e) {
    dispatch({ type: "setCitizenshipFrontFile", value: e });
  }
  function setSignature(e) {
    dispatch({ type: "setSignature", value: e });
  }
  function setSignatureFile(e) {
    dispatch({ type: "setSignatureFile", value: e });
  }
  function setBirthCertificate(e) {
    dispatch({ type: "setBirthCertificate", value: e });
  }
  function setBirthCertificateFile(e) {
    dispatch({ type: "setBirthCertificateFile", value: e });
  }
  function lng(e) {
    setLongitude(e);
  }
  function lat(e) {
    setLatitude(e);
  }
  const [errors, setErros] = useState("");

  useEffect(() => {
    if (state.sendCount) {
      if (!formState.showGuardian) {
        formDispatch({
          type: "saveDocuments",
          value: {
            photo: state.documents.displayPicture.value,
            // photo_file: state.documents.displayPicture.file,

            gov_issued_id_front: state.documents.citizenshipFront.value,
            // gov_issued_id_front_file: state.documents.citizenshipFront.file,

            gov_issued_id_back: state.documents.citizenshipBack.value,
            // gov_issued_id_back_file: state.documents.citizenshipBack.file,

            thumb_left: state.documents.thumbLeft.value,
            // thumb_left_file: state.documents.thumbLeft.file,

            thumb_right: state.documents.thumbRight.value,
            // thumb_right_file: state.documents.thumbRight.file,

            signature: state.documents.signature.value,
            // signature_file: state.documents.signature.file,

            lat: longitude,
            long: latitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            photo_file: state.documents.displayPicture.file,
            gov_issued_id_front_file: state.documents.citizenshipFront.file,
            gov_issued_id_back_file: state.documents.citizenshipBack.file,
            thumb_left_file: state.documents.thumbLeft.file,
            thumb_right_file: state.documents.thumbRight.file,
            signature_file: state.documents.signature.file,
          },
        });
      } else {
        formDispatch({
          type: "saveDocuments",
          value: {
            birth_certificate: state.documents.birthCertificate.value,
            lat: latitude,
            long: longitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            birth_certificate_file: state.documents.birthCertificate.file,
          },
        });
      }
    }
    console.log("documents saved from the documents component");
  }, [state.sendCount]);

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    dispatch({ type: "saveSubmitCount" });
  }, [state.documents]);

  return (
    <>
      <form action="" className="form">
        <div className="form-container personal-info">
          <div className="text-container">
            <h3 className="text-primary">
              Upload your documents <span className="text-danger">*</span>
            </h3>
          </div>
          {!formState.showGuardian && (
            <>
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your PP size photo</p>
                    </div>
                    <div className="card-body">
                      <DisplayPicture
                        setDisplayPicture={setDisplayPicture}
                        setDisplayPictureFile={setDisplayPictureFile}
                        setError={state.documents.displayPicture.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.displayPicture.hasErrors && (
                        <p className="text-danger">
                          {state.documents.displayPicture.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Thumbprint Left</p>
                    </div>
                    <div className="card-body">
                      <ThumbPrintLeft
                        setThumbprintLeft={setThumbprintLeft}
                        setThumbprintLeftFile={setThumbprintLeftFile}
                        setError={state.documents.thumbLeft.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.thumbLeft.hasErrors && (
                        <p className="text-danger">
                          {state.documents.thumbLeft.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Thumbprint Right</p>
                    </div>
                    <div className="card-body">
                      <ThumbprintRight
                        setThumbprintRight={setThumbprintRight}
                        setThumbprintRightFile={setThumbprintRightFile}
                        setError={state.documents.thumbRight.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.thumbRight.hasErrors && (
                        <p className="text-danger">
                          {state.documents.thumbRight.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Citizenship card front</p>
                    </div>
                    <div className="card-body">
                      <CitizenshipFront
                        setCitizenshipFront={setCitizenshipFront}
                        setCitizenshipFrontFile={setCitizenshipFrontFile}
                        setError={state.documents.citizenshipFront.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.citizenshipFront.hasErrors && (
                        <p className="text-danger">
                          {state.documents.citizenshipFront.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Citizenship card back</p>
                    </div>
                    <div className="card-body">
                      <CitizenshipBack
                        setCitizenshipBack={setCitizenshipBack}
                        setCitizenshipBackFile={setCitizenshipBackFile}
                        setError={state.documents.citizenshipBack.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.citizenshipBack.hasErrors && (
                        <p className="text-danger">
                          {state.documents.citizenshipBack.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Signature</p>
                    </div>
                    <div className="card-body">
                      <Signature
                        setSignature={setSignature}
                        setSignatureFile={setSignatureFile}
                        setError={state.documents.signature.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.signature.hasErrors && (
                        <p className="text-danger">
                          {state.documents.signature.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {formState.showGuardian && (
            <div className="row mb-5">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Birth Certificate</p>
                  </div>
                  <div className="card-body">
                    <BirthCertificate
                      setBirthCertificate={setBirthCertificate}
                      setBirthCertificateFile={setBirthCertificateFile}
                      setError={state.documents.birthCertificate.hasErrors}
                      setErrorCount={setErrorCount}
                    />
                    {state.documents.birthCertificate.hasErrors && (
                      <p className="text-danger">
                        {state.documents.birthCertificate.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mb-5">
            <div className="col-md-12">
              <div
                className="mapGl"
                style={{ height: "400px", position: "relative" }}
              >
                <MapI setLng={lng} setLat={lat} height={"400px"} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Documents;
