import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import adbs from "ad-bs-converter";
import districts from "../../../Assets/district-list-nepal";

//CONTEXTS
import FormState from "../FormState";
import FormDispatch from "../FormDispatch";
import InfoModal from "./InfoModal";
import { Info } from "../../Layout/Icons";

function PersonalInfo(props) {
  const formState = useContext(FormState);
  const formDispatch = useContext(FormDispatch);

  const initialState = {
    fullName: {
      name: "fullName",
      value: formState.formData.customers.full_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobBs: {
      name: "dobBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobAd: {
      name: "dobAd",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dobFormatted: "",
    },
    gender: {
      name: "gender",
      value: formState.formData.personal_informations.gender,
      hasErrors: false,
      message: "",
      touched: false,
    },
    maritalStatus: {
      name: "maritalStatus",
      value: formState.formData.personal_informations.marital_status,
      hasErrors: false,
      message: "",
      touched: false,
    },
    accountType: {
      name: "accountType",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    nationality: {
      name: "nationality",
      value: formState.formData.personal_informations.nationality,
      hasErrors: false,
      message: "",
      touched: false,
    },
    typeOfId: {
      name: "typeOfId",
      value: formState.formData.personal_informations.identity_card_type,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idNo: {
      name: "idNo",
      value: formState.formData.personal_informations.identity_card_number,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDistrict: {
      name: "idIssueDistrict",
      value:
        formState.formData.personal_informations.identity_card_issue_district,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDateBs: {
      name: "idIssueDateBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDate: {
      name: "idIssueDate",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dateFormatted: "",
    },
    pan: {
      name: "pan",
      value: formState.formData.personal_informations.pan_number,
      hasErrors: false,
      message: "",
      touched: true,
      checkCount: 0,
      checkingNow: false,
    },
    checkCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
            draft[key].value = action.value;
          }
        }
        return;
      case "fullNameChange":
        draft.fullName.touched = true;
        if (action.value.length > 50) {
          draft.fullName.hasErrors = true;
          draft.fullName.message = "Full name cannot exceed 50 characters";
          return;
        }
        draft.fullName.hasErrors = false;
        draft.fullName.value = action.value;
        return;
      case "validateFullName":
        if (action.value.length < 3) {
          draft.fullName.hasErrors = true;
          draft.fullName.message =
            "Full name must be alteast 3 character's long";
        }
        return;
      case "dobBsChange":
        draft.dobBs.touched = true;
        draft.dobBs.hasErrors = false;
        draft.dobBs.value = action.value;
        return;
      case "dobAdChange":
        console.log("dobadchanged reducer hit");
        draft.dobAd.touched = true;
        draft.dobAd.hasErrors = false;
        draft.dobAd.value = action.value;
        draft.dobAd.dobFormatted = formatDate(action.value);
        console.log(draft.dobAd.value?.getFullYear());
        console.log(
          new Date()?.getFullYear() - draft.dobAd.value?.getFullYear()
        );
        const yearToday =
          new Date()?.getFullYear() - draft.dobAd.value?.getFullYear();
        if (parseInt(yearToday) < 16) {
          console.log("Age is less than 16 years onld");
          formDispatch({ type: "minorTrue", value: "true" });
        } else {
          formDispatch({ type: "minorFalse", value: "false" });
        }

        return;
      case "idIssueDateChange":
        console.log("Id issue date change reducer hit");
        draft.idIssueDate.touched = true;
        draft.idIssueDate.hasErrors = false;
        draft.idIssueDate.value = action.value;
        draft.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "panChange":
        draft.pan.touched = true;
        draft.pan.hasErrors = false;
        draft.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9 && action.value.length > 0) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number invalid";
          return;
        }
        if (action.value.length === 0) {
          draft.pan.hasErrors = false;
          draft.pan.message = "";
          return;
        } else if (action.value.length > 9) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be more than 9 digits";
          return;
        }
        draft.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.pan.checkingNow = true;
        draft.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.pan.message = "PAN number available";
        draft.pan.hasErrors = false;
        return;
      case "panUnavailable":
        draft.pan.message = "PAN number already in use";
        draft.pan.hasErrors = true;
        return;
      case "checkForErrors":
        console.log("checking for errors");
        var errorCount = 0;
        var unTouchedCount = 0;
        for (const key in draft) {
          var touchedCount;

          if (!draft[key].touched) {
            draft[key].hasErrors = true;
            draft[key].message = "This field is required";
            unTouchedCount++;
            // console.log("untouchedcount", unTouchedCount)
          }
          if (draft[key].hasErrors) {
            errorCount++;
          }
        }
        console.log("ready to submit", errorCount);
        if (errorCount < 1) {
          draft.submitCount.counter++;
        }
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  const handleDate = ({ bsDate, adDate }) => {
    dispatch({ type: "dobBsChange", value: bsDate, field: "dobBs" });
    dispatch({ type: "dobAdChange", value: new Date(adDate), field: "dobAd" });
  };
  // const handleDate = (date) => {
  //   // console.log(bsDate);
  //   dispatch({ type: "dobBsChange", value: date, field: "dobBs" });
  //   dispatch({
  //     type: "dobAdChange",
  //     value: new Date(date),
  //     field: "dobAd",
  //   });
  //   // console.log(bsDate);
  // };

  function handleAdDate(date) {
    dispatch({ type: "dobAdChange", value: date, field: "dobAd" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    // console.log(formattedNepaliDate);
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "dobBs",
    });
  }

  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({ type: "inputChange", value: bsDate, field: "idIssueDateBs" });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    dispatch({ type: "idIssueDateChange", value: date, field: "idIssueDate" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }

  useEffect(() => {
    console.log("hello from the personal info");
    console.log();
    for (const key in state) {
      // console.log(`${key}: ${state[key]}`)
      var arr = state[key];
      // console.log("arr", state[key])
    }
  }, []);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          console.log(response.data);
          if (response.data.success === true) {
            dispatch({ type: "panAvailable" });
          } else {
            dispatch({ type: "panUnavailable" });
          }
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }

    return () => ourRequest.cancel();
  }, [state.pan.checkCount]);

  useEffect(() => {
    if (state.submitCount.counter) {
      console.log("ready to submit the personal form");
      formDispatch({
        type: "savePersonalInfo",
        value: {
          dob: state.dobAd.dobFormatted,
          gender: state.gender.value,
          marital_status: state.maritalStatus.value,
          nationality: state.nationality.value,
          pan_number: state.pan.value,
          identity_card_type: state.typeOfId.value,
          identity_card_number: state.idNo.value,
          identity_card_issue_district: state.idIssueDistrict.value,
          identity_card_issue_date: state.idIssueDate.dateFormatted,
        },
      });
      formDispatch({ type: "saveFullName", value: state.fullName.value });
      if (formState.showGuardian === true) {
        // props.history.push("/customers/create/guardian");
        formDispatch({ type: "activeTabs", value: "guardian" });

        // return;
      }
      if (formState.showGuardian === false) {
        // props.history.push("/customers/create/address");
        formDispatch({ type: "activeTabs", value: "address" });
      }
    }
  }, [state.submitCount.counter]);

  function handleSubmit() {
    dispatch({ type: "checkForErrors" });
  }

  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    // console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  return (
    <>
      <form className="form position-relative">
        <div className="form-container personal-info">
          <div className="text-container">
            <h2>Please complete all details</h2>
            <span
              className="position-absolute end-0 top-0"
              onClick={() => {
                formDispatch({ type: "setInfo" });
              }}
            >
              <Info />
            </span>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.fullName.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-full_name">
                  Full Name / पूरा नाम
                </label>
                <span className="text-danger">*</span>
                <input
                  value={state.fullName.value}
                  onBlur={(e) =>
                    dispatch({
                      type: "validateFullName",
                      value: e.target.value,
                      field: "fullName",
                    })
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "fullNameChange",
                      value: e.target.value,
                      field: "fullName",
                    })
                  }
                  type="text"
                  id="personal_information-full_name"
                  className="form-input"
                  placeholder="Your Full Name"
                  required
                />

                {state.fullName.hasErrors && (
                  <span className="error">{state.fullName.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className={`input-group`}>
                <label htmlFor="personal_information-mobile">
                  Mobile / मोबाइल नं.
                </label>
                <span className="text-danger">*</span>
                <input
                  readOnly
                  value={formState.formData.customers.mobile}
                  type="number"
                  id="personal_information-mobile"
                  placeholder="Your 10 digit phone number"
                  className="form-input"
                  required
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className={`input-group `}>
                <label htmlFor="personal_information-email">Email / ईमेल</label>
                <span className="text-danger">*</span>
                <input
                  readOnly
                  value={formState.formData.customers.email}
                  type="email"
                  id="dob personal_information-email"
                  placeholder="Your email address"
                  className="form-input"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.dobBs.hasErrors && "form-error"
                }`}
              >
                <div className="input-sub-group" style={{ width: "100%" }}>
                  <label htmlFor="personal_information-date_of_birth_bs">
                    Date of Birth (B.S.) / जन्म मिति(वि.स.)
                  </label>
                  <span className="text-danger">*</span>
                  <section className="diy-calendar-section">
                    <input
                      value={state.dobBs.value}
                      type="text"
                      id="personal_information-date_of_birth_bs"
                      className="form-input"
                    />
                    <div className="diy-calendar">
                      <Calendar
                        className="form-input custom-control nepaliDate"
                        value={state.dobBs.value}
                        onChange={handleDate}
                        theme="deepdark"
                      />
                    </div>
                  </section>
                  {state.dobBs.hasErrors && (
                    <span className="error">{state.dobBs.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.dobAd.hasErrors && "form-error"
                }`}
              >
                <div className="input-sub-group">
                  <label htmlFor="personal_information-date_of_birth_ad">
                    Date of Birth (A.D.) / जन्म मिति(ई.सं.)
                  </label>
                  <span className="text-danger">*</span>
                  {/* <input
                    value={state.dobAd.value}
                    onChange={(e) =>
                      dispatch({
                        type: "inputChange",
                        value: e.target.value,
                        field: "dob",
                      })
                    }
                    type="text"
                    id="personal_information-date_of_birth_ad"
                    className="form-input"
                    required
                  /> */}
                  <DatePicker
                    className="form-input custom-control englishDate"
                    selected={state.dobAd.value}
                    onChange={handleAdDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {state.dobAd.hasErrors && (
                    <span className="error">{state.dobAd.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.gender.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-gender">
                  Gender / लिङ्ग
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.gender.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "gender",
                    })
                  }
                  name=""
                  id="personal_information-gender"
                  className="form-input"
                  required
                >
                  <option value="" selected disabled>
                    Select your gender
                  </option>
                  <option value="1">Decline TO Answer</option>
                  <option value="2">Male</option>
                  <option value="3">Female</option>
                </select>
                {state.gender.hasErrors && (
                  <span className="error">{state.gender.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.maritalStatus.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-martial_status">
                  Marital Status / वैवाहिक स्थिति
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.maritalStatus.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "maritalStatus",
                    })
                  }
                  name=""
                  id="personal_information-martial_status"
                  className="form-input"
                >
                  <option value="">Select your martial status</option>
                  <option value="1">Single</option>
                  <option value="2">Married</option>
                </select>
                {state.maritalStatus.hasErrors && (
                  <span className="error">{state.maritalStatus.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.accountType.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-account_type">
                  Account Type / खाताको किसिम
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.accountType.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "accountType",
                    })
                  }
                  name=""
                  id="personal_information-account_type"
                  className="form-input"
                  required
                >
                  <option value="">Select your account type</option>
                  <option value="1">Nepalese</option>
                  <option value="2">NRN - Non Residental Nepali</option>
                  <option value="3">Foreign</option>
                </select>
                {state.accountType.hasErrors && (
                  <span className="error">{state.accountType.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.nationality.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-nationality">
                  Nationality / राष्ट्रियता
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.nationality.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "nationality",
                    })
                  }
                  name=""
                  id="personal_information-nationality"
                  className="form-input"
                  required
                >
                  <option value="">Select Nationality</option>
                  <option value="1">Nepalese</option>
                  <option value="2">NRN - Non Residental Nepali</option>
                  <option value="3">Foreign</option>
                </select>
                {state.nationality.hasErrors && (
                  <span className="error">{state.nationality.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.typeOfId.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-type_of_identity_card">
                  Type of Identitiy card / परिचय पत्रको किसिम
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.typeOfId.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "typeOfId",
                    })
                  }
                  name=""
                  id="personal_information-type_of_identity_card"
                  className="form-input"
                  required
                >
                  <option value="">Choose your type of ID</option>
                  <option value="1">Citizenship</option>
                </select>
                {state.typeOfId.hasErrors && (
                  <span className="error">{state.typeOfId.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.idNo.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-identification_number">
                  Identification Number / परिचय पत्र नं
                </label>
                <span className="text-danger">*</span>
                <input
                  value={state.idNo.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "idNo",
                    })
                  }
                  type="text"
                  id="personal_information-identification_number"
                  className="form-input"
                />
                {state.idNo.hasErrors && (
                  <span className="error">{state.idNo.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.idIssueDistrict.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-id_issued_district">
                  ID Issued District / जारी जिल्ला
                </label>
                <span className="text-danger">*</span>
                <select
                  value={state.idIssueDistrict.value}
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "idIssueDistrict",
                    })
                  }
                  name=""
                  id="personal_information-id_issued_district"
                  className="form-input"
                  required
                >
                  {districts.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>

                {state.idIssueDistrict.hasErrors && (
                  <span className="error">{state.idIssueDistrict.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.idIssueDateBs.hasErrors && "form-error"
                }`}
                style={{ flexDirection: "column" }}
              >
                <div>
                  <label htmlFor="personal_information-id_issue_date_bs">
                    ID issue Date(B.S) / जारी मिति (वि.स.)
                  </label>
                  <span className="text-danger">*</span>
                </div>
                <section className="diy-calendar-section">
                  <input
                    value={state.idIssueDateBs.value}
                    type="text"
                    id="personal_information-id_issue_date_bs"
                    className="form-input"
                  />
                  <div className="diy-calendar">
                    <Calendar
                      className="form-input"
                      value={state.idIssueDateBs.value}
                      onChange={handleIdIssueDateBs}
                      theme="deepdark"
                    />
                  </div>
                </section>
                {state.idIssueDateBs.hasErrors && (
                  <span className="error">{state.idIssueDateBs.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.idIssueDate.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-id_issued_date_ad">
                  ID Issue Date(A.D) / जारी मिति (ई.सं.)
                </label>
                <span className="text-danger">*</span>
                {/* <input
                  value={state.idIssueDate.value}
                  type="text"
                  id="personal_information-id_issued_date_ad"
                  className="form-input"
                  required
                /> */}
                <DatePicker
                  className="form-input"
                  selected={state.idIssueDate.value}
                  onChange={handleIdIssueDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {state.idIssueDate.hasErrors && (
                  <span className="error">{state.idIssueDate.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${state.pan.hasErrors && "form-error"}`}
              >
                <label htmlFor="personal_information-pan">
                  Permanent Account Number(PAN) / स्थायी लेखा नं.
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  value={state.pan.value}
                  onBlur={(e) =>
                    dispatch({
                      type: "validatePAN",
                      value: e.target.value,
                    })
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "panChange",
                      value: e.target.value,
                      field: "pan",
                    })
                  }
                  type="number"
                  id="personal_information-pan"
                  className="form-input"
                />
                {/* {state.pan.hasErrors && (
                  <span className="error">{state.pan.message}</span>
                )} */}
                {(state.pan.hasErrors || state.pan.checkingNow) && (
                  <span
                    className={`error ${
                      !state.pan.hasErrors && "text-success"
                    }`}
                  >
                    {state.pan.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <span className="btn-blue zoom-in" onClick={handleSubmit}>
            Next Page
            <span> →</span>
          </span>
        </div>
      </form>
      <InfoModal />
    </>
  );
}

export default withRouter(PersonalInfo);
