import Dashboard from "../Admin/Dashboard";
import Users from "../Admin/Users/Users";
import Customers from "../Admin/Customer/Customers";
import Roles from "../Admin/Roles/Roles";
import {
  Bank,
  Briefcase,
  Check,
  CircleCheck,
  Document,
  Info,
  Key,
  Location,
  Monitor,
} from "../Layout/Icons";

const customerCreateRoutes = [
  {
    path: "/customers/create",
    name: "Registration",
    icon: <Check className="icon" />,
  },
  {
    path: "/customers/create/verification",
    name: "Verification",
    icon: <CircleCheck className="icon" />,
  },
  {
    path: "/customers/create/createPassword",
    name: "Create Password",
    icon: <Key className="icon" />,
  },
  {
    path: "/customers/create/personalInformation",
    name: "Personal Information",
    icon: <Info className="icon" />,
  },
  {
    path: "/customers/create/guardian",
    name: "Guardian",
    icon: <Key className="icon" />,
  },
  {
    path: "/customers/create/address",
    name: "Address",
    icon: <Location className="icon" />,
  },
  {
    path: "/customers/create/familyInformation",
    name: "Family",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
    ),
  },
  {
    path: "/customers/create/nominee",
    name: "Nominee",
    icon: <Location className="icon" />,
  },
  {
    path: "/customers/create/account",
    name: "Account",
    icon: <Bank className="icon" />,
  },
  {
    path: "/customers/create/occupation",
    name: "Occupation",
    icon: <Briefcase className="icon" />,
  },
  {
    path: "/customers/create/documents",
    name: "Documents",
    icon: <Document className="icon" />,
  },
  {
    path: "/customers/create/agreement",
    name: "Terms and Conditions",
    icon: <Briefcase className="icon" />,
  },
];

const sidebarRoutesSA = [
  {
    path: "",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin/dashboard",
    icon: <Monitor className="icon" />,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    layout: "/admin/dashboard",
    icon: (
      <svg
        className="text-success"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
    ),
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
    layout: "/admin/dashboard",
    icon: (
      <svg
        className="text-info"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
      </svg>
    ),
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    layout: "/admin/dashboard",
    icon: (
      <svg
        className="text-danger"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    ),
  },
];
const sidebarRoutes = [
  {
    path: "",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin/dashboard",
    icon: <Monitor className="icon" />,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    layout: "/admin/dashboard",
    icon: (
      <svg
        className="text-danger"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
      </svg>
    ),
  },
];

const customerRoutes = [
  { path: "/admin/dashboard/customers", name: "Pending" },
  { path: "/admin/dashboard/customers/verified", name: "Verified" },
  { path: "/admin/dashboard/customers/progress", name: "DEMAT In Progress" },
  { path: "/admin/dashboard/customers/generated", name: "DEMAT Generated" },
  { path: "/admin/dashboard/customers/rejected", name: "Rejected" },
  { path: "/admin/dashboard/customers/freezed", name: "DEMAT Freezed" },
  { path: "/admin/dashboard/customers/reconcile", name: "Reconcile" },
  { path: "/admin/dashboard/customers/all", name: "All Records" },
];

const customerRecordRoutes = [
  { path: "", name: "Personal Detail" },
  { path: "guardian", name: "Guardian" },
  { path: "address", name: "Address" },
  { path: "family", name: "Family" },
  { path: "nominee", name: "Nominee" },
  { path: "occupation", name: "Occupation" },
  { path: "account", name: "Bank Account" },
  { path: "documents", name: "Documents" },
  { path: "payments", name: "Payments" },
];

const customerRecordRoutesDoc = [
  { path: "", name: "Personal Detail" },
  { path: "guardian", name: "Guardian" },
  { path: "address", name: "Address" },
  { path: "family", name: "Family" },
  { path: "nominee", name: "Nominee" },
  { path: "occupation", name: "Occupation" },
  { path: "account", name: "Bank Account" },
  { path: "documents", name: "Documents" },
];

const userRoutes = [
  { path: "/admin/dashboard/users", name: "Admin" },
  { path: "/admin/dashboard/users/customer", name: "Customer" },
];

export {
  customerCreateRoutes,
  sidebarRoutesSA,
  sidebarRoutes,
  customerRoutes,
  customerRecordRoutes,
  customerRecordRoutesDoc,
  userRoutes,
};
