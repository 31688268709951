import React, { useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import StateContext from "../../../StateContext";

// reactstrap components
import { Button, Card, Container } from "reactstrap";

//COMPONENTS
import CustomerView from "./CustomerView/CustomerView";
import Pending from "./CustomerView/CustomerListView/Pending";
import Loading from "../../Layout/Loading";
import CustomerCreate from "./CustomerCreate/CustomerCreate";
import CustomerEdit from "./CustomerEdit/CustomerEdit";
import CustomerRecordSA from "./CustomerView/CustomerListView/CustomerRecordSA";
import CustomerRecordDOC from "./CustomerView/CustomerListView/CustomerRecordDOC";
import CustomerRecordPAY from "./CustomerView/CustomerListView/CustomerRecordPAY";

function Customers() {
  const appState = useContext(StateContext);
  const initialState = {
    userData: [],
    pending: [],
    rejected: [],
    verified: [],
    inProgress: [],
    generated: [],
    paid: [],
    isMinor: [],
    hasNominee: [],
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUsers":
        draft.userData = action.value;
        return;
      case "sortUsers":
        draft.pending = draft.userData?.filter(
          (item) => item.status === "pending"
        );
        draft.rejected = draft.userData?.filter(
          (item) => item.status === "rejected"
        );
        draft.verified = draft.userData?.filter(
          (item) => item.status === "verified"
        );
        draft.inProgress = draft.userData?.filter(
          (item) => item.status === "in-progress"
        );
        draft.generated = draft.userData?.filter(
          (item) => item.status === "generated"
        );
        draft.paid = draft.userData?.filter(
          (item) => item.payment_status == "paid"
        );
        // draft.isMinor = draft.userData?.filter(
        //   (item) => (item.is_minor = "yes")
        // );
        // draft.hasNominee = draft.userData?.filter(
        //   (item) => item.nominee == "yes"
        // );
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers`,
          {
            cancelToken: ourRequest.token,
          }
        );
        // console.log(response.data);
        dispatch({ type: "loadUsers", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    dispatch({ type: "sortUsers" });
  }, [state.userData]);

  if (!state.userData?.length) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <Container className="mt-8" fluid>
      <Card className="shadow">
        <Switch>
          {appState.user.data.user.roles[0]?.name === "super_admin" && (
            <>
              <Route path="/admin/dashboard/customers/view/:id">
                <CustomerView />
              </Route>
              <Route path="/admin/dashboard/customers" exact>
                <CustomerRecordSA data={state.userData} />
              </Route>
            </>
          )}
          {appState.user.data.user.roles[0]?.name === "document_manager" && (
            <>
              <Route path="/admin/dashboard/customers/view/:id">
                <CustomerView />
              </Route>
              <Route path="/admin/dashboard/customers" exact>
                <CustomerRecordDOC data={state.userData} />
              </Route>
              <Route path="/admin/dashboard/customers/edit/:id" exact>
                <CustomerEdit />
              </Route>
            </>
          )}
          {appState.user.data.user.roles[0]?.name === "payment_manager" && (
            <>
              <Route path="/admin/dashboard/customers/view/:id">
                <CustomerView />
              </Route>
              <Route path="/admin/dashboard/customers" exact>
                <CustomerRecordPAY data={state.userData} />
              </Route>
            </>
          )}
          {appState.user.data.user.roles[0]?.name === "admin_manager" && (
            <>
              <Route path="/admin/dashboard/customers/view/:id">
                <CustomerView />
              </Route>
              <Route path="/admin/dashboard/customers" exact>
                <Pending data={state.pending} />
              </Route>
              <Route path="/admin/dashboard/customers/verified" exact>
                <Pending data={state.verified} />
              </Route>
              <Route path="/admin/dashboard/customers/progress" exact>
                <Pending data={state.inProgress} />
              </Route>
              <Route path="/admin/dashboard/customers/generated" exact>
                <Pending data={state.generated} />
              </Route>
              <Route path="/admin/dashboard/customers/rejected" exact>
                <Pending data={state.rejected} />
              </Route>
              <Route path="/admin/dashboard/customers/freezed" exact>
                <Pending data={state.freezed} />
              </Route>
              <Route path="/admin/dashboard/customers/reconcile" exact>
                <Pending data={state.reconcile} />
              </Route>
              <Route path="/admin/dashboard/customers/all" exact>
                <Pending data={state.userData} />
              </Route>
              <Route path="/admin/dashboard/customers/create" exact>
                <CustomerCreate />
              </Route>
              <Route path="/admin/dashboard/customers/edit/:id" exact>
                <CustomerEdit />
              </Route>
            </>
          )}
          {/* else if(appState.user.data.user.roles[0]?.name === "document_manager")
          {
            <Route path="/admin/dashboard/customers" exact>
              <CustomerRecordDOC data={state.userData} />
            </Route>
          }
          else if(appState.user.data.user.roles[0]?.name === "payment_manager")
          {
            <Route path="/admin/dashboard/customers" exact>
              <CustomerRecordPAY data={state.userData} />
            </Route>
          } */}
          {/* <Route path="/admin/dashboard/customers/:id">
            <CustomerView />
          </Route> */}
        </Switch>
      </Card>
    </Container>
  );
}

export default Customers;
