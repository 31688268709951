import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import StateContext from "../../../../../StateContext";
import Loading from "../../../../Layout/Loading";

const Renewal = () => {
  const appState = useContext(StateContext);
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          { cancelToken: ourRequest.token }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  if (!state.userLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="renewForm">
      <header>
        <div className=" container">
          <div className="header-content">
            <div className="header__text">
              <h1>Standing Instruction Slip</h1>
            </div>
            <div className="logo-container">
              <img src="../gurkhas-logo.png" alt="Gurkhas Logo" />
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="letter__head">
            <div className="letter__head--content">
              <p>Branch</p>
              <div className="letter__box">
                {state?.userData?.accounts[0]?.branch_name}
              </div>
            </div>
            <div className="letter__head--content">
              <p>Date</p>
              <div className="letter__box">{state?.userData?.created_at}</div>
            </div>
          </div>
          <div className="letter__body">
            <p>
              I authorise the bank to create standing instruction to debit demat
              related fees for the following demat account holders in my bank
              accoount on starting of every fiscal years as mentioned below.
            </p>
          </div>
          <p className="text-center text-underline" style={{ width: "91%" }}>
            Details of Bank Account
          </p>
          <div className="letter__form">
            <div className="label">
              <p>Bank Account Name</p>
            </div>
            <div className="letter__form--box">
              {state?.userData?.accounts[0]?.bank_name}
            </div>
          </div>
          <div className="letter__form">
            <div className="label">
              <p>Bank Account Number</p>
            </div>
            <div className="letter__form--box">
              {state?.userData?.accounts[0]?.number}
            </div>
          </div>
          <p className="text-center">Details of Demat Account</p>
          <div className="account">
            <div className="account__dp">
              <p>13018000</p>
            </div>
            <div className="account__client--id">
              <p className="text-center">Client ID(s)</p>
              <div className="client--name--box">
                <div>{state?.userData?.BOID}</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="account__client--name">
              <p className="text-center">Name of Client(s)</p>
              <div className="client--name--box">
                <div>{state?.userData?.full_name}</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Renewal;
