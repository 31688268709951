import React, { useState, useContext } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Search } from "../../Layout/Icons";
import logo from "../../../Assets/images/logo.png";
import { sidebarRoutesSA, sidebarRoutes } from "../../Handler/Routes";
import Avatar from "../../../Assets/images/avatar-small.jpg";
import StateContext from "../../../StateContext";

function Sidebar() {
  const appState = useContext(StateContext);

  const [collapseOpen, setCollapseOpen] = useState();
  const [showSubMenu, setShowSubMenu] = useState(false);
  // function showMenu(props) {
  //   setShowSubMenu(!showSubMenu);
  // }
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  let routes = "";
  if (appState.user.data.user?.roles[0]?.name === "super_admin") {
    routes = sidebarRoutesSA;
  } else {
    routes = sidebarRoutes;
  }
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            {prop.icon}
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  // if (appState.user.data.user.roles[0]?.name === "super_admin") {
  //   const createLinks = (sidebarRoutesSA) => {
  //     return sidebarRoutes.map((prop, key) => {
  //       return (
  //         <NavItem key={key}>
  //           <NavLink
  //             to={prop.layout + prop.path}
  //             tag={NavLinkRRD}
  //             onClick={closeCollapse}
  //             activeClassName="active"
  //           >
  //             {prop.icon}
  //             {prop.name}
  //           </NavLink>
  //         </NavItem>
  //       );
  //     });
  //   };
  // } else {
  //   const createLinks = (sidebarRoutes) => {
  //     return sidebarRoutes.map((prop, key) => {
  //       return (
  //         <NavItem key={key}>
  //           <NavLink
  //             to={prop.layout + prop.path}
  //             tag={NavLinkRRD}
  //             onClick={closeCollapse}
  //             activeClassName="active"
  //           >
  //             {prop.icon}
  //             {prop.name}
  //           </NavLink>
  //         </NavItem>
  //       );
  //     });
  //   };
  // }
  return (
    // <div className="sidebar">
    //   <div className="sidebar-container">
    //     <div className="logo-container">
    //       <img src={logo} alt="Gurkhas finance" />
    //     </div>
    //     <ul className="sidenav-menu">
    //       <li className="sidenav-item">
    //         <Link to="/admin/dashboard">
    //           <FontAwesomeIcon icon={faChartLine} />
    //           Dashboard
    //         </Link>
    //       </li>
    //       <li className="sidenav-item sidenav-item-has-children">
    //         <FontAwesomeIcon icon={faCog} />
    //         Access Management
    //         <FontAwesomeIcon icon={faChevronDown} onClick={showMenu} />
    //         {showSubMenu && (
    //           <ul className="sub-menu">
    //             <li className="sub-menu-item">
    //               <Link to="/admin/dashboard/users">
    //                 <FontAwesomeIcon icon={faUser} />
    //                 Users
    //               </Link>
    //             </li>

    //             <li className="sub-menu-item">
    //               <Link to="/admin/dashboard/roles">
    //                 <FontAwesomeIcon icon={faKey} />
    //                 Roles{" "}
    //               </Link>
    //             </li>
    //           </ul>
    //         )}
    //       </li>{" "}
    //       <li className="sidenav-item">
    //         <Link to="/admin/dashboard/customers">
    //           <FontAwesomeIcon icon={faUsers} />
    //           Customers
    //         </Link>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0">
            <img alt="logo" className="navbar-brand-img" src={logo} />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={Avatar} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo && (
                    <Link to="/admin/dashboard">
                      <img alt="Gurkhas Finance" src={logo} />
                    </Link>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Search className="icon" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          {<Nav navbar>{createLinks(routes)}</Nav>}
          {/* Divider */}
          <hr className="my-3" />
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Sidebar;
