import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
// reactstrap components
import {
  Row,
  Col,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Button,
  Badge,
} from "reactstrap";
import StateContext from "../../../../../StateContext";
import pagination from "../../../../Handler/Pagination";
import { Edit, Next, Prev } from "../../../../Layout/Icons";
import { customerRoutes, customerRoutesSA } from "../../../../Handler/Routes";
import Batch from "./Batch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CustomerRecordSA(props) {
  const appState = useContext(StateContext);
  const location = useLocation();
  const [newData, setNewData] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntries, setShowEntries] = useState(10);
  const [batchStatus, setBatchStatus] = useState(false);
  const [batch, setBatch] = useState({});
  const [verified, setVerified] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [count, setCount] = useState(0);

  console.log(appState);

  // Filter
  function search(e) {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
    setSearchTerm(lowerCase);
  }

  const filteredData = props.data?.filter((el) => {
    //if no input the return the original
    if (searchTerm === "") {
      return el;
    }
    // return the item which contains the user input
    else {
      return (
        el.full_name.toLowerCase().includes(searchTerm) ||
        el.status.toLowerCase().includes(searchTerm) ||
        el.email.toLowerCase().includes(searchTerm)
      );
    }
  });

  // Paginate
  useEffect(() => {
    setNewData(pagination({ filteredData, showEntries }));
  }, [props.data, searchTerm, showEntries]);

  const nextPage = () => {
    setPage((oldPage) => {
      let nextPage = oldPage + 1;
      if (nextPage > newData.length - 1) {
        nextPage = 0;
      }
      setCount(nextPage);
      return nextPage;
    });
  };

  const prevPage = () => {
    setPage((oldPage) => {
      let prevPage = oldPage - 1;
      if (prevPage < 0) {
        prevPage = newData.length - 1;
      }
      setCount(prevPage);
      return prevPage;
    });
  };
  const handlePage = (index) => {
    setPage(index);
    setCount(index);
  };

  // Export Verified Customers
  const customersExport = () => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function exportCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/export_csv`,
          { cancelToken: ourRequest.token }
        );
        console.log(response);
        setBatchStatus(response?.data?.success);
        setBatch(response?.data?.data);
      } catch (e) {
        console.log(e, "there was an error exporting customers");
      }
    }
    exportCustomers();
    return () => ourRequest.cancel();
  };
  // Tooltip
  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Simple tooltip
  //   </Tooltip>
  // );

  // Download and change Status
  useEffect(() => {
    if (batchStatus === true) {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/download_csv/${batch.id}`, {
        method: "GET",
        // mode: "no-cors",
        headers: {
          Authorization: `Bearer ${appState.user.data.token}`,
          "Content-Type": "application/zip",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${batch.filename}.zip`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          window.location.reload();
        });
    }
  }, [batch]);

  useEffect(() => {
    props.data?.map((item, i) => {
      if (item.status === "verified") {
        setVerified(true);
      }
      if (item.status === "in-progress") {
        setInProgress(true);
      }
      if (item.status !== "in-progress") {
        setInProgress(false);
      }
      if (item.status !== "verified") {
        setVerified(false);
      }
    });
  }, [props]);

  const handleEntries = (e) => {
    e.preventDefault();
    setShowEntries(e.target.value);
  };
  // console.log(newData[page]);

  return (
    <>
      <CardHeader className="border-0 d-flex align-items-center justify-content-between">
        <h3 className="mb-0">Customer List</h3>
      </CardHeader>
      <Container fluid className="mb-4">
        {verified ? (
          <Row>
            <Col lg="12" className="mb-4">
              <Button className="btn-neutral" onClick={customersExport}>
                Generate file
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col lg="6">
            <div className="d-flex align-items-baseline">
              <label htmlFor="handleEntries" className="text-light mr-2">
                Show
              </label>
              <select
                name="handleEntries"
                id=""
                className="form-control-alternative calendar"
                style={{ width: "5rem", fontSize: "1.2rem" }}
                onChange={handleEntries}
              >
                <option value="10" selected>
                  10
                </option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="text-light ml-2">entries</span>
            </div>
          </Col>
          <Col lg="6">
            <form className="d-flex justify-content-end align-items-center">
              <label htmlFor="search" className="text-light">
                Search: &nbsp;&nbsp;
              </label>
              <input
                type="text"
                name="search"
                id="search"
                className="form-control"
                style={{ width: "20rem" }}
                onChange={search}
              />
            </form>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        {/* Table */}
        <Table className="align-items-center table-flush delay" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col" className="content-hide">
                REGISTRATION NO
              </th>
              <th scope="col">FULL NAME</th>
              {/* <th scope="col" className="content-hide">
                USERNAME
              </th> */}
              <th scope="col">EMAIL</th>
              <th scope="col" className="content-hide">
                IS MINOR
              </th>
              <th scope="col" className="content-hide">
                NOMINEE
              </th>
              <th scope="col">PAYMENT</th>
              <th scope="col">STATUS</th>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 100 }}
                overlay={
                  <Tooltip
                    id="tooltip-dv-head"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Document Verified
                  </Tooltip>
                }
              >
                <th scope="col" className="mx-0 px-0 text-center">
                  {/* <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                > */}
                  DV
                  {/* </OverlayTrigger> */}
                </th>
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 100 }}
                className="text-lg"
                overlay={
                  <Tooltip
                    id="tooltip-pv"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Payment Verified
                  </Tooltip>
                }
              >
                <th scope="col" className="mx-0 px-0 text-center">
                  PV
                </th>
              </OverlayTrigger>
              <th scope="col" className="mx-0 px-0 text-center">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {newData[page] ? (
              newData[page]?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + count * showEntries}</td>
                    <td className="content-hide">{item.registration_number}</td>
                    <td>{item.full_name}</td>
                    {/* <td className="content-hide">{item.username}</td> */}
                    <td>{item.email}</td>
                    <td className="content-hide">{item.is_minor}</td>
                    <td className="content-hide">{item.nominee}</td>
                    <td>{item.payment_status}</td>
                    <td>
                      <Badge color="" className="badge-dot mx-0 px-0">
                        <i
                          className={`${
                            (item.status === "pending" && "bg-default") ||
                            (item.status === "verified" && "bg-yellow") ||
                            (item.status === "in-progress" && "bg-primary") ||
                            (item.status === "generated" && "bg-success") ||
                            (item.status === "rejected" && "bg-danger") ||
                            (item.status === "failed" && "bg-danger")
                          }
                          `}
                        />
                      </Badge>
                      {item.status}
                    </td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 100, hide: 100 }}
                      className="text-lg"
                      overlay={
                        <Tooltip
                          id="tooltip-dv"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {item.document_verified === "yes"
                            ? "Document Verified"
                            : "Document Not Verified"}
                        </Tooltip>
                      }
                    >
                      <td className=" mx-0 px-0">
                        <Badge
                          color=""
                          className="badge-dot mx-0 px-0 text-center"
                        >
                          <i
                            className={`${
                              (item.document_verified === "yes" &&
                                "bg-success") ||
                              (item.document_verified === "no" && "bg-danger")
                            }
                          `}
                          />
                        </Badge>
                      </td>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 100, hide: 100 }}
                      className="text-lg"
                      overlay={
                        <Tooltip
                          id="tooltip-pv"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          {item.payment_verified === "yes"
                            ? "Payment Verified"
                            : "Payment Not Verified"}
                        </Tooltip>
                      }
                    >
                      <td className=" mx-0 px-0 text-center">
                        <Badge color="" className="badge-dot mx-0 px-0">
                          <i
                            className={`${
                              (item.payment_verified === "yes" &&
                                "bg-success") ||
                              (item.payment_verified === "no" && "bg-danger")
                            }
                          `}
                          />
                        </Badge>
                      </td>
                    </OverlayTrigger>
                    <td className="text-center mx-0 px-0">
                      {/* <UncontrolledDropdown>
                        <DropdownToggle
                          className="text-light"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <More className="icon" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            onClick={(e) => e.preventDefault()}
                            className="px-0"
                          >*/}
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 100, hide: 100 }}
                        className="text-lg"
                        overlay={
                          <Tooltip
                            id="tooltip-top"
                            style={{
                              fontSize: "15px",
                            }}
                          >
                            Edit
                          </Tooltip>
                        }
                      >
                        <Link
                          to={`/admin/dashboard/customers/view/${item.id}`}
                          className="px-5 py-3"
                        >
                          <Edit className="icon" />
                          {/* <span className="px-3">View Form</span> */}
                        </Link>
                      </OverlayTrigger>
                      {/* </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  No Data Found ...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <Row>
            <Col lg="2">
              <span className="text-light ml-2">
                Showing 1 to {showEntries} of {filteredData?.length} entries
              </span>
            </Col>
            <Col lg="10">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem>
                    <PaginationLink onClick={prevPage}>
                      <Prev className="icon" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {newData.length > 3 ? (
                    <>
                      <PaginationItem
                        className={` ${0 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(0)}>
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${1 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(1)}>
                          2
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${2 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(2)}>
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink>. . .</PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${
                          newData.length - 1 === page ? "active" : null
                        }`}
                      >
                        <PaginationLink
                          onClick={() => handlePage(newData.length - 1)}
                        >
                          {newData.length - 1}
                        </PaginationLink>
                      </PaginationItem>
                    </>
                  ) : (
                    <>
                      {newData.map((item, index) => {
                        return (
                          <PaginationItem
                            key={index}
                            className={` ${index === page ? "active" : null}`}
                          >
                            <PaginationLink onClick={() => handlePage(index)}>
                              {index + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                    </>
                  )}

                  <PaginationItem>
                    <PaginationLink onClick={nextPage}>
                      <Next className="icon" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </Col>
          </Row>
        </CardFooter>
        {inProgress && <Batch />}
      </Container>
    </>
  );
}

export default CustomerRecordSA;
