import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import Switch from "react-switch";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import StateContext from "../../../../StateContext";
import { Eye } from "../../../Layout/Icons";
import Loading from "../../../Layout/Loading";

const UserCreate = () => {
  const appState = useContext(StateContext);
  const history = useHistory();

  const initialState = {
    submitCount: 0,
    errorCount: 0,
    isPending: false,
    adminRoles: {},
    formData: {
      fullName: {
        name: "email",
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      email: {
        name: "email",
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      password: {
        value: "",
        hasErrors: false,
        message: "",
        required: true,
        type: "password",
        touched: false,
      },
      confirmPassword: {
        value: "",
        hasErrors: false,
        message: "",
        required: true,
        type: "password",
        touched: false,
      },
      role: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      active: {
        value: 2,
        hasErrors: false,
        message: "",
        touched: false,
      },
      userCreated: {
        message: false,
        value: "",
        hasErrors: false,
        touched: false,
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "fullNameChange":
        draft.formData.fullName.value = action.value;
        draft.formData.fullName.hasErrors = false;
        draft.formData.fullName.touched = true;
        return;
      case "fullNameRules":
        if (draft.formData.fullName.value.trim().length < 4) {
          draft.formData.fullName.hasErrors = true;
          draft.formData.fullName.message =
            "Fullname must be more than 3 character";
        }
        return;
      case "emailChange":
        draft.formData.email.value = action.value;
        draft.formData.email.hasErrors = false;
        draft.formData.email.touched = true;
        return;
      case "emailRules":
        if (action.value.trim().length < 1) {
          console.log("this route was hit");
          draft.formData.email.hasErrors = true;
          draft.formData.email.message = "This field cannot be blank";
          return;
        } else if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            draft.formData.email.value
          )
        ) {
          draft.formData.email.hasErrors = true;
          draft.formData.email.message =
            "You must provide a valid email address";
          return;
        }
        return;
      case "passwordChange":
        draft.formData.password.hasErrors = false;
        draft.formData.password.touched = true;
        draft.formData.password.value = action.value;
        if (draft.formData.password.value.length > 50) {
          draft.formData.password.hasErrors = true;
          draft.formData.password.message =
            "Password cannot exceed 50 characters";
        }
        return;
      case "passwordAfterDelay":
        if (draft.formData.password.value.length < 1) {
          draft.formData.password.hasErrors = true;
          draft.formData.password.message = "You must provide a password";
        } else if (
          !/^(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{7,15}$/.test(
            draft.formData.password.value
          )
        ) {
          draft.formData.password.hasErrors = true;
          draft.formData.password.message =
            "Password must be more than 8 characters and must contain uppercase, lowercase, special character and number";
        }
        return;
      case "confirmPasswordChange":
        draft.formData.confirmPassword.hasErrors = false;
        draft.formData.confirmPassword.value = action.value;
        draft.formData.confirmPassword.touched = true;
        return;
      case "confirmPasswordAfterDelay":
        if (
          draft.formData.password.value !== draft.formData.confirmPassword.value
        ) {
          draft.formData.confirmPassword.hasErrors = true;
          draft.formData.confirmPassword.message = "Passwords do not match";
        }
        return;
      case "roleChange":
        draft.formData.role.hasErrors = false;
        draft.formData.role.value = action.value;
        draft.formData.role.touched = true;
        return;
      case "createUser":
        draft.formData.userCreated.message = true;
        draft.formData.userCreated.value = action.value;
        return;
      case "togglePassword":
        if (draft.formData.password.type === "password") {
          draft.formData.password.type = "text";
        } else {
          draft.formData.password.type = "password";
        }
        return;
      case "toggleConfirmPassword":
        if (draft.formData.confirmPassword.type === "password") {
          draft.formData.confirmPassword.type = "text";
        } else {
          draft.formData.confirmPassword.type = "password";
        }
        return;
      case "toggleSwitch":
        if (draft.formData.active.value === 1) {
          draft.formData.active.value = 2;
        } else {
          draft.formData.active.value = 1;
        }
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "checkForErrors":
        console.log("checking for errors");
        var errorCount = 0;
        var unTouchedCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].touched) {
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
            unTouchedCount++;
          }
          if (draft.formData[key].hasErrors) {
            errorCount++;
          }
        }
        console.log("ready to submit", errorCount);
        if (errorCount < 1) {
          draft.submitCount++;
        }
      case "submitForm":
        if (
          !draft.formData.fullName.hasErrors &&
          !draft.formData.password.hasErrors &&
          !draft.formData.confirmPassword.hasErrors &&
          !draft.formData.email.hasErrors &&
          draft.formData.email.value !== "" &&
          draft.formData.fullName.value !== ""
        ) {
          draft.submitCount++;
        }
        return;
      case "loadRoles":
        draft.adminRoles = action.value;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.formData.password.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "passwordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.formData.password.value]);

  useEffect(() => {
    if (state.formData.confirmPassword.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "confirmPasswordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.formData.confirmPassword.value]);

  function togglePassword() {
    dispatch({ type: "togglePassword" });
  }

  function toggleConfirmPassword() {
    dispatch({ type: "toggleConfirmPassword" });
  }

  function handleChange() {
    dispatch({ type: "toggleSwitch" });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "emailRules", value: state.formData.email.value });
    dispatch({ type: "passwordChange", value: state.formData.password.value });
    dispatch({
      type: "passwordAfterDelay",
      value: state.formData.password.value,
    });
    dispatch({
      type: "confirmPasswordChange",
      value: state.formData.confirmPassword.value,
    });
    dispatch({
      type: "confirmPasswordAfterDelay",
      value: state.formData.confirmPassword.value,
    });
    dispatch({ type: "checkForErrors" });
  }

  useEffect(() => {
    if (state.errorCount < 1) {
      dispatch({ type: "submitForm" });
    }
  }, [state.errorCount]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchRoles() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/roles`,
          {
            cancelToken: ourRequest.token,
          }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadRoles", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching roles");
      }
    }
    fetchRoles();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.submitCount) {
      dispatch({ type: "setIsPending", value: true });
      async function createUser() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/users`,
            {
              name: state.formData.fullName.value,
              email: state.formData.email.value,
              password: state.formData.password.value,
              c_password: state.formData.confirmPassword.value,
              roles: state.formData.role.value,
              active: state.formData.active.value,
            },
            { cancelToken: ourRequest.token }
          );
          if (!response.data.success) {
            console.log(response.data);
            dispatch({ type: "createUser", value: response.data.message });
          }
          dispatch({ type: "createUser", value: response.data.message });
          dispatch({ type: "setIsPending", value: false });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "setIsPending", value: false });
        }
      }
      createUser();
    }

    return () => ourRequest.cancel();
  }, [state.submitCount]);

  if (!state.adminRoles) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <>
      <CardHeader className="border-0 mb-0 pb-0">
        <h3 className="mb-0">Create Users</h3>
        <p>Fill in the following details to create a user</p>
      </CardHeader>

      <Card>
        <CardBody>
          <Form>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Full Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    placeholder="Full Name"
                    type="text"
                    onBlur={(e) =>
                      dispatch({
                        type: "fullNameRules",
                        value: e.target.value,
                      })
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "fullNameChange",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                  {state.formData.fullName.hasErrors && (
                    <p className="text-danger">
                      {state.formData.fullName.message}
                    </p>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="name@example.com"
                    type="email"
                    onBlur={(e) =>
                      dispatch({ type: "emailRules", value: e.target.value })
                    }
                    onChange={(e) =>
                      dispatch({ type: "emailChange", value: e.target.value })
                    }
                    required
                  />
                  {state.formData.email.hasErrors && (
                    <p className="text-danger">
                      {state.formData.email.message}
                    </p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-password"
                  >
                    Password
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      className="form-control-alternative"
                      id="input-password"
                      type={state.formData.password.type}
                      onChange={(e) =>
                        dispatch({
                          type: "passwordChange",
                          value: e.target.value,
                        })
                      }
                      value={state.formData.password.value}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      required
                    />
                    <InputGroupAddon
                      addonType="imepend"
                      role="button"
                      onClick={togglePassword}
                    >
                      <InputGroupText>
                        <Eye className="icon" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {state.formData.password.hasErrors && (
                    <p className="text-danger">
                      {state.formData.password.message}
                    </p>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-confirm_password"
                  >
                    Re-Password
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      className="form-control-alternative"
                      id="input-confirm_password"
                      type={state.formData.confirmPassword.type}
                      onChange={(e) =>
                        dispatch({
                          type: "confirmPasswordChange",
                          value: e.target.value,
                        })
                      }
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      value={state.formData.confirmPassword.value}
                      required
                    />
                    <InputGroupAddon
                      addonType="imepend"
                      role="button"
                      onClick={toggleConfirmPassword}
                    >
                      <InputGroupText>
                        <Eye className="icon" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {state.formData.confirmPassword.hasErrors && (
                    <p className="text-danger">
                      {state.formData.confirmPassword.message}
                    </p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="roles">
                    Roles
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      className="form-control-alternative"
                      name="roles"
                      type={"select"}
                      onChange={(e) =>
                        dispatch({
                          type: "roleChange",
                          value: e.target.value,
                        })
                      }
                    >
                      {state.adminRoles.length &&
                        state.adminRoles.map((item, i) => {
                          return (
                            <option value={item.name} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="active">
                    Active
                  </label>
                  <br />
                  <Switch
                    onChange={handleChange}
                    checked={state.formData.active.value === 2 ? "ckecled" : ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="7" md="10">
                <Button color="primary" onClick={handleSubmit}>
                  {state.isPending ? "Please Wait. . ." : "Create"}
                </Button>
                <Button color="danger" onClick={(e) => history.goBack()}>
                  Cancel
                </Button>
              </Col>
            </Row>
            {state.formData.userCreated.message && (
              <Row>
                <Col lg="12">
                  <p className="test-danger">
                    {state.formData.userCreated.value}
                  </p>
                </Col>
              </Row>
            )}
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default UserCreate;
