import React from "react";

const Page5 = (props) => {
  return (
    <div className="page">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "12px",
          color: "rgb(255, 60, 0)",
        }}
      >
        <img style={{ width: "100px", height: "auto" }} src="" />
        <h4 className="text-center" style={{ fontWeight: "bold" }}>
          Gurkhas Finance Ltd.
        </h4>
        <h4 className="text-center" style={{ fontWeight: "bold" }}>
          गोर्खाज फाइनान्स लि.
        </h4>
        <h6 className="text-center" style={{ paddingBottom: "25px" }}>
          <small style={{ fontSize: "8px" }}>
            (नेपाल राष्ट्र बैंकबाट "ग" बर्गको इजाजतपत्र प्राप्त संस्था)
          </small>
        </h6>

        <h6 className="text-center">
          <small style={{ fontWeight: "bold" }}>अनुसूची - १५</small>
        </h6>
        <h5 className="text-center mb-0 pb-0">
          निक्षेप सदस्य र हितग्राही व्यक्ति वा संस्थाबीचको सम्झौता
        </h5>
        <h5
          className="text-center"
          style={{ fontWeight: "bold", paddingBottom: "25px" }}
        >
          An Agreement between Depository Participant &amp; Beneficial Owner of
          the Organization.
        </h5>
        <h6 className="text-center mb-0 pb-0" style={{ fontWeight: "bold" }}>
          (धितोपत्र केन्द्रीय निक्षेप सेवा विनियमावली, २०६८ को विनिमय २० को
          उपविनिमय ३ सँग सम्बन्धित)
        </h6>
        <h6
          className="text-center mb-0 pb-0"
          style={{ fontWeight: "bold", paddingBottom: "25px" }}
        >
          (As per Section 20(3) of CDS By-law, 2068)
        </h6>
        <br />
        <p className="mb-0">
          डिल्लीबजार, काठमाण्डौं स्थित कार्यालय रहेको गोखांज फाइनान्स लिमिटेड
          (वैधानिक अस्तित्व भएको निकायको विवरण) (यसपछि "सदस्य" भनिएको) प्रथम
          पक्ष र
          <b>
            <span className="tc_address"></span>
          </b>
          स्थित कार्यालय रहेको <b>SELF</b>
          <b>(नागरिकता प्रमाणपत्र नं.)</b>
          (वैधानिक अस्तित्व भएको निकायको विवरण) यसपछि ("हितग्राही" भनिएको दोस्रो
          पक्ष बीच देहायका शर्तहरू पालना गर्ने सहमतिसाथ यो सम्झौता गरिएको छ।)
        </p>
        {/* <p>
                     (Gurkha Finance Limited Located at Dillibazar, Kathmandu( Description of Organization with Legal
                     entity)
                     (hereafter called “Member” as First party and <b>SELF</b>.( <b> citizenship no.<span
                             className="tc_citzen_no"></span></b>)
                     (Description of Organization with Legal entity) located at <b><span className="tc_address"></span></b>
                     .(here
                     after called “Beneficial Owner” )
                     as the second party involve in this agreement along with the understanding to full fill the
                     required
                     clauses.)
                 </p> */}

        <ul style={{ listTtyleType: "none" }}>
          <li className="one">
            <b>१. सामान्य प्रावधान : </b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  यस सम्झौताका पक्षहरू धितोपत्रको केन्द्रीय निक्षेप सेवा नियमवली
                  , २०६८ का व्यवस्था र सिडिएससिले समय समयमा जारी गरेका विनियमलाई
                  यसै सम्झौतामा उल्लेख भए सरह र यसै सम्झौतामा हिस्साको रूपमा
                  पालना गर्न सहमत छौं ।
                </p>
                {/* <p>(The parties to this agreement, Central Depository Services Regulations,
                                    2068
                                    full
                                    fill the
                                    provisions of the by-laws issued by the CDSC from time to time as the part of
                                    this
                                    agreement.)</p> */}
              </li>
            </ul>
          </li>
          <li className="two">
            <b>२. रकम असुल उपर : </b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  हितग्राहीले सदस्यलाई तिर्नुपर्ने रकम तोकिएको मितिभित्र बुझाउनु
                  पर्नेछ।
                </p>
                {/* <p>(The amount to be paid by Beneficiary to the Member should be submitted
                                    within
                                    the
                                    required
                                    allocated date.)</p> */}
              </li>
            </ul>
          </li>
          <li className="three">
            <b>३. खाताको फाँटवारी ( स्टेटमेन्ट ) :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  सदस्यले अनलाईन मार्फत् हितग्राहीलाई निजको खाताको फाँटवारी
                  हेर्ने सुविधा प्रदान गर्नेछ । साथै हितग्राहीले आफ्नो
                  धितोपत्रको फाँटवारी भौतिक रुपमा प्राप्त गर्न अनुरोध गरेमा
                  सदस्यले सो समेत प्रदान गर्न सक्नेछ। तर यस अवधिमा कुनै कारोबार
                  नभएमा खाताको फाँटवारी दिनु पर्ने छैन।
                </p>
                {/* <p>(Members at the end of the every three months interval should provide BO
                                    account
                                    statement
                                    but
                                    the account statement is not required in case there have been no transaction
                                    within
                                    these
                                    months.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="four">
            <b>
              ४. विवरणमा भएका परिवर्तनहरूबारे हितग्राहीले सूचित गर्नुपर्ने :
            </b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  हितग्राहीले सूचित गरेको अवस्था बाहेक , हितग्राहीले विवरणमा
                  भएको परिवर्तन सदस्यलाई जानकारी नगराएको कारणबाट हितग्राहीलाई
                  हुन सक्ने कुनै हानी उपर सदस्य उत्तरदायी वा जिम्मेवार हुनेछैन ।
                </p>
                {/* <p>(If Beneficiary occur any loss because of lack of furnishing the required
                                    information
                                    and
                                    the changes in the information, member won’t be liable for it except for the
                                    situation
                                    where BO has informed about the changes in description.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="five">
            <b>५. हितग्राहीको दावीप्रति निक्षेप सदस्य उत्तरदायी नहुने :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  सुरक्षणका लागि हितग्राहीको खाताबाट खर्च लेखिएको / क्रेडिट
                  गरिएको तेस्रो पक्षका दावी अदालत वा राजस्व निकायबाट तोकिएको वा
                  माग भएको कुनै शुल्क , दस्तुर , कर प्रति सिडिएससि तथा सदस्य
                  उत्तरदायी हुने छैनन् ।
                </p>
                {/* <p>(CDSC and member shall not be liable for debit/credit of BO Account
                                    according to
                                    security /
                                    mortgage third part
                                    liability ordered by court or tax authority or any fees, cess , taxes etc.
                                    demanded
                                    there
                                    on.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="six">
            <b>
              ६. प्रत्येक हितग्राही निम्न कुरामा विशेष रूपले जिम्मेवार हुनेछ :
            </b>
            <div className="offset-md-1">
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <p className="mb-0">
                    (क) निक्षेप सदस्यसँग भएको सम्झौता र खाता खोल्दाका विवरणहरू
                    तथा तथ्य सम्बन्धमा ,
                  </p>
                  {/* <p>(a) (Agreement and Understanding with Depository, Participant while
                                        opening
                                        the
                                        account.)
                                    </p> */}
                </li>
                <li>
                  <p className="mb-0">
                    (ख) निक्षेप सदस्यसँग खाता खोल्दा पेश गरेको लिखतको अधिकारीकता
                    र सत्यता सम्बन्धमा,
                  </p>
                  {/* <p>(b) (Genuineness of Documents submitted to the Depository Participants.)
                                    </p> */}
                </li>
                <li>
                  <p className="mb-0">
                    (ग) निक्षेप सदस्यबाट प्रत्येक कारोबार निर्देशन बमोजिम
                    खाताबाट घटाएको र थपेको कुराका सुनिश्चित गर्ने,
                  </p>
                  {/* <p>(c) (Confirmation of deduction and addition of each and every
                                        transaction as
                                        per
                                        the
                                        request by Depository Participants)</p> */}
                </li>
                <li>
                  <p className="mb-0">
                    (घ) हितग्राहीको खातामा भएको परिवर्तनका विवरण सम्बन्धमा जस्तै
                    : ठेगाना , बैंक विवरण स्थिति अख्तियारी , आदेश मनोनयन दस्तखत
                    आदि,
                  </p>
                  {/* <p>(d) (Changes of address , Bank Account details , signature , authority
                                        delegation
                                        ,
                                        orders etc. while opening the BO
                                        account of the Depositary Participants)</p> */}
                </li>
                <li>
                  <p className="mb-0">
                    (ङ) कुनैपनि निष्काशित धिताेपत्र खरिद गरेकाेमा साे काे सत्य
                    विवरण ।
                  </p>
                  {/* <p>(e) (Truthful disclosure of purchase of any issued securities)</p> */}
                </li>
              </ul>
            </div>
          </li>
          <li className="seven">
            <b>७. आधिकारिक प्रतिनिधि :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  हितग्राही सङ्गठित संस्था वा कानूनी व्यक्ति भएमा त्यस्तो संस्था
                  वा व्यक्तिको तर्फबाट प्रतिनिधित्व गर्ने अख्तियार प्राप्त
                  व्यक्तिले सदस्यसँगको सम्झौता क्रियान्चित गर्नेछन । प्रतिनिधिको
                  हेरफेर , वा अन्य कुनै किसिमको परिवर्तन भएमा हितग्राहीले
                  सदस्यलाई तुरून्त जानकारी गराउनेछ ।
                </p>
                {/* <p>(Any BO being Incorporated Body or Legal entity, authorized legal
                                    representative
                                    of
                                    the same
                                    should have a legal contract.
                                    Any changes in the representative or any other kind of changes should be
                                    informed by
                                    the BO
                                    to the member immediately.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="eight">
            <b>८ .सम्झौता रद्द गर्ने :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  विनियामवाली तथा सञ्चालन निर्देशिकामा उल्लेख गरिएअनुसारका
                  शर्तबन्देजको अधिनमा रही पक्षहरूले यो सम्झौता जुनसुकै समयमा
                  रद्द गर्न सक्नेछन। कुनै पनि पक्षले सम्झौता रद्द गरेमा
                  हितग्राहीको खातामा भएका धितोपत्रहरूलाई सोही हितग्राहीको
                  निर्देशिनबमोजिम सदस्यले व्यवस्थापन गर्नेछ ।
                </p>
                {/* <p>(Parties involved can anytime discard this agreement in consultation with
                                    agreed
                                    rules and
                                    regulations within the conditions placed
                                    in the bylaws as well as the operating procedures.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="nine">
            <b>९. काबुबाहिरकाे परिस्थति :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  यस सम्झौता वा विनियमावलीमा जुनसुकै कुरा लेखिएको भए तापनि आँधी
                  , तुफान , बाढी , चट्याङ , भूईचालो , आगलागी , विस्फोटन वा दैवी
                  प्रकोप , युद्ध , विद्रोह , क्रान्ति , हुलदंगा , निषेधाज्ञा ,
                  नाकाबन्दी , अवरोध , दंगा , नागरिक कलह , हड्ताल , तालाबन्दी ,
                  बहिस्कार , कुनै उपयोगी सेवामा अवरोधजस्ता मजदुर कारवाहि वा
                  अशान्ति , शत्रुको कारवाहि , अपराधिक षड्यन्त्र , आतंककारीको
                  गतिविधि वा तोडफोड , विध्वंस , प्रणालीमा गडबडी , अनाहक प्रवेश
                  वा प्रतिकार गर्न नसकिने अन्य कुनै शक्ति वा बाध्यतालगायत काबु
                  वा नियन्त्रणबाहिरका घटनाद्वारा यस सम्झौताअन्तर्गतको दायित्वमा
                  कुनै कार्य सम्पादन नगरेको , बिलम्ब गरेको वा उल्लंघन भएकोमा
                  कुनै एक पक्षलाई हुन गएको हानीनोक्सानी , क्षतिको सोधभर्ना वा
                  क्षतिपुर्ति दिन अर्को पक्ष उत्तरदायी हुनेछैन ।
                </p>
                {/* <p>(Apart from whatever might be written in this agreement as well as bylaws
                                    any
                                    kind of
                                    loss
                                    occurring due to any natural disaster earthquake , hurricane , flood ,
                                    thundering ,
                                    fire ,
                                    blast , violence , war,
                                    strike , lockout , protest, revolution , mass attack , terror attack etc.
                                    causing
                                    any kind
                                    of harm to one of the parties will not hold the other party liable for the
                                    same.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="ten">
            <b>१०. जनाउ :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  यस सम्झौताअनुसार दिइने अथवा आवश्यक हुने कुनै पनि जनाउ वा
                  सञ्चार लिखित रूपमा र प्रापककाे हालसालकाे ठेगानामा नपठाएसम्म
                  बन्धनकारी हुनेछैन ।
                </p>
                {/* <p>(Any information or communication to be posted as per the agreement unless
                                    and
                                    until
                                    posted
                                    to the recipients’ current
                                    address can’t be imposed towards the recipient.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="eleven">
            <b>११. विवादको समाधान :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  पक्षहरूका बीचमा उत्पन्न हुन सक्ने विवाद तथा भिन्नताका
                  सम्बन्धमा विनियमालीमा तोकिएअनुसारको मध्यस्थता समितिको व्यवस्था
                  यस सम्झौताका पक्षहरूलाई पनि लागू हुनेछ ।
                </p>
                {/* <p>(The Provision of arbitration committee as per By-laws in case of any kind
                                    of
                                    disagreement or
                                    conflict that may arise between
                                    and among the parties shall be applicable to the parties of the agreement.)
                                </p> */}
              </li>
            </ul>
          </li>
          <li className="twelve">
            <b>१२. नियमनकारी कानुन :</b>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <p className="mb-0">
                  यो सम्झौता प्रचलित नेपाल कानूनद्वारा नियमन तथा व्याख्या हुनेछ
                  ।
                </p>
                {/* <p>(This agreement shall be legally regulated and interpreted as per legal
                                    provision of
                                    Nepalese
                                    law.)</p> */}
              </li>
            </ul>
          </li>
        </ul>
        <div className="pt-3 pb-2 px-2 w-100">
          <div className="row">
            <div className="col-6">
              <p
                style={{
                  background: "rgb(255, 60, 0)",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                सम्झौतामा प्रथम पक्ष
              </p>
              <p>गोर्खाज फाइनान्स लि. को तर्फबाट अधिकारी प्राप्त</p>
              <p>व्यक्तिको नाम :</p>
              <p>दस्तखत :</p>
              <p>कम्पनीको छाप :</p>
              <p>साक्षी</p>
            </div>
            <div className="col-6">
              <p
                style={{
                  background: "rgb(255, 60, 0)",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                सम्झौतामा दोस्रो पक्ष
              </p>
              <p>हितग्राहीको तर्फबाट अधिकार प्राप्त</p>
              <p>
                व्यक्तिको नाम :{" "}
                <span style={{ color: "black" }}>{props.data?.full_name}</span>
              </p>
              <p>
                दस्तखत :
                <img
                  src={
                    props.guardian !== false
                      ? `${process.env.REACT_APP_BASE_URL}/img/` +
                        props.data.email +
                        "/guardian/" +
                        props.guardian?.signature_name
                      : `${process.env.REACT_APP_BASE_URL}/img/` +
                        props.data.email +
                        "/" +
                        props.data.documents[0]?.signature_name
                  }
                  alt=""
                  style={{ height: "50px", width: "200px" }}
                />
              </p>
              <p>कम्पनीको छाप :</p>
              <p>साक्षी</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page5;
