import React, { useState, useEffect, useRef, useCallback } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapGL, { Marker } from "!react-map-gl";
import Geocoder from "react-map-gl-geocoder";

/* eslint import/no-webpack-loader-syntax: off */

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlqZW4iLCJhIjoiY2w0ODJldHRmMDJsNDNpcnk5MndpNTVwOCJ9.oI1lm1fea7Ktv4XqZbOSTg";

const MapI = (props) => {
  const [viewport, setViewport] = useState({
    latitude: 27.717691,
    longitude: 85.339579,
    zoom: 12,
  });
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );

  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  useEffect(() => {
    props.setLng(parseFloat(viewport.longitude));
    props.setLat(parseFloat(viewport.latitude));
  }, [viewport.latitude, viewport.longitude]);

  return (
    <div style={{ height: `${props?.height}` }}>
      <div
        ref={geocoderContainerRef}
        style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }}
      />
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/bijen/cl4887nzz003u15p1s53vhbhy"
      >
        <Marker
          longitude={viewport.longitude}
          latitude={viewport.latitude}
          anchor="bottom"
          //   draggable
          style={{ color: "#fff", opacity: "1" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ color: "#fff", opacity: "1" }}
          >
            <path d="M20 10c0 6-8 12-8 12S4 16 4 10a8 8 0 0 1 16 0Z"></path>
            <circle cx="12" cy="10" r="3"></circle>
          </svg>
        </Marker>
        <Geocoder
          mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          position="top-left"
        />
      </MapGL>
    </div>
  );
};

export default MapI;
