import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";

//CONTEXTS
import FormDispatch from "../../FormDispatch";

const Register = () => {
  const formDispatch = useContext(FormDispatch);
  const [warning, setWarning] = useState(true);

  const initialState = {
    email: {
      name: "email",
      value: "",
      hasErrors: false,
      message: "",
    },
    mobile: {
      name: "mobile",
      value: "",
      hasErrors: false,
      message: "",
    },
    duplicateEntry: {
      value: false,
      message: "",
    },
    sendCount: 0,
    isPending: false,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "emailChange":
        draft.email.value = action.value;
        draft.email.hasErrors = false;
        draft.duplicateEntry.value = false;
        return;
      case "mobileChange":
        draft.mobile.value = action.value;
        draft.mobile.hasErrors = false;
        draft.duplicateEntry.value = false;
        return;
      case "emailRules":
        if (action.value.trim().length < 1) {
          console.log("this route was hit");
          draft.email.hasErrors = true;
          draft.email.message = "This field cannot be blank";
          return;
        } else if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            draft.email.value
          )
        ) {
          draft.email.hasErrors = true;
          draft.email.message = "You must provide a valid email address";
          return;
        }
        return;
      case "mobileRules":
        if (action.value.trim() == "") {
          draft.mobile.hasErrors = true;
          draft.mobile.message = "This field cannot be blank";
        } else if (action.value.trim().length != 10) {
          draft.mobile.hasErrors = true;
          draft.mobile.message = "Mobile number must be 10 digits long";
        }
        return;
      case "submitForm":
        if (
          !draft.email.hasErrors &&
          !draft.mobile.hasErrors &&
          draft.email.value != "" &&
          draft.mobile.value != ""
        )
          draft.sendCount++;
        return;
      case "serverValidation":
        // if (!action.value) {
        draft.duplicateEntry.value = true;
        draft.duplicateEntry.message = action.value;
        // }
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    console.log(state.sendCount);
    const ourRequest = axios.CancelToken.source();
    if (state.sendCount) {
      dispatch({ type: "setIsPending", value: true });
      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/generateOTP`,
            {
              email: state.email.value,
              countryCode: 977,
              phone: state.mobile.value,
            },
            { cancelToken: ourRequest.token }
          );
          if (!response.data.success) {
            console.log(response.data);
            dispatch({
              type: "serverValidation",
              value: response.data.message,
            });
          }
          formDispatch({
            type: "customerUpdate",
            value: {
              email: state.email.value,
              mobile: state.mobile.value,
              uniqueId: response.data.data.uniqueId,
            },
          });
          // history.push("/customers/create/verification");
          formDispatch({ type: "activeTab", value: "verification" });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "setIsPending", value: false });
        }
      }
      register();
    }

    return () => ourRequest.cancel();
  }, [state.sendCount]);

  function submitHandler(e) {
    e.preventDefault();
    dispatch({ type: "emailRules", value: state.email.value });
    dispatch({ type: "mobileRules", value: state.mobile.value });
    // dispatch({ type: "serverValidation", value: state.duplicateEntry.value });
    //if successful the push to the other page
    dispatch({ type: "submitForm" });
  }

  return (
    <div className="registration section section-box mx-auto">
      <h3>Registration</h3>
      <p>Enter your phone number and email to signup</p>
      <form>
        <div className="input-group">
          <label htmlFor="email">Email Address</label>
          <input
            className="form-input"
            type="email"
            name="email"
            placeholder="example@address.com"
            onBlur={(e) =>
              dispatch({ type: "emailRules", value: e.target.value })
            }
            onChange={(e) =>
              dispatch({ type: "emailChange", value: e.target.value })
            }
            required
          />
          {state.email.hasErrors && (
            <span className="error">{state.email.message}</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="phone">Phone Number</label>
          <div className="input-group">
            <select name="countryCode" id="countryCode">
              <option value="977">977</option>
              <option value="977">977</option>
              <option value="977">977</option>
              <option value="977">977</option>
            </select>
            <input
              className="form-input"
              type="number"
              name="mobile"
              placeholder="your 10 digit phone number"
              onBlur={(e) =>
                dispatch({ type: "mobileRules", value: e.target.value })
              }
              onChange={(e) =>
                dispatch({ type: "mobileChange", value: e.target.value })
              }
              required
            />
            {state.mobile.hasErrors && (
              <span className="error">{state.mobile.message}</span>
            )}
          </div>
        </div>
        {state.duplicateEntry.message && (
          <span className="error">{state.duplicateEntry.message}</span>
        )}
        <button
          className="navigate zoom-in"
          onClick={submitHandler}
          disabled={
            state.isPending ||
            state.email.hasErrors ||
            state.mobile.hasErrors ||
            state.email.value == "" ||
            state.mobile.value == "" ||
            state.duplicateEntry.value
          }
        >
          {state.isPending ? "Sending . . ." : "Send OTP"}
        </button>
      </form>
      <p className="signIn">
        Already Have an account?
        <Link to="/login" className="text-danger">
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default withRouter(Register);
