import React, { useState, useEffect } from "react";
import adbs from "ad-bs-converter";

const Page1 = (props) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedIssuedDate, setFormattedIssuedDate] = useState("");
  console.log(props);
  console.log(props.guardian);

  const date = new Date(props.data.personal_information[0].dob);
  const issueDate = new Date(
    props.data.personal_information[0].identity_card_issue_date
  );

  useEffect(() => {
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    // console.log(convertedNepaliDate);
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    setFormattedDate(formattedNepaliDate);
  }, [props]);

  useEffect(() => {
    const convertedNepaliDate = adbs.ad2bs(
      `${issueDate.getFullYear()}/${
        issueDate.getMonth() + 1
      }/${issueDate.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    setFormattedIssuedDate(formattedNepaliDate);
  }, [props]);

  console.log(props.data.personal_information[0]?.identity_card_number);

  return (
    <div className="page">
      <div className="form-header d-flex justify-content-start">
        <div className="image-container logo">
          <img src="../gurkhas-logo.png" alt="" />
        </div>
        <div className="text-container">
          <p>अनुसूची - १२ </p>
          <p>
            (धितोपत्रको केन्दिय निछेप सेवा बिनियमावलि ,२०६८ को विनियम २० सँग
            सम्बन्धित )
          </p>
          <h5>प्राकृतिक व्यक्ति को हितग्राही खाता खोल्ने निबेदन </h5>
          <h6>Beneficial Owner Account Opening Form For Individuals</h6>
        </div>
        <div className="display-picture">
          <img
            src={
              props.guardian !== false
                ? `${process.env.REACT_APP_BASE_URL}/img/` +
                  props.data.email +
                  "/guardian/" +
                  props.guardian?.photo_name
                : `${process.env.REACT_APP_BASE_URL}/img/` +
                  props.data.email +
                  "/" +
                  props.data.documents[0]?.photo_name
            }
            alt=""
            style={{ height: "255px", width: "200px" }}
          />
        </div>
      </div>

      {/* form-header */}
      <div className="form-row">
        <p>
          Gurkhas finance Limited <span className="branch">..........</span>
          शाखा / branch
        </p>
      </div>
      <div className="form-row">
        <h5> कार्यालय प्रयोजनको लागि मात्र /For official use only</h5>
      </div>
      <div className="form-row w-80">
        <div className="col">
          <div className="border-wrapper ">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="applicationNo">
                  <p>आबेदन नम्बर</p>
                  <p>Application Number</p>
                </label>
                <input
                  className="form-input"
                  id="applicationNo"
                  type="text"
                  readOnly
                  defaultValue={props.data?.registration_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col date-wrapper">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="date">
                  <p>मिति </p>
                  <p>Date</p>
                </label>
                <input
                  className="form-input"
                  id="date"
                  type="text"
                  readOnly
                  defaultValue={props.data?.created_at}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row w-80">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="symbolNo">
                  <p>संकेत नम्बर</p>
                  <p>Symbol Number</p>
                </label>
                <input className="form-input" id="symbolNo" type="text" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row w-80">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="BOIdentification-number">
                  <p>हितग्राही परिचय नम्बर </p>
                  <p>BO Identification No :</p>
                </label>
                <input
                  className="form-input"
                  id="BOIdentification-number"
                  type="number"
                  readOnly
                  defaultValue={props.data?.BOID}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="padding-wrapper">
            <p>
              तल उल्लेखित सम्पूर्ण विवरण राम्रोसंग भर्नु पर्नेछ। आफुसंग सरोकार
              नभएको विवरण उल्लेख गर्ने कोठामा तेस्रो धर्का तानिदिनु होला।
            </p>
            <p>
              (Please complete all details and strike out the non-applicable
              fields/boxes.)
            </p>
            <p> निछेप सदस्य नाम (Name Of Depository Participant)</p>
          </div>
        </div>
      </div>
      <div className="form-row no-border">
        <div className="col">
          <p>खाताको किसिम </p>
          <p>Type of Account :</p>
        </div>
        <div className="col">
          <input
            type="radio"
            name="account-type1"
            id="account-type-individual"
            readOnly
            checked={
              props.data?.demat_account_type === "nepalese" ? "checked" : ""
            }
          />
          <label htmlFor="individual">
            <p>व्यक्तिगत </p>
            <p>Individual</p>
          </label>
        </div>
        <div className="col">
          <input
            type="radio"
            name="account-type2"
            id="account-type-nrn"
            readOnly
            checked={props.data?.demat_account_type === "nrn" ? "checked" : ""}
          />
          <label htmlFor="NRN">
            <p>गैर आवासीय नेपाली </p>
            <p>NRN</p>
          </label>
        </div>
        <div className="col">
          <input
            type="radio"
            name="account-type3"
            id="account-type-foreigner"
            readOnly
            checked={
              props.data?.demat_account_type === "foreigner" ? "checked" : ""
            }
          />
          <label htmlFor="foreigner">
            <p>बिदेशी </p>
            <p>Foreigner</p>
          </label>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="benificary-name">
                  <p>
                    हितग्राहीको नाम
                    <span className="sub-label"> नेपालीमा</span>
                    <span className="sub-label">
                      श्री /श्रीमती /सुश्री /नाबालक
                    </span>
                  </p>
                </label>
                <input
                  className="form-input"
                  id="benificary-name"
                  type="text"
                  readOnly
                  defaultValue={props.data?.full_name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="benificary-name-eng">
                  <p>
                    Name of Beneficial Owner
                    <span className="sub-label"> In English</span>
                    <span className="sub-label">Mr./Mrs./Miss./Minor</span>
                  </p>
                </label>
                <input
                  className="form-input"
                  id="benificary-name-eng"
                  type="text"
                  readOnly
                  defaultValue={props.data?.full_name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="benificary-DOB">
                  <p>जन्म मिति</p>
                  <p>Date of Birth</p>
                </label>
                <span className="d-flex justify-content-between fg-1">
                  <div className="sub-group d-flex w-50 br-1 px-2">
                    <label htmlFor="english-dob">
                      <p>बि.सं.</p>
                      <p>BS</p>
                    </label>
                    <input
                      className="form-input"
                      id="benificary-DOB"
                      type="text"
                      readOnly
                      defaultValue={formattedDate}
                    />
                  </div>
                  <div className="sub-group d-flex w-50 px-2">
                    <label htmlFor="english-dob-AD">
                      <p>ई.सं.</p>
                      <p>AD</p>
                    </label>
                    <input
                      className="form-input"
                      id="benificary-DOB-AD"
                      type="text"
                      readOnly
                      defaultValue={props.data?.personal_information[0]?.dob}
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className="col-3 br-1 mr-2">
                  <p> लिङ्ग </p>
                  <p>Gender:</p>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="gender1"
                    id="gender-male"
                    readOnly
                    checked={`${
                      props.data?.personal_information[0]?.gender === "male"
                        ? "checked"
                        : ""
                    }`}
                  />
                  <label htmlFor="individual">
                    <p>पुरुष </p>
                    <p>Male</p>
                  </label>
                </div>
                <div className="col no-border">
                  <input
                    type="radio"
                    name="gender2"
                    id="gender-female"
                    readOnly
                    checked={`${
                      props.data?.personal_information[0]?.gender === "female"
                        ? "checked"
                        : ""
                    }`}
                  />

                  <label htmlFor="individual">
                    <p>महिला </p>
                    <p>Female</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="form-row">
                <div className="col-3 br-1 mr-2">
                  <p> रास्ट्रियेता </p>
                  <p>Nationality:</p>
                </div>
                <div className="col no-border px-3">
                  <input
                    type="radio"
                    name="nationality1"
                    id="nationality-nepali"
                    readOnly
                    checked={`${
                      props.data?.personal_information[0]?.nationality ===
                      "nepalese"
                        ? "checked"
                        : ""
                    }`}
                  />
                  <label htmlFor="individual">
                    <p>नेपाली </p>
                    <p>Nepali</p>
                  </label>
                </div>
                <div className="col no-border px-3">
                  <input
                    type="radio"
                    name="nationality2"
                    id="nationality-others"
                    readOnly
                    checked={`${
                      props.data?.personal_information[0]?.nationality !==
                      "nepalese"
                        ? "checked"
                        : ""
                    }`}
                  />
                  <label htmlFor="individual">
                    <p>अन्य </p>
                    <p>Others</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="ctNo">
                  <p>नागरिकता नं: </p>
                  <p>Citizenship No.</p>
                </label>
                <input
                  type="text"
                  name="ctNo"
                  id="ctNo"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "1"
                      ? props.data.personal_information[0]?.identity_card_number
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="issueDistrict">
                  <p>जारी जिल्ला: </p>
                  <p>Issue District</p>
                </label>
                <input
                  type="text"
                  name="issueDistrict"
                  id="issueDistrict"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "1"
                      ? props.data.personal_information[0]
                          ?.identity_card_issue_district
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="issudeDateBS">
                  <p>जारी मिति बि.सं.:</p>
                  <p>Issude Date B.S.</p>
                </label>
                <input
                  type="text"
                  name="issudeDateBS"
                  id="issudeDateBS"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "1"
                      ? formattedIssuedDate
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="issudeDateAD">
                  <p>जारी मिति ई.सं.:</p>
                  <p>Issue Date AD:</p>
                </label>
                <input
                  type="text"
                  name="issudeDateAD"
                  id="issudeDateAD"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "1"
                      ? props.data.personal_information[0]
                          ?.identity_card_issue_date
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="passportNo">
                  <p>राहदानी नं:</p>
                  <p>Passport No.</p>
                </label>
                <input
                  type="number"
                  name="passportNo"
                  id="passportNo"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "2"
                      ? props.data.personal_information[0]?.identity_card_number
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="passprot-issued-place">
                  <p>जारी ठाउँ </p>
                  <p>Place of issue</p>
                </label>
                <input
                  type="text"
                  name="passprot-issued-place"
                  id="passprot-issued-place"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "2"
                      ? props.data.personal_information[0]
                          ?.identity_card_issue_district
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="issudeDate-passport">
                  <p>जारी मिति:</p>
                  <p>Issue Date</p>
                </label>
                <input
                  type="text"
                  name="issudeDate-passport"
                  id="issudeDate-passport"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "2"
                      ? props.data.personal_information[0]
                          ?.identity_card_issue_date
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="expiryDate-passport">
                  <p>म्याद सकिने मिति: </p>
                  <p>Expiry Date:</p>
                </label>
                <input
                  type="text"
                  name="expiryDate-passport"
                  id="expiryDate-passport"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "2"
                      ? props.data.personal_information[0]
                          ?.identity_card_expiry_date
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="typeOfID">
                  <p>परिचयपत्र को किसिम: </p>
                  <p>Type of Identity Card</p>
                </label>
                <input
                  type="text"
                  name="typeOfID"
                  id="typeOfID"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_type ===
                    "1"
                      ? "Citizenship"
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="IDNo">
                  <p>परिचयपत्र नं: </p>
                  <p>Identification No.</p>
                </label>
                <input
                  type="text"
                  name="IDNo"
                  id="IDNo"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_number
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="issue-insitution">
                  <p>जारी गर्ने निकाय: </p>
                  <p>Issuance Authority</p>
                </label>
                <input
                  type="text"
                  name="issue-insitution"
                  id="issue-insitution"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]
                      ?.identity_card_issue_district
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className=" col col-4">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="id-issue-date">
                  <p>जारि मिति: </p>
                  <p>Issue Date</p>
                </label>
                <input
                  type="text"
                  name="id-issue-date"
                  id="id-issue-date"
                  className="form-input"
                  readOnly
                  defaultValue={
                    props.data.personal_information[0]?.identity_card_issue_date
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-6">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="PANNo">
                  <p>प्यान नं: </p>
                  <p>PAN No.</p>
                </label>
                <input
                  type="number"
                  name="PANNo"
                  id="PANNo"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.personal_information[0]?.pan_number}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3 mb-2">
        <div className="col">
          <h4>पत्राचार गर्ने ठेगाना (Corresponding Address):</h4>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="nation">
                  <p>राष्ट्र: </p>
                  <p>Nation</p>
                </label>
                <input
                  type="text"
                  name="nation"
                  id="nation"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.country}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="zone">
                  <p>अञ्चल: </p>
                  <p>Zone</p>
                </label>
                <input
                  type="text"
                  name="zone"
                  id="zone"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.province}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="district">
                  <p>जिल्ला: </p>
                  <p>District</p>
                </label>
                <input
                  type="text"
                  name="district"
                  id="district"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.district}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="VDC-muncipality">
                  <p>गा.बि.स/न.पा/म.न.पा: </p>
                  <p>VDC/Muncipality/Metropolitan</p>
                </label>
                <input
                  type="text"
                  name="VDC-muncipality"
                  id="VDC-muncipality"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.municipality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="locality">
                  <p>टोल: </p>
                  <p>Locality.</p>
                </label>
                <input
                  type="text"
                  name="locality"
                  id="locality"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.locality}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="ward-no">
                  <p>वडा नं: </p>
                  <p>Ward No.</p>
                </label>
                <input
                  type="text"
                  name="ward-no"
                  id="ward-no"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.ward_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="block-no">
                  <p>ब्लक नं: </p>
                  <p>Block No.</p>
                </label>
                <input
                  type="text"
                  name="block-no"
                  id="block-no"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.block_number}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="phone-no">
                  <p>फोन नं: </p>
                  <p>Phone No.</p>
                </label>
                <input
                  type="text"
                  name="phone-no"
                  id="phone-no"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[0]?.phone_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="mobile-no">
                  <p>मोबाइल नं: </p>
                  <p>Mobile No.</p>
                </label>
                <input
                  type="text"
                  name="mobile-no"
                  id="mobile-no"
                  className="form-input"
                  readOnly
                  defaultValue={props.data?.mobile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="fax-no">
                  <p>फ्याक्स नं: </p>
                  <p>Fax No.</p>
                </label>
                <input
                  type="text"
                  name="fax-no"
                  id="fax-no"
                  className="form-input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="email-no1">
                  <p>ईमेल: </p>
                  <p>Email </p>
                </label>
                <input
                  type="text"
                  name="email-no1"
                  id="email"
                  className="form-input"
                  readOnly
                  defaultValue={props.data?.email}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3 mb-2">
        <div className="col">
          <h4>स्थायी ठेगाना (Permanent Address):</h4>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="nation1">
                  <p>राष्ट्र: </p>
                  <p>Nation</p>
                </label>
                <input
                  type="text"
                  name="nation1"
                  id="nation1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.country}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="zone1">
                  <p>अञ्चल: </p>
                  <p>Zone</p>
                </label>
                <input
                  type="text"
                  name="zone1"
                  id="zone1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.province}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="district1">
                  <p>जिल्ला: </p>
                  <p>District</p>
                </label>
                <input
                  type="text"
                  name="district1"
                  id="district1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.district}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="VDC-muncipality 1">
                  <p>गा.बि.स/न.पा/म.न.पा: </p>
                  <p>VDC/Muncipality/Metropolitan</p>
                </label>
                <input
                  type="text"
                  name="VDC-muncipality1"
                  id="VDC-muncipality1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.municipality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="locality1">
                  <p>टोल: </p>
                  <p>Locality.</p>
                </label>
                <input
                  type="text"
                  name="locality1"
                  id="locality1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.locality}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="ward-no1">
                  <p>वडा नं: </p>
                  <p>Ward No.</p>
                </label>
                <input
                  type="text"
                  name="ward-no1"
                  id="ward-no1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.ward_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="block-no1">
                  <p>ब्लक नं: </p>
                  <p>Block No.</p>
                </label>
                <input
                  type="text"
                  name="block-no1"
                  id="block-no1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.block_number}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="phone-no1">
                  <p>फोन नं: </p>
                  <p>Phone No.</p>
                </label>
                <input
                  type="text"
                  name="phone-no1"
                  id="phone-no1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data.addresses[1]?.phone_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="mobile-no1">
                  <p>मोबाइल नं: </p>
                  <p>Mobile No.</p>
                </label>
                <input
                  type="text"
                  name="mobile-no1"
                  id="mobile-no1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data?.mobile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="fax-no1">
                  <p>फ्याक्स नं: </p>
                  <p>Fax No.</p>
                </label>
                <input
                  type="text"
                  name="fax-no1"
                  id="fax-no1"
                  className="form-input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="email-no1">
                  <p>ईमेल: </p>
                  <p>Email </p>
                </label>
                <input
                  type="text"
                  name="email-no1"
                  id="email-no1"
                  className="form-input"
                  readOnly
                  defaultValue={props.data?.email}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="nearest-landmark1">
                  <p>नजिकको ल्याण्डमार्क : </p>
                  <p>Nearest Landmark</p>
                </label>
                <input
                  type="text"
                  name="nearest-landmark1"
                  id="nearest-landmark1"
                  className="form-input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;
