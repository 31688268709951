// reactstrap components
import { Table, Container } from "reactstrap";

function Account(props) {
  return (
    <Container fluid>
      <Table className="align-items-center table-flush mb-5 " responsive>
        <tbody>
          <tr>
            <td className="font-weight-bold">Type of Bank Account:</td>
            <td>{props.account.type}</td>
            <td className="font-weight-bold">Bank Name:</td>
            <td>{props.account.bank_name}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Branch:</td>
            <td>{props.account.branch_name}</td>
            <td className="font-weight-bold">Bank Account Number:</td>
            <td>{props.account.number}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default Account;
