import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// reactstrap components
import {
  Row,
  Col,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Button,
  Badge,
} from "reactstrap";
import { Edit, Next, Prev } from "../../../../Layout/Icons";

import pagination from "../../../../Handler/Pagination";
import { userRoutes } from "../../../../Handler/Routes";

const UserList = (props) => {
  const [newData, setNewData] = useState([]);
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntries, setShowEntries] = useState(10);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState("/admin/dashboard/users");

  // Filter
  function search(e) {
    e.preventDefault();
    var lowerCase = e.target.value.toLowerCase();
    setSearchTerm(lowerCase);
  }

  const filteredData = props.data?.filter((el) => {
    //if no input the return the original
    if (searchTerm === "") {
      return el;
    }
    // return the item which contains the user input
    else {
      return (
        el.name.toLowerCase().includes(searchTerm) ||
        el.email.toLowerCase().includes(searchTerm)
      );
    }
  });

  // Paginate
  useEffect(() => {
    setNewData(pagination({ filteredData, showEntries }));
  }, [props.data, searchTerm, showEntries]);

  const nextPage = () => {
    setPage((oldPage) => {
      let nextPage = oldPage + 1;
      if (nextPage > newData.length - 1) {
        nextPage = 0;
      }
      setCount(nextPage);
      return nextPage;
    });
  };
  const prevPage = () => {
    setPage((oldPage) => {
      let prevPage = oldPage - 1;
      if (prevPage < 0) {
        prevPage = newData.length - 1;
      }
      setCount(prevPage);
      return prevPage;
    });
  };
  const handlePage = (index) => {
    setPage(index);
    setPage(index);
  };

  // Sort Entries
  const handleEntries = (e) => {
    setShowEntries(e.target.value);
  };

  return (
    <>
      <CardHeader className="border-0 d-flex align-items-center justify-content-between">
        <h3 className="mb-0">User List</h3>
        <Link to="/admin/dashboard/users/create" className="text-white">
          <Button className="btn-default btn-lg">Create Users</Button>
        </Link>
      </CardHeader>
      <Table className="align-items-center table-flush mb-5" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">
              {userRoutes.map((item, i) => (
                <Button
                  className={`btn-neutral px-0 ${
                    item.path == location.pathname && "active"
                  }`}
                  onClick={() => setActive(item.path)}
                  key={i}
                >
                  <Link to={item.path} className="py-4 px-5">
                    {item.name}
                  </Link>
                </Button>
              ))}
            </th>
          </tr>
        </thead>
      </Table>
      <Container fluid className="mb-4">
        <Row>
          <Col lg="6">
            <div className="d-flex align-items-baseline">
              <label htmlFor="handleEntries" className="text-light mr-2">
                Show
              </label>
              <select
                name="handleEntries"
                id=""
                className="form-control-alternative calendar"
                style={{ width: "5rem", fontSize: "1.2rem" }}
                onChange={handleEntries}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="text-light ml-2">entries</span>
            </div>
          </Col>
          <Col lg="6">
            <form className="d-flex justify-content-end align-items-center">
              <label htmlFor="search" className="text-light">
                Search: &nbsp;&nbsp;
              </label>
              <input
                type="text"
                name="search"
                id="search"
                className="form-control"
                style={{ width: "20rem", height: "3rem" }}
                // ref={searchValue}
                onChange={search}
              />
            </form>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">EMAIL</th>
              <th scope="col">NAME</th>
              <th scope="col">ROLES</th>
              <th scope="col">ACTIVE</th>
              <th scope="col">ACTION</th>
              {/* <th scope="col" /> */}
            </tr>
          </thead>
          <tbody>
            {newData[page] ? (
              newData[page]?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.name}</td>
                    <td>{item.roles[0]?.name}</td>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i
                          className={`${
                            (item.active === "yes" && "bg-success") ||
                            (item.active === "no" && "bg-danger")
                          }
                          `}
                        />
                      </Badge>
                      {item.active}
                    </td>
                    <td>
                      <Link to={`/admin/dashboard/users/${item.id}`}>
                        <Edit className="icon" />
                      </Link>
                      {/* {item.roles[0]?.name === "customer" && (
                        <Link to="">
                          <Trash className="icon" />
                        </Link>
                      )} */}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Data Found ...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <Row>
            <Col lg="2">
              <span className="text-light ml-2">
                Showing 1 to {showEntries} of {filteredData?.length} entries
              </span>
            </Col>
            <Col lg="10">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem>
                    <PaginationLink onClick={prevPage}>
                      <Prev className="icon" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {newData.length > 3 ? (
                    <>
                      <PaginationItem
                        className={` ${0 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(0)}>
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${1 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(1)}>
                          2
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${2 === page ? "active" : null}`}
                      >
                        <PaginationLink onClick={() => handlePage(2)}>
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink>. . .</PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={` ${
                          newData.length - 1 === page ? "active" : null
                        }`}
                      >
                        <PaginationLink
                          onClick={() => handlePage(newData.length - 1)}
                        >
                          {newData.length - 1}
                        </PaginationLink>
                      </PaginationItem>
                    </>
                  ) : (
                    <>
                      {newData.map((item, index) => {
                        return (
                          <PaginationItem
                            key={index}
                            className={` ${index === page ? "active" : null}`}
                          >
                            <PaginationLink onClick={() => handlePage(index)}>
                              {index + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                    </>
                  )}

                  <PaginationItem>
                    <PaginationLink onClick={nextPage}>
                      <Next className="icon" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </Col>
          </Row>
        </CardFooter>
      </Container>
    </>
  );
};

export default UserList;
