import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import adbs from "ad-bs-converter";
import axios from "axios";

import FormDispatch from "../FormDispatch";
import districts from "../../../Assets/district-list-nepal";
import provinceData from "../../../Assets/province-district.json";
import country from "../../../Assets/country";

import CitizenshipBack from "../../Handler/Documents/CitizenshipBack";
import CitizenshipFront from "../../Handler/Documents/CitizenshipFront";
import Signature from "../../Handler/Documents/Signature";
import DisplayPicture from "../../Handler/Documents/DisplayPicture";
import InfoModal from "./InfoModal";
import { Info } from "../../Layout/Icons";

function Guardian(props) {
  const formDispatch = useContext(FormDispatch);
  const [errorCount, setErrorCount] = useState(0);
  const [errors, setErrors] = useState("");

  const initialState = {
    errorCount: 0,
    sendCount: 0,
    backCount: 0,
    formData: {
      nameOfGuardian: {
        name: "nameOfGuardian",
        value: "",
        hasErrors: false,
        message: "",
      },
      relationshipWithApplicant: {
        name: "relationshipWithApplicant",
        value: "",
        hasErrors: false,
        message: "",
      },
      fathersName: {
        name: "fathersName",
        value: "",
        hasErrors: false,
        message: "",
      },
      grandFathersName: {
        name: "grandFathersName",
        value: "",
        hasErrors: false,
        message: "",
      },
      mobile: {
        name: "mobile",
        value: "",
        hasErrors: false,
        message: "",
      },
      email: {
        name: "email",
        value: "",
        hasErrors: false,
        message: "",
      },
      typeOfIdCard: {
        name: "typeOfIdCard",
        value: "",
        hasErrors: false,
        message: "",
      },
      idNo: {
        name: "idNo",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDistrict: {
        name: "idIssueDistrict",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDateBs: {
        name: "idIssueDateBs",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDate: {
        name: "idIssueDate",
        value: "",
        hasErrors: false,
        message: "",
        dateFormatted: "",
      },
      pan: {
        name: "pan",
        value: "",
        hasErrors: false,
        message: "",
        touched: true,
        checkCount: 0,
        checkingNow: false,
      },

      addresses: {
        temporary: {
          country: {
            name: "country",
            value: "",
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
        permanent: {
          country: {
            name: "country",
            value: "",
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
      },
      documents: {
        displayPicture: {
          value: "",
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipBack: {
          value: "",
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipFront: {
          value: "",
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        signature: {
          value: "",
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
      },
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft.formData[key].hasErrors = false;
            draft.formData[key].touched = true;
            draft.formData[key].value = action.value;
            if (draft.formData[key].name == "mobile") {
              if (action.value.length > 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message =
                  "Mobile number cannot exceed 10 digits";
              }
            }
            // if (draft.formData[key].name == "pan") {
            //   if (action.value.length > 9) {
            //     draft.formData[key].hasErrors = true;
            //     draft.formData[key].message =
            //       "Pan number cannot exceed 9 digits";
            //   }
            // }
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft.formData) {
          if (draft.formData[key].name === action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() === "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field cannot be blank`;
              draft.formData.pan.hasErrors = false;
              draft.formData.pan.message = "";
              return;
            }

            draft.formData[key].value = action.value;
            if (draft.formData[key].name === "email") {
              if (
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  draft.formData.email.value
                )
              ) {
                draft.formData.email.hasErrors = true;
                draft.formData.email.message =
                  "You must provide a valid email address";
                return;
              }
            }
            if (draft.formData[key].name === "mobile") {
              if (action.value.length !== 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message = "Mobile number must be 10 digits";
                return;
              }
            }
            // if (draft.formData[key].name === "pan") {
            //   if (action.value.length != 9) {
            //     draft.formData[key].hasErrors = true;
            //     draft.formData[key].message = "Pan number must be 9 digits";
            //   }
            // }
          }
        }
        return;
      case "idIssueDateChange":
        console.log("id issue date change reducer hit");
        draft.formData.idIssueDate.touched = true;
        draft.formData.idIssueDate.hasErrors = false;
        draft.formData.idIssueDate.value = action.value;
        draft.formData.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "renderProvinces":
        console.log("this reducer was hit at the render province");
        if (!draft.formData.addresses.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.formData.addresses.temporary.province.list.push(key);
            draft.formData.addresses.permanent.province.list.push(key);
          }
        }
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          provinceData[key].map((item, i) => {
            draft.formData.addresses.temporary.district.list.push(
              item.district
            );
            draft.formData.addresses.permanent.district.list.push(
              item.district
            );
          });
        }
        draft.formData.addresses.temporary.district.listToShow =
          draft.formData.addresses.temporary.district.list;
        draft.formData.addresses.permanent.district.listToShow =
          draft.formData.addresses.permanent.district.list;
        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.formData.addresses.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.formData.addresses.temporary.municipality.list.push(item);
              draft.formData.addresses.permanent.municipality.list.push(item);
            });
          });
        }
        draft.formData.addresses.temporary.municipality.listToShow =
          draft.formData.addresses.temporary.municipality.list;
        draft.formData.addresses.permanent.municipality.listToShow =
          draft.formData.addresses.permanent.municipality.list;
        return;
      case "temporaryInputChange":
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].name == action.field) {
            draft.formData.addresses.temporary[key].hasErrors = false;
            draft.formData.addresses.temporary[key].value = action.value;
            if (draft.formData.addresses.temporary[key].name == "province") {
              draft.formData.addresses.temporary.district.listToShow = [];
              draft.formData.addresses.temporary.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.temporary.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.temporary[key].name == "district") {
              draft.formData.addresses.temporary.municipality.listToShow = [];
              draft.formData.addresses.temporary.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.temporary.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.temporary.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "permanentInputChange":
        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].name == action.field) {
            draft.formData.addresses.permanent[key].hasErrors = false;
            draft.formData.addresses.permanent[key].value = action.value;
            if (draft.formData.addresses.permanent[key].name == "province") {
              draft.formData.addresses.permanent.district.listToShow = [];
              draft.formData.addresses.permanent.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.permanent.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.permanent[key].name == "district") {
              draft.formData.addresses.permanent.municipality.listToShow = [];
              draft.formData.addresses.permanent.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.permanent.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.permanent.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "setDisplayPicture":
        draft.formData.documents.displayPicture.hasErrors = false;
        draft.formData.documents.displayPicture.value = action.value;
        return;
      case "setDisplayPictureFile":
        draft.formData.documents.displayPicture.file = action.value;
        return;
      case "setCitizenshipBack":
        draft.formData.documents.citizenshipBack.hasErrors = false;
        draft.formData.documents.citizenshipBack.value = action.value;
        return;
      case "setCitizenshipBackFile":
        draft.formData.documents.citizenshipBack.file = action.value;
        return;
      case "setCitizenshipFront":
        draft.formData.documents.citizenshipFront.hasErrors = false;
        draft.formData.documents.citizenshipFront.value = action.value;
        return;
      case "setCitizenshipFrontFile":
        draft.formData.documents.citizenshipFront.file = action.value;
        return;
      case "setSignature":
        draft.formData.documents.signature.hasErrors = false;
        draft.formData.documents.signature.value = action.value;
        return;
      case "setSignatureFile":
        draft.formData.documents.signature.file = action.value;
        return;
      case "sameAsCurrent":
        draft.formData.addresses.permanent.country.value =
          draft.formData.addresses.temporary.country.value;
        draft.formData.addresses.permanent.country.touched = true;
        draft.formData.addresses.permanent.province.value =
          draft.formData.addresses.temporary.province.value;
        draft.formData.addresses.permanent.province.touched = true;
        draft.formData.addresses.permanent.district.value =
          draft.formData.addresses.temporary.district.value;
        draft.formData.addresses.permanent.district.touched = true;
        draft.formData.addresses.permanent.municipality.value =
          draft.formData.addresses.temporary.municipality.value;
        draft.formData.addresses.permanent.municipality.touched = true;
        draft.formData.addresses.permanent.city.value =
          draft.formData.addresses.temporary.city.value;
        draft.formData.addresses.permanent.city.touched = true;
        draft.formData.addresses.permanent.locality.value =
          draft.formData.addresses.temporary.locality.value;
        draft.formData.addresses.permanent.locality.touched = true;
        draft.formData.addresses.permanent.ward_number.value =
          draft.formData.addresses.temporary.ward_number.value;
        draft.formData.addresses.permanent.ward_number.touched = true;
        draft.formData.addresses.permanent.block_number.value =
          draft.formData.addresses.temporary.block_number.value;
        draft.formData.addresses.permanent.block_number.touched = true;
        draft.formData.addresses.permanent.phone_number.value =
          draft.formData.addresses.temporary.phone_number.value;
        draft.formData.addresses.permanent.phone_number.touched = true;
        draft.formData.addresses.permanent.country.hasErrors = false;
        draft.formData.addresses.permanent.province.hasErrors = false;
        draft.formData.addresses.permanent.district.hasErrors = false;
        draft.formData.addresses.permanent.municipality.hasErrors = false;
        draft.formData.addresses.permanent.city.hasErrors = false;
        draft.formData.addresses.permanent.locality.hasErrors = false;
        draft.formData.addresses.permanent.ward_number.hasErrors = false;
        draft.formData.addresses.permanent.block_number.hasErrors = false;
        draft.formData.addresses.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.formData.addresses.permanent.country.value = "";
        draft.formData.addresses.permanent.province.value = "";
        draft.formData.addresses.permanent.district.value = "";
        draft.formData.addresses.permanent.municipality.value = "";
        draft.formData.addresses.permanent.city.value = "";
        draft.formData.addresses.permanent.locality.value = "";
        draft.formData.addresses.permanent.ward_number.value = "";
        draft.formData.addresses.permanent.block_number.value = "";
        draft.formData.addresses.permanent.phone_number.value = "";
        draft.formData.addresses.permanent.country.touched = false;
        draft.formData.addresses.permanent.province.touched = false;
        draft.formData.addresses.permanent.district.touched = false;
        draft.formData.addresses.permanent.municipality.touched = false;
        draft.formData.addresses.permanent.city.touched = false;
        draft.formData.addresses.permanent.locality.touched = false;
        draft.formData.addresses.permanent.ward_number.touched = false;
        draft.formData.addresses.permanent.block_number.touched = false;
        draft.formData.addresses.permanent.phone_number.touched = false;
        return;
      case "validateForm":
        //check for errors
        console.log("handle form reducer thit at guardians");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (draft.formData[key].value === "") {
            console.log("there is an errror");
            draft.errorCount++;
            console.log(draft.formData[key].name);
            console.log(draft.errorCount);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field cannot be empty";
          }
        }
        //check for errors in address section
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].value == "") {
            console.log("there is an errror in temporary address section");
            draft.errorCount++;
            console.log(draft.formData.addresses.temporary[key].name);
            console.log(draft.errorCount);
            draft.formData.addresses.temporary[key].hasErrors = true;
            draft.formData.addresses.temporary[key].message =
              "This field cannot be empty";
          }
        }

        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].value == "") {
            console.log("there is an errror in permanent address section");
            draft.errorCount++;
            console.log(draft.formData.addresses.permanent[key].name);
            console.log(draft.errorCount);
            draft.formData.addresses.permanent[key].hasErrors = true;
            draft.formData.addresses.permanent[key].message =
              "This field cannot be empty";
          }
        }
        //check for errors in the documents section
        for (const key in draft.formData.documents) {
          console.log(
            "the loop now runs for the documents seciton foo the guardian"
          );
          if (draft.formData.documents[key].value == "") {
            draft.errorCount++;
            draft.formData.documents[key].hasErrors = true;
            draft.formData.documents[key].message = "This document is required";
          }
        }
        // exceptions
        if (draft.formData.addresses.permanent.city.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.city.hasErrors = false;
        }
        if (draft.formData.addresses.permanent.block_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.block_number.hasErrors = false;
        }
        if (draft.formData.addresses.permanent.phone_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.phone_number.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.city.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.city.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.block_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.block_number.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.phone_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.phone_number.hasErrors = false;
        }
        if (draft.formData.pan.value === "") {
          draft.errorCount--;
          draft.formData.pan.hasErrors = false;
        }

        //dispatch the state if error count is 0
        //check which element has Error
        if (!draft.errorCount && errorCount < 1) {
          draft.sendCount++;
          console.log("there are 0 errors on guardian page ");
        }
        return;
      case "panChange":
        draft.formData.pan.touched = true;
        draft.formData.pan.hasErrors = false;
        draft.formData.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9 && action.value.length > 0) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message = "PAN number invalid";
          return;
        }
        if (action.value.length === 0) {
          draft.formData.pan.hasErrors = false;
          draft.formData.pan.message = "";
          return;
        } else if (action.value.length > 9) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be more than 9 digits";
          return;
        }
        draft.formData.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.formData.pan.checkingNow = true;
        draft.formData.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.formData.pan.message = "PAN number available";
        draft.formData.pan.hasErrors = false;
        return;
      case "panUnavailable":
        draft.formData.pan.message = "PAN number already in use";
        draft.formData.pan.hasErrors = true;
        return;
      case "setErrorCount":
        draft.errorCount++;
        return;
      case "setBackCount":
        draft.backCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (errorCount > 0) {
      dispatch({ type: "setErrorCount" });
      console.log(123);
    }
  }, [errorCount]);

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  function handleCheck(e) {
    console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({ type: "inputChange", value: bsDate, field: "idIssueDateBs" });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    dispatch({ type: "idIssueDateChange", value: date, field: "idIssueDate" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }
  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    // console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  function handleSubmit() {
    console.log("handle submit function");
    dispatch({ type: "validateForm" });
  }
  // PAN Check
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.formData.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.formData.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          console.log(response.data);
          if (response.data.success === true) {
            dispatch({ type: "panAvailable" });
          } else {
            dispatch({ type: "panUnavailable" });
          }
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }

    return () => ourRequest.cancel();
  }, [state.formData.pan.checkCount]);

  useEffect(() => {
    if (state.sendCount) {
      console.log("form dispatch form the guardians info here");
      formDispatch({
        type: "saveGuardian",
        value: {
          type: "1",
          full_name: state.formData.nameOfGuardian.value,
          email: state.formData.email.value,
          relation: state.formData.relationshipWithApplicant.value,
          father_name: state.formData.fathersName.value,
          grand_father_name: state.formData.grandFathersName.value,
          mobile: state.formData.mobile.value,
          addresses: [
            {
              type: "1",
              block_number:
                state.formData.addresses.temporary.block_number.value,
              phone_number:
                state.formData.addresses.temporary.phone_number.value,
              ward_number: state.formData.addresses.temporary.ward_number.value,
              locality: state.formData.addresses.temporary.locality.value,
              municipality:
                state.formData.addresses.temporary.municipality.value,
              district: state.formData.addresses.temporary.district.value,
              province: state.formData.addresses.temporary.province.value,
              country: state.formData.addresses.temporary.country.value,
              city: state.formData.addresses.temporary.city.value,
            },
            {
              type: "2",
              block_number:
                state.formData.addresses.permanent.block_number.value,
              phone_number:
                state.formData.addresses.permanent.phone_number.value,
              ward_number: state.formData.addresses.permanent.ward_number.value,
              locality: state.formData.addresses.permanent.locality.value,
              municipality:
                state.formData.addresses.permanent.municipality.value,
              district: state.formData.addresses.permanent.district.value,
              province: state.formData.addresses.permanent.province.value,
              country: state.formData.addresses.permanent.country.value,
              city: state.formData.addresses.permanent.city.value,
            },
          ],
          personal_informations: {
            identity_card_type: state.formData.typeOfIdCard.value,
            identity_card_number: state.formData.idNo.value,
            identity_card_issue_district: state.formData.idIssueDistrict.value,
            identity_card_issue_date: state.formData.idIssueDate.dateFormatted,
            pan_number: "",
          },
          documents: {
            photo: state.formData.documents.displayPicture.value,
            signature: state.formData.documents.signature.value,
            gov_issued_id_front: state.formData.documents.citizenshipBack.value,
            gov_issued_id_back: state.formData.documents.citizenshipFront.value,
          },
        },
      });
      formDispatch({
        type: "saveGuardianDocumentsFile",
        value: {
          guardian_photo_file: state.formData.documents.displayPicture.file,
          guardian_gov_issued_id_front_file:
            state.formData.documents.citizenshipFront.file,
          guardian_gov_issued_id_back_file:
            state.formData.documents.citizenshipBack.file,
          guardian_signature_file: state.formData.documents.signature.file,
        },
      });
      //dispatch form data from here
      // props.history.push("/customers/create/address");
      formDispatch({ type: "activeTabs", value: "address" });
    }
  }, [state.sendCount]);

  function setDisplayPicture(e) {
    dispatch({ type: "setDisplayPicture", value: e });
  }
  function setDisplayPictureFile(e) {
    dispatch({ type: "setDisplayPictureFile", value: e });
  }
  function setCitizenshipBack(e) {
    dispatch({ type: "setCitizenshipBack", value: e });
  }
  function setCitizenshipBackFile(e) {
    dispatch({ type: "setCitizenshipBackFile", value: e });
  }
  function setCitizenshipFront(e) {
    dispatch({ type: "setCitizenshipFront", value: e });
  }
  function setCitizenshipFrontFile(e) {
    dispatch({ type: "setCitizenshipFrontFile", value: e });
  }
  function setSignature(e) {
    dispatch({ type: "setSignature", value: e });
  }
  function setSignatureFile(e) {
    dispatch({ type: "setSignatureFile", value: e });
  }

  function handleBackCount(e) {
    e.preventDefault();
    dispatch({ type: "setBackCount" });
  }

  useEffect(() => {
    if (state.backCount > 0) {
      formDispatch({ type: "activeTabs", value: "personalInfo" });
    }
  }, [state.backCount]);

  return (
    <>
      <form action="" className="form position-relative">
        <div className="form-container personal-info">
          <div className="text-container">
            <h3>Guardian's Information</h3>
            <span
              className="position-absolute end-0 top-0"
              onClick={() => {
                formDispatch({ type: "setInfo" });
              }}
            >
              <Info />
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.nameOfGuardian.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="guardian-name_of_guardian">
                  Name of Guardian / संरक्षकको नाम
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "nameOfGuardian",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "nameOfGuardian",
                    })
                  }
                  type="text"
                  id="guardian-name_of_guardian"
                  className="form-input"
                  placeholder="Guardian's Full Name"
                  required
                />
                {state.formData.nameOfGuardian.hasErrors && (
                  <span className="error">
                    {state.formData.nameOfGuardian.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.relationshipWithApplicant.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="guaridan-relationship_with_applicant">
                  Relationship with applicant / निवेदकसंगको सम्बन्ध
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "relationshipWithApplicant",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "relationshipWithApplicant",
                    })
                  }
                  type="text"
                  id="guaridan-relationship_with_applicant"
                  placeholder="Relationship with applicant"
                  className="form-input"
                  required
                />
                {state.formData.relationshipWithApplicant.hasErrors && (
                  <span className="error">
                    {state.formData.relationshipWithApplicant.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.fathersName.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="guardian-fathers_name">
                  Father's Name / बुबाको नाम
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "fathersName",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "fathersName",
                    })
                  }
                  type="text"
                  id="dob guardian-fathers_name"
                  placeholder="Father's Name"
                  className="form-input"
                  required
                />
                {state.formData.fathersName.hasErrors && (
                  <span className="error">
                    {state.formData.fathersName.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.grandFathersName.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="guardian-grandFathers_name">
                  GrandFather's Name / बाजेको नाम
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "grandFathersName",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "grandFathersName",
                    })
                  }
                  type="text"
                  id="dob guardian-grandFathers_name"
                  placeholder="GrandFather's Name"
                  className="form-input"
                  required
                />
                {state.formData.grandFathersName.hasErrors && (
                  <span className="error">
                    {state.formData.grandFathersName.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.mobile.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-mobile">
                  Mobile / मोबाइल नं
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "mobile",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "mobile",
                    })
                  }
                  type="number"
                  id="personal_information-mobile"
                  placeholder="Your 10 digit phone number"
                  className="form-input"
                  required
                />
                {state.formData.mobile.hasErrors && (
                  <span className="error">{state.formData.mobile.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.email.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-email">Email/ ईमेल</label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "email",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "email",
                    })
                  }
                  type="email"
                  id="dob personal_information-email"
                  placeholder="Your email address"
                  className="form-input"
                  required
                />
                {state.formData.email.hasErrors && (
                  <span className="error">{state.formData.email.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.typeOfIdCard.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-type_of_identity_card">
                  Type of Identitiy card / परिचय पत्रको किसिम
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "typeOfIdCard",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "typeOfIdCard",
                    })
                  }
                  value={state.formData.typeOfIdCard.value}
                  name=""
                  id="personal_information-type_of_identity_card"
                  className="form-input"
                  required
                >
                  <option value="">Choose your type of ID</option>
                  <option value="1">Citizenship</option>
                </select>
                {state.formData.typeOfIdCard.hasErrors && (
                  <span className="error">
                    {state.formData.typeOfIdCard.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.idNo.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-identification_number">
                  Identification Number / परिचय पत्र नं
                </label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "idNo",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "idNo",
                    })
                  }
                  value={state.formData.idNo.value}
                  type="text"
                  id="personal_information-identification_number"
                  className="form-input"
                />
                {state.formData.idNo.hasErrors && (
                  <span className="error">{state.formData.idNo.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.idIssueDistrict.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-id_issued_district">
                  ID Issued District / जारी जिल्ला
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "inputChange",
                      value: e.target.value,
                      field: "idIssueDistrict",
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: "inputBlur",
                      value: e.target.value,
                      field: "idIssueDistrict",
                    })
                  }
                  value={state.formData.idIssueDistrict.value}
                  name=""
                  id="personal_information-id_issued_district"
                  className="form-input"
                  required
                >
                  {districts.map((item, i) => {
                    return (
                      <option value={item.name} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.formData.idIssueDistrict.hasErrors && (
                  <span className="error">
                    {state.formData.idIssueDistrict.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.idIssueDateBs.hasErrors && "form-error"
                }`}
                style={{ flexDirection: "column" }}
              >
                <div>
                  <label htmlFor="personal_information-id_issue_date_bs">
                    ID issue Date(B.S) / जारी मिति (वि.स.)
                  </label>
                  <span className="text-danger">*</span>
                </div>
                <section className="diy-calendar-section">
                  <input
                    value={state.formData.idIssueDateBs.value}
                    type="text"
                    id="personal_information-id_issue_date_bs"
                    className="form-input"
                    required
                  />
                  <div className="diy-calendar">
                    <Calendar
                      className="form-input"
                      value={state.formData.idIssueDateBs.value}
                      onChange={handleIdIssueDateBs}
                      theme="deepdark"
                    />
                  </div>
                </section>
                {state.formData.idIssueDateBs.hasErrors && (
                  <span className="error">
                    {state.formData.idIssueDateBs.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.idIssueDate.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-id_issued_date_ad">
                  ID Issue Date(A.D) / जारी मिति (ई.सं.)
                </label>
                <span className="text-danger">*</span>
                {/* <input
                    value={state.formData.idIssueDate.value}
                    type="text"
                    id="personal_information-id_issued_date_ad"
                    className="form-control"
                    required
                  /> */}
                <DatePicker
                  className="form-input"
                  selected={state.formData.idIssueDate.value}
                  onChange={handleIdIssueDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {state.formData.idIssueDate.hasErrors && (
                  <span className="error">
                    {state.formData.idIssueDate.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.pan.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="personal_information-pan">
                  Permanent Account Number(PAN) / स्थायी लेखा नं.
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onBlur={(e) =>
                    dispatch({
                      type: "validatePAN",
                      value: e.target.value,
                    })
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "panChange",
                      value: e.target.value,
                      field: "pan",
                    })
                  }
                  value={state.formData.pan.value}
                  type="number"
                  id="personal_information-pan"
                  className="form-input"
                />
                {(state.formData.pan.hasErrors ||
                  state.formData.pan.checkingNow) && (
                  <span
                    className={`error ${
                      !state.formData.pan.hasErrors && "text-success"
                    }`}
                  >
                    {state.formData.pan.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <h3>Guardian's Current Address</h3>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.country.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-country">Country / राष्ट्र</label>
                <span className="text-danger">*</span>

                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "country",
                    })
                  }
                  value={state.formData.addresses.temporary.country.value}
                  name=""
                  id="address-country"
                  className="form-input"
                  placeholder="Select a country"
                  required
                >
                  {country.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.formData.addresses.temporary.country.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.country.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.province.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-country">Province / प्रदेश</label>
                <span className="text-danger">*</span>

                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "province",
                    })
                  }
                  value={state.formData.addresses.temporary.province.value}
                  name=""
                  id="address-country"
                  className="form-input"
                  placeholder="Select a Province"
                  required
                >
                  {state.formData.addresses.temporary.province.list.map(
                    (item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.temporary.province.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.province.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.district.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="guardian-temporary_district">
                  District / जिल्ला
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "district",
                    })
                  }
                  value={state.formData.addresses.temporary.district.value}
                  name=""
                  id="guardian-temporary_district"
                  className="form-input"
                  required
                >
                  {state.formData.addresses.temporary.district.listToShow.map(
                    (item, i) => {
                      return (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.temporary.district.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.district.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.city.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-city">City / शहर</label>
                {/* <span className="text-danger">*</span> */}

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "city",
                    })
                  }
                  value={state.formData.addresses.temporary.city.value}
                  type="text"
                  id="address-city"
                  className="form-input"
                  placeholder="Your City Name"
                />
                {state.formData.addresses.temporary.city.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.city.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.municipality.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-municipality">
                  Municipality / न.पा
                </label>
                <span className="text-danger">*</span>

                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "municipality",
                    })
                  }
                  value={state.formData.addresses.temporary.municipality.value}
                  type="text"
                  id="address-municipality"
                  className="form-input"
                  placeholder="Your Municipality / VDC name"
                  required
                >
                  {state.formData.addresses.temporary.municipality.listToShow.map(
                    (item, i) => {
                      return (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.temporary.municipality.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.municipality.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.locality.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-locality_tole">Locality / टोल</label>
                <span className="text-danger">*</span>

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "locality",
                    })
                  }
                  value={state.formData.addresses.temporary.locality.value}
                  type="text"
                  id="address-locality_tole"
                  className="form-input"
                  placeholder="Enter your street address"
                  required
                />
                {state.formData.addresses.temporary.locality.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.locality.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.ward_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-ward_no">Ward No / वडा नं</label>
                <span className="text-danger">*</span>

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "ward_number",
                    })
                  }
                  value={state.formData.addresses.temporary.ward_number.value}
                  type="number"
                  id="address-ward_no"
                  className="form-input"
                  placeholder="Enter your ward number"
                  required
                />
                {state.formData.addresses.temporary.ward_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.ward_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.block_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-block_no">Block No / ब्लक नं</label>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: "block_number",
                    })
                  }
                  value={state.formData.addresses.temporary.block_number.value}
                  type="number"
                  id="address-block_no"
                  className="form-input"
                  placeholder="Enter your block number"
                />
                {state.formData.addresses.temporary.block_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.block_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.temporary.phone_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-telephone_number">
                  Telephone Number / फोन नं
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryInputChange",
                      value: e.target.value,
                      field: " phone_number",
                    })
                  }
                  value={state.formData.addresses.temporary.phone_number.value}
                  type="number"
                  id="address-telephone_number"
                  className="form-input"
                  placeholder="Enter your phones number"
                />
                {state.formData.addresses.temporary.phone_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.temporary.phone_number.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <h3>Guardian's Permanent Address</h3>
          <div className="input-wrapper">
            <div className={`input-group`}>
              <input
                type="checkbox"
                className="form-input"
                id="permanent-address"
                onClick={handleCheck}
                style={{ width: "14px" }}
              />
              <label htmlFor="permanent-address" className="mb-0 mx-2">
                Same as Current Address
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.country.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-country">Country / राष्ट्र</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "country",
                    })
                  }
                  value={state.formData.addresses.permanent.country.value}
                  name=""
                  id="address-country"
                  className="form-input"
                  placeholder="Select a country"
                  required
                >
                  {country.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.formData.addresses.permanent.country.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.country.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.province.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-country">Province / प्रदेश</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "province",
                    })
                  }
                  value={state.formData.addresses.permanent.province.value}
                  name=""
                  id="address-country"
                  className="form-input"
                  placeholder="Select a Province"
                  required
                >
                  {state.formData.addresses.permanent.province.list.map(
                    (item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.permanent.province.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.province.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.district.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="guardian-permanent_district">
                  District / जिल्ला
                </label>
                <span className="text-danger">*</span>

                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "district",
                    })
                  }
                  value={state.formData.addresses.permanent.district.value}
                  name=""
                  id="guardian-permanent_district"
                  className="form-input"
                >
                  {state.formData.addresses.permanent.district.listToShow.map(
                    (item, i) => {
                      return (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.permanent.district.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.district.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.city.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-city">City / शहर</label>
                {/* <span className="text-danger">*</span> */}

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "city",
                    })
                  }
                  value={state.formData.addresses.permanent.city.value}
                  type="text"
                  id="address-city"
                  className="form-input"
                  placeholder="Your City Name"
                  required
                />
                {state.formData.addresses.permanent.city.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.city.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.municipality.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-municipality">
                  Municipality / न.पा
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "municipality",
                    })
                  }
                  value={state.formData.addresses.permanent.municipality.value}
                  type="text"
                  id="address-municipality"
                  className="form-input"
                  placeholder="Your Municipality / VDC name"
                  required
                >
                  {state.formData.addresses.permanent.municipality.listToShow.map(
                    (item, i) => {
                      return (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      );
                    }
                  )}
                </select>
                {state.formData.addresses.permanent.municipality.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.municipality.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.locality.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-locality_tole">Locality / टोल</label>
                <span className="text-danger">*</span>

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "locality",
                    })
                  }
                  value={state.formData.addresses.permanent.locality.value}
                  type="text"
                  id="address-locality_tole"
                  className="form-input"
                  placeholder="Enter your street address"
                  required
                />
                {state.formData.addresses.permanent.locality.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.locality.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.ward_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-ward_no">Ward No / वडा नं</label>
                <span className="text-danger">*</span>

                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "ward_number",
                    })
                  }
                  value={state.formData.addresses.permanent.ward_number.value}
                  type="number"
                  id="address-ward_no"
                  className="form-input"
                  placeholder="Enter your ward number"
                  required
                />
                {state.formData.addresses.permanent.ward_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.ward_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.block_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-block_no">Block No / ब्लक नं</label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: "block_number",
                    })
                  }
                  value={state.formData.addresses.permanent.block_number.value}
                  type="number"
                  id="address-block_no"
                  className="form-input"
                  placeholder="Enter your block number"
                />
                {state.formData.addresses.permanent.block_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.block_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.formData.addresses.permanent.phone_number.hasErrors &&
                  "form-error"
                }`}
              >
                <label htmlFor="address-telephone_number">
                  Telephone Number / फोन नं
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentInputChange",
                      value: e.target.value,
                      field: " phone_number",
                    })
                  }
                  value={state.formData.addresses.permanent.phone_number.value}
                  type="number"
                  id="address-telephone_number"
                  className="form-input"
                  placeholder="Enter your phones number"
                />
                {state.formData.addresses.permanent.phone_number.hasErrors && (
                  <span className="error">
                    {state.formData.addresses.permanent.phone_number.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <h3>Upload your documents</h3>
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <p className="lead">Your PP size photo</p>
              </div>
              <div className="card-body">
                <DisplayPicture
                  setDisplayPicture={setDisplayPicture}
                  setDisplayPictureFile={setDisplayPictureFile}
                  setErrors={errors ? errors : ""}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.displayPicture.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.displayPicture.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <p className="lead">Your Citizenship card front</p>
              </div>
              <div className="card-body">
                <CitizenshipFront
                  setCitizenshipFront={setCitizenshipFront}
                  setCitizenshipFrontFile={setCitizenshipFrontFile}
                  setErrors={errors ? errors : ""}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.citizenshipFront.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.citizenshipFront.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <p className="lead">Your Citizenship card back</p>
              </div>
              <div className="card-body">
                <CitizenshipBack
                  setCitizenshipBack={setCitizenshipBack}
                  setCitizenshipBackFile={setCitizenshipBackFile}
                  setErrors={errors ? errors : ""}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.citizenshipBack.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.citizenshipBack.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <p className="lead">Your Signature</p>
              </div>
              <div className="card-body">
                <Signature
                  setSignature={setSignature}
                  setSignatureFile={setSignatureFile}
                  setErrors={errors ? errors : ""}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.signature.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.signature.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn-transparent mr-2 "
          // to="/customers/create/personalInformation"
          onClick={handleBackCount}
        >
          <span>← </span>
          Previous Page
        </span>
        <span className="btn-blue" onClick={handleSubmit}>
          Next Page
          <span> →</span>
        </span>
      </form>
      <InfoModal />
    </>
  );
}

export default withRouter(Guardian);
