import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useImmerReducer } from "use-immer";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  Alert,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import FormState from "../FormState";
import FormDispatch from "../FormDispatch";
import Account from "./Account";
import Address from "./Address";
import Documents from "./Documents";
import FamilyInformation from "./FamilyInformation";
import Guardian from "./Guardian";
import Nominee from "./Nominee";
import Occupation from "./Occupation";
import PersonalInfo from "./PersonalInfo";
import StateContext from "../../../StateContext";
import Loading from "../../Layout/Loading";

const CustomerView = () => {
  const appState = useContext(StateContext);
  const token = appState.client.data.token;
  const history = useHistory();
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
    hasNominee: false,
    nomineeData: {},
    hasGuardian: false,
    guardianData: {},
    uniqueId: "",
    saveCount: 0,
    sendCount: 0,
    formLevel: 0,
    showGuardian: false,
    showNominee: false,
    resMsg: "",
    rejectCount: {
      personal_informations: 0,
      addresses: 0,
      families: 0,
      occupations: 0,
      accounts: 0,
      documents: 0,
      guardian: 0,
      nominee: 0,
      payment_status: 0,
    },
    formData: {
      customers: {
        demat_account_type: "1",
        full_name: "",
        email: "",
        mobile: "",
        username: "",
        // password: "",
        is_minor: "1",
        nominee: "1",
      },
      personal_informations: {
        dob: "",
        gender: "",
        marital_status: "",
        nationality: "",
        pan_number: null,
        identity_card_type: "",
        identity_card_number: "",
        identity_card_issue_district: "",
        identity_card_issue_date: "",
      },
      addresses: {
        type: "",
        block_number: "",
        phone_number: "",
        ward_number: "",
        locality: "",
        municipality: "",
        district: "",
        province: "",
        country: "",
      },
      families: {
        father_name: "",
        grand_father_name: "",
        mother_name: "",
        spouse: "",
        son_name: "",
        daughter_name: "",
        father_in_law_name: "",
        daughter_in_law_name: "",
      },
      occupations: {
        title: "",
        type: "",
        organization_name: "",
        address: "",
        designation: "",
        financial_details: "",
      },
      accounts: {
        bank_name: "",
        bank_code: "",
        branch_name: "",
        number: "",
        type: "",
      },
      documents: {},
    },
    relationships: {},
    relationshipsGuardian: {},
    relationshipsNominee: {},
    documents_file: {},
    guardian_documents_file: {},
    nominee_documents_file: {},
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasNominee":
        draft.hasNominee = true;
        draft.nomineeData = action.value;
        return;
      case "hasGuardian":
        draft.hasGuardian = true;
        draft.guardianData = action.value;
        return;
      case "infoUpdate":
        draft.rejectCount.personal_informations++;
        return;
      case "addressUpdate":
        draft.rejectCount.addresses++;
        return;
      case "familyUpdate":
        draft.rejectCount.families++;
        return;
      case "nomineeUpdate":
        draft.rejectCount.nominee++;
        return;
      case "guardianUpdate":
        draft.rejectCount.guardian++;
        return;
      case "accountUpdate":
        draft.rejectCount.accounts++;
        return;
      case "occupationUpdate":
        draft.rejectCount.occupations++;
        return;
      case "documentsUpdate":
        draft.rejectCount.documents++;
        return;
      case "payment_statusUpdate":
        draft.rejectCount.payment_status++;
        return;
      case "customerUpdate":
        console.log("this reducer was hit on customer form main");
        console.log(action.value.email, "action.value");
        draft.formData.customers.email = action.value.email;
        draft.formData.customers.mobile = action.value.mobile;
        draft.formData.customers.id = action.value.id;
        draft.formData.customers.registration_number =
          action.value.registration_number;
        draft.uniqueId = action.value.uniqueId;
        return;
      case "saveUsername":
        draft.formData.customers.username = action.value;
        return;
      case "savePassword":
        draft.formData.customers.password = action.value;
        return;
      case "savePersonalInfo":
        draft.formData.personal_informations = action.value;
        return;
      case "saveGuardian":
        draft.relationshipsGuardian = action.value;
        return;
      case "minorTrue":
        draft.formData.customers.is_minor = "2";
        draft.showGuardian = true;
        // draft.formData.documents = action.value;
        return;
      case "minorFalse":
        draft.formData.customers.is_minor = "1";
        draft.showGuardian = false;
        // draft.formData.documents = action.value;
        return;
      case "nomineeTrue":
        draft.formData.customers.nominee = "2";
        draft.showNominee = true;
        return;
      case "saveNominee":
        draft.relationshipsNominee = action.value;
        return;
      case "nomineeFalse":
        draft.formData.customers.nominee = "1";
        draft.showNominee = false;
        return;

      case "saveFullName":
        draft.formData.customers.full_name = action.value;
        return;
      case "saveAddress":
        draft.formData.addresses = action.value;
        return;
      case "saveFamily":
        draft.formData.families = action.value;
        return;
      case "saveOccupation":
        draft.formData.occupations = action.value;
        return;
      case "saveAccount":
        draft.formData.accounts = action.value;
        return;
      case "saveDocuments":
        draft.formData.documents = action.value;
        return;
      case "saveDocumentsFile":
        draft.documents_file = action.value;
        return;
      case "saveGuardianDocumentsFile":
        draft.guardian_documents_file = action.value;
        return;
      case "saveNomineeDocumentsFile":
        draft.nominee_documents_file = action.value;
        return;
      case "saveResMsg":
        draft.resMsg = action.value;
        return;
      case "changeCount":
        draft.sendCount++;
        return;
      case "saveForm":
        draft.saveCount++;
      case "default":
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          {
            cancelToken: ourRequest.token,
          }
        );
        console.log(response.data.message);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customer");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    if (state.userLoaded) {
      if (state.userData.relationships && state.userData.relationships.length) {
        state.userData.relationships.map((item, i) => {
          if (item.type === "nominee") {
            dispatch({ type: "hasNominee", value: item });
            // dispatch({ type: "nomineeTrue", value: item });
          }
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardian", value: item });
          }
        });
      }
      if (state.userData.status == "rejected") {
        console.log("this form was rejected");
        dispatch({ type: "disableReject" });
      }
    }
  }, [state.userLoaded]);

  // Is minor ?
  useEffect(() => {
    if (state.formData.documents === "false") {
      if (state.userData.is_minor == "yes") {
        dispatch({
          type: "minorTrue",
          value: {
            birth_certificate_name:
              state.userData.documents[0]?.birth_certificate_name,
            birth_certificate_id:
              state.userData.documents[0]?.birth_certificate_id,
            lat: state.userData.documents[0]?.lat,
            long: state.userData.documents[0]?.long,
          },
        });
      }
      if (state.userData.is_minor == "no") {
        dispatch({
          type: "minorFalse",
          value: {
            id: state.userData.documents[0]?.id,
            photo: state.userData.documents[0]?.photo_name,
            photo_id: state.userData.documents[0]?.photo_id,
            gov_issued_id_front:
              state.userData.documents[0]?.gov_issued_id_front_name,
            gov_issued_id_front_id:
              state.userData.documents[0]?.gov_issued_id_front_id,
            gov_issued_id_back:
              state.userData.documents[0]?.gov_issued_id_back_name,
            gov_issued_id_back_id:
              state.userData.documents[0]?.gov_issued_id_back_id,
            thumb_left: state.userData.documents[0]?.thumb_left_name,
            thumb_left_id: state.userData.documents[0]?.thumb_left_id,
            thumb_right: state.userData.documents[0]?.thumb_right_name,
            thumb_right_id: state.userData.documents[0]?.thumb_right_id,
            signature_name: state.userData.documents[0]?.signature_name,
            signature_id: state.userData.documents[0]?.signature_id,
            lat: state.userData.documents[0]?.lat,
            long: state.userData.documents[0]?.long,
          },
        });
      }
    }
  }, [state.formData.documents]);

  // Is nominee ?
  useEffect(() => {
    if (state.userData) {
      if (state.userData.nominee == "yes") {
        dispatch({ type: "nomineeTrue" });
      }
    }
  }, [state.userLoaded]);

  useEffect(() => {
    state.userData.reasons?.map((item, i) => {
      if (item.type === "personal_information") {
        dispatch({ type: "infoUpdate" });
      }
      if (item.type === "address") {
        dispatch({ type: "addressUpdate" });
      }
      if (item.type === "family") {
        dispatch({ type: "familyUpdate" });
      }
      if (item.type === "nominee") {
        dispatch({ type: "nomineeUpdate" });
      }
      if (item.type === "guardian") {
        dispatch({ type: "guardianUpdate" });
      }
      if (item.type === "account") {
        dispatch({ type: "accountUpdate" });
      }
      if (item.type === "occupation") {
        dispatch({ type: "occupationUpdate" });
      }
      if (item.type === "documents") {
        dispatch({ type: "documentsUpdate" });
      }
      if (item.type === "payment_status") {
        dispatch({ type: "payment_statusUpdate" });
      }
    });
    dispatch({ type: "saveUsername", value: state.userData.username });
  }, [state.userLoaded]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      let dataToSend = {};

      if (state.userData.is_minor == "yes" && state.userData.nominee == "no") {
        console.log("the user is a minor from the customer form");
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsGuardian],
        };
      } else if (
        state.userData.is_minor == "no" &&
        state.userData.nominee == "yes"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsNominee],
        };
      } else if (
        state.userData.is_minor == "yes" &&
        state.userData.nominee == "yes"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [
            state.relationshipsGuardian,
            state.relationshipsNominee,
          ],
        };
      } else {
        dataToSend = state.formData;
      }

      if (state.userData.reasons.length > 0) {
        let reason_id = [];
        state.userData.reasons.map((item, i) => {
          reason_id.push(item.id);
        });
        console.log(reason_id);
        dataToSend = {
          ...state.formData,
          reasonIds: reason_id,
        };
        console.log(dataToSend);
      }
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.client.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      console.log(dataToSend);
      async function register() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
            dataToSend,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          dispatch({
            type: "saveResMsg",
            value: response.data.success,
          });
          dispatch({ type: "changeCount" });
          console.log(response);
        } catch (e) {
          console.log(e.response, "there was an error");
          dispatch({
            type: "saveResMsg",
            value: e.response?.data?.success,
          });
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);
  console.log(state.saveCount);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.sendCount > 0) {
      const formData = new FormData();
      formData.append("email", state.formData.customers.email);
      if (
        state.userData.is_minor === "yes" &&
        state.userData.nominee === "no"
      ) {
        console.log("the user is a minor from the customer form");
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
      } else if (
        state.userData.is_minor === "no" &&
        state.userData.nominee === "yes"
      ) {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else if (
        state.userData.is_minor == "yes" &&
        state.userData.nominee == "yes"
      ) {
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
      }
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.client.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/upload_image`,
            formData,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
          window.location.reload();
          // history.push("/dashboard");
        } catch (e) {
          console.log(e.response, "there was an error");
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.sendCount]);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "saveForm" });
  }

  if (!state.userLoaded) {
    return (
      <div className="admin">
        <Loading />
      </div>
    );
  }

  return (
    <FormState.Provider value={state}>
      <FormDispatch.Provider value={dispatch}>
        <Container>
          <Card className="shadow rejected-card">
            <CardHeader className="border-0 d-flex reject-title overflow-auto rejected-card-header">
              {state.userData.reasons.map((item, i) => (
                <h4 className="mb-0 px-2" key={i}>
                  {item.title}
                </h4>
              ))}
            </CardHeader>
          </Card>
          <Accordion defaultActiveKey="7" className="accordian">
            <Accordion.Item eventKey="0" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.personal_informations > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.personal_informations}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Personal Information
              </Accordion.Header>
              <Accordion.Body>
                <PersonalInfo data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
            {state.hasGuardian && (
              <Accordion.Item eventKey="1" className="accordion-item">
                <Accordion.Header className="accordian-header">
                  {state.rejectCount.guardian > 0 ? (
                    <span className="badge badge-danger mx-3">
                      {state.rejectCount.guardian}
                    </span>
                  ) : (
                    <span className="badge badge-success mx-3">✔</span>
                  )}
                  Guardian
                </Accordion.Header>
                <Accordion.Body>
                  <Guardian
                    userData={state.userData}
                    data={state.hasGuardian ? state.guardianData : ""}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            <Accordion.Item eventKey="2" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.addresses > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.addresses}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Address
              </Accordion.Header>
              <Accordion.Body>
                <Address data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.families > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.families}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Family
              </Accordion.Header>
              <Accordion.Body>
                <FamilyInformation data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
            {state.hasNominee && (
              <Accordion.Item eventKey="4" className="accordion-item">
                <Accordion.Header className="accordian-header">
                  {state.rejectCount.nominee > 0 ? (
                    <span className="badge badge-danger mx-3">
                      {state.rejectCount.nominee}
                    </span>
                  ) : (
                    <span className="badge badge-success mx-3">✔</span>
                  )}
                  Nominee
                </Accordion.Header>
                <Accordion.Body>
                  <Nominee
                    userData={state.userData}
                    data={state.hasNominee ? state.nomineeData : ""}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            <Accordion.Item eventKey="5" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.accounts > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.accounts}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Account
              </Accordion.Header>
              <Accordion.Body>
                <Account data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.occupations > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.occupations}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Occupation
              </Accordion.Header>
              <Accordion.Body>
                <Occupation data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="accordion-item">
              <Accordion.Header className="accordian-header">
                {state.rejectCount.documents > 0 ? (
                  <span className="badge badge-danger mx-3">
                    {state.rejectCount.documents}
                  </span>
                ) : (
                  <span className="badge badge-success mx-3">✔</span>
                )}
                Documents
              </Accordion.Header>
              <Accordion.Body>
                <Documents data={state.userData} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {state.resMsg === false && (
            <Row className="mt-4">
              <Col lg="12">
                <Alert className="alert-danger mt-4 text-center">
                  There was an error !!!
                </Alert>
              </Col>
            </Row>
          )}

          {state.resMsg === true && (
            <Row className="mt-4">
              <Col lg="12">
                <Alert className="alert-success mt-4 text-center">
                  Customer Updated Successfully
                </Alert>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col lg="10"></Col>
            <Col lg="2" className="d-flex justify-content-end">
              <Button onClick={handleSubmit} className="btn-default">
                ReSubmit
              </Button>
            </Col>
          </Row>
        </Container>
      </FormDispatch.Provider>
    </FormState.Provider>
  );
};

export default CustomerView;
