import React, { useState, useEffect, useContext } from "react";
import { useParams, Switch, Route, Link } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";

// reactstrap components
import { CardHeader, Table, Button } from "reactstrap";

//COMPONENTS
import StateContext from "../../../../StateContext";
import {
  customerRecordRoutes,
  customerRecordRoutesDoc,
} from "../../../Handler/Routes";

import PersonalInfo from "./PersonalInfo";
import Guardian from "./Guardian";
import Address from "./Address";
import Family from "./Family";
import Nominee from "./Nominee";
import Occupation from "./Occupation";
import Account from "./Account";
import Documents from "./Documents";
import RejectPanel from "./rejectPanel";
import Loading from "../../../Layout/Loading";
import { Verify } from "../../../Layout/Icons";
import Payment from "./Payment";

function CustomerView() {
  const appState = useContext(StateContext);
  const [routes, setRoutes] = useState(customerRecordRoutes);
  const { id } = useParams();
  const [active, setActive] = useState("");
  const segment = new URL(window.location.href).pathname
    .split("/")
    .filter(Boolean)
    .pop();

  const initialState = {
    userLoaded: false,
    hasNominee: false,
    hasNomineeDoc: false,
    nomineeData: {},
    nomineeDoc: {},
    hasGuardian: false,
    guardianData: {},
    hasGuardianDoc: {},
    guardianDoc: {},
    userData: {},
    temporaryAddress: {},
    permanentAddress: {},
    showRejectPanel: false,
    documentApprove: {
      customers: {
        document_verified: "2",
      },
    },
    paymentApprove: {
      customers: {
        payment_verified: "2",
      },
    },
    rejectForm: {
      customers: {
        status: "5",
      },
      reason: {},
    },
    hasApproved: 0,
    hasDocumentApproved: 0,
    hasPaymentApproved: 0,
    sendCount: 0,
    sendPayment: 0,
    sendDocument: 0,
    paymentRejectCount: 0,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasNominee":
        draft.hasNominee = true;
        draft.nomineeData = action.value;
        return;
      case "hasNomineeDoc":
        draft.hasNomineeDoc = true;
        draft.nomineeDoc = action.value;
        return;
      case "hasGuardian":
        draft.hasGuardian = true;
        draft.guardianData = action.value;
        return;
      case "hasGuardianDoc":
        draft.hasGuardianDoc = true;
        draft.guardianDoc = action.value;
        return;
      case "updateTemporaryAddress":
        draft.temporaryAddress = action.value;
        return;
      case "updatePermanentAddress":
        draft.permanentAddress = action.value;
        return;
      case "toggleRejectPanel":
        draft.showRejectPanel = !draft.showRejectPanel;
        return;
      case "rejectForm":
        draft.formRejected = true;
        draft.showRejectPanel = false;
        return;
      case "rejectPayment":
        draft.paymentRejectCount++;
        return;
      case "disableReject":
        draft.formRejected = true;
        return;
      case "saveApproved":
        draft.hasApproved++;
        return;
      case "saveDocumentApproved":
        draft.hasDocumentApproved++;
        return;
      case "savePaymentApproved":
        draft.hasPaymentApproved++;
        return;
      case "submitDocument":
        draft.sendDocument++;
        return;
      case "submitPayment":
        draft.sendPayment++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);
  // console.log(state.hasGuardian);
  useEffect(() => {
    if (state.userLoaded) {
      if (state.userData.relationships && state.userData.relationships.length) {
        state.userData.relationships.map((item, i) => {
          if (item.type === "nominee") {
            dispatch({ type: "hasNominee", value: item });
            dispatch({ type: "hasNomineeDoc", value: item.documents[0] });
          }
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardian", value: item });
            dispatch({ type: "hasGuardianDoc", value: item.documents[0] });
          }
        });
      }

      if (state.userData.addresses && state.userData.addresses.length) {
        state.userData.addresses.map((item, i) => {
          if (item.type == "temporary") {
            dispatch({ type: "updateTemporaryAddress", value: item });
          }
          if (item.type == "permanent") {
            dispatch({ type: "updatePermanentAddress", value: item });
          }
        });
      }
      if (state.userData.status == "rejected") {
        console.log("this formm was rejected");
        dispatch({ type: "disableReject" });
      }
    }
  }, [state.userLoaded]);

  console.log(state.userData);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          { cancelToken: ourRequest.token }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const bodyParameters = state.rejectForm;
    const ourRequest = axios.CancelToken.source();
    if (state.paymentRejectCount) {
      async function rejectPayment() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}/verify`,
            bodyParameters,
            { cancelToken: ourRequest.token }
          );
          console.log(response.data);
          dispatch({ type: "formRejected" });
          window.location.reload();
        } catch (e) {
          console.log(e, "there was an error approving customer");
        }
      }
      rejectPayment();
    }
    return () => ourRequest.cancel();
  }, [state.paymentRejectCount]);

  useEffect(() => {
    console.log(state.sendDocument);
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    let approveDocumentData = { ...state.documentApprove };
    const ourRequest = axios.CancelToken.source();
    if (state.sendDocument) {
      async function approveDocument() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}/document_verify`,
            approveDocumentData,
            { cancelToken: ourRequest.token }
          );
          console.log(response.data);
          dispatch({ type: "saveDocumentApproved" });
          window.location.reload();
        } catch (e) {
          console.log(e, "there was an error approving customer");
        }
      }
      approveDocument();
      return () => ourRequest.cancel();
    }
  }, [state.sendDocument]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    let approvePaymentData = { ...state.paymentApprove };
    const ourRequest = axios.CancelToken.source();
    if (state.sendPayment) {
      async function approvePayment() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}/payment_verify`,
            approvePaymentData,
            { cancelToken: ourRequest.token }
          );
          console.log(response.data);
          dispatch({ type: "savePaymentApproved" });
          window.location.reload();
        } catch (e) {
          console.log(e, "there was an error approving customer");
        }
      }
      approvePayment();
      return () => ourRequest.cancel();
    }
  }, [state.sendPayment]);

  const handleApprove = () => {
    dispatch({ type: "submitForm" });
  };
  const handleApprovePayment = () => {
    dispatch({ type: "submitPayment" });
  };
  const handleApproveDocument = () => {
    dispatch({ type: "submitDocument" });
  };

  function toggleRejectPanel() {
    dispatch({ type: "toggleRejectPanel" });
  }

  function rejectForm() {
    dispatch({ type: "rejectForm" });
  }
  function handleRejectPayment() {
    dispatch({ type: "rejectPayment" });
  }
  useEffect(() => {
    if (appState.user.data.user.roles[0]?.name === "document_manager") {
      setRoutes(customerRecordRoutesDoc);
    }
  }, [appState.user.data.user.roles[0]]);

  if (!state.userLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <CardHeader className={`border-0 `}>
        <Link className="btn btn-neutral" to="/admin/dashboard/customers">
          <span>← </span> Back
        </Link>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <h3 className={`mb-0`}>Customer Record</h3>
            <span className="d-flex ml-3">
              {state.userData?.status === "verified" && (
                <>
                  <Verify props={"text-success ml-2"} />
                  <h3 className="mb-0 text-success">Verified</h3>
                </>
              )}
              {state.userData?.status === "rejected" && (
                <>
                  <Verify props={"text-danger ml-2"} />
                  <h3 className="mb-0 text-danger">Rejected</h3>
                </>
              )}
              {state.userData?.document_verified === "yes" &&
                state.userData?.payment_verified === "no" && (
                  <>
                    <Verify props={"text-success ml-2"} />
                    <h3 className="mb-0 text-success">Document Verified</h3>
                  </>
                )}
            </span>
          </div>
          <div>
            <Link to={`/kyc/${id}`} target="_blank" className="py-2 mx-2">
              <Button className="btn-default">View KYC</Button>
            </Link>
            <Link to={`/meroShare/${id}`} target="_blank" className="py-2 mx-2">
              <Button className="btn-default"> View Mero Share Form</Button>
            </Link>
            <Link to={`/renew/${id}`} target="_blank" className="py-2 mx-2">
              <Button className="btn-default"> View Renew Form</Button>
            </Link>
            {(appState.user.data.user.roles[0]?.name === "admin_manager" ||
              appState.user.data.user.roles[0]?.name === "document_manager") &&
              state.userData?.status === "pending" && (
                <Link
                  to={`/admin/dashboard/customers/edit/${id}`}
                  className="py-2 mx-2"
                >
                  <Button className="btn-default">Edit Customer</Button>
                </Link>
              )}
          </div>
        </div>
        <div className="button-group">
          {appState.user.data.user.roles[0]?.name !== "super_admin" && (
            <>
              {state.hasApproved || state.userData?.status === "pending" ? (
                <>
                  {appState.user.data.user.roles[0]?.name !==
                    "document_manager" &&
                  state.userData?.document_verified === "yes" ? (
                    <Button
                      onClick={handleRejectPayment}
                      className="btn-danger"
                      disabled={state.formRejected}
                    >
                      Reject
                    </Button>
                  ) : (
                    <>
                      <Button
                        onClick={toggleRejectPanel}
                        className="btn-danger"
                        disabled={state.formRejected}
                      >
                        {!state.formRejected
                          ? `${
                              state.showRejectPanel
                                ? "Cancle Reject"
                                : "Reject Form"
                            }`
                          : "Rejected"}
                      </Button>
                    </>
                  )}

                  {/* {appState.user.data.user.roles[0]?.name !== "document_manager" ? (
<>
                  {!state.formRejected && (
                    <Button className="btn-success" onClick={handleApprove}>
                      Approve Payment
                    </Button>
                  )}
                  
          ) :( {!state.formRejected && (
                    <Button className="btn-success" onClick={handleApprove}>
                      Approve Document
                    </Button>
                  )}
                  </>
          )
          } */}
                  {appState.user.data.user.roles[0]?.name ===
                  "payment_manager" ? (
                    <>
                      {!state.formRejected &&
                        state.userData?.payment_verified === "no" &&
                        state.userData?.payment_status === "paid" && (
                          <Button
                            className="btn-success"
                            onClick={handleApprovePayment}
                          >
                            Approve Payment
                          </Button>
                        )}
                    </>
                  ) : (
                    <>
                      {!state.formRejected &&
                        state.userData?.document_verified === "no" &&
                        state.userData?.payment_status === "paid" && (
                          <Button
                            className="btn-success"
                            onClick={handleApproveDocument}
                          >
                            Approve Document
                          </Button>
                        )}
                      {appState.user.data.user.roles[0]?.name ===
                        "admin_manager" && (
                        <>
                          {!state.formRejected &&
                            state.userData?.payment_verified === "no" &&
                            state.userData?.document_verified === "yes" &&
                            state.userData?.payment_status === "paid" && (
                              <Button
                                className="btn-success"
                                onClick={handleApprovePayment}
                              >
                                Approve Payment
                              </Button>
                            )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </CardHeader>
      {/* <Button>
        <Link to="/admin/dashboard/customers"> Customer List</Link>
      </Button> */}

      {state.showRejectPanel && (
        <div className="reject-panel-container">
          <RejectPanel rejectForm={rejectForm} userData={state.userData} />
        </div>
      )}
      {appState.user.data.user.roles[0]?.name !== "payment_manager" && (
        <>
          <Table className="align-items-center table-flush mb-5" responsive>
            <thead className="thead-light">
              <tr>
                {routes.map((item, i) => (
                  <th scope="col" key={i}>
                    <Button
                      className={`btn-neutral px-0 ${
                        item.path === segment && "active"
                      }`}
                      onClick={() => setActive(item.path)}
                    >
                      <Link
                        to={`/admin/dashboard/customers/view/${id}/${item.path}`}
                        className="py-4 px-5"
                      >
                        {item.name}
                      </Link>
                    </Button>
                  </th>
                ))}
              </tr>
            </thead>
          </Table>
          <Switch>
            <Route path="/admin/dashboard/customers/view/:id" exact>
              <PersonalInfo
                fullName={state.userData?.full_name}
                userName={state.userData?.username}
                mobile={state.userData?.mobile}
                email={state.userData?.email}
                isMinor={state.userData?.is_minor}
                nominee={state.userData?.nominee}
                accountType={state.userData?.demat_account_type}
                dematAcType={state.userData?.demat_account_type}
                personalInfo={
                  state.userData?.personal_information
                    ? state.userData?.personal_information[0]
                    : ""
                }
              />
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/guardian" exact>
              {state.hasGuardian ? (
                <Guardian guardian={state.guardianData} />
              ) : (
                <h2 className="text-center mb-5">No guardian data ! . . .</h2>
              )}
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/address" exact>
              <Address
                temporary={state.temporaryAddress}
                permanent={state.permanentAddress}
              />
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/family" exact>
              <Family
                family={
                  state.userData.families ? state.userData.families[0] : ""
                }
              />
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/nominee" exact>
              {state.hasNominee ? (
                <Nominee nominee={state.hasNominee ? state.nomineeData : ""} />
              ) : (
                <h2 className="text-center mb-5">No nominee data ! . .</h2>
              )}
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/occupation" exact>
              <Occupation
                occupation={
                  state.userData.occupations.length
                    ? state.userData.occupations[0]
                    : ""
                }
              />
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/account" exact>
              <Account
                account={
                  state.userData.accounts ? state.userData.accounts[0] : ""
                }
              />
            </Route>
            <Route path="/admin/dashboard/customers/view/:id/documents" exact>
              <Documents
                email={state.userData?.email}
                documents={
                  state.userData.documents ? state.userData.documents[0] : ""
                }
                guardian={
                  state.hasGuardianDoc === true ? state.guardianDoc : false
                }
                nominee={
                  state.hasNomineeDoc === true ? state.nomineeDoc : false
                }
              />
            </Route>
            {appState.user.data.user.roles[0]?.name === "admin_manager" && (
              <Route path="/admin/dashboard/customers/view/:id/payments" exact>
                <Payment
                  email={state.userData.email}
                  payment={
                    state.userData.payments ? state.userData.payments[0] : ""
                  }
                />
              </Route>
            )}
          </Switch>
        </>
      )}
      {appState.user.data.user.roles[0]?.name === "payment_manager" && (
        <>
          {/* <Table className="align-items-center table-flush mb-5" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <Button
                    className={`active`}
                    onClick={() => setActive("payments")}
                  >
                    <Link
                      to={`/admin/dashboard/customers/view/${id}/payments`}
                      className="py-4 px-5"
                    >
                      Payments
                    </Link>
                  </Button>
                </th>
              </tr>
            </thead>
          </Table> */}
          {/* <Switch>
            <Route path="/admin/dashboard/customers/view/:id/payments" exact> */}
          <Payment
            email={state.userData.email}
            payment={state.userData.payments ? state.userData.payments[0] : ""}
          />
          {/* </Route>
          </Switch> */}
        </>
      )}
    </div>
  );
}

export default CustomerView;
