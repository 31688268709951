import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import FormDispatch from "../FormDispatch";
import provinceData from "../../../Assets/province-district.json";
// import districts from "../../../Assets/district-list-nepal";
import country from "../../../Assets/country";
import FormState from "../FormState";
import { Info } from "../../Layout/Icons";

function Address(props) {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);
  const [savecount, setSaveCount] = useState(0);
  const intitalState = {
    backCount: 0,
    temporary: {
      // type: "1",
      block_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        touched: false,
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
      },
      province: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
    permanent: {
      // type: "2",
      block_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      province: {
        value: "",
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "temporaryCountryChange":
        draft.temporary.country.hasErrors = false;
        draft.temporary.country.value = action.value;
        draft.temporary.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.temporary.province.list.push(key);
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "temporaryProvincechange":
        draft.temporary.province.hasErrors = false;
        draft.temporary.province.value = action.value;
        draft.temporary.province.touched = true;
        //empty distrct listToShow
        draft.temporary.district.listToShow = [];
        //empty distrct value
        draft.temporary.district.value = "";
        //filter the array
        let selectedProvince;
        for (const key in provinceData) {
          selectedProvince = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince.map((item, i) => {
          draft.temporary.district.listToShow.push(item.district);
        });
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          draft.temporary.district.list.push();
          provinceData[key].map((item, i) => {
            // console.log("from the array", item.district)
            draft.temporary.district.list.push(item.district);
            draft.permanent.district.list.push(item.district);
          });
        }
        draft.temporary.district.listToShow = draft.temporary.district.list;
        draft.permanent.district.listToShow = draft.permanent.district.list;
        return;
      case "temporaryDistrictChange":
        draft.temporary.district.hasErrors = false;
        draft.temporary.district.value = action.value;
        draft.temporary.district.touched = true;
        //empty municipality listToShow
        draft.temporary.municipality.listToShow = [];
        //empty municipality value
        draft.temporary.municipality.value = "";
        //filter the array
        let selectedDistrict;
        for (const key in provinceData) {
          selectedDistrict = provinceData[draft.temporary.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.list.push(item);
              draft.permanent.municipality.list.push(item);
            });
          });
        }
        draft.temporary.municipality.listToShow =
          draft.temporary.municipality.list;
        draft.permanent.municipality.listToShow =
          draft.permanent.municipality.list;
        return;
      case "temporaryMunicipalityChange":
        draft.temporary.municipality.hasErrors = false;
        draft.temporary.municipality.value = action.value;
        draft.temporary.municipality.touched = true;
        return;
      case "temporaryCityChange":
        draft.temporary.city.hasErrors = false;
        draft.temporary.city.value = action.value;
        draft.temporary.city.touched = true;
        return;
      case "temporaryLocalityChange":
        draft.temporary.locality.hasErrors = false;
        draft.temporary.locality.value = action.value;
        draft.temporary.locality.touched = true;
        return;
      case "temporaryWardChange":
        draft.temporary.ward_number.hasErrors = false;
        draft.temporary.ward_number.value = action.value;
        draft.temporary.ward_number.touched = true;
        return;
      case "temporaryBlockChange":
        draft.temporary.block_number.hasErrors = false;
        draft.temporary.block_number.value = action.value;
        draft.temporary.block_number.touched = true;
        return;
      case "temporaryPhoneChange":
        draft.temporary.phone_number.hasErrors = false;
        draft.temporary.phone_number.value = action.value;
        draft.temporary.phone_number.touched = true;
        return;
      //PERMANENT
      case "permanentCountryChange":
        draft.permanent.country.hasErrors = false;
        draft.permanent.country.value = action.value;
        draft.permanent.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.permanent.province.list.length) {
          for (const key in provinceData) {
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "permanentProvincechange":
        draft.permanent.province.hasErrors = false;
        draft.permanent.province.value = action.value;
        draft.permanent.province.touched = true;
        //empty distrct listToShow
        draft.permanent.district.listToShow = [];
        //empty distrct value
        draft.permanent.district.value = "";
        //filter the array
        let selectedProvince2;
        for (const key in provinceData) {
          selectedProvince2 = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince2.map((item, i) => {
          draft.permanent.district.listToShow.push(item.district);
        });
        return;
      case "permanentDistrictChange":
        draft.permanent.district.hasErrors = false;
        draft.permanent.district.value = action.value;
        draft.permanent.district.touched = true;
        //empty municipality listToShow
        draft.permanent.municipality.listToShow = [];
        //empty municipality value
        draft.permanent.municipality.value = "";
        //filter the array
        let selectedDistrict2;
        for (const key in provinceData) {
          selectedDistrict2 = provinceData[draft.permanent.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict2.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.permanent.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "permanentMunicipalityChange":
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.municipality.value = action.value;
        draft.permanent.municipality.touched = true;
        return;
      case "permanentCityChange":
        draft.permanent.city.hasErrors = false;
        draft.permanent.city.value = action.value;
        draft.permanent.city.touched = true;
        return;
      case "permanentLocalityChange":
        draft.permanent.locality.hasErrors = false;
        draft.permanent.locality.value = action.value;
        draft.permanent.locality.touched = true;
        return;
      case "permanentWardChange":
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.ward_number.value = action.value;
        draft.permanent.ward_number.touched = true;
        return;
      case "permanentBlockChange":
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.block_number.value = action.value;
        draft.permanent.block_number.touched = true;
        return;
      case "permanentPhoneChange":
        draft.permanent.phone_number.hasErrors = false;
        draft.permanent.phone_number.value = action.value;
        draft.permanent.phone_number.touched = true;
        return;
      case "sameAsCurrent":
        draft.permanent.country.value = draft.temporary.country.value;
        draft.permanent.country.touched = true;
        draft.permanent.province.value = draft.temporary.province.value;
        draft.permanent.province.touched = true;
        draft.permanent.district.value = draft.temporary.district.value;
        draft.permanent.district.touched = true;
        draft.permanent.municipality.value = draft.temporary.municipality.value;
        draft.permanent.municipality.touched = true;
        draft.permanent.city.value = draft.temporary.city.value;
        draft.permanent.city.touched = true;
        draft.permanent.locality.value = draft.temporary.locality.value;
        draft.permanent.locality.touched = true;
        draft.permanent.ward_number.value = draft.temporary.ward_number.value;
        draft.permanent.ward_number.touched = true;
        draft.permanent.block_number.value = draft.temporary.block_number.value;
        draft.permanent.block_number.touched = true;
        draft.permanent.phone_number.value = draft.temporary.phone_number.value;
        draft.permanent.phone_number.touched = true;
        draft.permanent.country.hasErrors = false;
        draft.permanent.province.hasErrors = false;
        draft.permanent.district.hasErrors = false;
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.city.hasErrors = false;
        draft.permanent.locality.hasErrors = false;
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.permanent.country.value = "";
        draft.permanent.province.value = "";
        draft.permanent.district.value = "";
        draft.permanent.municipality.value = "";
        draft.permanent.city.value = "";
        draft.permanent.locality.value = "";
        draft.permanent.ward_number.value = "";
        draft.permanent.block_number.value = "";
        draft.permanent.phone_number.value = "";
        draft.permanent.country.touched = false;
        draft.permanent.province.touched = false;
        draft.permanent.district.touched = false;
        draft.permanent.municipality.touched = false;
        draft.permanent.city.touched = false;
        draft.permanent.locality.touched = false;
        draft.permanent.ward_number.touched = false;
        draft.permanent.block_number.touched = false;
        draft.permanent.phone_number.touched = false;
        return;
      case "validationRules":
        let errorCount = 0;
        for (const key in draft) {
          for (const key2 in draft[key]) {
            if (!draft[key][key2].touched || draft[key][key2].value == "") {
              // console.log(draft[key][key2])
              draft[key][key2].hasErrors = true;
              draft[key][key2].message = "This field is required";
            }
          }
        }
        for (const key in draft) {
          for (const key2 in draft[key]) {
            if (draft[key][key2].hasErrors) {
              errorCount++;
            }
          }
        }
        if (draft.temporary.city.value === "") {
          errorCount--;
          draft.temporary.city.hasErrors = false;
        }
        if (draft.temporary.block_number.value === "") {
          errorCount--;
          draft.temporary.block_number.hasErrors = false;
        }
        if (draft.temporary.phone_number.value === "") {
          errorCount--;
          draft.temporary.phone_number.hasErrors = false;
        }
        if (draft.permanent.city.value === "") {
          errorCount--;
          draft.permanent.city.hasErrors = false;
        }
        if (draft.permanent.block_number.value === "") {
          errorCount--;
          draft.permanent.block_number.hasErrors = false;
        }
        if (draft.permanent.phone_number.value === "") {
          errorCount--;
          draft.permanent.phone_number.hasErrors = false;
        }
        console.log("errorCount", errorCount);
        console.log(!errorCount);
        {
          !errorCount && setSaveCount(savecount + 1);
        }
        // console.log(saveCount)
        console.log(savecount);
        return;
      case "setBackCount":
        draft.backCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, intitalState);
  // function fetchProvinceList() {
  //   "<option> some rendered province name </option>"
  // }

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  useEffect(() => {
    console.log("save count form the useeffect", savecount);
    console.log("save the the state and navigate to another page");
    if (savecount) {
      console.log("there is a savecount");
      formDispatch({
        type: "saveAddress",
        value: [
          {
            type: "1",
            block_number: state.temporary.block_number.value,
            phone_number: state.temporary.phone_number.value,
            ward_number: state.temporary.ward_number.value,
            locality: state.temporary.locality.value,
            municipality: state.temporary.municipality.value,
            district: state.temporary.district.value,
            province: state.temporary.province.value,
            country: state.temporary.country.value,
            city: state.temporary.city.value,
          },
          {
            type: "2",
            block_number: state.permanent.block_number.value,
            phone_number: state.permanent.phone_number.value,
            ward_number: state.permanent.ward_number.value,
            locality: state.permanent.locality.value,
            municipality: state.permanent.municipality.value,
            district: state.permanent.district.value,
            province: state.permanent.province.value,
            country: state.permanent.country.value,
            city: state.permanent.city.value,
          },
        ],
      });
      // props.history.push("/customers/create/familyInformation");
      formDispatch({ type: "activeTabs", value: "family" });
    }

    // console.log(Object.values(state))
  }, [savecount]);

  function handleCheck(e) {
    // console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  function handleSubmit() {
    dispatch({ type: "validationRules" });
  }

  function handleBackCount(e) {
    e.preventDefault();
    dispatch({ type: "setBackCount" });
  }

  useEffect(() => {
    if (state.backCount > 0) {
      if (formState.showGuardian === true) {
        formDispatch({ type: "activeTabs", value: "guardian" });
      }
      if (formState.showGuardian === false) {
        formDispatch({ type: "activeTabs", value: "personalInfo" });
      }
    }
  }, [state.backCount]);

  return (
    <>
      <form action="" className="form position-relative">
        <div className="form-container address">
          <div className="text-container">
            <h2>Address details</h2>
            <span
              className="position-absolute end-0 top-0"
              onClick={() => {
                formDispatch({ type: "setInfo" });
              }}
            >
              <Info />
            </span>
          </div>

          <h3>Current Address</h3>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.country.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-country">Country / राष्ट्र</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryCountryChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.country.value}
                  name=""
                  id="address-country"
                  className="form-input"
                  placeholder="Select a country"
                  required
                >
                  {country.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.country.hasErrors && (
                  <span className="error">
                    {state.temporary.country.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.province.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-province">Province / प्रदेश</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryProvincechange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.province.value}
                  name=""
                  id="address-province"
                  className="form-input"
                  required
                >
                  {state.temporary.province.list.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.province.hasErrors && (
                  <span className="error">
                    {state.temporary.province.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.district.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-district">District / जिल्ला</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryDistrictChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.district.value}
                  name=""
                  id="address-district"
                  className="form-input"
                  placeholder="Select a District"
                  required
                >
                  {state.temporary.district.listToShow.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.district.hasErrors && (
                  <span className="error">
                    {state.temporary.district.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.city.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-city">City / शहर</label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryCityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.city.value}
                  type="text"
                  id="address-city"
                  className="form-input"
                  placeholder="Your City Name"
                />
                {state.temporary.city.hasErrors && (
                  <span className="error">{state.temporary.city.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.municipality.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-municipality">
                  Municipality / न.पा
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryMunicipalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.municipality.value}
                  type="text"
                  id="address-municipality"
                  className="form-input"
                  placeholder="Your Municipality / VDC name"
                  required
                >
                  {state.temporary?.municipality?.listToShow?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.municipality.hasErrors && (
                  <span className="error">
                    {state.temporary.municipality.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.locality.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-locality_tole">Locality / टोल</label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryLocalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.locality.value}
                  type="text"
                  id="address-locality_tole"
                  className="form-input"
                  placeholder="Enter your street address"
                  required
                />
                {state.temporary.locality.hasErrors && (
                  <span className="error">
                    {state.temporary.locality.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.ward_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-ward_no">Ward No / वडा नं</label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryWardChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.ward_number.value}
                  type="number"
                  id="address-ward_no"
                  className="form-input"
                  placeholder="Enter your ward number"
                  required
                />
                {state.temporary.ward_number.hasErrors && (
                  <span className="error">
                    {state.temporary.ward_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.block_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-block_no">Block No / ब्लक नं</label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryBlockChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.block_number.value}
                  type="number"
                  id="address-block_no"
                  className="form-input"
                  placeholder="Enter your block number"
                />
                {state.temporary.block_number.hasErrors && (
                  <span className="error">
                    {state.temporary.block_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.temporary.phone_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-telephone_number">
                  Telephone Number / फोन नं
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "temporaryPhoneChange",
                      value: e.target.value,
                    })
                  }
                  value={state.temporary.phone_number.value}
                  type="number"
                  id="address-telephone_number"
                  className="form-input"
                  placeholder="Enter your phones number"
                />
                {state.temporary.phone_number.hasErrors && (
                  <span className="error">
                    {state.temporary.phone_number.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <h3>Permanent Address</h3>
          <div className="input-wrapper">
            <div className={`input-group`}>
              <input
                type="checkbox"
                className="form-input"
                id="permanent-address-sameAs"
                onClick={handleCheck}
                style={{ width: "14px" }}
              />
              <label htmlFor="permanent-address-sameAs" className="mb-0 mx-2">
                Same as Current Address
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.country.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-country">Country / राष्ट्र</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentCountryChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.country.value}
                  name=""
                  id="address-country"
                  className="form-input"
                >
                  {country.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.permanent.country.hasErrors && (
                  <span className="error">
                    {state.permanent.country.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.province.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-province">Province / प्रदेश</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentProvincechange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.province.value}
                  name=""
                  id="address-province"
                  className="form-input"
                >
                  {state.permanent.province.list.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.permanent.province.hasErrors && (
                  <span className="error">
                    {state.permanent.province.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.district.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-district">District / जिल्ला</label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentDistrictChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.district.value}
                  name=""
                  id="address-district"
                  className="form-input"
                >
                  {state.permanent.district.listToShow.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.permanent.district.hasErrors && (
                  <span className="error">
                    {state.permanent.district.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.city.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-city">City / शहर</label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentCityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.city.value}
                  type="text"
                  id="address-city"
                  className="form-input"
                  placeholder="Your City Name"
                />
                {state.permanent.city.hasErrors && (
                  <span className="error">{state.permanent.city.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.municipality.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-municipality">
                  Municipality / न.पा
                </label>
                <span className="text-danger">*</span>
                <select
                  onChange={(e) =>
                    dispatch({
                      type: "permanentMunicipalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.municipality.value}
                  type="text"
                  id="address-municipality"
                  className="form-input"
                  // placeholder="Your Municipality / VDC name"
                >
                  {state.permanent?.municipality?.listToShow?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.permanent.municipality.hasErrors && (
                  <span className="error">
                    {state.permanent.municipality.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.locality.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-locality_tole">Locality / टोल</label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentLocalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.locality.value}
                  type="text"
                  id="address-locality_tole"
                  className="form-input"
                  placeholder="Permanent locality"
                />
                {state.permanent.locality.hasErrors && (
                  <span className="error">
                    {state.permanent.locality.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.ward_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-ward_no">Ward No / वडा नं</label>
                <span className="text-danger">*</span>
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentWardChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.ward_number.value}
                  type="number"
                  id="address-ward_no"
                  className="form-input"
                  placeholder="Permanent ward no"
                />
                {state.permanent.ward_number.hasErrors && (
                  <span className="error">
                    {state.permanent.ward_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.block_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-block_no">Block No / ब्लक नं</label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentBlockChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.block_number.value}
                  type="number"
                  id="address-block_no"
                  className="form-input"
                  placeholder="Permanent block no"
                />
                {state.permanent.block_number.hasErrors && (
                  <span className="error">
                    {state.permanent.block_number.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`input-group ${
                  state.permanent.phone_number.hasErrors && "form-error"
                }`}
              >
                <label htmlFor="address-telephone_number">
                  Telephone Number / फोन नं
                </label>
                {/* <span className="text-danger">*</span> */}
                <input
                  onChange={(e) =>
                    dispatch({
                      type: "permanentPhoneChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.phone_number.value}
                  type="number"
                  id="address-telephone_number"
                  className="form-input"
                  placeholder="Permanent Telephone number"
                />
                {state.permanent.phone_number.hasErrors && (
                  <span className="error">
                    {state.permanent.phone_number.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <span className="btn-transparent mr-2 " onClick={handleBackCount}>
            <span>← </span>
            Previous Page
          </span>
          <span className="btn-blue" onClick={handleSubmit}>
            Next Page
            <span> →</span>
          </span>
        </div>
      </form>
    </>
  );
}

export default withRouter(Address);
