import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
//CONTEXTS
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

//CONTEXTS
import FormState from "../../../CustomerForm/FormState";
import FormDispatch from "../../../CustomerForm/FormDispatch";

import PersonalInfo from "./PersonalInfo";
import Guardian from "./Guardian";
import Address from "./Address";
import FamilyInformation from "./FamilyInformation";
import Nominee from "./Nominee";
import Account from "./Account";
import Occupation from "./Occupation";
import Documents from "./Documents";

const CustomerCreate = () => {
  var generator = require("generate-password");

  const initialState = {
    uniqueId: "",
    saveCount: 0,
    formLevel: 0,
    showGuardian: false,
    showNominee: false,
    resMsg: "",
    validationCheck: 0,
    formData: {
      customers: {
        demat_account_type: "1",
        full_name: "",
        email: "",
        mobile: "",
        username: "",
        password: "",
        is_minor: "1",
        nominee: "1",
      },
      personal_informations: {
        dob: "",
        gender: "",
        marital_status: "",
        nationality: "",
        pan_number: null,
        identity_card_type: "",
        identity_card_number: "",
        identity_card_issue_district: "",
        identity_card_issue_date: "",
      },
      addresses: [
        {
          type: "",
          block_number: "",
          phone_number: "",
          ward_number: "",
          locality: "",
          municipality: "",
          district: "",
          province: "",
          country: "",
          city: "",
        },
        {
          type: "",
          block_number: "",
          phone_number: "",
          ward_number: "",
          locality: "",
          municipality: "",
          district: "",
          province: "",
          country: "",
          city: "",
        },
      ],
      families: {
        father_name: "",
        grand_father_name: "",
        mother_name: "",
        spouse: "",
        son_name: "",
        daughter_name: "",
        father_in_law_name: "",
        daughter_in_law_name: "",
      },
      occupations: {
        title: "",
        type: "",
        organization_name: "",
        address: "",
        designation: "",
        financial_details: "",
      },
      accounts: {
        bank_name: "",
        bank_code: "",
        branch_name: "",
        number: "",
        type: "",
        demat: 0,
        meroShare: 0,
      },
      documents: {
        photo: "",
        gov_issued_id_front: "",
        gov_issued_id_back: "",
        thumb_left: "",
        thumb_right: "",
        signature: "",
        lat: "27.6915196",
        long: "85.3420486",
      },
    },
    relationships: {},
    relationshipsGuardian: {},
    relationshipsNominee: {},
    documents_file: {},
    guardian_documents_file: {},
    nominee_documents_file: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "customerUpdate":
        console.log("this reducer was hit on customer form main");
        console.log(action.value.email, "action.value");
        draft.formData.customers.email = action.value.email;
        draft.formData.customers.mobile = action.value.mobile;
        draft.formData.customers.username = action.value.username;
        draft.uniqueId = action.value.uniqueId;
        return;
      case "saveUsername":
        draft.formData.customers.username = action.value;
        return;
      case "savePassword":
        draft.formData.customers.password = action.value;
        return;
      case "savePersonalInfo":
        draft.formData.personal_informations = action.value;
        return;
      case "saveGuardian":
        draft.relationshipsGuardian = action.value;
        return;
      case "minorTrue":
        draft.formData.customers.is_minor = "2";
        draft.showGuardian = true;
        draft.formData.documents = {
          birth_certificate: "",
        };
        return;
      case "minorFalse":
        draft.formData.customers.is_minor = "1";
        draft.showGuardian = false;
        draft.formData.documents = {
          photo: "",
          gov_issued_id_front: "",
          gov_issued_id_back: "",
          thumb_left: "",
          thumb_right: "",
          signature: "",
          lat: "27.6915196",
          long: "85.3420486",
        };
        return;
      case "nomineeTrue":
        draft.formData.customers.nominee = "2";
        draft.showNominee = true;
        return;
      case "saveNominee":
        draft.relationshipsNominee = action.value;
        return;
      case "nomineeFalse":
        draft.formData.customers.nominee = "1";
        draft.showNominee = false;
        return;

      case "saveFullName":
        draft.formData.customers.full_name = action.value;
        return;
      case "saveAddress":
        draft.formData.addresses = action.value;
        return;
      case "saveFamily":
        draft.formData.families = action.value;
        return;
      case "saveOccupation":
        draft.formData.occupations = action.value;
        return;
      case "saveAccount":
        draft.formData.accounts = action.value;
        return;
      case "saveDocuments":
        draft.formData.documents = action.value;
        return;
      case "saveDocumentsFile":
        draft.documents_file = action.value;
        return;
      case "saveGuardianDocumentsFile":
        draft.guardian_documents_file = action.value;
        return;
      case "saveNomineeDocumentsFile":
        draft.nominee_documents_file = action.value;
        return;
      case "saveResMsg":
        draft.resMsg = action.value;
        return;
      case "saveValidation":
        draft.validationCheck++;
        return;
      case "saveForm":
        draft.saveCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "saveValidation" });
  }

  useEffect(() => {
    if (state.validationCheck) {
      dispatch({ type: "saveForm" });
    }
  }, [state.validationCheck]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      let dataToSend = {};
      if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "1"
      ) {
        console.log("the user is a minor from the customer form");
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsGuardian],
        };
      } else if (
        state.formData.customers.is_minor == "1" &&
        state.formData.customers.nominee == "2"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsNominee],
        };
      } else if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "2"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [
            state.relationshipsGuardian,
            state.relationshipsNominee,
          ],
        };
      } else {
        dataToSend = state.formData;
      }
      console.log("data to save ", dataToSend);
      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/register_customer`,
            dataToSend,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
          dispatch({ type: "saveResMsg", value: true });
        } catch (e) {
          console.log(e.response, "there was an error");
          dispatch({ type: "saveResMsg", value: false });
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      const formData = new FormData();
      formData.append("email", state.formData.customers.email);
      if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "1"
      ) {
        console.log("the user is a minor from the customer form");
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
      } else if (
        state.formData.customers.is_minor == "1" &&
        state.formData.customers.nominee == "2"
      ) {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "2"
      ) {
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
      }

      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/upload_image`,
            formData,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
        } catch (e) {
          console.log(e.response, "there was an error");
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);

  var password = generator.generate({
    length: 10,
    numbers: true,
    uppercase: true,
    symbols: true,
  });

  useEffect(() => {
    dispatch({ type: "savePassword", value: password });
  }, [state.saveCount]);

  return (
    <>
      <FormState.Provider value={state}>
        <FormDispatch.Provider value={dispatch}>
          <Card className="">
            <CardHeader className="border-0 pb-1">
              <h3 className="mb-0">Create New Customer</h3>
              <p className="text-sm">
                Fill in the following details to create a Customer
              </p>
            </CardHeader>
            <CardBody className="shadow py-5">
              <Row>
                <Col xl="6" lg="12">
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Personal Information</h3>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <PersonalInfo />
                    </CardBody>
                  </Card>
                  {state.showGuardian && (
                    <Card className="shadow mb-5">
                      <CardHeader>
                        <h3 className="mb-0">Guardian</h3>
                      </CardHeader>
                      <CardBody className="mx-3">
                        <Guardian />
                      </CardBody>
                    </Card>
                  )}
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Account</h3>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <Account />
                    </CardBody>
                  </Card>
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Occupation</h3>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <Occupation />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6" lg="12">
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Address Information</h3>
                      <h4>(Current Address)</h4>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <Address />
                    </CardBody>
                  </Card>
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Family Information</h3>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <FamilyInformation />
                    </CardBody>
                  </Card>
                  {state.showNominee && (
                    <Card className="shadow mb-5">
                      <CardHeader className="d-flex">
                        <h3 className="mb-0">Nominee</h3>
                      </CardHeader>
                      <CardBody className="mx-3">
                        <Nominee />
                      </CardBody>
                    </Card>
                  )}
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Documents</h3>
                    </CardHeader>
                    <CardBody className="mx-3">
                      <Documents />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {state.resMsg === false && (
                <Row className="mt-4">
                  <Col lg="12">
                    <Alert className="alert-danger mt-4 text-center">
                      There was an error !!!
                    </Alert>
                  </Col>
                </Row>
              )}

              {state.resMsg === true && (
                <Row className="mt-4">
                  <Col lg="12">
                    <Alert className="alert-success mt-4 text-center">
                      Customer Created
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row className="mt-4">
                <Col lg="10"></Col>
                <Col lg="2" className="d-flex justify-content-end">
                  <Button onClick={handleSubmit} className="btn-default">
                    Submit
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </FormDispatch.Provider>
      </FormState.Provider>
    </>
  );
};

export default CustomerCreate;
