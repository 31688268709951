import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Container, Table } from "react-bootstrap";
import StateContext from "../../../../StateContext";

const RoleEdit = () => {
  const appState = useContext(StateContext);
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const { id } = useParams();

  const initialState = {
    submitCount: 0,
    errorCount: 0,
    isPending: false,
    message: "",
    roleData: {},
    roleLoaded: false,
    roleError: false,
    formData: {
      fullName: {
        name: "fullName",
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
    permissions: {
      roles: {
        allRoles: 1,
        roleView: {
          value: 1,
          checked: 1,
        },
        roleCreate: {
          value: 2,
          checked: 1,
        },
        roleEdit: {
          value: 3,
          checked: 1,
        },
        roleDelete: {
          value: 4,
          checked: 1,
        },
      },
      users: {
        allUser: 1,
        userView: {
          value: 5,
          checked: 1,
        },
        userCreate: {
          value: 6,
          checked: 1,
        },
        userEdit: {
          value: 7,
          checked: 1,
        },
        userDelete: {
          value: 8,
          checked: 1,
        },
      },
      customers: {
        allCustomers: 1,
        customerView: {
          value: 9,
          checked: 1,
        },
        customerCreate: {
          value: 10,
          checked: 1,
        },
        customerEdit: {
          value: 11,
          checked: 1,
        },
        customerDelete: {
          value: 12,
          checked: 1,
        },
        customerShow: {
          value: 13,
          checked: 1,
        },
        customerDashboard: {
          value: 14,
          checked: 1,
        },
      },
      payments: {
        allPayments: 1,
        paymentView: {
          value: 16,
          checked: 1,
        },
        paymentCreate: {
          value: 17,
          checked: 1,
        },
        paymentEdit: {
          value: 18,
          checked: 1,
        },
        paymentDelete: {
          value: 19,
          checked: 1,
        },
        paymentList: {
          value: 15,
          checked: 1,
        },
      },
      verify: {
        allVerify: 1,
        verifyDocument: {
          value: 20,
          checked: 1,
        },
        verifyPayment: {
          value: 21,
          checked: 1,
        },
      },
      batch: {
        allBatches: 1,
        batchList: {
          value: 23,
          checked: 1,
        },
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "saveRoleData":
        draft.roleData = action.value;
        draft.roleLoaded = true;
        return;
      case "fullNameChange":
        draft.formData.fullName.value = action.value;
        draft.formData.fullName.hasErrors = false;
        draft.formData.fullName.touched = true;
        return;
      case "fullNameRules":
        if (draft.formData.fullName.value.trim().length < 4) {
          draft.formData.fullName.hasErrors = true;
          draft.formData.fullName.message =
            "Fullname must be more than 3 character";
        }
        return;
      case "createRole":
        draft.message = action.value;
        return;
      // Roles
      case "checkAllRoles":
        draft.permissions.roles.allRoles = 2;
        draft.permissions.roles.roleView.checked = 2;
        draft.permissions.roles.roleCreate.checked = 2;
        draft.permissions.roles.roleEdit.checked = 2;
        draft.permissions.roles.roleDelete.checked = 2;
        return;
      case "uncheckAllRoles":
        draft.permissions.roles.allRoles = 1;
        draft.permissions.roles.roleView.checked = 1;
        draft.permissions.roles.roleCreate.checked = 1;
        draft.permissions.roles.roleEdit.checked = 1;
        draft.permissions.roles.roleDelete.checked = 1;
        return;
      case "checkRolesView":
        draft.permissions.roles.roleView.checked = 2;
        return;
      case "uncheckRolesView":
        draft.permissions.roles.roleView.checked = 1;
        return;
      case "checkRolesCreate":
        draft.permissions.roles.roleCreate.checked = 2;
        return;
      case "uncheckRolesCreate":
        draft.permissions.roles.roleCreate.checked = 1;
        return;
      case "checkRolesEdit":
        draft.permissions.roles.roleEdit.checked = 2;
        return;
      case "uncheckRolesEdit":
        draft.permissions.roles.roleEdit.checked = 1;
        return;
      case "checkRolesDelete":
        draft.permissions.roles.roleDelete.checked = 2;
        return;
      case "uncheckRolesDelete":
        draft.permissions.roles.roleDelete.checked = 1;
        return;
      // Users
      case "checkAllUsers":
        draft.permissions.users.allUsers = 2;
        draft.permissions.users.userView.checked = 2;
        draft.permissions.users.userCreate.checked = 2;
        draft.permissions.users.userEdit.checked = 2;
        draft.permissions.users.userDelete.checked = 2;
        return;
      case "uncheckAllUsers":
        draft.permissions.users.allUsers = 1;
        draft.permissions.users.userView.checked = 1;
        draft.permissions.users.userCreate.checked = 1;
        draft.permissions.users.userEdit.checked = 1;
        draft.permissions.users.userDelete.checked = 1;
        return;
      case "checkUsersView":
        draft.permissions.users.userView.checked = 2;
        return;
      case "uncheckUsersView":
        draft.permissions.users.userView.checked = 1;
        return;
      case "checkUsersCreate":
        draft.permissions.users.userCreate.checked = 2;
        return;
      case "uncheckUsersCreate":
        draft.permissions.users.userCreate.checked = 1;
        return;
      case "checkUsersEdit":
        draft.permissions.users.userEdit.checked = 2;
        return;
      case "uncheckUsersEdit":
        draft.permissions.users.userEdit.checked = 1;
        return;
      case "checkUsersDelete":
        draft.permissions.users.userDelete.checked = 2;
        return;
      case "uncheckUsersDelete":
        draft.permissions.users.userDelete.checked = 1;
        return;
      // Customers
      case "checkAllCustomers":
        draft.permissions.customers.allCustomers = 2;
        draft.permissions.customers.customerView.checked = 2;
        draft.permissions.customers.customerCreate.checked = 2;
        draft.permissions.customers.customerEdit.checked = 2;
        draft.permissions.customers.customerDelete.checked = 2;
        draft.permissions.customers.customerShow.checked = 2;
        draft.permissions.customers.customerDashboard.checked = 2;
        return;
      case "uncheckAllCustomers":
        draft.permissions.customers.allCustomers = 1;
        draft.permissions.customers.customerView.checked = 1;
        draft.permissions.customers.customerCreate.checked = 1;
        draft.permissions.customers.customerEdit.checked = 1;
        draft.permissions.customers.customerDelete.checked = 1;
        draft.permissions.customers.customerShow.checked = 1;
        draft.permissions.customers.customerDashboard.checked = 1;
        return;
      case "checkCustomersView":
        draft.permissions.customers.customerView.checked = 2;
        return;
      case "uncheckCustomersView":
        draft.permissions.customers.customerView.checked = 1;
        return;
      case "checkCustomersCreate":
        draft.permissions.customers.customerCreate.checked = 2;
        return;
      case "uncheckCustomersCreate":
        draft.permissions.customers.customerCreate.checked = 1;
        return;
      case "checkCustomersEdit":
        draft.permissions.customers.customerEdit.checked = 2;
        return;
      case "uncheckCustomersEdit":
        draft.permissions.customers.customerEdit.checked = 1;
        return;
      case "checkCustomersDelete":
        draft.permissions.customers.customerDelete.checked = 2;
        return;
      case "uncheckCustomersDelete":
        draft.permissions.customers.customerDelete.checked = 1;
        return;
      case "checkCustomersShow":
        draft.permissions.customers.customerShow.checked = 2;
        return;
      case "uncheckCustomersShow":
        draft.permissions.customers.customerShow.checked = 1;
        return;
      case "checkCustomersDashboard":
        draft.permissions.customers.customerDashboard.checked = 2;
        return;
      case "uncheckCustomersDashboard":
        draft.permissions.customers.customerDashboard.checked = 1;
        return;
      // Payments
      case "checkAllPayments":
        draft.permissions.payments.allPayments = 2;
        draft.permissions.payments.paymentView.checked = 2;
        draft.permissions.payments.paymentCreate.checked = 2;
        draft.permissions.payments.paymentEdit.checked = 2;
        draft.permissions.payments.paymentDelete.checked = 2;
        draft.permissions.payments.paymentList.checked = 2;
        return;
      case "uncheckAllPayments":
        draft.permissions.payments.allPayments = 1;
        draft.permissions.payments.paymentView.checked = 1;
        draft.permissions.payments.paymentCreate.checked = 1;
        draft.permissions.payments.paymentEdit.checked = 1;
        draft.permissions.payments.paymentDelete.checked = 1;
        draft.permissions.payments.paymentList.checked = 1;
        return;
      case "checkPaymentsView":
        draft.permissions.payments.paymentView.checked = 2;
        return;
      case "uncheckPaymentsView":
        draft.permissions.payments.paymentView.checked = 1;
        return;
      case "checkPaymentsCreate":
        draft.permissions.payments.paymentCreate.checked = 2;
        return;
      case "uncheckPaymentsCreate":
        draft.permissions.payments.paymentCreate.checked = 1;
        return;
      case "checkPaymentsEdit":
        draft.permissions.payments.paymentEdit.checked = 2;
        return;
      case "uncheckPaymentsEdit":
        draft.permissions.payments.paymentEdit.checked = 1;
        return;
      case "checkPaymentsDelete":
        draft.permissions.payments.paymentDelete.checked = 2;
        return;
      case "uncheckPaymentsDelete":
        draft.permissions.payments.paymentDelete.checked = 1;
        return;
      case "checkPaymentsList":
        draft.permissions.payments.paymentList.checked = 2;
        return;
      case "uncheckPaymentsList":
        draft.permissions.payments.paymentList.checked = 1;
        return;
      // Verify
      case "checkAllVerify":
        draft.permissions.verify.allVerify = 2;
        draft.permissions.verify.verifyDocument.checked = 2;
        draft.permissions.verify.verifyPayment.checked = 2;
        return;
      case "uncheckAllVerify":
        draft.permissions.verify.allVerify = 1;
        draft.permissions.verify.verifyDocument.checked = 1;
        draft.permissions.verify.verifyPayment.checked = 1;
        return;
      case "checkVerifyDocument":
        draft.permissions.verify.verifyDocument.checked = 2;
        return;
      case "uncheckVerifyDocument":
        draft.permissions.verify.verifyDocument.checked = 1;
        return;
      case "checkVerifyPayment":
        draft.permissions.verify.verifyPayment.checked = 2;
        return;
      case "uncheckVerifyPayment":
        draft.permissions.verify.verifyPayment.checked = 1;
        return;
      // Batch
      case "checkAllBatch":
        draft.permissions.batch.allBatch = 2;
        draft.permissions.batch.batchList.checked = 2;
        return;
      case "uncheckAllBatch":
        draft.permissions.batch.allBatch = 1;
        draft.permissions.batch.batchList.checked = 1;
        return;
      case "checkBatchList":
        draft.permissions.batch.batchList.checked = 2;
        return;
      case "uncheckBatchList":
        draft.permissions.batch.batchList.checked = 1;
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "checkForErrors":
        console.log("checking for errors");
        var errorCount = 0;
        var unTouchedCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].touched) {
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
            unTouchedCount++;
          }
          if (draft.formData[key].hasErrors) {
            errorCount++;
          }
        }
        console.log("ready to submit", errorCount);
        if (errorCount < 1) {
          draft.submitCount++;
        }
      case "submitForm":
        if (
          !draft.formData.fullName.hasErrors &&
          draft.formData.fullName.value !== ""
        ) {
          draft.submitCount++;
        }
        return;
      case "saveRoleError":
        draft.roleError = action.value;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  let permission = [];

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "checkForErrors" });
  }

  //   Handle Checked ROLE
  const handleRole = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllRoles" });
    } else {
      dispatch({ type: "uncheckAllRoles" });
    }
  };
  const handleRoleView = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkRolesView" });
    } else {
      dispatch({ type: "uncheckRolesView" });
    }
  };
  const handleRoleCreate = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkRolesCreate" });
    } else {
      dispatch({ type: "uncheckRolesCreate" });
    }
  };
  const handleRoleEdit = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkRolesEdit" });
    } else {
      dispatch({ type: "uncheckRolesEdit" });
    }
  };
  const handleRoleDelete = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkRolesDelete" });
    } else {
      dispatch({ type: "uncheckRolesDelete" });
    }
  };

  //   Handle Checked USER
  const handleUser = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllUsers" });
    } else {
      dispatch({ type: "uncheckAllUsers" });
    }
  };
  const handleUserView = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkUsersView" });
    } else {
      dispatch({ type: "uncheckUsersView" });
    }
  };
  const handleUserCreate = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkUsersCreate" });
    } else {
      dispatch({ type: "uncheckUsersCreate" });
    }
  };
  const handleUserEdit = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkUsersEdit" });
    } else {
      dispatch({ type: "uncheckUsersEdit" });
    }
  };
  const handleUserDelete = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkUsersDelete" });
    } else {
      dispatch({ type: "uncheckUsersDelete" });
    }
  };

  //   Handle Checked CUSTOMER
  const handleCustomer = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllCustomers" });
    } else {
      dispatch({ type: "uncheckAllCustomers" });
    }
  };
  const handleCustomerView = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersView" });
    } else {
      dispatch({ type: "uncheckCustomersView" });
    }
  };
  const handleCustomerCreate = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersCreate" });
    } else {
      dispatch({ type: "uncheckCustomersCreate" });
    }
  };
  const handleCustomerEdit = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersEdit" });
    } else {
      dispatch({ type: "uncheckCustomersEdit" });
    }
  };
  const handleCustomerDelete = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersDelete" });
    } else {
      dispatch({ type: "uncheckCustomersDelete" });
    }
  };
  const handleCustomerShow = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersShow" });
    } else {
      dispatch({ type: "uncheckCustomersShow" });
    }
  };
  const handleCustomerDashboard = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkCustomersDashboard" });
    } else {
      dispatch({ type: "uncheckCustomersDashboard" });
    }
  };

  //   Handle Checked PAYMENT
  const handlePayment = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllPayments" });
    } else {
      dispatch({ type: "uncheckAllPayments" });
    }
  };
  const handlePaymentView = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkPaymentsView" });
    } else {
      dispatch({ type: "uncheckPaymentsView" });
    }
  };
  const handlePaymentCreate = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkPaymentsCreate" });
    } else {
      dispatch({ type: "uncheckPaymentsCreate" });
    }
  };
  const handlePaymentEdit = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkPaymentsEdit" });
    } else {
      dispatch({ type: "uncheckPaymentsEdit" });
    }
  };
  const handlePaymentDelete = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkPaymentsDelete" });
    } else {
      dispatch({ type: "uncheckPaymentsDelete" });
    }
  };
  const handlePaymentList = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkPaymentsList" });
    } else {
      dispatch({ type: "uncheckPaymentsList" });
    }
  };

  //   Handle Checked VERIFY
  const handleVerify = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllVerify" });
    } else {
      dispatch({ type: "uncheckAllVerify" });
    }
  };
  const handleVerifyDocument = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkVerifyDocument" });
    } else {
      dispatch({ type: "uncheckVerifyDocument" });
    }
  };
  const handleVerifyPayment = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkVerifyPayment" });
    } else {
      dispatch({ type: "uncheckVerifyPayment" });
    }
  };

  //   Handle Checked BATCH
  const handleBatch = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkAllBatch" });
    } else {
      dispatch({ type: "uncheckAllBatch" });
    }
  };
  const handleBatchList = (e) => {
    if (e.target.checked) {
      dispatch({ type: "checkBatchList" });
    } else {
      dispatch({ type: "uncheckBatchList" });
    }
  };

  //   Save Role
  useEffect(() => {
    dispatch({ type: "saveRoles" });
  }, []);

  useEffect(() => {
    // roles
    if (state.permissions.roles.roleView.checked === 2) {
      permission.push(state.permissions.roles.roleView.value);
    }
    if (state.permissions.roles.roleCreate.checked === 2) {
      permission.push(state.permissions.roles.roleCreate.value);
    }
    if (state.permissions.roles.roleEdit.checked === 2) {
      permission.push(state.permissions.roles.roleEdit.value);
    }
    if (state.permissions.roles.roleDelete.checked === 2) {
      permission.push(state.permissions.roles.roleDelete.value);
    }
    // users
    if (state.permissions.users.userView.checked === 2) {
      permission.push(state.permissions.users.userView.value);
    }
    if (state.permissions.users.userCreate.checked === 2) {
      permission.push(state.permissions.users.userCreate.value);
    }
    if (state.permissions.users.userEdit.checked === 2) {
      permission.push(state.permissions.users.userEdit.value);
    }
    if (state.permissions.users.userDelete.checked === 2) {
      permission.push(state.permissions.users.userDelete.value);
    }
    // customer
    if (state.permissions.customers.customerView.checked === 2) {
      permission.push(state.permissions.customers.customerView.value);
    }
    if (state.permissions.customers.customerCreate.checked === 2) {
      permission.push(state.permissions.customers.customerCreate.value);
    }
    if (state.permissions.customers.customerEdit.checked === 2) {
      permission.push(state.permissions.customers.customerEdit.value);
    }
    if (state.permissions.customers.customerDelete.checked === 2) {
      permission.push(state.permissions.customers.customerDelete.value);
    }
    if (state.permissions.customers.customerShow.checked === 2) {
      permission.push(state.permissions.customers.customerShow.value);
    }
    if (state.permissions.customers.customerDashboard.checked === 2) {
      permission.push(state.permissions.customers.customerDashboard.value);
    }
    // payment
    if (state.permissions.payments.paymentView.checked === 2) {
      permission.push(state.permissions.payments.paymentView.value);
    }
    if (state.permissions.payments.paymentCreate.checked === 2) {
      permission.push(state.permissions.payments.paymentCreate.value);
    }
    if (state.permissions.payments.paymentEdit.checked === 2) {
      permission.push(state.permissions.payments.paymentEdit.value);
    }
    if (state.permissions.payments.paymentDelete.checked === 2) {
      permission.push(state.permissions.payments.paymentDelete.value);
    }
    if (state.permissions.payments.paymentList.checked === 2) {
      permission.push(state.permissions.payments.paymentList.value);
    }
    // verify
    if (state.permissions.verify.verifyDocument.checked === 2) {
      permission.push(state.permissions.verify.verifyDocument.value);
    }
    if (state.permissions.verify.verifyPayment.checked === 2) {
      permission.push(state.permissions.verify.verifyPayment.value);
    }
    // batch
    if (state.permissions.batch.batchList.checked === 2) {
      permission.push(state.permissions.batch.batchList.value);
    }

    setPermissions(permission);
  }, [state.permissions, state.roleLoaded]);

  useEffect(() => {
    // RoleLoaded
    if (state.roleLoaded) {
      dispatch({ type: "fullNameChange", value: state.roleData.name });
      //   Permission map
      state.roleData.permissions.map((item, i) => {
        // role
        if (state.permissions.roles.roleView.value === item.id) {
          dispatch({ type: "checkRolesView" });
        }
        if (state.permissions.roles.roleCreate.value === item.id) {
          dispatch({ type: "checkRolesCreate" });
        }
        if (state.permissions.roles.roleEdit.value === item.id) {
          dispatch({ type: "checkRolesEdit" });
        }
        if (state.permissions.roles.roleDelete.value === item.id) {
          dispatch({ type: "checkRolesDelete" });
        }
        // user
        if (state.permissions.users.userView.value === item.id) {
          dispatch({ type: "checkUsersView" });
        }
        if (state.permissions.users.userCreate.value === item.id) {
          dispatch({ type: "checkUsersCreate" });
        }
        if (state.permissions.users.userEdit.value === item.id) {
          dispatch({ type: "checkUsersEdit" });
        }
        if (state.permissions.users.userDelete.value === item.id) {
          dispatch({ type: "checkUsersDelete" });
        }
        // customer
        if (state.permissions.customers.customerView.value === item.id) {
          dispatch({ type: "checkCustomersView" });
        }
        if (state.permissions.customers.customerCreate.value === item.id) {
          dispatch({ type: "checkCustomersCreate" });
        }
        if (state.permissions.customers.customerEdit.value === item.id) {
          dispatch({ type: "checkCustomersEdit" });
        }
        if (state.permissions.customers.customerDelete.value === item.id) {
          dispatch({ type: "checkCustomersDelete" });
        }
        if (state.permissions.customers.customerShow.value === item.id) {
          dispatch({ type: "checkCustomersShow" });
        }
        if (state.permissions.customers.customerDashboard.value === item.id) {
          dispatch({ type: "checkCustomersDashboard" });
        }
        // payments
        if (state.permissions.payments.paymentView.value === item.id) {
          dispatch({ type: "checkPaymentsView" });
        }
        if (state.permissions.payments.paymentCreate.value === item.id) {
          dispatch({ type: "checkPaymentsCreate" });
        }
        if (state.permissions.payments.paymentEdit.value === item.id) {
          dispatch({ type: "checkPaymentsEdit" });
        }
        if (state.permissions.payments.paymentDelete.value === item.id) {
          dispatch({ type: "checkPaymentsDelete" });
        }
        if (state.permissions.payments.paymentList.value === item.id) {
          dispatch({ type: "checkPaymentsList" });
        }
        // verify
        if (state.permissions.verify.verifyDocument.value === item.id) {
          dispatch({ type: "checkVerifyDocument" });
        }
        if (state.permissions.verify.verifyPayment.value === item.id) {
          dispatch({ type: "checkVerifyPayment" });
        }
        // batch
        if (state.permissions.batch.batchList.value === item.id) {
          dispatch({ type: "checkBatchList" });
        }
      });
    }
  }, [state.roleLoaded]);

  // console.log(permissions);

  useEffect(() => {
    if (state.errorCount < 1 && state.submitCount > 0) {
      dispatch({ type: "submitForm" });
    }
  }, [state.errorCount]);

  // RoleData
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/roles/${id}`,
          {
            cancelToken: ourRequest.token,
          }
        );
        // setUser(response.data.data);
        dispatch({ type: "saveRoleData", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching user");
        dispatch({ type: "setErrorMsg", value: "There was an error!" });
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  // UpdateRole
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    if (state.submitCount) {
      dispatch({ type: "setIsPending", value: true });

      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.user.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      async function createUser() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/roles/${id}`,
            {
              name: state.formData.fullName.value,
              permission: permissions.map((item, i) => item),
            },
            { cancelToken: ourRequest.token }
          );
          if (!response.data.success) {
            console.log(response.data);
            dispatch({ type: "createRole", value: response.data.message });
            dispatch({ type: "saveRoleError", value: true });
          }
          dispatch({ type: "createRole", value: response.data.message });
          dispatch({ type: "saveRoleError", value: false });
          dispatch({ type: "setIsPending", value: false });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "saveRoleError", value: true });
          dispatch({ type: "createRole", value: "There was an error" });
        }
      }
      createUser();
    }
    return () => ourRequest.cancel();
  }, [state.submitCount]);

  return (
    <>
      <Container className="mt-8" fluid>
        <Card>
          <CardHeader className="border-1 mb-0 pb-0 ">
            <h3 className="mb-0">Edit Roles</h3>
            <p>Fill in the following details to upadate role</p>
          </CardHeader>

          <CardBody>
            <Form>
              <Container fluid>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Full Name"
                        type="text"
                        value={state.formData.fullName.value}
                        onBlur={(e) =>
                          dispatch({
                            type: "fullNameRules",
                            value: e.target.value,
                          })
                        }
                        onChange={(e) =>
                          dispatch({
                            type: "fullNameChange",
                            value: e.target.value,
                          })
                        }
                        required
                      />
                      {state.formData.fullName.hasErrors && (
                        <p className="text-danger">
                          {state.formData.fullName.message}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <label className="form-control-label">Permissions</label>
                <Table
                  className="align-items-center table-flush mt-4"
                  responsive
                >
                  <thead>
                    <tr>
                      <th scope="col">MODULES</th>
                      <th scope="col">PERMISSIONS</th>
                    </tr>
                  </thead>
                  <tbody className="position-relative">
                    <tr>
                      <td>
                        <b> ROLE</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_roles"
                            onChange={handleRole}
                          />
                          <label htmlFor="select-all_roles" className="mx-2">
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_view_roles"
                              onChange={handleRoleView}
                              checked={
                                state.permissions.roles.roleView.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_view_roles"
                              className="mx-2"
                            >
                              View Roles
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_create_roles"
                              onChange={handleRoleCreate}
                              checked={
                                state.permissions.roles.roleCreate.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_create_roles"
                              className="mx-2"
                            >
                              Create Roles
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_edit_roles"
                              onChange={handleRoleEdit}
                              checked={
                                state.permissions.roles.roleEdit.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_edit_roles"
                              className="mx-2"
                            >
                              Edit Roles
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_delete_roles"
                              onChange={handleRoleDelete}
                              checked={
                                state.permissions.roles.roleDelete.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_delete_roles"
                              className="mx-2"
                            >
                              Delete Roles
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>USER</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_users"
                            onChange={handleUser}
                          />
                          <label htmlFor="select-all_users" className="mx-2">
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_view_users"
                              onChange={handleUserView}
                              checked={
                                state.permissions.users.userView.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_view_users"
                              className="mx-2"
                            >
                              View User
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_create_users"
                              onChange={handleUserCreate}
                              checked={
                                state.permissions.users.userCreate.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_create_users"
                              className="mx-2"
                            >
                              Create User
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_edit_users"
                              onChange={handleUserEdit}
                              checked={
                                state.permissions.users.userEdit.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_edit_users"
                              className="mx-2"
                            >
                              Edit User
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_delete_users"
                              onChange={handleUserDelete}
                              checked={
                                state.permissions.users.userDelete.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_delete_users"
                              className="mx-2"
                            >
                              Delete User
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CUSTOMER</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_customers"
                            onChange={handleCustomer}
                          />
                          <label
                            htmlFor="select-all_customers"
                            className="mx-2"
                          >
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_view_customers"
                              onChange={handleCustomerView}
                              checked={
                                state.permissions.customers.customerView
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_view_customers"
                              className="mx-2"
                            >
                              View Customer
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_create_customers"
                              onChange={handleCustomerCreate}
                              checked={
                                state.permissions.customers.customerCreate
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_create_customers"
                              className="mx-2"
                            >
                              Create Customer
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_edit_customers"
                              onChange={handleCustomerEdit}
                              checked={
                                state.permissions.customers.customerEdit
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_edit_customers"
                              className="mx-2"
                            >
                              Edit Customer
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_delete_customers"
                              onChange={handleCustomerDelete}
                              checked={
                                state.permissions.customers.customerDelete
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_delete_customers"
                              className="mx-2"
                            >
                              Delete Customer
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_show_customers"
                              onChange={handleCustomerShow}
                              checked={
                                state.permissions.customers.customerShow
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_show_customers"
                              className="mx-2"
                            >
                              Show Customer
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_dashboard_customers"
                              onChange={handleCustomerDashboard}
                              checked={
                                state.permissions.customers.customerDashboard
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_dashboard_customers"
                              className="mx-2"
                            >
                              Customer Dashboard
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>PAYMENT</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_payments"
                            onChange={handlePayment}
                          />
                          <label htmlFor="select-all_payments" className="mx-2">
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_view_payments"
                              onChange={handlePaymentView}
                              checked={
                                state.permissions.payments.paymentView
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_view_payments"
                              className="mx-2"
                            >
                              View Payments
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_create_payments"
                              onChange={handlePaymentCreate}
                              checked={
                                state.permissions.payments.paymentCreate
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_create_payments"
                              className="mx-2"
                            >
                              Create Payments
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_edit_payments"
                              onChange={handlePaymentEdit}
                              checked={
                                state.permissions.payments.paymentEdit
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_edit_payments"
                              className="mx-2"
                            >
                              Edit Payments
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_delete_payments"
                              onChange={handlePaymentDelete}
                              checked={
                                state.permissions.payments.paymentDelete
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_delete_payments"
                              className="mx-2"
                            >
                              Delete Payments
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_list_payments"
                              onChange={handlePaymentList}
                              checked={
                                state.permissions.payments.paymentList
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_list_payments"
                              className="mx-2"
                            >
                              List Payments
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>VERIFY</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_verify"
                            onChange={handleVerify}
                          />
                          <label htmlFor="select-all_verify" className="mx-2">
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_document_verify"
                              onChange={handleVerifyDocument}
                              checked={
                                state.permissions.verify.verifyDocument
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_document_verify"
                              className="mx-2"
                            >
                              Verify Document
                            </label>
                          </span>
                          <span className="ml-5">
                            <Input
                              type="checkbox"
                              id="select-all_payment_verify"
                              onChange={handleVerifyPayment}
                              checked={
                                state.permissions.verify.verifyPayment
                                  .checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_payment_verify"
                              className="mx-2"
                            >
                              Verify Payment
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>BATCH</b>
                      </td>
                      <td>
                        <div>
                          <Input
                            type="checkbox"
                            id="select-all_batch"
                            onChange={handleBatch}
                          />
                          <label htmlFor="select-all_batch" className="mx-2">
                            Select All
                          </label>
                        </div>
                        <div>
                          <span>
                            <Input
                              type="checkbox"
                              id="select-all_list_batch"
                              onChange={handleBatchList}
                              checked={
                                state.permissions.batch.batchList.checked === 2
                                  ? "checked"
                                  : ""
                              }
                            />
                            <label
                              htmlFor="select-all_list_batch"
                              className="mx-2"
                            >
                              List Batches
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
              <Container fluid className="mb-4">
                <Row>
                  <Col lg="7" md="10">
                    <Button color="primary" onClick={handleSubmit}>
                      {state.isPending ? "Please Wait. . ." : "Update"}
                    </Button>
                    <Button color="danger" onClick={(e) => history.goBack()}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
                {state.message && (
                  <Row>
                    <Col>
                      <Alert
                        className={`mt-3 ${
                          state.roleError ? "alert-danger" : "alert-success"
                        }`}
                      >
                        {state.message}
                      </Alert>
                    </Col>
                  </Row>
                )}
              </Container>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default RoleEdit;
