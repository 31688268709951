import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import { withRouter, Link } from "react-router-dom";
import FormState from "../../FormState";
import FormDispatch from "../../FormDispatch";
import { Eye } from "../../../Layout/Icons";

const CreatePassword = (props) => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);

  const initialState = {
    username: {
      value: "",
      hasErrors: false,
      message: "",
    },
    password: {
      value: "",
      hasErrors: false,
      type: "password",
      message: "",
    },
    confirmPassword: {
      value: "",
      hasErrors: false,
      type: "password",
      message: "",
      match: false,
    },
    submitCount: 0,
    isPending: false,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "usernameChange":
        draft.username.hasErrors = false;
        draft.username.value = action.value;
        return;
      case "passwordChange":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        return;
      case "confirmPasswordChange":
        draft.confirmPassword.hasErrors = false;
        draft.confirmPassword.value = action.value;
        return;
      case "usernameRules":
        if (draft.username.value.trim() == "") {
          draft.username.hasErrors = true;
          draft.username.message = "You must provide a username";
        }
        return;
      case "passwordRules":
        if (draft.password.value.trim().length < 1) {
          draft.password.hasErrors = true;
          draft.password.message = "You must provide a password";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/.test(
            draft.password.value
          )
        ) {
          draft.password.hasErrors = true;
          draft.password.message =
            "Password must be more than 8 characters and must contain uppercase, lowercase, special character and number";
        }
        return;
      case "confirmPasswordRules":
        if (draft.confirmPassword.value.trim() == "") {
          draft.confirmPassword.hasErrors = true;
          draft.confirmPassword.message = "You must confirm the password";
        }
        if (draft.password.value !== draft.confirmPassword.value) {
          console.log("password do not matched");
          draft.confirmPassword.hasErrors = true;
          draft.confirmPassword.message = "Password do not match";
        }
        return;
      case "togglePassword":
        if (draft.password.type === "password") {
          draft.password.type = "text";
        } else {
          draft.password.type = "password";
        }
        return;
      case "toggleConfirmPassword":
        if (draft.confirmPassword.type === "password") {
          draft.confirmPassword.type = "text";
        } else {
          draft.confirmPassword.type = "password";
        }
        return;
      case "formSubmit":
        if (
          !draft.username.hasErrors &&
          !draft.password.hasErrors &&
          !draft.confirmPassword.hasErrors
        ) {
          draft.submitCount++;
          console.log(draft.submitCount, "submitcount");
        }
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount >= 1) {
      dispatch({ type: "setIsPending", value: true });
      formDispatch({ type: "saveUsername", value: state.username.value });
      formDispatch({ type: "savePassword", value: state.password.value });
      // props.history.push("/customers/register/readyDocuments");
      // formDispatch({ type: "activeTabRegister", value: "personalInfo" });
      props.history.push("/customers/create");
    }
  }, [state.submitCount]);

  function togglePassword() {
    dispatch({ type: "togglePassword" });
  }

  function toggleConfirmPassword() {
    dispatch({ type: "toggleConfirmPassword" });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "usernameRules" });
    dispatch({ type: "passwordRules" });
    dispatch({ type: "confirmPasswordRules" });
    dispatch({ type: "formSubmit" });
  }

  return (
    <div className="section login section-box mx-auto">
      <h3>Create Login Info</h3>
      <p>{formState?.formData.customers.email}</p>
      <div className="text-box">
        <p>
          +977 -
          <span className="number phone_number">
            {formState?.formData.customers.mobile}
          </span>
        </p>
        <p className="email user_email"></p>
      </div>
      <form action="/add_customer" method="POST" id="createUserForm">
        <input type="hidden" name="email" className="user_email_input" />
        <input type="hidden" name="mobile" className="user_phone_input" />
        <div className="input-group">
          <label htmlFor="username">Create Username</label>
          <input
            name="username"
            type="text"
            placeholder="E.g. myusername"
            className="form-input name-group"
            onBlur={(e) =>
              dispatch({ type: "usernameRules", value: e.target.value })
            }
            onChange={(e) =>
              dispatch({ type: "usernameChange", value: e.target.value })
            }
            required
          />
          {state.username.hasErrors && (
            <span className="error">{state.username.message}</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="password">Create Password</label>
          <input
            name="password"
            type={state.password.type}
            className="form-input password"
            placeholder="8 characters long with symbol and number"
            onBlur={(e) =>
              dispatch({ type: "passwordRules", value: e.target.value })
            }
            onChange={(e) =>
              dispatch({ type: "passwordChange", value: e.target.value })
            }
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            required
          />
          <span
            className="view-icon"
            data="view-password"
            onClick={togglePassword}
          >
            <Eye className="icon" />
          </span>
          {state.password.hasErrors && (
            <span className="error">{state.password.message}</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="c_password">Confirm Password</label>
          <input
            name="c_password"
            type={state.confirmPassword.type}
            className="form-input password"
            placeholder="Retype your password"
            onBlur={(e) =>
              dispatch({ type: "confirmPasswordRules", value: e.target.value })
            }
            onChange={(e) =>
              dispatch({ type: "confirmPasswordChange", value: e.target.value })
            }
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            required
          />
          <span
            className="view-icon"
            data="view-password"
            onClick={toggleConfirmPassword}
          >
            <Eye className="icon" />
          </span>
          {state.confirmPassword.hasErrors && (
            <span className="error">{state.confirmPassword.message}</span>
          )}
          {state.confirmPassword.match && (
            <p className="text-success">{state.confirmPassword.message}</p>
          )}
        </div>
        <button
          className="zoom-in"
          onClick={handleSubmit}
          type="submit"
          id="userSubmit"
          disabled={
            state.username.hasErrors ||
            state.password.hasErrors ||
            state.confirmPassword.hasErrors
          }
        >
          {state.isPending ? "Submiting . . ." : "Submit"}
        </button>
      </form>
      <p className="signIn">
        Already Have an account?{" "}
        <Link to="/login" className="text-danger">
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default withRouter(CreatePassword);
