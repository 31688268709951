import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useImmerReducer } from "use-immer";

// reactstrap components
import { Button } from "reactstrap";

const RejectedNavigation = ({ data }) => {
  const id = data.id;

  const initialState = {
    personal_information: {
      count: 0,
    },
    address: {
      count: 0,
    },
    family: {
      count: 0,
    },
    nominee: {
      count: 0,
    },
    guardian: {
      count: 0,
    },
    account: {
      count: 0,
    },
    occupation: {
      count: 0,
    },
    documents: {
      count: 0,
    },
    // payment_status: {
    //   count: 0,
    // },
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "personal_infoRejected":
        draft.personal_information.count++;
        return;
      case "addressRejected":
        draft.address.count++;
        return;
      case "familyRejected":
        draft.family.count++;
        return;
      case "nomineeRejected":
        draft.nominee.count++;
        return;
      case "guardianRejected":
        draft.guardian.count++;
        return;
      case "accountRejected":
        draft.account.count++;
        return;
      case "occupationRejected":
        draft.occupation.count++;
        return;
      case "documentsRejected":
        draft.documents.count++;
        return;
      // case "payment_statusRejected":
      //   draft.payment_status.count++;
      //   return;
      case "default":
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    data.reasons?.map((item, i) => {
      if (item.type === "personal_information") {
        dispatch({ type: "personal_infoRejected" });
      }
      if (item.type === "address") {
        dispatch({ type: "addressRejected" });
      }
      if (item.type === "family") {
        dispatch({ type: "familyRejected" });
      }
      if (item.type === "nominee") {
        dispatch({ type: "nomineeRejected" });
      }
      if (item.type === "guardian") {
        dispatch({ type: "guardianRejected" });
      }
      if (item.type === "account") {
        dispatch({ type: "accountRejected" });
      }
      if (item.type === "occupation") {
        dispatch({ type: "occupationRejected" });
      }
      if (item.type === "documents") {
        dispatch({ type: "documentsRejected" });
      }
      // if (item.type === "payment_status") {
      //   dispatch({ type: "payment_statusRejected" });
      // }
    });
  }, []);

  return (
    <ul className="nav nav-pills flex-column flex-md-row justify-content-start">
      {state.personal_information.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Personal Info
              <span className="badge badge-danger">
                {state.personal_information.count}
              </span>
            </Button>
          </Link>
        </li>
      )}
      {state.address.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Address
              <span className="badge badge-danger">{state.address.count}</span>
            </Button>
          </Link>
        </li>
      )}
      {state.family.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Family
              <span className="badge badge-danger">{state.family.count}</span>
            </Button>
          </Link>
        </li>
      )}
      {state.nominee.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Nominee
              <span className="badge badge-danger">{state.nominee.count}</span>
            </Button>
          </Link>
        </li>
      )}
      {state.guardian.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Guardian
              <span className="badge badge-danger">{state.guardian.count}</span>
            </Button>
          </Link>
        </li>
      )}
      {state.account.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Account
              <span className="badge badge-danger">{state.account.count}</span>
            </Button>
          </Link>
        </li>
      )}
      {state.occupation.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Occupation
              <span className="badge badge-danger">
                {state.occupation.count}
              </span>
            </Button>
          </Link>
        </li>
      )}
      {state.documents.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Documents
              <span className="badge badge-danger">
                {state.documents.count}
              </span>
            </Button>
          </Link>
        </li>
      )}
      {/* {state.payment_status.count > 0 && (
        <li className="nav-item pt-3">
          <Link to={`/dashboard/customers/rejected/${id}`}>
            <Button className="btn-neutral btn-outline-danger">
              Payments
              <span className="badge badge-danger">
                {state.payment_status.count}
              </span>
            </Button>
          </Link>
        </li>
      )} */}
    </ul>
  );
};

export default RejectedNavigation;
