import React, { useContext } from "react";
import { Tab, Nav } from "react-bootstrap";

import FormState from "../FormState";

// Component
import Account from "./Account";
import Address from "./Address";
import Agreement from "./Agreement";
import Documents from "./Documents";
import FamilyInformation from "./FamilyInformation";
import Guardian from "./Guardian";
import Nominee from "./Nominee";
import Occupation from "./Occupation";
import PersonalInfo from "./PersonalInfo";

// Icons
import {
  Bank,
  Briefcase,
  Document,
  PersonalInfoIcon,
  Key,
  Location,
  Users,
} from "../../Layout/Icons";

const CustomerForm = () => {
  const formState = useContext(FormState);

  return (
    <div>
      <Tab.Container
        id="left-tabs-example-1"
        defaultActiveKey="personalInfo"
        activeKey={formState.tabs}
      >
        <section className="navigation">
          <div className="container-fluid">
            <Nav variant="pills" className="justify-content-center">
              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="personalInfo" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <PersonalInfoIcon className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Personal Information
                </Nav.Item>
              </div>
              {formState.showGuardian && (
                <div className="item-container align-items-center">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="guardian" className="nav-link">
                      <span className="number">
                        <div className="icon-container ">
                          <Key className="icon" />
                        </div>
                      </span>
                    </Nav.Link>
                    Guardian
                  </Nav.Item>
                </div>
              )}

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="address" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Location className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Address
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="family" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Users className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Family Information
                </Nav.Item>
              </div>
              {formState.showNominee && (
                <div className="item-container align-items-center">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="nominee" className="nav-link">
                      <span className="number">
                        <div className="icon-container ">
                          <Location className="icon" />
                        </div>
                      </span>
                    </Nav.Link>
                    Nominee
                  </Nav.Item>
                </div>
              )}

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="account" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Bank className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Account
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="occupation" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Briefcase className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Occupation
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="document" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Document className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Documents
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="agreement" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Briefcase className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Agreement
                </Nav.Item>
              </div>
            </Nav>
          </div>
        </section>
        <section className="section-form">
          <div className="container">
            <div className="row align-items-center">
              <Tab.Content>
                <Tab.Pane eventKey="personalInfo">
                  <PersonalInfo />
                </Tab.Pane>
                {formState.showGuardian && (
                  <Tab.Pane eventKey="guardian">
                    <Guardian />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="address">
                  <Address />
                </Tab.Pane>
                <Tab.Pane eventKey="family">
                  <FamilyInformation />
                </Tab.Pane>
                <Tab.Pane eventKey="account">
                  <Account />
                </Tab.Pane>
                {formState.showNominee && (
                  <Tab.Pane eventKey="nominee">
                    <Nominee />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="occupation">
                  <Occupation />
                </Tab.Pane>
                <Tab.Pane eventKey="document">
                  <Documents />
                </Tab.Pane>
                {/* <Maps setLng={state.formData.documents.long} setLat={12.21} /> */}
                <Tab.Pane eventKey="agreement">
                  <Agreement />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </section>
      </Tab.Container>
    </div>
  );
};

export default CustomerForm;
