import React, { useContext } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import DispatchContext from "../../../DispatchContext";
import StateContext from "../../../StateContext";
import Avatar from "../../../Assets/images/avatar-small.jpg";
import { Cog, Logout, Search, User } from "../../Layout/Icons";

function Header({ id }) {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  function handleLogout(e) {
    e.preventDefault();
    appDispatch({ type: "adminLogout" });
  }

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/admin/dashboard"
        >
          Gurkhas Finance Ltd.
        </Link>
        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Search className="icon" />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Search" type="text" />
            </InputGroup>
          </FormGroup>
        </Form>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="Gurkhas Finance Ltd." src={Avatar} />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {appState.user.data.user?.name}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to={`/admin/dashboard/profile/${id}`} tag={Link}>
                <User className="icon" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to={`/admin/dashboard/settings/${id}`} tag={Link}>
                <Cog className="icon" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="" onClick={handleLogout}>
                <Logout className="icon" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
