import { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";

//components
import AdminLoggedOut from "./Components/AdminLoggedOut";
import Welcome from "./Components/Welcome";
import ClientLoggedOut from "./Components/ClientLoggedOut";
import AdminDashboard from "./Components/AdminDashboard";
import ClientDashboard from "./Components/ClientDashboard";
import PageNotFound from "./Components/PageNotFound";
import CustomerCreate from "./Components/CustomerForm/CustomerCreate";
import KYC from "./Components/Admin/Customer/CustomerView/CustomerFormView/KYC/KYC";
import MeroShare from "./Components/Admin/Customer/CustomerView/CustomerFormView/MeroShare";
import Renewal from "./Components/Admin/Customer/CustomerView/CustomerFormView/Renewal";

//context
import StateContext from "./StateContext";
import DispatchContext from "./DispatchContext";
import ForgotPassword from "./Components/Handler/ForgotPassword";
import Verify from "./Components/Handler/Verify";

function App() {
  const initialState = {
    adminLoggedIn: Boolean(localStorage.getItem("GurkhasAdminToken")),
    clientLoggedIn: Boolean(localStorage.getItem("GurkhasClientToken")),
    user: {
      data: {
        token: localStorage.getItem("GurkhasAdminToken"),
        user: JSON.parse(localStorage.getItem("GurkhasAdminData")),
      },
    },
    client: {
      data: {
        token: localStorage.getItem("GurkhasClientToken"),
        user: JSON.parse(localStorage.getItem("GurkhasClientData")),
      },
    },
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function ourReducer(draft, action) {
    switch (action.type) {
      case "adminLogin":
        draft.adminLoggedIn = true;
        draft.user = action.value;
        return;
      case "adminLogout":
        draft.adminLoggedIn = false;
        return;
      case "clientLogin":
        draft.client = action.value;
        if (
          draft.client.data.user.roles[0] &&
          draft.client.data.user.roles[0].name === "customer"
        ) {
          console.log("THE USER IS A CUSTOMER");
          draft.clientLoggedIn = true;
        } else {
          console.log("USER IS NOT CUSTOMER");
        }
        return;
      case "clientLogout":
        draft.clientLoggedIn = false;
        return;
      case "otpSend":
        draft.user.uniqueId = action.value;
        return;
      case "saveUsername":
        draft.user.username = action.value;
        return;
      case "savePassword":
        draft.user.password = action.value;
        return;
    }
  }

  useEffect(() => {
    if (state.adminLoggedIn) {
      // console.log("user data", state.user);
      localStorage.setItem("GurkhasAdminToken", state.user.data.token);
      // localStorage.setItem("GurkhasAdminUsername", state.user.data.user.email);
      localStorage.setItem(
        "GurkhasAdminData",
        JSON.stringify(state.user.data.user)
      );
    } else {
      localStorage.removeItem("GurkhasAdminToken");
      localStorage.removeItem("GurkhasAdminUsername");
      localStorage.removeItem("GurkhasAdminData");
    }
  }, [state.adminLoggedIn]);

  useEffect(() => {
    // console.log("client logged in status changed");
    if (state.clientLoggedIn) {
      // console.log("client data", state.client);
      localStorage.setItem("GurkhasClientToken", state.client.data.token);
      localStorage.setItem(
        "GurkhasClientData",
        JSON.stringify(state.client.data.user)
      );
    } else {
      localStorage.removeItem("GurkhasClientToken");
      localStorage.removeItem("GurkhasClientUsername");
      localStorage.removeItem("GurkhasClientData");
    }
  }, [state.clientLoggedIn]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Switch>
            <Route path="/admin" exact>
              <AdminLoggedOut />
            </Route>
            <Route path="/admin/dashboard">
              <AdminDashboard />
            </Route>
            <Route path="/login" exact>
              <ClientLoggedOut />
            </Route>
            <Route path="/dashboard">
              <ClientDashboard />
            </Route>
            <Route path="/kyc/:id">
              <KYC />
            </Route>
            <Route path="/meroShare/:id">
              <MeroShare />
            </Route>
            <Route path="/renew/:id">
              <Renewal />
            </Route>
            <Route path="/" exact>
              <Welcome />
            </Route>
            <Route path="/forgotPassword" exact>
              <ForgotPassword />
            </Route>
            <Route path="/customers">
              <CustomerCreate />
            </Route>
            <Route path="/password/reset">
              <Verify />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
