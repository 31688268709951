import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import logo from "../../Assets/images/logo.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Mail } from "../Layout/Icons";
import Footer from "../Layout/Footer";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const initialState = {
    email: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
    },
    success: null,
    error: null,
    submitCount: 0,
    isPending: false,
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "emailChange":
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        draft.error = null;
        return;
      case "emailAfterDelay":
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            draft.email.value
          )
        ) {
          draft.email.hasErrors = true;
          draft.email.message = "You must provide a valid email address";
        }
        return;
      case "submitForm":
        if (!draft.email.hasErrors) {
          draft.submitCount++;
        }
        return;
      case "setSuccess":
        draft.success = action.value;
      case "setError":
        draft.error = action.value;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.email.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "emailAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.email.value]);

  useEffect(() => {
    if (state.submitCount) {
      dispatch({ type: "setIsPending", value: true });
      const ourRequest = axios.CancelToken.source();
      async function reset() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/password/forgot-password`,
            {
              email: state.email.value,
            },
            { cancelToken: ourRequest.token }
          );
          if (response.data) {
            dispatch({ type: "setSuccess", value: response.data.message });
          }
          console.log("response.data.forgot", response);
          dispatch({ type: "setIsPending", value: false });
        } catch (e) {
          console.log(e, "there was an error");
          if (e.response?.data) {
            dispatch({ type: "setError", value: e.response?.data?.message });
            dispatch({ type: "setIsPending", value: false });
          }
        }
      }
      reset();
      return () => ourRequest.cancel();
    }
  }, [state.submitCount]);

  console.log(state.email.hasErrors);
  console.log(state.submitCount);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "submitForm" });
  }
  return (
    <div className="admin">
      <div className="bg-default">
        <div className="main-content">
          <div className="header py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7"></div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent">
                    <div className="text-center px-5 py-3">
                      <img
                        alt="Gurkhas Finance Ltd"
                        src={logo}
                        style={{
                          height: "80px",
                          width: "260px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </CardHeader>

                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Forgot your password</small>
                    </div>
                    {state.email.hasErrors && (
                      <Alert className="alert-danger alert-dismissible">
                        {state.email.message}
                      </Alert>
                    )}
                    <Form role="form">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <Mail className="icon" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Enter your email"
                            type="email"
                            autoComplete="new-email"
                            onChange={(e) =>
                              dispatch({
                                type: "emailChange",
                                value: e.target.value,
                              })
                            }
                            value={state.email.value}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={handleSubmit}
                        >
                          {state.isPending ? "Please wait . . ." : "Send Code"}
                        </Button>
                        {state.error && (
                          <Alert className="alert-danger alert-dismissible mt-4">
                            {state.error}
                          </Alert>
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link to="/login" className="text-light">
                      <small>Sign in</small>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link to="/" className="text-light">
                      <small>Create new account</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
