import React from "react";

const Page6 = (props) => {
  console.log(props);
  return (
    <div className="page">
      <div className="text-center">
        <img
          src={
            props.guardian !== false
              ? `${process.env.REACT_APP_BASE_URL}/img/` +
                props.data.email +
                "/guardian/" +
                props.guardian?.gov_issued_id_front_name
              : `${process.env.REACT_APP_BASE_URL}/img/` +
                props.data.email +
                "/" +
                props.data.documents[0]?.gov_issued_id_front_name
          }
          alt=""
          style={{ height: "800px", width: "800px", objectFit: "contain" }}
        />
        <img
          src={
            props.guardian !== false
              ? `${process.env.REACT_APP_BASE_URL}/img/` +
                props.data.email +
                "/guardian/" +
                props.guardian?.gov_issued_id_back_name
              : `${process.env.REACT_APP_BASE_URL}/img/` +
                props.data.email +
                "/" +
                props.data.documents[0]?.gov_issued_id_back_name
          }
          alt=""
          style={{ height: "800px", width: "800px", objectFit: "contain" }}
        />
      </div>
    </div>
  );
};

export default Page6;
