import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import BankData from "../../../Assets/banks.json";
import { Info } from "../../Layout/Icons";
import FormState from "../FormState";
import FormDispatch from "./../FormDispatch";

function Account(props) {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);

  const initialState = {
    formData: {
      typeOfBank: {
        name: "typeOfBank",
        value: "",
        hasErrors: false,
        message: "",
      },
      bankName: {
        name: "bankName",
        value: "Gurkhas Finance Ltd.",
        hasErrors: false,
        message: "",
        bankList: [],
        bankListToShow: [],
      },
      branch: {
        name: "branch",
        value: " Head Office Branch",
        hasErrors: false,
        message: "",
        branchList: [
          " Head Office Branch",
          "Janagal Branch",
          "Bhedetar Branch",
          " Itahari Branch",
          " New Road Branch",
          " Satdobato Branch",
          " Birtamode Branch",
          " Narayanghat Branch",
          " Bhaktapur Branch",
          "Boudha Branch",
          "Damak Branch",
          "Dharan Branch",
          "Banepa Branch",
          "Bhaktapur",
          "Rabi Branch",
          "Chaumala Branch",
          "Padsari Branch",
          "Chapradi Branch",
          "Pokhara Branch",
          "Bhojpur Branch",
          "Surkhet Branch",
          "Nepalgunj Branch",
          "Birgunj Branch",
          "Gongabu Branch",
          "Belatar Branch",
          "Hetauda Branch",
          "Barahathawa Branch",
          "Koteshwor Branch",
          "Bijauri Branch",
        ],
      },
      accountNo: {
        name: "accountNo",
        value: "",
        hasErrors: false,
        message: "",
      },
    },
    demat: 2,
    meroShare: 2,
    errorCount: 0,
    saveCount: 0,
    backCount: 0,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
          }
        }
        return;
      case "bankValueChange":
        console.log("bank value change");
        draft.formData.bankName.hasErrors = false;
        draft.formData.bankName.value = action.value;

        draft.formData.branch.branchList = [];
        draft.formData.branch.branchList.push();
        let branches = draft.formData.bankName.bankList.filter((item) =>
          item.includes(action.value)
        );
        branches.map((item, i) => {
          draft.formData.branch.branchList.push(
            item.substring(item.indexOf("-") + 1, item.length)
          );
        });
        return;
      case "renderBanks":
        BankData.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              draft.formData.bankName.bankList.push(item.bankName);
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        // console.log(draft.formData.bankName.bankList.length)
        draft.formData.bankName.bankList.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              if (
                !draft.formData.bankName.bankListToShow.includes(
                  item.substring(0, item.indexOf("-"))
                )
              ) {
                draft.formData.bankName.bankListToShow.push(
                  item.substring(0, item.indexOf("-"))
                );
              }
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        return;
      case "dematFalse":
        draft.demat = 1;
        return;
      case "dematTrue":
        draft.demat = 2;
        return;
      case "meroShareFalse":
        draft.meroShare = 1;
        return;
      case "meroShareTrue":
        draft.meroShare = 2;
        return;
      case "validateForm":
        console.log("validate form");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].value) {
            console.log("no value in", draft.formData[key]);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
        }
        for (const key in draft.formData) {
          draft.formData[key].hasErrors && draft.errorCount++;
        }
        if (!draft.errorCount) {
          draft.saveCount++;
        }
        return;
      case "setBackCount":
        draft.backCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    dispatch({ type: "renderBanks" });
  }, []);

  const dematCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "dematTrue" });
    } else {
      dispatch({ type: "dematFalse" });
    }
  };

  const meroShareCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "meroShareTrue" });
    } else {
      dispatch({ type: "meroShareFalse" });
    }
  };
  useEffect(() => {
    if (state.formData?.bankName?.value !== "Gurkhas Finance Ltd.") {
      dispatch({ type: "dematFalse" });
    } else {
      dispatch({ type: "dematTrue" });
    }
  }, [state.formData?.bankName?.value]);

  useEffect(() => {
    if (state.saveCount) {
      console.log("ready to save");
      formDispatch({
        type: "saveAccount",
        value: {
          bank_name: state.formData.bankName.value,
          bank_code: "1101",
          branch_name: state.formData.branch.value,
          number: state.formData.accountNo.value,
          type: state.formData.typeOfBank.value,
          demat: state.demat,
          meroShare: state.meroShare,
        },
      });
      // props.history.push("/customers/create/occupation");
      formDispatch({ type: "activeTabs", value: "occupation" });
    }
  }, [state.saveCount]);

  function handleSubmit() {
    dispatch({ type: "validateForm" });
  }

  function handleBackCount(e) {
    e.preventDefault();
    dispatch({ type: "setBackCount" });
  }

  useEffect(() => {
    if (state.backCount > 0) {
      if (formState.showNominee === true) {
        formDispatch({ type: "activeTabs", value: "nominee" });
      }
      if (formState.showNominee === false) {
        formDispatch({ type: "activeTabs", value: "family" });
      }
    }
  }, [state.backCount]);

  return (
    <form action="" className="form position-relative">
      <div className="form-container personal-info">
        <div className="text-container">
          <h2>Account details</h2>
          <span
            className="position-absolute end-0 top-0"
            onClick={() => {
              formDispatch({ type: "setInfo" });
            }}
          >
            <Info />
          </span>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.typeOfBank.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="account-type_of_bank_account">
                Type of Bank Account / बैंक खाताको किसिम
              </label>
              <span className="text-danger">*</span>
              <select
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "typeOfBank",
                  })
                }
                name=""
                id="account-type_of_bank_account"
                className="form-input"
                required
              >
                <option value="" selected disabled>
                  Select an account
                </option>
                <option value="1">Saving</option>
                <option value="2">Current</option>
              </select>
              {state.formData.typeOfBank.hasErrors && (
                <span className="error">
                  {state.formData.typeOfBank.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.bankName.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="account-bank_name">
                Bank Name / खाता रहेको बैंकको नाम
              </label>
              <span className="text-danger">*</span>
              <select
                onChange={(e) =>
                  dispatch({
                    type: "bankValueChange",
                    value: e.target.value,
                    field: "bankName",
                  })
                }
                name=""
                id="account-bank_name"
                className="form-input"
                required
              >
                {state.formData.bankName.bankListToShow.map((item, i) => {
                  return (
                    <option
                      key={i}
                      value={item}
                      selected={i === 79 && "selected"}
                    >
                      {item}
                    </option>
                  );
                })}
              </select>

              {state.formData.bankName.hasErrors && (
                <span className="error">{state.formData.bankName.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.branch.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="account-branch">Branch / बैंक शाखाको नाम</label>
              <span className="text-danger">*</span>
              <select
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "branch",
                  })
                }
                name=""
                id="account-branch"
                className="form-input"
                value={state.formData.branch.value}
                required
              >
                {state.formData.branch.branchList.length &&
                  state.formData.branch.branchList.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item}
                        selected={i === 0 && "selected"}
                      >
                        {item}
                      </option>
                    );
                  })}
              </select>
              {state.formData.branch.hasErrors && (
                <span className="error">{state.formData.branch.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.formData.accountNo.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="account-bank_account_no">
                Bank Account No / बैंक खाता नंम्बर
              </label>
              <span className="text-danger">*</span>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "accountNo",
                  })
                }
                type="number"
                name=""
                id="account-bank_account_no"
                className="form-input"
                placeholder="Enter Bank account no"
                required
              />
              {state.formData.accountNo.hasErrors && (
                <span className="error">
                  {state.formData.accountNo.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="checkbox"
                className="form-input"
                id="account-open_mero_share"
                onClick={meroShareCheck}
                checked={"checked"}
                style={{ width: "14px" }}
              />
              <label htmlFor="account-open_mero_share" className="mb-0 mx-2">
                Open Mero Share Account
              </label>
            </div>
          </div>
        </div>
        {state.formData?.bankName?.value === "Gurkhas Finance Ltd." ? (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="input-group mb-0">
                  <input
                    type="checkbox"
                    className="form-input"
                    id="account-renew_my_demat"
                    onClick={dematCheck}
                    checked={state.demat === 2 ? "checked" : ""}
                    style={{ width: "14px" }}
                  />
                  <label htmlFor="account-renew_my_demat" className="mb-0 mx-2">
                    Renew my Demat Account automatically
                  </label>
                </div>
                <p
                  style={{
                    fontSize: "15px",
                    display: "inline-block",
                    marginLeft: "20px",
                    marginBottom: "20px",
                  }}
                >
                  If you agree for this, the amount for renewal will be deducted
                  from your Gurkhas Finance’s account
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <span
          className="btn-transparent mr-2 "
          // to="/customers/create/familyInformation"
          onClick={handleBackCount}
        >
          <span>← </span>
          Previous Page
        </span>
        <span onClick={handleSubmit} className="btn-blue">
          Next Page
          <span> →</span>
        </span>
      </div>
    </form>
  );
}

export default withRouter(Account);
