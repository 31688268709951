// reactstrap components
import { Table, Container } from "reactstrap";

function Family(props) {
  return (
    <Container fluid>
      <Table className="align-items-center table-flush mb-5 " responsive>
        <tbody>
          <tr>
            <td className="font-weight-bold">Father's Name:</td>
            <td>{props.family.father_name}</td>
            <td className="font-weight-bold">Grandfather's Name:</td>
            <td>{props.family.grand_father_name}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Mother's Name:</td>
            <td>{props.family.mother_name}</td>
            <td className="font-weight-bold">spouse's Name:</td>
            <td>{props.family.spouse_name}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Son's Name:</td>
            <td>{props.family.son_name}</td>
            <td className="font-weight-bold">Daughter's Name:</td>
            <td>{props.family.daughter_name}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Father in Law's Name:</td>
            <td>{props.family.father_in_law_name}</td>
            <td className="font-weight-bold">Daughter's Name:</td>
            <td>{props.family.daughter_in_law_name}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default Family;
