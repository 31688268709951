import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";

import { Alert, Col, FormGroup, Input, Row } from "reactstrap";

import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

const FamilyInformation = (props) => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);
  const [saveCount, setSaveCount] = useState(0);
  const initialState = {
    fathersName: {
      name: "fathersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    grandFathersName: {
      name: "grandFathersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    mothersName: {
      name: "mothersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    spousesName: {
      name: "spousesName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughterInLawsName: {
      name: "daughterInLawsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    fatherInLawsName: {
      name: "fatherInLawsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    sonsName: {
      name: "sonsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughtersName: {
      name: "daughtersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "validateForm":
        let errorCount = 0;
        if (!draft.fathersName.value.length) {
          draft.fathersName.hasErrors = true;
          draft.fathersName.message = "This field is required";
        }
        if (!draft.grandFathersName.value.length) {
          draft.grandFathersName.hasErrors = true;
          draft.grandFathersName.message = "This field is required";
        }
        if (!draft.mothersName.value.length) {
          draft.mothersName.hasErrors = true;
          draft.mothersName.message = "This field is required";
        }
        for (const key in draft) {
          if (draft[key].hasErrors) {
            errorCount++;
          }
        }
        // console.log("error count is ", errorCount)
        if (!errorCount) {
          setSaveCount(saveCount + 1);
        }
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (saveCount) {
      formDispatch({
        type: "saveFamily",
        value: {
          father_name: state.fathersName.value,
          grand_father_name: state.grandFathersName.value,
          mother_name: state.mothersName.value,
          spouse: state.spousesName.value,
          son_name: state.sonsName.value,
          daughter_name: state.daughtersName.value,
          father_in_law_name: state.fatherInLawsName.value,
          daughter_in_law_name: state.daughterInLawsName.value,
        },
      });
    }
  }, [saveCount]);

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    setSaveCount(saveCount + 1);
  }, [
    state.fathersName,
    state.grandFathersName,
    state.mothersName,
    state.spousesName,
    state.daughterInLawsName,
    state.fatherInLawsName,
    state.sonsName,
    state.daughtersName,
  ]);

  function handleCheck(e) {
    if (e.target.checked) {
      formDispatch({ type: "nomineeTrue" });
    } else {
      formDispatch({ type: "nomineeFalse" });
    }
  }
  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-father_name">
              Father's Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.fathersName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-father_name"
              type="text"
              placeholder={` ${
                state.fathersName.hasErrors
                  ? state.fathersName.message
                  : "Enter your Father's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "fathersName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "fathersName",
                })
              }
              value={state.fathersName.value}
            />
            {/* {state.fathersName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.fathersName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-grandfather_name"
            >
              Grandfather's Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.grandFathersName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-grandfather_name"
              type="text"
              placeholder={` ${
                state.grandFathersName.hasErrors
                  ? state.grandFathersName.message
                  : "Enter your Grandfather's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "grandFathersName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "grandFathersName",
                })
              }
              value={state.grandFathersName.value}
            />
            {/* {state.grandFathersName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.grandFathersName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-mother_name">
              Mother's Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.mothersName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-mother_name"
              type="text"
              placeholder={` ${
                state.mothersName.hasErrors
                  ? state.mothersName.message
                  : "Enter your Mother's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "mothersName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "mothersName",
                })
              }
              value={state.mothersName.value}
            />
            {/* {state.mothersName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.mothersName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-spouse_name">
              Spouse Name
            </label>
            <Input
              className={`form-control-alternative ${
                state.spousesName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-spouse_name"
              type="text"
              placeholder={` ${
                state.spousesName.hasErrors
                  ? state.spousesName.message
                  : "Enter your Spouse's Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "spousesName",
                })
              }
              value={state.spousesName.value}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-daughter_in_law_name"
            >
              Daughter in Law's Name
            </label>
            <Input
              className={`form-control-alternative ${
                state.daughterInLawsName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-daughter_in_law_name"
              type="text"
              placeholder={` ${
                state.daughterInLawsName.hasErrors
                  ? state.daughterInLawsName.message
                  : "Enter your Daughter in Law's Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "daughterInLawsName",
                })
              }
              value={state.daughterInLawsName.value}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-father_in_law_name"
            >
              Father's in Law's Name
            </label>
            <Input
              className={`form-control-alternative ${
                state.fatherInLawsName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-father_in_law_name"
              type="text"
              placeholder={` ${
                state.fatherInLawsName.hasErrors
                  ? state.fatherInLawsName.message
                  : "Enter your Father in Law's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "fatherInLawsName",
                })
              }
              value={state.fatherInLawsName.value}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-son_name">
              Son's Name
            </label>
            <Input
              className={`form-control-alternative ${
                state.sonsName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-son_name"
              type="text"
              placeholder={` ${
                state.sonsName.hasErrors
                  ? state.sonsName.message
                  : "Enter your Son's Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "sonsName",
                })
              }
              value={state.sonsName.value}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-daughter_name">
              Daughter's Name
            </label>
            <Input
              className={`form-control-alternative ${
                state.daughtersName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-daughter_name"
              type="text"
              placeholder={` ${
                state.daughtersName.hasErrors
                  ? state.daughtersName.message
                  : "Enter your Daughter's Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "daughtersName",
                })
              }
              value={state.daughtersName.value}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <FormGroup>
          <Input
            onChange={handleCheck}
            type="checkbox"
            className="form-input  alert-primary ml-1"
            id="family-add_nominee"
          />
          <label
            className="form-control-label"
            htmlFor="family-add_nominee"
            style={{ marginLeft: "3rem" }}
          >
            Do you want to add nominee?
          </label>
        </FormGroup>
      </Row>
    </>
  );
};

export default FamilyInformation;
