import React, { useContext } from "react";
import { Tab, Nav } from "react-bootstrap";

import FormState from "../../FormState";

// Components
import CreatePassword from "./CreatePassword";
import Register from "./Register";
import Verification from "./Verification";
// Icons
import { RegisterIcon, Check, CircleCheck, Key } from "../../../Layout/Icons";

const CustomerRegister = () => {
  const formState = useContext(FormState);

  return (
    <div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="register"
        activeKey={formState.tab}
      >
        <section className="navigation my-4">
          <div className="container-fluid">
            <Nav variant="pills" className="justify-content-center">
              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="register" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <RegisterIcon className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  <span className="text">Registration</span>
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="verification" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <CircleCheck className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Verification
                </Nav.Item>
              </div>

              <div className="item-container align-items-center">
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="createPassword" className="nav-link">
                    <span className="number">
                      <div className="icon-container ">
                        <Key className="icon" />
                      </div>
                    </span>
                  </Nav.Link>
                  Create Account
                </Nav.Item>
              </div>
            </Nav>
          </div>
        </section>

        <div className="container">
          <div className="row align-items-center">
            <Tab.Content>
              <Tab.Pane eventKey="register">
                <Register />
              </Tab.Pane>
              <Tab.Pane eventKey="verification">
                <Verification />
              </Tab.Pane>
              <Tab.Pane eventKey="createPassword">
                <CreatePassword />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};

export default CustomerRegister;
