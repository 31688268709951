import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Table, Container } from "reactstrap";

function Documents(props) {
  return (
    <div>
      <Container fluid>
        <Table className="align-items-center table-flush mb-5 " responsive>
          <tbody>
            {props.nominee !== false && (
              <>
                <tr>
                  <td className="font-weight-bold">
                    Passport size photo(Nominee):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/nominee/" +
                        props.nominee?.photo_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/nominee/" +
                          props.nominee?.photo_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">
                    Certificate front(Nominee):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/nominee/" +
                        props.nominee?.gov_issued_id_front_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/nominee/" +
                          props.nominee?.gov_issued_id_front_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">
                    Certificate back(Nominee):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/nominee/" +
                        props.nominee?.gov_issued_id_back_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/nominee/" +
                          props.nominee?.gov_issued_id_back_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Signature(Nominee):</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/nominee/" +
                        props.nominee?.signature_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/nominee/" +
                          props.nominee?.signature_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
              </>
            )}
            {props.guardian !== false && (
              <>
                <tr>
                  <td className="font-weight-bold">Birth Certificate:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.birth_certificate_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.birth_certificate_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">
                    Passport size photo(Guardian):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/guardian/" +
                        props.guardian?.photo_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/guardian/" +
                          props.guardian?.photo_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">
                    Certificate front(Guardian):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/guardian/" +
                        props.guardian?.gov_issued_id_front_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/guardian/" +
                          props.guardian?.gov_issued_id_front_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">
                    Certificate back(Guardian):
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/guardian/" +
                        props.guardian?.gov_issued_id_back_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/guardian/" +
                          props.guardian?.gov_issued_id_back_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Signature(Guardian):</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/guardian/" +
                        props.guardian?.signature_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/guardian/" +
                          props.guardian?.signature_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
              </>
            )}
            {props.guardian === false && (
              <>
                <tr>
                  <td className="font-weight-bold">Passport Size photo:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.photo_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.photo_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Certificate front:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.gov_issued_id_front_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.gov_issued_id_front_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Certificate back:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.gov_issued_id_back_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.gov_issued_id_back_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Thumb right:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.thumb_right_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.thumb_right_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Thumb left:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.thumb_left_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.thumb_left_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold">Signature:</td>
                  <td>
                    <a
                      target="_blank"
                      href={
                        `${process.env.REACT_APP_BASE_URL}/img/` +
                        props?.email +
                        "/" +
                        props.documents?.signature_name
                      }
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BASE_URL}/img/` +
                          props?.email +
                          "/" +
                          props.documents?.signature_name
                        }
                        alt=""
                        style={{ height: "500px", objectFit: "contain" }}
                      />
                    </a>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default Documents;
