import React, { useRef, useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "!mapbox-gl";
// import SearchBox from "@mapbox/search-js-react";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmlqZW4iLCJhIjoiY2w0ODJldHRmMDJsNDNpcnk5MndpNTVwOCJ9.oI1lm1fea7Ktv4XqZbOSTg";
const Maps = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  /* eslint import/no-webpack-loader-syntax: off */

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/bijen/cl4887nzz003u15p1s53vhbhy",
      center: [props.setLng, props.setLat],
      zoom: 15,
    });
  }, []);

  const onLoaded = (map: MapboxGl.Map) => {
    map.resize();
  };

  return (
    <>
      <div className="map-marker">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20 10c0 6-8 12-8 12S4 16 4 10a8 8 0 0 1 16 0Z"></path>
          <circle cx="12" cy="10" r="3"></circle>
        </svg>
      </div>
      <div ref={mapContainer} className="map-container" />
    </>
  );
};

export default Maps;
