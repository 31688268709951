import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import adbs from "ad-bs-converter";
import districts from "../../../../Assets/district-list-nepal";
import nationality from "../../../../Assets/nationality";

//CONTEXTS
import FormState from "../../../CustomerForm/FormState";
import FormDispatch from "../../../CustomerForm/FormDispatch";

import { FormGroup, Input, Row, Col, Alert } from "reactstrap";

const PersonalInfo = () => {
  const formState = useContext(FormState);
  const formDispatch = useContext(FormDispatch);
  const initialState = {
    fullName: {
      name: "fullName",
      value: formState.formData.customers.full_name,
      hasErrors: false,
      message: "",
      touched: false,
    },
    mobile: {
      name: "mobile",
      value: formState.formData.customers.mobile,
      hasErrors: false,
      message: "",
      touched: false,
    },
    email: {
      name: "email",
      value: formState.formData.customers.email,
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobBs: {
      name: "dobBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    dobAd: {
      name: "dobAd",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dobFormatted: "",
    },
    gender: {
      name: "gender",
      value: formState.formData.personal_informations.gender,
      hasErrors: false,
      message: "",
      touched: false,
    },
    maritalStatus: {
      name: "maritalStatus",
      value: formState.formData.personal_informations.marital_status,
      hasErrors: false,
      message: "",
      touched: false,
    },
    accountType: {
      name: "accountType",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    nationality: {
      name: "nationality",
      value: formState.formData.personal_informations.nationality,
      hasErrors: false,
      message: "",
      touched: false,
    },
    typeOfId: {
      name: "typeOfId",
      value: formState.formData.personal_informations.identity_card_type,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idNo: {
      name: "idNo",
      value: formState.formData.personal_informations.identity_card_number,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDistrict: {
      name: "idIssueDistrict",
      value:
        formState.formData.personal_informations.identity_card_issue_district,
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDateBs: {
      name: "idIssueDateBs",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    idIssueDate: {
      name: "idIssueDate",
      value: new Date(),
      hasErrors: false,
      message: "",
      touched: false,
      dateFormatted: "",
    },
    pan: {
      name: "pan",
      value: formState.formData.personal_informations.pan_number,
      hasErrors: false,
      message: "",
      touched: false,
      checkCount: 0,
      checkingNow: false,
    },
    checkCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft) {
          if (draft[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft[key].hasErrors = true;
              draft[key].message = `This field cannot be blank`;
              return;
            }
            draft[key].value = action.value;
          }
        }
        return;
      case "fullNameChange":
        draft.fullName.touched = true;
        if (action.value.length > 50) {
          draft.fullName.hasErrors = true;
          draft.fullName.message = "Full name cannot exceed 50 characters";
          return;
        }
        draft.fullName.hasErrors = false;
        draft.fullName.value = action.value;
        return;
      case "validateFullName":
        if (action.value.length < 3) {
          draft.fullName.hasErrors = true;
          draft.fullName.message =
            "FullName must be alteast 3 character's long";
        }
        return;
      case "mobileChange":
        draft.mobile.touched = true;
        draft.mobile.hasErrors = false;
        draft.mobile.value = action.value;
        return;
      case "validateMobile":
        if (action.value.length !== 10) {
          draft.mobile.hasErrors = true;
          draft.mobile.message = "Mobile number must be 10 character's long";
        }
        return;
      case "emailChange":
        draft.email.touched = true;
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        return;
      case "validateEmail":
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            draft.email.value
          )
        ) {
          draft.email.hasErrors = true;
          draft.email.message = "You must provide a valid email address";
        }
        return;
      case "dobBsChange":
        draft.dobBs.touched = true;
        draft.dobBs.hasErrors = false;
        draft.dobBs.value = action.value;
        return;
      case "dobAdChange":
        console.log("dobadchanged reducer hit");
        draft.dobAd.touched = true;
        draft.dobAd.hasErrors = false;
        draft.dobAd.value = action.value;
        draft.dobAd.dobFormatted = formatDate(action.value);
        console.log(draft.dobAd.value.getFullYear());
        console.log(new Date().getFullYear() - draft.dobAd.value.getFullYear());
        const yearToday =
          new Date().getFullYear() - draft.dobAd.value.getFullYear();
        if (parseInt(yearToday) < 16) {
          console.log("Age is less than 16 years onld");
          formDispatch({ type: "minorTrue", value: "true" });
        } else {
          formDispatch({ type: "minorFalse", value: "false" });
        }

        return;
      case "idIssueDateChange":
        console.log("Id issue date change reducer hit");
        draft.idIssueDate.touched = true;
        draft.idIssueDate.hasErrors = false;
        draft.idIssueDate.value = action.value;
        draft.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "panChange":
        draft.pan.touched = true;
        draft.pan.hasErrors = false;
        draft.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number invalid";
          return;
        } else if (action.value.length > 9) {
          draft.pan.hasErrors = true;
          draft.pan.message = "PAN number must not be more than 9 digits";
          return;
        }
        draft.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.pan.checkingNow = true;
        draft.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.pan.message = "PAN number available";
        return;
      case "panUnavailable":
        draft.pan.message = "PAN number already in use";
        return;

      case "saveSubmitCount":
        draft.submitCount.counter++;
        return;
      case "checkForErrors":
        console.log("checking for errors");
        var errorCount = 0;
        var unTouchedCount = 0;
        for (const key in draft) {
          var touchedCount;

          if (!draft[key].touched) {
            draft[key].hasErrors = true;
            draft[key].message = "This field is required";
            unTouchedCount++;
            // console.log("untouchedcount", unTouchedCount)
          }
          if (draft[key].hasErrors) {
            errorCount++;
          }
        }
        if (!draft.pan.touched) {
          draft.pan.hasErrors = false;
          draft.pan.message = "";
          unTouchedCount--;
        }
        console.log("ready to submit", errorCount);
        if (errorCount <= 1) {
          draft.submitCount.counter++;
        }
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  const handleDate = ({ bsDate, adDate }) => {
    // console.log(bsDate);
    dispatch({ type: "dobBsChange", value: bsDate, field: "dobBs" });
    dispatch({
      type: "dobAdChange",
      value: new Date(adDate),
      field: "dobAd",
    });
  };

  function handleAdDate(date) {
    dispatch({ type: "dobAdChange", value: date, field: "dobAd" });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    // console.log(formattedNepaliDate);
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "dobBs",
    });
  }
  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({
      type: "inputChange",
      value: bsDate,
      field: "idIssueDateBs",
    });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    dispatch({
      type: "idIssueDateChange",
      value: date,
      field: "idIssueDate",
    });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }

  useEffect(() => {
    console.log("hello from the personal info");
    console.log();
    for (const key in state) {
      // console.log(`${key}: ${state[key]}`)
      var arr = state[key];
      // console.log("arr", state[key])
    }
  }, []);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          console.log(response.data);
          dispatch({ type: "panAvailable" });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }

    return () => ourRequest.cancel();
  }, [state.pan.checkCount]);

  useEffect(() => {
    if (state.submitCount.counter) {
      console.log("ready to submit the personal form");
      formDispatch({
        type: "savePersonalInfo",
        value: {
          dob: state.dobAd.dobFormatted,
          gender: state.gender.value,
          marital_status: state.maritalStatus.value,
          nationality: state.nationality.value,
          pan_number: state.pan.value,
          identity_card_type: state.typeOfId.value,
          identity_card_number: state.idNo.value,
          identity_card_issue_district: state.idIssueDistrict.value,
          identity_card_issue_date: state.idIssueDate.dateFormatted,
        },
      });
      formDispatch({
        type: "saveFullName",
        value: state.fullName.value,
      });
      formDispatch({
        type: "customerUpdate",
        value: {
          email: state.email.value,
          mobile: state.mobile.value,
          username: state.fullName.value,
        },
      });
    }
  }, [state.submitCount.counter]);

  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "checkForErrors" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    dispatch({ type: "saveSubmitCount" });
  }, [
    state.fullName,
    state.mobile,
    state.email,
    state.dobBs,
    state.dobAd,
    state.gender,
    state.maritalStatus,
    state.accountType,
    state.nationality,
    state.typeOfId,
    state.idNo,
    state.idIssueDistrict,
    state.idIssueDateBs,
    state.idIssueDate,
    state.pan,
  ]);

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="personal_information-full_name"
            >
              Full Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.fullName.hasErrors && "is-invalid border-danger"
              }`}
              // value={state.fullName.value}
              onBlur={(e) =>
                dispatch({
                  type: "validateFullName",
                  value: e.target.value,
                  field: "fullName",
                })
              }
              onChange={(e) =>
                dispatch({
                  type: "fullNameChange",
                  value: e.target.value,
                  field: "fullName",
                })
              }
              type="text"
              id="personal_information-full_name"
              placeholder={` ${
                state.fullName.hasErrors
                  ? state.fullName.message
                  : "Your Full Name"
              } `}
              required
            />
            {state.fullName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.fullName.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-mobile">
              Mobile
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.mobile.hasErrors && "is-invalid border-danger"
              }`}
              id="input-mobile"
              placeholder={` ${
                state.mobile.hasErrors
                  ? state.mobile.message
                  : "Your 10 digit phone number"
              } `}
              type="number"
              onBlur={(e) =>
                dispatch({
                  type: "validateMobile",
                  value: e.target.value,
                  field: "mobile",
                })
              }
              onChange={(e) =>
                dispatch({
                  type: "mobileChange",
                  value: e.target.value,
                  field: "mobilelName",
                })
              }
            />
            {state.mobile.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.mobile.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-email">
              Email
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.email.hasErrors && "is-invalid border-danger"
              }`}
              id="input-email"
              placeholder={` ${
                state.email.hasErrors
                  ? state.email.message
                  : "Your email address"
              } `}
              type="text"
              onBlur={(e) =>
                dispatch({
                  type: "validateEmail",
                  value: e.target.value,
                  field: "email",
                })
              }
              onChange={(e) =>
                dispatch({
                  type: "emailChange",
                  value: e.target.value,
                  field: "email",
                })
              }
            />
            {state.email.hasErrors && (
              <Alert className="alert-danger mt-4">{state.email.message}</Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-bs">
              Date of Birth (B.S.)
            </label>
            <span className="text-danger">*</span>
            <section className="diy-calendar-section">
              <input
                value={state.dobBs.value}
                type="text"
                className={`form-control-alternative nepaliDate calendar ${
                  state.dobBs.hasErrors && "is-invalid border-danger"
                }`}
              />
              <div className="diy-calendar">
                <Calendar
                  className={`form-control-alternative nepaliDate calendar ${
                    state.dobBs.hasErrors && "is-invalid border-danger"
                  }`}
                  onChange={handleDate}
                  theme="deepdark"
                />
              </div>
            </section>
            {/* {state.dobBs.hasErrors && (
              <Alert className="alert-danger mt-4">{state.dobBs.message}</Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-ad">
              Date of Birth (A.D.)
            </label>
            <span className="text-danger">*</span>
            {/* <Input
              className={`form-control-alternative englishDate calendar`}
              value={state.dobAd.value}
              onChange={handleAdDate}
              disabled
            /> */}
            <DatePicker
              className={`form-control-alternative englishDate calendar`}
              selected={state.dobAd.value}
              onChange={handleAdDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {/* {state.dobAd.hasErrors && (
              <Alert className="alert-danger mt-4">{state.dobAd.message}</Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-gender">
              Gender
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.gender.hasErrors && "is-invalid border-danger"
              }`}
              id="input-gender"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "gender",
                })
              }
            >
              <option value="">Select your gender</option>
              <option value="1">Decline TO Answer</option>
              <option value="2">Male</option>
              <option value="3">Female</option>
            </select>
            {/* {state.gender.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.gender.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-marital-status"
            >
              Marital Status
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.maritalStatus.hasErrors && "is-invalid border-danger"
              }`}
              id="input-marital-status"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "maritalStatus",
                })
              }
            >
              <option value="">Select your martial status</option>
              <option value="1">Single</option>
              <option value="2">Married</option>
            </select>
            {/* {state.maritalStatus.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.maritalStatus.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-account-type">
              Account Type
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.accountType.hasErrors && "is-invalid border-danger"
              }`}
              id="input-account-type"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "accountType",
                })
              }
            >
              <option>Select your account type</option>
              <option value="1">Nepalese</option>
              <option value="2">NRN - Non Residental Nepali</option>
              <option value="3">Foreign</option>
            </select>
            {/* {state.accountType.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.accountType.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-nationality">
              Nationality
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.nationality.hasErrors && "is-invalid border-danger"
              }`}
              id="input-nationality"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "nationality",
                })
              }
            >
              {nationality.map((item, i) => {
                return (
                  <option key={i} value={item.id} selected>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.nationality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.nationality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-type_of_identity_card"
            >
              Type of Identitiy card
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.typeOfId.hasErrors && "is-invalid border-danger"
              }`}
              id="input-type_of_identity_card"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "typeOfId",
                })
              }
            >
              <option>Choose your type of ID</option>
              <option value="1">Citizenship</option>
              <option value="2">PAN</option>
            </select>
            {/* {state.typeOfId.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.typeOfId.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-identification_number"
            >
              Identification Number
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.idNo.hasErrors && "is-invalid border-danger"
              }`}
              placeholder={` ${
                state.idNo.hasErrors ? "Enter ID number" : state.idNo.message
              } `}
              id="input-identification_number"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "idNo",
                })
              }
            />
            {/* {state.idNo.hasErrors && (
              <Alert className="alert-danger mt-4">{state.idNo.message}</Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-id_issued_district"
            >
              ID Issued District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.idIssueDistrict.hasErrors && "is-invalid border-danger"
              }`}
              id="input-id_issued_district"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "idIssueDistrict",
                })
              }
            >
              {districts.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.idIssueDistrict.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.idIssueDistrict.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-id_issue_date_bs"
            >
              ID issue Date(B.S)
            </label>
            <span className="text-danger">*</span>
            <section className="diy-calendar-section">
              <input
                value={state.idIssueDateBs.value}
                type="text"
                id="personal_information-id_issue_date_bs"
                className="form-control-alternative calendar"
              />
              <div className="diy-calendar">
                <Calendar
                  className="form-control-alternative calendar"
                  id="input-id_issue_date_bs"
                  selected={state.idIssueDateBs.value}
                  onChange={handleIdIssueDateBs}
                  theme="deepdark"
                />
              </div>
            </section>
            {state.idIssueDateBs.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.idIssueDateBs.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-id_issued_date_ad"
            >
              ID Issue Date(A.D)
            </label>
            <span className="text-danger">*</span>
            {/* <Input
              className={`form-control-alternative calendar`}
              id="input-id_issued_date_ad"
              value={state.idIssueDate.value}
              onChange={handleIdIssueDate}
              disabled
            /> */}
            <DatePicker
              className={`form-control-alternative calendar`}
              selected={state.idIssueDate.value}
              onChange={handleIdIssueDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {state.idIssueDate.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.idIssueDate.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-id-pan">
              Permanent Account Number(PAN)
            </label>
            <Input
              className={`form-control-alternative ${
                state.pan.hasErrors && "is-invalid border-danger"
              }`}
              id="input-id-pan"
              type="number"
              placeholder={` ${
                state.pan.hasErrors
                  ? state.pan.message
                  : "Enter Your Permanent Account Number"
              } `}
              onBlur={(e) =>
                dispatch({
                  type: "validatePAN",
                  value: e.target.value,
                })
              }
              onChange={(e) =>
                dispatch({
                  type: "panChange",
                  value: e.target.value,
                  field: "pan",
                })
              }
            />
            {/* {state.pan.hasErrors && (
              <Alert className="alert-danger mt-4">{state.pan.message}</Alert>
            )} */}
            {(state.pan.checkingNow || state.pan.hasErrors) && (
              <Alert
                className={`${
                  state.pan.hasErrors
                    ? "alert-danger mt-4"
                    : "alert-success mt-4"
                }`}
              >
                {state.pan.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default PersonalInfo;
