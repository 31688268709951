//district list of nepal

const districts = [
  {
    id: 0,
    name: "",
    province_id: "0",
  },
  {
    id: 1,
    name: "Bhojpur",
    province_id: "1",
  },
  {
    id: 2,
    name: "Dhankuta",
    province_id: "1",
  },
  {
    id: 3,
    name: "Ilam",
    province_id: "1",
  },
  {
    id: 4,
    name: "Jhapa",
    province_id: "1",
  },
  {
    id: 5,
    name: "Khotang",
    province_id: "1",
  },
  {
    id: 6,
    name: "Morang",
    province_id: "1",
  },
  {
    id: 7,
    name: "Okhaldhunga",
    province_id: "1",
  },
  {
    id: 8,
    name: "Panchthar",
    province_id: "1",
  },
  {
    id: 9,
    name: "Sankhuwasabha",
    province_id: "1",
  },
  {
    id: 10,
    name: "Solukhumbu",
    province_id: "1",
  },
  {
    id: 11,
    name: "Sunsari",
    province_id: "1",
  },
  {
    id: 12,
    name: "Taplejung",
    province_id: "1",
  },
  {
    id: 13,
    name: "Terhathum",
    province_id: "1",
  },
  {
    id: 14,
    name: "Udayapur",
    province_id: "1",
  },
  {
    id: 15,
    name: "Bara",
    province_id: "2",
  },
  {
    id: 16,
    name: "Dhanusha",
    province_id: "2",
  },
  {
    id: 17,
    name: "Mahottari",
    province_id: "2",
  },
  {
    id: 18,
    name: "Parsa",
    province_id: "2",
  },
  {
    id: 19,
    name: "Rautahat",
    province_id: "2",
  },
  {
    id: 20,
    name: "Saptari",
    province_id: "2",
  },
  {
    id: 21,
    name: "Sarlahi",
    province_id: "2",
  },
  {
    id: 22,
    name: "Siraha",
    province_id: "2",
  },
  {
    id: 23,
    name: "Bhaktapur",
    province_id: "3",
  },
  {
    id: 24,
    name: "Chitwan",
    province_id: "3",
  },
  {
    id: 25,
    name: "Dhading",
    province_id: "3",
  },
  {
    id: 26,
    name: "Dolakha",
    province_id: "3",
  },
  {
    id: 27,
    name: "Kathmandu",
    province_id: "3",
  },
  {
    id: 28,
    name: "Kavrepalanchok",
    province_id: "3",
  },
  {
    id: 29,
    name: "Lalitpur",
    province_id: "3",
  },
  {
    id: 30,
    name: "Makwanpur",
    province_id: "3",
  },
  {
    id: 31,
    name: "Nuwakot",
    province_id: "3",
  },
  {
    id: 32,
    name: "Ramechhap",
    province_id: "3",
  },
  {
    id: 33,
    name: "Rasuwa",
    province_id: "3",
  },
  {
    id: 34,
    name: "Sindhuli",
    province_id: "3",
  },
  {
    id: 35,
    name: "Sindhupalchok",
    province_id: "3",
  },
  {
    id: 36,
    name: "Baglung",
    province_id: "4",
  },
  {
    id: 37,
    name: "Gorkha",
    province_id: "4",
  },
  {
    id: 38,
    name: "Kaski",
    province_id: "4",
  },
  {
    id: 39,
    name: "Lamjung",
    province_id: "4",
  },
  {
    id: 40,
    name: "Manang",
    province_id: "4",
  },
  {
    id: 41,
    name: "Mustang",
    province_id: "4",
  },
  {
    id: 42,
    name: "Myagdi",
    province_id: "4",
  },
  {
    id: 43,
    name: "Nawalpur",
    province_id: "4",
  },
  {
    id: 44,
    name: "Parbat",
    province_id: "4",
  },
  {
    id: 45,
    name: "Syangja",
    province_id: "4",
  },
  {
    id: 46,
    name: "Tanahun",
    province_id: "4",
  },
  {
    id: 47,
    name: "Arghakhanchi",
    province_id: "5",
  },
  {
    id: 48,
    name: "Banke",
    province_id: "5",
  },
  {
    id: 49,
    name: "Bardiya",
    province_id: "5",
  },
  {
    id: 50,
    name: "Dang Deukhuri",
    province_id: "5",
  },
  {
    id: 51,
    name: "Eastern Rukum",
    province_id: "5",
  },
  {
    id: 52,
    name: "Gulmi",
    province_id: "5",
  },
  {
    id: 53,
    name: "Kapilvastu",
    province_id: "5",
  },
  {
    id: 54,
    name: "Parasi",
    province_id: "5",
  },
  {
    id: 55,
    name: "Palpa",
    province_id: "5",
  },
  {
    id: 56,
    name: "Pyuthan",
    province_id: "5",
  },
  {
    id: 57,
    name: "Rolpa",
    province_id: "5",
  },
  {
    id: 58,
    name: "Rupandehi",
    province_id: "5",
  },
  {
    id: 59,
    name: "Dailekh",
    province_id: "6",
  },
  {
    id: 60,
    name: "Dolpa",
    province_id: "6",
  },
  {
    id: 61,
    name: "Humla",
    province_id: "6",
  },
  {
    id: 62,
    name: "Jajarkot",
    province_id: "6",
  },
  {
    id: 63,
    name: "Jumla",
    province_id: "6",
  },
  {
    id: 64,
    name: "Kalikot",
    province_id: "6",
  },
  {
    id: 65,
    name: "Mugu",
    province_id: "6",
  },
  {
    id: 66,
    name: "Salyan",
    province_id: "6",
  },
  {
    id: 67,
    name: "Surkhet",
    province_id: "6",
  },
  {
    id: 68,
    name: "Western Rukum",
    province_id: "6",
  },
  {
    id: 69,
    name: "Achham",
    province_id: "7",
  },
  {
    id: 70,
    name: "Baitadi",
    province_id: "7",
  },
  {
    id: 71,
    name: "Bajhang",
    province_id: "7",
  },
  {
    id: 72,
    name: "Bajura",
    province_id: "7",
  },
  {
    id: 73,
    name: "Dadeldhura",
    province_id: "7",
  },
  {
    id: 74,
    name: "Darchula",
    province_id: "7",
  },
  {
    id: 75,
    name: "Doti",
    province_id: "7",
  },
  {
    id: 76,
    name: "Kailali",
    province_id: "7",
  },
  {
    id: 77,
    name: "Kanchanpur",
    province_id: "7",
  },
];

export default districts;
