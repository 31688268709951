import React, { useEffect, useRef } from "react";
import { useImmerReducer, userImmerReducer } from "use-immer";
import { useParams } from "react-router-dom";
import axios from "axios";
import StateContext from "../../../../StateContext";
import { useContext } from "react";
import { Container, Row, Col, Badge, Button } from "reactstrap";

let mainCategory = [];

function RejectPanel(props) {
  const appState = useContext(StateContext);
  const { id } = useParams();
  const initialState = {
    sendCount: "",
    selectedCategory: "",
    subCategory: [],
    badgeList: [],
    categoryCount: 1,
    rejectForm: {
      customers: {
        status: "5",
      },
      reason: {},
    },
    category: [
      {
        id: "0",
        name: "Select Catagory",
        nameForForm: "Select Catagory",
      },
      {
        id: "1",
        name: "Personal Information",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Full Name",
            nameForForm: "fullname",
          },
          {
            id: "2",
            name: "Mobile",
            nameForForm: "mobile",
          },
          {
            id: "3",
            name: "Email",
            nameForForm: "email",
          },
          {
            id: "4",
            name: "DOB",
            nameForForm: "dob",
          },
          {
            id: "5",
            name: "Gender",
            nameForForm: "gender",
          },
          {
            id: "6",
            name: "Marital Status",
            nameForForm: "maritial_status",
          },
          {
            id: "7",
            name: "Nationality",
            nameForForm: "nationality",
          },
          {
            id: "8",
            name: "Account Type",
            nameForForm: "account_type",
          },
          {
            id: "9",
            name: "id Card Number",
            nameForForm: "id_card_number",
          },
          {
            id: "10",
            name: "id Card Type",
            nameForForm: "id_card_type",
          },
          {
            id: "11",
            name: "id Card Issue Date",
            nameForForm: "id_issue_date",
          },
          {
            id: "12",
            name: "id Card Issue District",
            nameForForm: "id_card_issuei_dstrict",
          },
          {
            id: "13",
            name: "PAN",
            nameForForm: "pan",
          },
        ],
      },
      {
        id: "2",
        name: "Guardian",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Name Of Guardian",
          },
          {
            id: "2",
            name: "Relationship With Applicant",
          },
          {
            id: "3",
            name: "Fathers Name",
          },
          {
            id: "4",
            name: "GrandFathers Name",
          },
          {
            id: "5",
            name: "Mobile",
          },
          {
            id: "6",
            name: "Email",
          },
          {
            id: "7",
            name: "Nationality",
          },
          {
            id: "8",
            name: "id Card Type",
          },
          {
            id: "9",
            name: "Id Number",
          },
          {
            id: "10",
            name: "Id Issue District",
          },
          {
            id: "11",
            name: "Id Issue Date",
          },
          {
            id: "12",
            name: "PAN",
          },
          {
            id: "13",
            name: "Temporary Country",
          },
          {
            id: "14",
            name: "Temporary Province",
          },
          {
            id: "15",
            name: "Temporary District",
          },
          {
            id: "16",
            name: "Temporary Municipality",
          },
          {
            id: "17",
            name: "Temporary Localitty",
          },
          {
            id: "18",
            name: "Temporary Ward no",
          },
          {
            id: "19",
            name: "Temporary Block no",
          },
          {
            id: "20",
            name: "Permanent Country",
          },
          {
            id: "21",
            name: "Permanent Province",
          },
          {
            id: "22",
            name: "Permanent District",
          },
          {
            id: "23",
            name: "Permanent Municipality",
          },
          {
            id: "24",
            name: "Permanent Locality",
          },
          {
            id: "25",
            name: "Permanent Ward no",
          },
          {
            id: "26",
            name: "Permanent Block no",
          },
        ],
      },
      {
        id: "3",
        name: "Address",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Temporary Country",
          },
          {
            id: "2",
            name: "Temporary Province",
          },
          {
            id: "3",
            name: "Temporary District",
          },
          {
            id: "4",
            name: "Temporary Municipality",
          },
          {
            id: "5",
            name: "Temporary Locality",
          },
          {
            id: "6",
            name: "Temporary Ward No",
          },
          {
            id: "7",
            name: "Temporary Block No",
          },
          {
            id: "8",
            name: "Permanent Country",
          },
          {
            id: "9",
            name: "Permanent Province",
          },
          {
            id: "10",
            name: "Permanent District",
          },
          {
            id: "11",
            name: "Permanent Municipality",
          },
          {
            id: "12",
            name: "Permanent Locality",
          },
          {
            id: "13",
            name: "Permanent Ward No",
          },
          {
            id: "14",
            name: "Permanent Block No",
          },
        ],
      },
      {
        id: "4",
        name: "Family",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Fathers Name",
          },
          {
            id: "2",
            name: "Grandfathers Name",
          },
          {
            id: "3",
            name: "Mothers Name",
          },
          {
            id: "4",
            name: "Spouses Name",
          },
          {
            id: "5",
            name: "Sons Name",
          },
          {
            id: "6",
            name: "Daughters Name",
          },
          {
            id: "7",
            name: "Father in Laws Name",
          },
          {
            id: "8",
            name: "Daughters Name",
          },
        ],
      },
      {
        id: "5",
        name: "Nominee",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Name of Nominee",
          },
          {
            id: "2",
            name: "Relationship with Applicant",
          },
          {
            id: "3",
            name: "Fathers Name",
          },
          {
            id: "4",
            name: "Grandfathers Name",
          },
          {
            id: "5",
            name: "Mobile",
          },
          {
            id: "6",
            name: "Email",
          },
          {
            id: "7",
            name: "Nationality",
          },
          {
            id: "8",
            name: "ID card type",
          },
          {
            id: "9",
            name: "ID card number",
          },
          {
            id: "10",
            name: "ID issue district",
          },
          {
            id: "11",
            name: "id issue date",
          },
          {
            id: "12",
            name: "PAN",
          },
          {
            id: "13",
            name: "Temporary Country",
          },
          {
            id: "14",
            name: "Temporary Province",
          },
          {
            id: "15",
            name: "Temporary District",
          },
          {
            id: "16",
            name: "Temporary Municipality",
          },
          {
            id: "17",
            name: "Temporary Locality",
          },
          {
            id: "18",
            name: "Temporary Ward No",
          },
          {
            id: "19",
            name: "Temporary Block No",
          },
          {
            id: "20",
            name: "Permanent Country",
          },
          {
            id: "21",
            name: "Permanent Province",
          },
          {
            id: "22",
            name: "Permanent District",
          },
          {
            id: "23",
            name: "Permanent Municipality",
          },
          {
            id: "24",
            name: "Permanent Locality",
          },
          {
            id: "25",
            name: "Permanent Ward No",
          },
          {
            id: "26",
            name: "Permanent Block No",
          },
        ],
      },
      {
        id: "6",
        name: "Occupation",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Occupation",
          },
          {
            id: "2",
            name: "Business Type",
          },
          {
            id: "3",
            name: "Organization Name",
          },
          {
            id: "4",
            name: "Organization Address",
          },
          {
            id: "5",
            name: "Designation",
          },
          {
            id: "6",
            name: "Income Range",
          },
        ],
      },
      {
        id: "7",
        name: "Account",
        data: [
          {
            id: "0",
            name: "Select Sub Catagory",
          },
          {
            id: "1",
            name: "Type of Bank Account",
          },
          {
            id: "2",
            name: "Bank Name",
          },
          {
            id: "3",
            name: "Branch Name",
          },
          {
            id: "4",
            name: "Bank Account No",
          },
        ],
      },
      {
        id: "8",
        name: "Documents",
        data: [
          {
            id: "0",
            name: "Photo",
          },
          {
            id: "1",
            name: "Signature",
          },
          {
            id: "2",
            name: "Thumb Left",
          },
          {
            id: "3",
            name: "Thumb Right",
          },
          {
            id: "4",
            name: "Gov Issued ID Front",
          },
          {
            id: "5",
            name: "Gov Issued ID Back",
          },

          {
            id: "6",
            name: "Birth Certificate",
          },
          {
            id: "7",
            name: "Photo(Guardian)",
          },
          {
            id: "8",
            name: "Signature(Guardian)",
          },
          {
            id: "9",
            name: "Gov Issued ID Front(Guardian)",
          },
          {
            id: "10",
            name: "Gov Issued ID Back(Guardian)",
          },

          {
            id: "11",
            name: "Photo(Nominee)",
          },
          {
            id: "12",
            name: "Signature(Nominee)",
          },
          {
            id: "13",
            name: "Gov Issued ID Front(Nominee)",
          },
          {
            id: "14",
            name: "Gov Issued ID Back(Nominee)",
          },
        ],
      },
    ],
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "mainCategoryChange":
        draft.selectedCategory = action.value;
        mainCategory.map((item, i) => {
          if (item.name == action.value) {
            console.log("selected category is ", item.name);
            draft.subCategory = item.data;
          }
        });
        return;
      case "subCategoryChange":
        if (
          !draft.badgeList.includes(`${draft.selectedCategory}-${action.value}`)
        ) {
          draft.badgeList.push(`${draft.selectedCategory}-${action.value}`);
        }
        return;
      case "removeBadgeAndInput":
        draft.badgeList &&
          draft.badgeList.map((item, i) => {
            if (item == action.value) {
              const index = draft.badgeList.indexOf(item);
              draft.badgeList.splice(index, 1);
            }
          });
        return;
      case "emptyValue":
        console.log(
          "action.category form the empty value reducer",
          action.category,
          "action.subcategory",
          action.subCategory
        );
        for (const key in draft.rejectForm.reason) {
          if (key == action.category) {
            for (const subKey in draft.rejectForm.reason[key]) {
              if (subKey == action.subCategory) {
                draft.rejectForm.reason[key][subKey] = "";
                delete draft.rejectForm.reason[key][subKey];
              }
            }
          }
        }
        return;
      case "handleInputChange":
        if (!draft.rejectForm.reason[action.category]) {
          draft.rejectForm.reason[action.category] = {};
        }
        draft.rejectForm.reason[action.category][action.subCategory] =
          action.value;
        return;
      case "submitForm":
        draft.sendCount++;
        return;
      case "changeCategoryCount":
        draft.categoryCount++;
        return;
      case "formRejected":
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);
  console.log(state.rejectForm.reason);
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const bodyParameters = state.rejectForm;
    if (state.sendCount && state.rejectForm.reason !== {}) {
      const ourRequest = axios.CancelToken.source();
      async function fetchCustomers() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}/verify`,
            bodyParameters,
            { cancelToken: ourRequest.token }
          );
          // console.log(response.data.data)
          dispatch({ type: "formRejected" });
          if (response.data.data) {
            props.rejectForm();
            // window.location.reload();
          }
        } catch (e) {
          console.log(e, "there was an error fetching customers");
        }
      }
      fetchCustomers();
      return () => ourRequest.cancel();
    }
  }, [state.sendCount]);

  function changeMainCategory() {
    if (state.categoryCount === 1) {
      console.log(state.category);
      if (mainCategory.length === 0) {
        mainCategory = mainCategory.concat(state.category);
      } else {
        mainCategory = [];
        mainCategory = mainCategory.concat(state.category);
      }

      if (props.userData.is_minor === "no") {
        console.log(234);
        mainCategory.splice(2, 1);
        mainCategory[mainCategory.length - 1].data.splice(6, 5);
      }

      if (props.userData.is_minor === "yes") {
        mainCategory[mainCategory.length - 1].data.splice(0, 6);
      }
      console.log(mainCategory[mainCategory.length - 1].data);

      if (props.userData.nominee === "no") {
        console.log(123);
        if (mainCategory.length === 9) {
          mainCategory.splice(5, 1);
        } else {
          mainCategory.splice(4, 1);
        }
        if (mainCategory[mainCategory.length - 1].data.length === 10) {
          mainCategory[mainCategory.length - 1].data.splice(6, 4);
        } else {
          mainCategory[mainCategory.length - 1].data.splice(5, 4);
        }
      }
    }

    dispatch({ type: "changeCategoryCount" });
  }
  function mainCategoryChange(e) {
    console.log(e.target.value);
    dispatch({ type: "mainCategoryChange", value: e.target.value });
  }
  function subCategoryChange(e) {
    dispatch({ type: "subCategoryChange", value: e.target.value });
  }
  function deleteBadgeAndInput(e) {
    console.log("from the function", e.target.getAttribute("category"));
    dispatch({
      type: "removeBadgeAndInput",
      value: e.target.getAttribute("data"),
    });
    dispatch({
      type: "emptyValue",
      category: e.target.getAttribute("category"),
      subCategory: e.target.getAttribute("subcategory"),
    });
  }
  function handleSubmit(e) {
    // console.log("fom submit button clicked")
    // console.log(e.target.elements)
    e.preventDefault();
    dispatch({ type: "submitForm" });
    // dispatch({ type: "validateForm" });
  }
  function handleChange(e) {
    // console.log("from the handle change", e.target, e.target.name, e.target.category, e.target.subCategory)
    dispatch({
      type: "handleInputChange",
      value: e.target.value,
      subCategory: e.target.getAttribute("subCategory"),
      category: e.target.getAttribute("category"),
    });
  }
  console.log(state.rejectForm);
  return (
    <Container fluid className="mb-4">
      <Row className="mb-3">
        <Col lg={3}>
          <label htmlFor="mainCategory">Main Category</label>
          <div style={{ position: "relative" }}>
            <select
              name="mainCategory"
              id=""
              className="form-control"
              onChange={mainCategoryChange}
              onClick={changeMainCategory}
            >
              {mainCategory.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <span
              someattribute="someattribute"
              className="text-primary"
              style={{
                position: "absolute",
                right: "10px",
                top: "10%",
                fontWeight: "900",
              }}
            >
              &#8964;
            </span>
          </div>
        </Col>
        <Col lg={3}>
          <label htmlFor="subCategory">Sub Category</label>
          <div style={{ position: "relative" }}>
            <select
              name="subCategory"
              id=""
              className="form-control"
              onChange={subCategoryChange}
            >
              {state.subCategory &&
                state.subCategory.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
            <span
              someattribute="someattribute"
              className="text-primary"
              style={{
                position: "absolute",
                right: "10px",
                top: "10%",
                fontWeight: "900",
              }}
            >
              &#8964;
            </span>
          </div>
        </Col>
        {/* </Row>
      <Row className="mb-3"> */}
        <Col lg={6}>
          {state.badgeList &&
            state.badgeList.map((item, i) => {
              const formattedString = item.toLowerCase().replaceAll(" ", "_");
              const myArr = formattedString.split("-");
              const category = myArr[0];
              const subCategory = myArr[1];
              return (
                <a
                  key={i}
                  onClick={deleteBadgeAndInput}
                  category={category}
                  subcategory={subCategory}
                  data={item}
                  className="btn btn-neutral btn-md icon-close my-3"
                >
                  {item}
                  <span someattribute="someattribute" className="text-primary">
                    &times;
                  </span>
                </a>
              );
            })}
        </Col>
        <Col lg={6}>
          <form action="">
            {state.badgeList &&
              state.badgeList.map((item, i) => {
                const formattedString = item.toLowerCase().replaceAll(" ", "_");
                const myArr = formattedString.split("-");
                const category = myArr[0];
                const subCategory = myArr[1];
                return (
                  <div className="input-wrapper" key={i}>
                    <label htmlFor={item}>{item}</label>
                    <div
                      className="input-group d-flex align-items-center"
                      style={{ postion: "relative" }}
                    >
                      <input
                        type="text"
                        name={formattedString}
                        onChange={handleChange}
                        category={category}
                        subcategory={subCategory}
                        className="form-control"
                        id={item}
                        placeholder={item}
                        style={{ borderRight: "1px solid #cad1d7" }}
                        required
                      />
                      <div
                        className="input-group-append text-primary"
                        onClick={deleteBadgeAndInput}
                        category={category}
                        subcategory={subCategory}
                        data={item}
                        style={{
                          color: "#172b4d",
                          width: "20px",
                          marginLeft: "0px",
                          fontWeight: "900",
                          cursor: "pointer",
                          position: "absolute",
                          right: "0",
                        }}
                      >
                        &times;
                      </div>
                    </div>
                  </div>
                );
              })}
            <Button
              className="btn-success mt-3"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default RejectPanel;
