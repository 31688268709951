import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Compressor from "compressorjs";

function DisplayPicture(props) {
  const inputRef = useRef();
  const triggerPopup = () => inputRef.current.click();
  const [imageName, setImageIname] = useState(null);
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [max, setMax] = useState(false);

  // CompressImage
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("there is a file");
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setCurrentImage(reader.result);
        setImageIname("photo");
        new Compressor(file, {
          convertSize: 500000,
          success: (compressedResult) => {
            // compressedResult
            setImage(compressedResult);
          },
        });
      });
    }
  };

  // SetImage
  useEffect(() => {
    if (image) {
      console.log(image);
      let extension = image.type.split("/");
      if (image.size < 250000) {
        props.setDisplayPictureFile(
          new File([image], `image.${extension[1]}`, {
            type: image.type,
          })
        );
        props.setDisplayPicture(imageName + "." + `${extension[1]}`);
        props.setErrorCount(0);
        setMax(false);
      }
      if (image.size > 250000) {
        props.setDisplayPictureFile("");
        props.setDisplayPicture("");
        props.setErrorCount(1);
        setMax(true);
      }
    }
  }, [image]);

  return (
    <div className=" documents">
      <input
        type="file"
        id="document-pp_size_photo"
        accept="image/*"
        className="form-control"
        ref={inputRef}
        onChange={onSelectFile}
        name="photo"
      />
      <div className="image-container image-person">
        <img
          src={
            image
              ? currentImage
              : `${process.env.REACT_APP_BASE_URL}/img/` +
                props.email +
                "/" +
                props.displayImg
          }
          alt=""
        />
      </div>
      {props.setErrors && <p className="error">{props.setErrors}</p>}
      {max && <p className="error">The file size should not exceed 250KB</p>}
      {/* <div className="container-cropper">
        <Cropper image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
        <Slider />
      </div> */}
      <div className="container-buttons">
        <Button variant="contained" color="primary" onClick={triggerPopup}>
          choose
        </Button>
      </div>
    </div>
  );
}

export default DisplayPicture;
