const pagination = (props) => {
  const itemsPerPage = props.showEntries;
  // const itemsPerPage = 10;

  const numberOfPages = Math.ceil(props.filteredData?.length / itemsPerPage);

  const newData = Array.from({ length: numberOfPages }, (_, index) => {
    const start = index * itemsPerPage;
    const end = parseInt(itemsPerPage) + parseInt(start);
    // console.log(test);
    //console.log(props.filteredData.slice(start, end));
    return props.filteredData.slice(start, end);
  });
  return newData;
};

export default pagination;
