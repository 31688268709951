import React, { useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Loading from "../../Layout/Loading";
import StateContext from "../../../StateContext";

const Profile = () => {
  const appState = useContext(StateContext);
  const token = appState.client.data.token;
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);
  console.log(state.userData);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          {
            cancelToken: ourRequest.token,
          }
        );
        console.log(response.data.message);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customer");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);
  console.log(state.userData.is_minor);

  if (!state.userLoaded) {
    return (
      <div className="admin">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <Container className="mt-6">
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8" className="d-flex align-items-center">
                    <div className="card-profile-image">
                      {state.userData.is_minor === "yes" ? (
                        <h2 className="text-danger">*</h2>
                      ) : (
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={
                            `${process.env.REACT_APP_BASE_URL}/img/` +
                            state.userData.email +
                            "/" +
                            state.userData?.documents[0]?.photo_name
                          }
                        />
                      )}
                    </div>
                    <h3 className="mb-0 ml-4">My Profile</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={state.userData?.username}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={state.userData?.email}
                            placeholder="jesse@example.com"
                            type="email"
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Full name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            defaultValue={state.userData?.full_name}
                            placeholder="Full name"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            DOB(AD)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            defaultValue={
                              state.userData?.personal_information[0]?.dob
                            }
                            placeholder="Full name"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            BOID
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={state.userData?.BOID}
                            id="input-last-name"
                            placeholder="BOID not generated"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" className="mb-3 text-center">
                        {state.userData.is_minor === "yes" ? (
                          <>
                            <h1>Minor Account</h1>
                            <p>This account is registered to a minor</p>
                          </>
                        ) : (
                          <img
                            src={
                              `${process.env.REACT_APP_BASE_URL}/img/` +
                              state.userData.email +
                              "/" +
                              state.userData?.documents[0]?.photo_name
                            }
                            alt=""
                            className="rounded-circle"
                            style={{
                              height: "300px",
                              width: "300px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-5" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={`${state.userData.addresses[0]?.municipality}, ${state.userData.addresses[0]?.locality}, ${state.userData.addresses[0]?.ward_number}, ${state.userData.addresses[0]?.block_number}`}
                            id="input-address"
                            placeholder="Municipality, locality, ward no., block no."
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={state.userData.addresses[0]?.city}
                            id="input-city"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={state.userData.addresses[0]?.country}
                            id="input-country"
                            type="text"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Phone number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            defaultValue={state.userData?.mobile}
                            type="number"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
