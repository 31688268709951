import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";

import { Alert, Col, FormGroup, Input, Row } from "reactstrap";

import BankData from "../../../../Assets/banks.json";
import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

const Account = () => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);

  const initialState = {
    formData: {
      typeOfBank: {
        name: "typeOfBank",
        value: "",
        hasErrors: false,
        message: "",
      },
      bankName: {
        name: "bankName",
        value: "Gurkhas Finance Ltd.",
        hasErrors: false,
        message: "",
        bankList: [],
        bankListToShow: [],
      },
      branch: {
        name: "branch",
        value: "",
        hasErrors: false,
        message: "",
        branchList: [
          " Head Office Branch",
          "Janagal Branch",
          "Bhedetar Branch",
          " Itahari Branch",
          " New Road Branch",
          " Satdobato Branch",
          " Birtamode Branch",
          " Narayanghat Branch",
          " Bhaktapur Branch",
          "Boudha Branch",
          "Damak Branch",
          "Dharan Branch",
          "Banepa Branch",
          "Bhaktapur",
          "Rabi Branch",
          "Chaumala Branch",
          "Padsari Branch",
          "Chapradi Branch",
          "Pokhara Branch",
          "Bhojpur Branch",
          "Surkhet Branch",
          "Nepalgunj Branch",
          "Birgunj Branch",
          "Gongabu Branch",
          "Belatar Branch",
          "Hetauda Branch",
          "Barahathawa Branch",
          "Koteshwor Branch",
          "Bijauri Branch",
        ],
      },
      accountNo: {
        name: "accountNo",
        value: "",
        hasErrors: false,
        message: "",
      },
    },
    demat: 2,
    meroShare: 2,
    errorCount: 0,
    saveCount: 0,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
            if (action.value.trim() == "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "bankValueChange":
        console.log("bank value change");
        draft.formData.bankName.hasErrors = false;
        draft.formData.bankName.value = action.value;

        draft.formData.branch.branchList = [];
        draft.formData.branch.branchList.push();
        let branches = draft.formData.bankName.bankList.filter((item) =>
          item.includes(action.value)
        );
        branches.map((item, i) => {
          draft.formData.branch.branchList.push(
            item.substring(item.indexOf("-") + 1, item.length)
          );
        });
        return;
      case "renderBanks":
        BankData.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              draft.formData.bankName.bankList.push(item.bankName);
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        // console.log(draft.formData.bankName.bankList.length)
        draft.formData.bankName.bankList.map((item, i) => {
          // console.log(item.bankName)
          async function fetchBanks() {
            try {
              if (
                !draft.formData.bankName.bankListToShow.includes(
                  item.substring(0, item.indexOf("-"))
                )
              ) {
                draft.formData.bankName.bankListToShow.push(
                  item.substring(0, item.indexOf("-"))
                );
              }
            } catch (e) {
              console.log(e, "there was an error fetching banks");
            }
          }
          fetchBanks();
        });
        return;
      case "dematFalse":
        draft.demat = 1;
        return;
      case "dematTrue":
        draft.demat = 2;
        return;
      case "meroShareFalse":
        draft.meroShare = 1;
        return;
      case "meroShareTrue":
        draft.meroShare = 2;
        return;
      case "validateForm":
        console.log("validate form");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].value) {
            console.log("no value in", draft.formData[key]);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
        }
        for (const key in draft.formData) {
          draft.formData[key].hasErrors && draft.errorCount++;
        }
        if (!draft.errorCount) {
          draft.saveCount++;
        }
        return;
      case "saveSubmitCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    dispatch({ type: "renderBanks" });
  }, []);

  const dematCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "dematTrue" });
    } else {
      dispatch({ type: "dematFalse" });
    }
  };

  const meroShareCheck = (e) => {
    if (e.target.checked) {
      dispatch({ type: "meroShareTrue" });
    } else {
      dispatch({ type: "meroShareFalse" });
    }
  };

  useEffect(() => {
    if (state.formData?.bankName?.value !== "Gurkhas Finance Ltd.") {
      dispatch({ type: "dematFalse" });
    } else {
      dispatch({ type: "dematTrue" });
    }
  }, [state.formData?.bankName?.value]);

  useEffect(() => {
    if (state.saveCount) {
      console.log("ready to save");
      formDispatch({
        type: "saveAccount",
        value: {
          bank_name: state.formData.bankName.value,
          bank_code: "1101",
          branch_name: state.formData.branch.value,
          number: state.formData.accountNo.value,
          type: state.formData.typeOfBank.value,
          demat: state.demat,
          meroShare: state.meroShare,
        },
      });
    }
  }, [state.saveCount]);

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    dispatch({ type: "saveSubmitCount" });
  }, [state.formData]);

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-type_of_bank_account"
            >
              Type of Bank Account
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.typeOfBank.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-type_of_bank_account"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "typeOfBank",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "typeOfBank",
                })
              }
              value={state.formData.typeOfBank.value}
            >
              <option value="">Select an account</option>
              <option value="1">Saving</option>
              <option value="2">Current</option>
            </select>
            {/* {state.formData.typeOfBank.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.typeOfBank.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-bank_name">
              Bank Name
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.bankName.hasErrors && "is-invalid border-danger"
              }`}
              id="input-bank_name"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "bankValueChange",
                  value: e.target.value,
                  field: "bankName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "bankName",
                })
              }
              value={state.formData.bankName.value}
            >
              {state.formData.bankName.bankListToShow.map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {/* {state.formData.bankName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.bankName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-branch">
              Branch
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.branch.hasErrors && "is-invalid border-danger"
              }`}
              id="input-branch"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "branch",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "branch",
                })
              }
              value={state.formData.branch.value}
            >
              {state.formData.branch.branchList.length &&
                state.formData.branch.branchList.map((item, i) => {
                  return (
                    <option
                      key={i}
                      value={item}
                      selected={i === 0 && "selected"}
                    >
                      {item}
                    </option>
                  );
                })}
            </select>
            {/* {state.formData.branch.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.branch.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-bank_account_no"
            >
              Bank Account No
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.accountNo.hasErrors && "is-invalid border-danger"
              }`}
              id="input-bank_account_no"
              type="number"
              placeholder={` ${
                state.formData.accountNo.hasErrors
                  ? state.formData.accountNo.message
                  : "Enter Bank account no"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "accountNo",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "accountNo",
                })
              }
              value={state.formData.accountNo.value}
            />
            {/* {state.formData.accountNo.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.accountNo.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      {state.formData?.bankName?.value === "Gurkhas Finance Ltd." ? (
        <Row>
          <Col lg="12">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                id="account-renew_my_demat"
                type="checkbox"
                onClick={dematCheck}
                checked={state.demat === 2 ? "checked" : ""}
              />
              <label
                className="form-control-label"
                htmlFor="account-renew_my_demat"
                style={{ marginLeft: "3rem" }}
              >
                Renew my Demat Account automatically
              </label>
            </FormGroup>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="open_mero_share"
              type="checkbox"
              onClick={meroShareCheck}
              checked={"checked"}
            />
            <label
              className="form-control-label"
              htmlFor="open_mero_share"
              style={{ marginLeft: "3rem" }}
            >
              Open Mero Share Account
            </label>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Account;
