import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import StateContext from "../../../../../../StateContext";
import Loading from "../../../../../Layout/Loading";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";

const KYC = () => {
  const appState = useContext(StateContext);
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
    hasNominee: false,
    hasNomineeDoc: false,
    nomineeData: {},
    hasGuardian: false,
    hasGuardianDoc: {},
    guardianData: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasNominee":
        draft.hasNominee = true;
        draft.nomineeData = action.value;
        return;
      case "hasNomineeDoc":
        draft.hasNomineeDoc = true;
        draft.nomineeDoc = action.value;
        return;
      case "hasGuardian":
        draft.hasGuardian = true;
        draft.guardianData = action.value;
        return;
      case "hasGuardianDoc":
        draft.hasGuardianDoc = true;
        draft.guardianDoc = action.value;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          { cancelToken: ourRequest.token }
        );
        console.log(response.data.data);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    if (state.userLoaded) {
      if (
        state.userData?.relationships &&
        state.userData?.relationships.length
      ) {
        state.userData?.relationships.map((item, i) => {
          if (item.type === "nominee") {
            dispatch({ type: "hasNominee", value: item });
            dispatch({ type: "hasNomineeDoc", value: item.documents[0] });
          }
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardian", value: item });
            dispatch({ type: "hasGuardianDoc", value: item.documents[0] });
          }
        });
      }
    }
  }, [state.userLoaded]);

  if (!state.userLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className="demat">
      <div
        className="form-body container"
        style={{ color: "rgb(255, 60, 0)", fontSize: "12px" }}
      >
        <Page1
          data={state.userData}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
        />
        {/* a4 page print */}
        <Page2
          data={state.userData}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
        />
        {/* second page for print on A4 */}
        <Page3
          data={state.userData}
          nomineeData={state.hasNominee ? state.nomineeData : ""}
          nominee={state.hasNomineeDoc === true ? state.nomineeDoc : false}
        />
        {/* page 3print break */}
        <Page4
          data={state.userData}
          guardianData={state.hasGuardian ? state.guardianData : ""}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
        />
        {/* page 4print break */}
        <Page5
          data={state.userData}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
        />
        {/* page 5print break */}
        <Page6
          data={state.userData}
          guardian={state.hasGuardianDoc === true ? state.guardianDoc : false}
        />
      </div>
    </div>
  );
};

export default KYC;
