import React, { useState, useContext } from "react";

// reactstrap components
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  CardBody,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
} from "reactstrap";
// import noticeImg from "../../Assets/images/GUFL_img6.png";
import connectIpsImg from "../../Assets/images/payment/connect-ips.png";
import esewaImg from "../../Assets/images/payment/esewa.png";
import khaltiImg from "../../Assets/images/payment/khalti.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StateContext from "../../StateContext";
import { Active, Bell, Bulb, Wallet, AlertCircle } from "../Layout/Icons";
import RejectedNavigation from "../Admin/Layout/RejectedNavigation";
import axios from "axios";

function Dashboard({ data }) {
  const appState = useContext(StateContext);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(true);
  const [onlinePayment, setOnlinePayment] = useState(true);
  const [dialogue, setDialogue] = useState(true);
  const [warning, setWarning] = useState(true);

  const handleDialogue = () => {
    setDialogue(!dialogue);
  };

  const handleWarning = () => {
    setWarning(!warning);
  };

  // Modal open state
  const [modal, setModal] = React.useState(false);

  // Toggle for Modal
  const toggleModal = () => setModal(!modal);

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleAlert = () => {
    setAlert(!alert);
  };

  const onlinePaymentHandle = () => {
    setOnlinePayment(!onlinePayment);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const ourRequest = axios.CancelToken.source();
    const formData = new FormData();
    formData.append("customer_id", data.id);
    formData.append("type", 2);
    formData.append("amount", 150);
    formData.append("image", image);

    async function payment() {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/payments`,
          formData,
          { cancelToken: ourRequest.token }
        );
        // console.log(response);
        setError(response?.data?.message);
        setIsPending(false);
        window.location.reload();
      } catch (e) {
        console.log(e.response?.data?.message, "there was an error");
        setError(e.response?.data?.message);
        setIsPending(false);
      }
    }
    payment();
  };
  const [error, setError] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  const imageHandle = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("there is a file");
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        fetch(reader.result)
          .then((res) => res.blob())
          .then((blob) => {
            setCurrentImage(reader.result);
            let extension = blob.type.split("/");
            setImage(
              new File([blob], `image.${extension[1]}`, {
                type: blob.type,
              })
            );
          });
      });
    }
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <Card className="shadow pb-5">
          <CardHeader className="border-0">
            <h3 className="mb-0">Welcome - {appState.client.data.user.name}</h3>
          </CardHeader>
          <Row className="mb-5 mx-3">
            <Col xl="3" lg="4" md="6" sm="12">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Demat Account Status
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {appState.client.data.user.active === "yes"
                          ? "Active"
                          : "Not Active"}
                      </span>
                    </Col>
                    <Col className="d-flex justify-content-center px-0">
                      <div
                        className={`icon icon-shape text-white rounded-circle shadow ${
                          appState.client.data.user.active === "yes"
                            ? "bg-gradient-green"
                            : "bg-gradient-red"
                        }`}
                      >
                        <Active className="icon" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-nowrap">Remaining Days - </span>
                    <span className="text-danger font-weight-bold mr-2">
                      360
                    </span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="4" md="6" sm="12">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col>
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Demat Processing Status
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {(data.status === "pending" && "Pending") ||
                          (data.status === "rejected" && "Rejected") ||
                          (data.status === "verified" && "Verified")}
                      </span>
                    </Col>
                    <Col className="d-flex justify-content-center px-0">
                      <div
                        className={`icon icon-shape text-white rounded-circle shadow ${
                          (data.status === "pending" && "bg-gradient-orange") ||
                          (data.status === "rejected" && "bg-gradient-red") ||
                          (data.status === "verified" && "bg-gradient-green")
                        }`}
                      >
                        <Bulb className="icon" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="4" md="6" sm="12">
              <Card className="mb-4">
                <CardBody>
                  <Row className="mb-5">
                    <Col>
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Payment Status
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {data.payment_status === "paid" ? "Paid" : "Not Paid"}
                      </span>
                    </Col>
                    <Col className="d-flex justify-content-center px-0">
                      <div
                        className={`icon icon-shape text-white rounded-circle shadow ${
                          data.payment_status === "paid"
                            ? "bg-gradient-green"
                            : "bg-gradient-red"
                        }`}
                      >
                        <Wallet className="icon" />
                      </div>
                    </Col>
                  </Row>
                  {data.payment_verified === "no" &&
                  data.status === "rejected" &&
                  data.reasons.length === 0 ? (
                    <Row>
                      <Col lg="12">
                        <p className="text-danger">
                          Your payment is not valid. Please contact your bank
                          for further details or make another payment via the
                          button below!
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col>
                      {data.payment_status === "not paid" && (
                        <Button className="btn-default" onClick={toggleModal}>
                          {data.payment_verified === "no" &&
                          data.status === "rejected" &&
                          data.reasons.length === 0
                            ? "Make Another Payment"
                            : "Make Payment"}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="4" sm="12">
              <Card className="mb-5" md="4">
                <CardBody>
                  <Row className="mb-5">
                    <Col>
                      <h5 className="card-title text-uppercase text-muted mb-0">
                        Demat Number
                      </h5>
                      <p className="text-muted">Demat number not generated</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col lg="9">
              <Card>
                <CardBody style={{ height: "75vh", overflow: "auto" }}>
                  <img src={noticeImg} alt="" />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          {data.status === "rejected" && (
            <>
              <Row className="px-6">
                {data.payment_verified === "no" && data.reasons.length < 1 ? (
                  <Alert className="alert-danger">
                    <span className="alert-icon">
                      <Bell className="icon" />
                    </span>
                    <span className="alert-text">
                      Your payment is not valid. Please contact your bank for
                      further details !
                    </span>
                  </Alert>
                ) : (
                  <Alert className="alert-danger">
                    <span className="alert-icon">
                      <Bell className="icon" />
                    </span>
                    <span className="alert-text">
                      Your documents is not valid. Please Edit The Documents !
                    </span>
                    <Button className="mx-5" onClick={handleClick}>
                      Edit Document
                    </Button>
                  </Alert>
                )}
              </Row>
              {visible && (
                <Row className="px-6">
                  <RejectedNavigation data={data} />
                </Row>
              )}
            </>
          )}
          {/* <hr className="my-6" />
          <Container fluid className="mb-5">
            <Row>
              <Col md="12">
                <div className="card-deck">
                  <Card>
                    <a href="https://www.gurkhasfinance.com.np/nari-bachat.html">
                      <img src={noticeImg} alt="" className="card-img-top" />
                    </a>
                    <CardBody>
                      <h5 className="card-title">Nari Bachat</h5>
                      <p className="card-text">
                        Nari Bachat aims to avail a saving solution to the range
                        of women customers by connecting them with to service of
                        modern day banking practices, enabling them to take
                        control over their financial behavior and decisions.
                      </p>
                      <p className="card-text">
                        <small className="text-muted">
                          <a
                            href="https://www.gurkhasfinance.com.np/nari-bachat.html"
                            className=""
                            target="_blank"
                          >
                            Details
                          </a>
                        </small>
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <a href="https://www.gurkhasfinance.com.np/nari-bachat.html">
                      <img src={noticeImg} alt="" className="card-img-top" />
                    </a>
                    <CardBody>
                      <h5 className="card-title">Nari Bachat</h5>
                      <p className="card-text">
                        Nari Bachat aims to avail a saving solution to the range
                        of women customers by connecting them with to service of
                        modern day banking practices, enabling them to take
                        control over their financial behavior and decisions.
                      </p>
                      <p className="card-text">
                        <small className="text-muted">
                          <a
                            href="https://www.gurkhasfinance.com.np/nari-bachat.html"
                            className=""
                            target="_blank"
                          >
                            Details
                          </a>
                        </small>
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <a href="https://www.gurkhasfinance.com.np/nari-bachat.html">
                      <img src={noticeImg} alt="" className="card-img-top" />
                    </a>
                    <CardBody>
                      <h5 className="card-title">Nari Bachat</h5>
                      <p className="card-text">
                        Nari Bachat aims to avail a saving solution to the range
                        of women customers by connecting them with to service of
                        modern day banking practices, enabling them to take
                        control over their financial behavior and decisions.
                      </p>
                      <p className="card-text">
                        <small className="text-muted">
                          <a
                            href="https://www.gurkhasfinance.com.np/nari-bachat.html"
                            className=""
                            target="_blank"
                          >
                            Details
                          </a>
                        </small>
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <a href="https://www.gurkhasfinance.com.np/nari-bachat.html">
                      <img src={noticeImg} alt="" className="card-img-top" />
                    </a>
                    <CardBody>
                      <h5 className="card-title">Nari Bachat</h5>
                      <p className="card-text">
                        Nari Bachat aims to avail a saving solution to the range
                        of women customers by connecting them with to service of
                        modern day banking practices, enabling them to take
                        control over their financial behavior and decisions.
                      </p>
                      <p className="card-text">
                        <small className="text-muted">
                          <a
                            href="https://www.gurkhasfinance.com.np/nari-bachat.html"
                            className=""
                            target="_blank"
                          >
                            Details
                          </a>
                        </small>
                      </p>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container> */}
        </Card>
      </Container>
      <Modal isOpen={modal} toggle={toggleModal} className="admin">
        <ModalHeader toggle={toggleModal}>Payment</ModalHeader>
        <ModalBody>
          <Container>
            <div className="payment-method-container">
              <p>
                Select your preferred payment method.
                <span className="text-danger">*</span>
              </p>
              <hr />
              <div className="payment-method">
                <div className="custom-control custom-radio mb-5 d-flex align-items-center">
                  <Input
                    type="radio"
                    name="payment-method"
                    id="online-payment"
                    className="custom-control-input "
                    onClick={onlinePaymentHandle}
                    checked={onlinePayment && "checked"}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="online-payment"
                    style={{ fontSize: "1.875rem" }}
                  >
                    Online Payment
                  </label>
                </div>

                <Row className="payment-body">
                  <Col className="online-payment-option" md="4">
                    <Form
                      action="https://www.connectips.com/connectipsgw/loginpage"
                      method="post"
                    >
                      <Input
                        name="MERCHANTID"
                        id="MERCHANTID"
                        value="1"
                        type="hidden"
                      />
                      <Input
                        name="APPID"
                        id="APPID"
                        value="MER-1-APP-1"
                        type="hidden"
                      />
                      <Input
                        name="APPNAME"
                        id="APPNAME"
                        value="dematfee"
                        type="hidden"
                      />
                      <Input
                        name="TXNID"
                        id="TXNID"
                        value="1234567890"
                        type="hidden"
                      />
                      <Input
                        name="TXNDATE"
                        id="TXNDATE"
                        value="2021-09-28"
                        type="hidden"
                      />
                      <Input
                        name="TXNCRNCY"
                        id="TXNCRNCY"
                        value="NPR"
                        type="hidden"
                      />
                      <Input
                        name="TXNAMT"
                        id="TXNAMT"
                        value="10000"
                        type="hidden"
                      />
                      <Input
                        name="REFERENCEID"
                        id="REFERENCEID"
                        value="7d4382a0-964e-47b2-98a7-a3ae0ab0f8b3"
                        type="hidden"
                      />
                      <Input
                        name="REMARKS"
                        id="REMARKS"
                        value="RMKS-001"
                        type="hidden"
                      />
                      <Input
                        name="PARTICULARS"
                        id="PARTICULARS"
                        value="PART-001"
                        type="hidden"
                      />
                      <Input
                        name="TOKEN"
                        id="TOKEN"
                        value="ZWI2MDExMjg2ZTQ3YWMwMDgwNGViMDE4ZjM3MGY1NWE0M2ZkZWM4MjczOGI2NDU0Y2E3ZGEzMTNhOTMzZGM5Ng=="
                        type="hidden"
                      />

                      <Button
                        className="onlinePayment btn btn-outline-default p-2"
                        type="submit"
                        disabled={!onlinePayment && "disabled"}
                      >
                        <img src={connectIpsImg} alt="Connect IPS" />
                      </Button>
                    </Form>
                  </Col>
                  <Col className="online-payment-option" md="4">
                    <Form
                      action="https://uat.esewa.com.np/epay/main"
                      method="POST"
                    >
                      <Input value="100" name="tAmt" type="hidden" />
                      <Input value="90" name="amt" type="hidden" />
                      <Input value="5" name="txAmt" type="hidden" />
                      <Input value="2" name="psc" type="hidden" />
                      <Input value="3" name="pdc" type="hidden" />
                      <Input value="EPAYTEST" name="scd" type="hidden" />
                      <Input value="123456789045" name="pid" type="hidden" />
                      <Input
                        value="http://127.0.0.1:8001/esewaPayment?customerId=59&amp;q=su"
                        type="hidden"
                        name="su"
                      />
                      <Input
                        value="http://127.0.0.1:8001/esewaPayment?customerId=59q=fu"
                        type="hidden"
                        name="fu"
                      />
                      <Button
                        className="btn btn-outline-default onlinePayment p-2"
                        type="button"
                        disabled={!onlinePayment && "disabled"}
                      >
                        <img src={esewaImg} alt="Esewa" />
                      </Button>
                    </Form>
                  </Col>
                  <Col className="online-payment-option" md="4">
                    <Button
                      type="button"
                      className="btn btn-outline-default onlinePayment p-2"
                      id="payment-button"
                      disabled={!onlinePayment && "disabled"}
                    >
                      <img src={khaltiImg} alt="Khalti" />
                    </Button>
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="payment-method">
                <div className="custom-control custom-radio mb-3">
                  <Input
                    type="radio"
                    name="payment-method"
                    id="voucher-payment"
                    className="custom-control-input payment-method-radio enableOnline"
                    onClick={onlinePaymentHandle}
                    checked={!onlinePayment && "checked"}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="voucher-payment"
                    style={{ fontSize: "1.875rem" }}
                  >
                    Voucher Payment
                  </label>
                </div>

                <div className="payment-body payment-body-bank">
                  <Form action="" method="" encType="multipart/form-data">
                    <Input
                      type="hidden"
                      name="_token"
                      value="abOTBogG5xP8FA1l9w2G3qVmKNDng63YNV0V9qi9"
                    />
                    <Col md="12">
                      <div className="input-group flex-column">
                        <label htmlFor="voucher">Upload Voucher</label>
                        <div className="input-container file-input-container file-input-container-pp">
                          <Input
                            type="file"
                            name="payment[0]"
                            id="voucher_payment"
                            className="form-control voucherPayment"
                            disabled={onlinePayment ? "disabled" : ""}
                            accept="image/jpg,image/png,image/jpeg,image/gif"
                            onChange={imageHandle}
                          />
                        </div>
                        <div
                          className="image-container image-person"
                          style={{ width: "205px" }}
                        >
                          <img src={currentImage} alt="" />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <Button
                        type="submit"
                        className="btn btn-success button-terms-disabled"
                        id="btn-to-submit"
                        disabled={onlinePayment && "disabled"}
                        onClick={handleSubmit}
                      >
                        {isPending ? "Submitting . . ." : "Submit"}
                      </Button>
                    </Col>
                    <Col md="12">
                      {error && <p className="text-danger">{error}</p>}
                    </Col>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>

      {data.payment_status === "not paid" && !warning && (
        <Dialog open={dialogue}>
          <DialogTitle className="text-center">
            <AlertCircle props="text-danger w-25 h-25" />
            <h1>
              <b className="d-flex">
                Your balance due amount is Rs.200 ! Please make your payment
              </b>
            </h1>
          </DialogTitle>
          <DialogContent className="text-center">
            <button className="btn btn-danger" onClick={handleDialogue}>
              Close
            </button>
          </DialogContent>
        </Dialog>
      )}
      {data.personal_visit === "no" && (
        <>
          {alert && (
            <Dialog open={warning} maxWidth="md">
              <DialogTitle className="text-center">
                <h2>
                  {/* Please visit Gurkha Finance Ltd. within 15 days with aplicable
                original documents ,the account will be frezed if not present
                within the stipulated time period ! */}
                  <b>
                    Customer shall be self-present with citizenship and passport
                    size photo for validation within 35 days to Gurkhas finance
                    ltd Head Office or nearest branches.
                  </b>
                </h2>
              </DialogTitle>
              <DialogContent className="text-center">
                <button className="btn btn-danger" onClick={handleWarning}>
                  Close
                </button>
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </>
  );
}

export default Dashboard;
