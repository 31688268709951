import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//CONTEXTS
import FormState from "../FormState";
import FormDispatch from "../FormDispatch";

const InfoModal = () => {
  const formState = useContext(FormState);
  const formDispatch = useContext(FormDispatch);

  return (
    <Dialog open={formState.info} maxWidth="md">
      <DialogTitle className="text-center">
        <h2 className="mt-3 mb-5 text-start">
          <b>
            डिम्याट खाता खाेल्दा अनिवार्य रूपमा निम्न विवरणहरू पेश गर्नुपर्ने छ
            ।
          </b>
        </h2>

        <div className="text-start">
          <p>
            - दुवै कान स्पष्ट देखिने गरी हालसालै खिचेको पासपोर्ट साइजको फोटो
            हुनुपर्ने ।
          </p>
          <p>
            - दस्तखत - अन्यत्र कतै प्रयोग नभएको सादा पेपरमा कालो वा निलो पेनले
            गरेको हुनुपर्ने ।
          </p>
          <p>
            -औँठा छाप (ल्याप्चे) - अन्यत्र कतै प्रयोग नभएको सादा पेपरमा दाँया र
            बाँया छुट्टाइएको हुनुपर्ने । नागरिकता, लाइसेन्स वा अन्यत्र प्रयोग
            भएको औँठा छाप प्रयोग गर्न नपाईने ।
          </p>
          <p>
            - नागरिकताको अगाडी र पछाडीको भाग स्पष्ट देखिने गरी खिचेको फोटो
            हुनुपर्ने ।
          </p>
          <p>
            -नाबालकको हकमा (१८ वर्ष भन्दा मुनी) जन्मदर्ता अनिवार्य हुनुपर्ने ।
            साथै संरक्षकमा बुवा वा आमा हुनुपर्ने । जन्मदर्ता बाहेक अन्य
            कागजातहरु जस्तै : अस्पतालको जन्मदर्ताको प्रमाणपत्र, नावालक परिचयपत्र
            मान्यहुने छैन ।
          </p>
          <p>
            - लोकेसन म्याप आफू हाल बसोबास गरिरहेको घरबाट नजिकैको कुनै प्रचलित
            स्थान हुने गरी स्पष्ट हुनुपर्ने ।
          </p>
        </div>
      </DialogTitle>
      <DialogContent className="text-center">
        <button
          className="btn btn-danger"
          onClick={() => {
            formDispatch({ type: "setInfo" });
          }}
        >
          Close
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default InfoModal;
