import React, { useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";

import StateContext from "../../../StateContext";
import Loading from "../../Layout/Loading";
import RolesCreate from "./RoleView/RolesCreate";
import RoleEdit from "./RoleView/RoleEdit";
import RoleList from "./RoleView/RoleListView/RoleList";

function Roles() {
  const appState = useContext(StateContext);

  const initialState = {
    roles: [],
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "userRoles":
        draft.roles = action.value;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);
  // console.log(appState.user.data.token);
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/roles`,
          {
            cancelToken: ourRequest.token,
          }
        );
        console.log(response.data);
        dispatch({ type: "userRoles", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching users");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  if (!state.roles?.length) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/admin/dashboard/roles" exact>
          <RoleList data={state.roles} />
        </Route>
        <Route path="/admin/dashboard/roles/create" exact>
          <RolesCreate />
        </Route>
        <Route path="/admin/dashboard/roles/:id">
          <RoleEdit />
        </Route>
      </Switch>
    </>
  );
}

export default Roles;
