import React, { useState, useEffect } from "react";

const Page4 = ({ data, guardianData, guardian }) => {
  const [personalInformations, setPersonalInformations] = useState({});
  const [permanentAddress, setPermanentAddress] = useState({});
  const [temporaryAddress, setTemporaryAddress] = useState({});

  useEffect(() => {
    guardianData?.personal_informations?.map((item) => {
      setPersonalInformations(item);
    });
    guardianData?.addresses?.map((item) => {
      if (item.type === "permanent") {
        setPermanentAddress(item);
      }
      if (item.type === "temporary") {
        setTemporaryAddress(item);
      }
    });
  }, [guardianData]);

  return (
    <div className="page">
      <div className="form-row">
        <div className="col">
          <h4>
            संरक्षकको विविरण (नाबालकको हकमा) (Guardian's information- in case of
            minor only):
          </h4>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>नाम / थर </p>
              <p>Name /Surname</p>
            </div>
          </div>
        </div>
        <div className="col col-9">
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianName"
                readOnly
                defaultValue={guardianData?.full_name}
              />
            </div>
          </div>
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianNameNepali"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>निवेदकसंगको सम्बन्ध</p>
              <p>Relationship with Applicant</p>
            </div>
          </div>
        </div>
        <div className="col col-9">
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianRelWithApplicant"
                readOnly
                defaultValue={guardianData?.relation}
              />
            </div>
          </div>
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianRelWithApplicantNepali"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>बुबाको नाम </p>
              <p>Father's Name</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianFatherName"
                readOnly
                defaultValue={guardianData?.father_name}
              />
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>बाजेको </p>
              <p>GrandFather's Name</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianGrandfatherName"
                readOnly
                defaultValue={guardianData?.grand_father_name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>राष्ट्र </p>
              <p>Nation</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="GurdianNation"
                  readOnly
                  defaultValue={permanentAddress?.country}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>अञ्चल </p>
              <p>Zone</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="GurdianZone"
                  readOnly
                  defaultValue={permanentAddress?.province}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>जिल्ला </p>
              <p>District</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="GurdianDistrict"
                  readOnly
                  defaultValue={permanentAddress?.district}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>गा.बि.स/न.पा/म.न.पा</p>
              <p>VDC/Muncipality/Meropolitan</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="GurdianVDC/Muncipality/Meropolitan"
                  readOnly
                  defaultValue={permanentAddress?.municipality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>टोल </p>
              <p>Locality</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="gurdianLocality"
                  readOnly
                  defaultValue={permanentAddress?.locality}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>वडा नं </p>
              <p>Ward No.</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="number"
                  className="form-input w-100"
                  id="gurdianWardNo"
                  readOnly
                  defaultValue={permanentAddress?.ward_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>ब्लक नं</p>
              <p>Block No.</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="gurdianBlockNo"
                  readOnly
                  defaultValue={permanentAddress?.block_number}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>पत्राचार ठेगाना</p>
              <p>Correspondance Address.</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianCorrespondanceAdd"
                readOnly
                defaultValue={temporaryAddress?.phone_number}
              />
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>फोन नं</p>
              <p>Telephone No.</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="gurdianTelephone"
                readOnly
                defaultValue={permanentAddress?.phone_number}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>फ्याक्स नं </p>
              <p>Fax No.</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="gurdianFax"
              />
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>मोबाइल नं</p>
              <p>Mobile No.</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="gurdianMobile"
                readOnly
                defaultValue={guardianData?.mobile}
              />
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>ईमेल </p>
              <p>Email ID.</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="gurdianEmail"
                readOnly
                defaultValue={guardianData?.email}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mb-3">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>स्थायी लेखा नं</p>
              <p>PAN NO.</p>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="gurdianPAN"
                readOnly
                defaultValue={personalInformations?.pan_number}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <h4>गैर आवसिय नेपालीको लागी (For Non resident Nepalese)</h4>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>बैदेशिक ठेगाना </p>
              <p>Foreign Address</p>
            </div>
          </div>
        </div>
        <div className="col col-9">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="foreignAddress"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>शहर</p>
              <p>City</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="foreignCity"
              />
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>राज्य </p>
              <p>Locality</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="foreignLocality"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>राष्ट्र</p>
              <p>Nation</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="foreignNation"
              />
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>गैर आवसिय कोड नं </p>
              <p>Foreign Code No.</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="foreignCodeNo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row my-3">
        <div className="col-6">
          <div className="input-group no-border mb-3 ">
            <label htmlFor="applicant-name">
              <p>निवेदकको नाम </p>
              <p>Applicant's Name</p>
            </label>
            <input
              type="text"
              className="form-input bottom-border fg-1"
              id="applicant-name"
              readOnly
              defaultValue={data?.full_name}
            />
          </div>
          <div className="input-group no-border align-items-center my-3">
            <label htmlFor="signature">
              <p>हस्ताछ्यर</p>
              <p>Signature</p>
            </label>
            <div className="image-wrapper fg-1">
              <div className="image-container signature-box">
                <img
                  src={
                    `${process.env.REACT_APP_BASE_URL}/img/` +
                    data.email +
                    "/guardian/" +
                    guardian?.photo_name
                  }
                  alt=""
                  style={{ height: "100px", width: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <div className="thumb-print">
            <p>Thumb Print</p>
            <div className="thumb-print-container">
              <div className="left">
                <div className="caption">
                  <p>Right</p>
                </div>
                <div className="image-container">
                  <img src="" alt="" />
                </div>
              </div>
              <div className="right">
                <div className="caption">
                  <p>left</p>
                </div>
                <div className="image-container">
                  <img src="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-break"></div>
    </div>
  );
};

export default Page4;
