//nationality list

const nationality = [
  {
    id: 1,
    name: "Nepalese",
    nationality_id: "1",
  },
  {
    id: 2,
    name: "NRN - Non Residental Nepali",
    nationality_id: "2",
  },
  {
    id: 3,
    name: "Foreign ",
    nationality_id: "3",
  },
];

export default nationality;
