import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useImmerReducer } from "use-immer";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import StateContext from "../../StateContext";
import { Eye } from "../Layout/Icons";

const Settings = () => {
  const appState = useContext(StateContext);
  const history = useHistory();
  const token =
    appState.adminLoggedIn === true
      ? appState.user.data.token
      : appState.client.data.token;

  const initialState = {
    oldPassword: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
      type: "password",
    },
    password: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
      type: "password",
    },
    confirmPassword: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
      type: "password",
    },
    passwordError: false,
    success: null,
    error: null,
    submitCount: 0,
    isPending: false,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "passwordChange":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (draft.password.value.length > 50) {
          draft.password.hasErrors = true;
          draft.password.message = "Password cannot exceed 50 characters";
        }
        return;
      case "passwordAfterDelay":
        if (draft.password.value.length < 1) {
          draft.password.hasErrors = true;
          draft.password.message = "You must provide a password";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/.test(
            draft.password.value
          )
        ) {
          draft.password.hasErrors = true;
          draft.password.message =
            "Password must be more than 8 characters and must contain uppercase, lowercase, special character and number";
        }
        return;
      case "oldPasswordChange":
        draft.oldPassword.hasErrors = false;
        draft.oldPassword.value = action.value;
        if (draft.oldPassword.value.length > 50) {
          draft.oldPassword.hasErrors = true;
          draft.oldPassword.message = "Password cannot exceed 50 characters";
        }
        return;
      case "oldPasswordAfterDelay":
        if (draft.oldPassword.value.length < 8) {
          draft.oldPassword.hasErrors = true;
          draft.oldPassword.message = "Password must be 8 characters long";
        }
        return;
      case "confirmPasswordChange":
        draft.confirmPassword.hasErrors = false;
        draft.confirmPassword.value = action.value;
        return;
      case "confirmPasswordAfterDelay":
        if (draft.password.value !== draft.confirmPassword.value) {
          draft.confirmPassword.hasErrors = true;
          draft.confirmPassword.message = "Passwords do not match";
        }
        return;
      case "toggleOldPassword":
        if (draft.oldPassword.type === "password") {
          draft.oldPassword.type = "text";
        } else {
          draft.oldPassword.type = "password";
        }
        return;
      case "togglePassword":
        if (draft.password.type === "password") {
          draft.password.type = "text";
        } else {
          draft.password.type = "password";
        }
        return;
      case "toggleConfirmPassword":
        if (draft.confirmPassword.type === "password") {
          draft.confirmPassword.type = "text";
        } else {
          draft.confirmPassword.type = "password";
        }
        return;
      case "submitForm":
        if (
          !draft.password.hasErrors &
          !draft.confirmPassword.hasErrors &
          !draft.oldPassword.hasErrors
        ) {
          draft.submitCount++;
        }
        return;
      case "setSuccess":
        draft.success = action.value;
        return;
      case "saveError":
        draft.passwordError = action.value;
        return;
      default:
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.password.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "passwordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.password.value]);

  useEffect(() => {
    if (state.confirmPassword.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "confirmPasswordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.confirmPassword.value]);

  useEffect(() => {
    if (state.submitCount && token) {
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      const ourRequest = axios.CancelToken.source();
      async function reset() {
        try {
          dispatch({ type: "setIsPending", value: true });
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/password/change`,
            {
              old_password: state.oldPassword.value,
              new_password: state.password.value,
              c_password: state.confirmPassword.value,
            },
            { cancelToken: ourRequest.token }
          );
          if (response.data) {
            dispatch({ type: "setSuccess", value: response.data.message });
            if (response.data.success === false) {
              dispatch({ type: "saveError", value: true });
            }
            if (response.data.success === true) {
              dispatch({ type: "saveError", value: false });
              history.push("/dashboard");
            }
          }
          console.log("response.data.forgot", response);
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "setSuccess", value: e.response?.data?.message });
          dispatch({ type: "saveError", value: true });

          // if (e.response.data) {
          //   dispatch({ type: "setError", value: e.response.data.message })
          // }
        }
      }
      reset();
      return () => ourRequest.cancel();
    }
  }, [state.submitCount]);

  function toggleOldPassword() {
    dispatch({ type: "toggleOldPassword" });
  }

  function togglePassword() {
    dispatch({ type: "togglePassword" });
  }

  function toggleConfirmPassword() {
    dispatch({ type: "toggleConfirmPassword" });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "passwordChange", value: state.password.value });
    dispatch({ type: "passwordAfterDelay", value: state.password.value });
    dispatch({
      type: "confirmPasswordChange",
      value: state.confirmPassword.value,
    });
    dispatch({
      type: "confirmPasswordAfterDelay",
      value: state.confirmPassword.value,
    });
    dispatch({ type: "submitForm" });
  }

  return (
    <Container className="mt-6">
      <Row>
        <Col className="order-xl-1" xl="12">
          <Accordion defaultActiveKey="0" className="accordian">
            <Accordion.Item eventKey="0" className="accordion-item">
              <Accordion.Header className="accordian-header">
                Reset Password
              </Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={handleSubmit}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Old Password
                          </label>
                          <div className="position-relative">
                            <Input
                              className="form-control-alternative"
                              placeholder="Your old password"
                              type={state.oldPassword.type}
                              onChange={(e) =>
                                dispatch({
                                  type: "oldPasswordChange",
                                  value: e.target.value,
                                })
                              }
                              value={state.oldPassword.value}
                            />
                            <span
                              className="view-icon position-absolute"
                              data="view-password"
                              onClick={toggleOldPassword}
                              style={{
                                right: "0%",
                                top: "0%",
                                cursor: "pointer",
                                padding: "6px",
                              }}
                            >
                              <Eye props={"h-10"} />
                            </span>
                          </div>
                          {state.oldPassword.hasErrors && (
                            <p className="text-danger">
                              {state.oldPassword.message}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Password
                          </label>
                          <div className="position-relative">
                            <Input
                              className="form-control-alternative"
                              placeholder="Password"
                              type={state.password.type}
                              onChange={(e) =>
                                dispatch({
                                  type: "passwordChange",
                                  value: e.target.value,
                                })
                              }
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              value={state.password.value}
                            />
                            <span
                              className="view-icon position-absolute"
                              data="view-password"
                              onClick={togglePassword}
                              style={{
                                right: "0%",
                                top: "0%",
                                cursor: "pointer",
                                padding: "6px",
                              }}
                            >
                              <Eye props={"h-10"} />
                            </span>
                          </div>
                          {state.password.hasErrors && (
                            <p className="text-danger">
                              {state.password.message}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Confirm Password
                          </label>
                          <div className="position-relative">
                            <Input
                              className="form-control-alternative"
                              placeholder="Confirm Password"
                              type={state.confirmPassword.type}
                              onChange={(e) =>
                                dispatch({
                                  type: "confirmPasswordChange",
                                  value: e.target.value,
                                })
                              }
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              value={state.confirmPassword.value}
                            />
                            <span
                              className="view-icon position-absolute"
                              data="view-password"
                              onClick={toggleConfirmPassword}
                              style={{
                                right: "0%",
                                top: "0%",
                                cursor: "pointer",
                                padding: "6px",
                              }}
                            >
                              <Eye props={"h-10"} />
                            </span>
                          </div>
                          {state.confirmPassword.hasErrors && (
                            <p className="text-danger">
                              {state.confirmPassword.message}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Button
                          // className="btn-success"
                          color="primary"
                          disabled={
                            state.password.hasErrors ||
                            state.confirmPassword.hasErrors
                          }
                        >
                          {state.isPending ? "Submitting . . ." : "Submit"}
                        </Button>
                      </Col>
                      <Col lg="12">
                        {state.success && (
                          <Alert
                            className={`mt-4 ${
                              state.passwordError
                                ? "alert-danger"
                                : "alert-success"
                            }`}
                          >
                            {state.success}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
