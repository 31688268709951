import React, { useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";

import { Alert, Col, FormGroup, Input, Row } from "reactstrap";

import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

import occupation from "../../../../Assets/occupation";

const Occupation = () => {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);

  const initialState = {
    errorCount: 0,
    submitCount: 0,
    formData: {
      occupation: {
        name: "occupation",
        value: "",
        hasErrors: false,
        message: "",
      },
      businessType: {
        name: "businessType",
        value: "",
        hasErrors: false,
        message: "",
      },
      organizationName: {
        name: "organizationName",
        value: "",
        hasErrors: false,
        message: "",
      },
      organizationAddress: {
        name: "organizationAddress",
        value: "",
        hasErrors: false,
        message: "",
      },
      designation: {
        name: "designation",
        value: "",
        hasErrors: false,
        message: "",
      },
      incomeRange: {
        name: "incomeRage",
        value: "",
        hasErrors: false,
        message: "",
      },
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          console.log("the reducer hit for the occupation page");
          if (draft.formData[key].name == action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
          }
        }
        return;
      case "setIncomeRange":
        draft.formData.incomeRange.value = action.value;
        draft.formData.incomeRange.hasErrors = false;
        return;
      case "validateForm":
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (!draft.formData[key].value.length) {
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field cannot be blank";
          }
        }
        for (const key in draft.formData) {
          if (draft.formData[key].hasErrors) {
            draft.errorCount++;
          }
        }
        if (draft.formData.organizationName.value === "") {
          draft.errorCount--;
          draft.formData.organizationName.hasErrors = false;
        }
        if (draft.formData.organizationAddress.value === "") {
          draft.errorCount--;
          draft.formData.organizationAddress.hasErrors = false;
        }
        if (draft.formData.designation.value === "") {
          draft.errorCount--;
          draft.formData.designation.hasErrors = false;
        }
        // console.log("error count is ", errorCount)
        if (!draft.errorCount) {
          console.log("there are no errors");
          draft.submitCount++;
        }
        return;
      case "saveSubmitCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount) {
      console.log("form can be submitted for the occuption blcok");
      formDispatch({
        type: "saveOccupation",
        value: {
          title: state.formData.occupation.value,
          type: state.formData.businessType.value,
          organization_name: state.formData.organizationName.value,
          address: state.formData.organizationAddress.value,
          designation: state.formData.designation.value,
          financial_details: state.formData.incomeRange.value,
        },
      });
    }
  }, [state.submitCount]);

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    dispatch({ type: "saveSubmitCount" });
  }, [state.formData]);

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-occupation">
              Occupation
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.occupation.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="input-occupation"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "occupation",
                })
              }
              value={state.formData.occupation.value}
            >
              {occupation.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.formData.occupation.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.occupation.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="occupation-type_of_business"
            >
              Type of Business
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.businessType.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="occupation-type_of_business"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "businessType",
                })
              }
              value={state.formData.businessType.value}
            >
              <option value="">Select a business</option>
              <option value="Production">Production</option>
              <option value="Service Oriented">Service Oriented</option>
            </select>
            {/* {state.formData.businessType.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.businessType.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="occupation-organization_name"
            >
              Organization's Name
            </label>
            {/* <span className="text-danger">*</span> */}
            <Input
              className={`form-control-alternative ${
                state.formData.organizationName.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="occupation-organization_name"
              type="text"
              placeholder={` ${
                state.formData.organizationName.hasErrors
                  ? state.formData.organizationName.message
                  : "Enter Your Organization's Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "organizationName",
                })
              }
              value={state.formData.organizationName.value}
            />
            {/* {state.formData.organizationName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.organizationName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="occupation-organization_address"
            >
              Address
            </label>
            {/* <span className="text-danger">*</span> */}
            <Input
              className={`form-control-alternative ${
                state.formData.organizationAddress.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="occupation-organization_address"
              placeholder={` ${
                state.formData.organizationAddress.hasErrors
                  ? state.formData.organizationAddress.message
                  : "Enter Your Organization's Address"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "organizationAddress",
                })
              }
              value={state.formData.organizationAddress.value}
            />
            {/* {state.formData.organizationAddress.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.organizationAddress.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="occupation-designation"
            >
              Designation
            </label>
            {/* <span className="text-danger">*</span> */}
            <Input
              className={`form-control-alternative ${
                state.formData.designation.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="occupation-designation"
              type="text"
              placeholder={` ${
                state.formData.designation.hasErrors
                  ? state.formData.designation.message
                  : "Enter Your designation in organization"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "designation",
                })
              }
              value={state.formData.designation.value}
            />
            {/* {state.formData.designation.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.designation.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>

      <h3 className="text-priary">
        Select your Financial Details <span className="text-danger">*</span>
      </h3>
      <Row className="my-4">
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="occupation-financial_details1"
              type="radio"
              name="income_range"
              onChange={(e) => dispatch({ type: "setIncomeRange", value: "1" })}
            />
            <label
              className="form-control-label"
              htmlFor="occupation-financial_details1"
              style={{ marginLeft: "3rem" }}
            >
              Upto Rs. 1,00,000
            </label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="occupation-financial_details2"
              type="radio"
              name="income_range"
              onChange={(e) => dispatch({ type: "setIncomeRange", value: "2" })}
            />
            <label
              className="form-control-label"
              htmlFor="occupation-financial_details2"
              style={{ marginLeft: "3rem" }}
            >
              From Rs. 1,00,001 to Rs. 2,00,000
            </label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="occupation-financial_details3"
              type="radio"
              name="income_range"
              onChange={(e) => dispatch({ type: "setIncomeRange", value: "3" })}
            />
            <label
              className="form-control-label"
              htmlFor="occupation-financial_details3"
              style={{ marginLeft: "3rem" }}
            >
              From Rs. 2,00,001 to Rs. 5,00,000
            </label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="occupation-financial_details4"
              type="radio"
              name="income_range"
              onChange={(e) => dispatch({ type: "setIncomeRange", value: "4" })}
            />
            <label
              className="form-control-label"
              htmlFor="occupation-financial_details4"
              style={{ marginLeft: "3rem" }}
            >
              Above Rs. 5,00,000
            </label>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              id="occupation-financial_details5"
              type="radio"
              name="income_range"
              onChange={(e) => dispatch({ type: "setIncomeRange", value: "5" })}
            />
            <label
              className="form-control-label"
              htmlFor="occupation-financial_details5"
              style={{ marginLeft: "3rem" }}
            >
              Not Available
            </label>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Occupation;
