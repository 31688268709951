import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Compressor from "compressorjs";

// import Cropper from "react-easy-crop";
// import getCroppedImg from "../CropImage";
// import Slider from "@material-ui/core/Slider";

function CitizenshipBack(props) {
  const inputRef = useRef();
  const triggerPopup = () => inputRef.current.click();
  const [imageName, setImageIname] = useState(null);
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [max, setMax] = useState(false);
  // const [rotation, setRotation] = useState(0);
  // const [croppedArea, setCroppedArea] = useState(null);
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState();
  // const [fileExt, setFileExt] = useState(null);

  // const [displayPicture, setDisplayPicture] = useState(null);
  // const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
  //   setCroppedArea(croppedAreaPixels);
  // };

  // CompressImage
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log("there is a file");
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setCurrentImage(reader.result);
        setImageIname("gov_issued_id_back");
        // setImage(reader.result);
        // setFileExt(e.target.files[0]);
        new Compressor(file, {
          convertSize: 500000,
          success: (compressedResult) => {
            // compressedResult
            setImage(compressedResult);
          },
        });
      });
    }
  };

  // SetImage
  useEffect(() => {
    if (image) {
      let extension = image.type.split("/");
      if (image.size < 250000) {
        props.setCitizenshipBackFile(
          new File([image], `image.${extension[1]}`, {
            type: image.type,
          })
        );
        props.setCitizenshipBack(imageName + "." + `${extension[1]}`);
        props.setErrorCount(0);
        setMax(false);
      }
      if (image.size > 250000) {
        props.setCitizenshipBackFile("");
        props.setCitizenshipBack("");
        props.setErrorCount(1);
        setMax(true);
      }
    }
  }, [image]);

  // function cancelSelection(e) {
  //   setImage(image);
  //   setCurrentImage(null);
  //   fetch(image)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       console.log(blob);
  //       let extension = blob.type.split("/");
  //       if (blob.size < 250000) {
  //         props.setCitizenshipBackFile(
  //           new File([blob], `image.${extension[1]}`, {
  //             type: blob.type,
  //           })
  //         );
  //         props.setCitizenshipBack(imageName + "." + `${extension[1]}`);
  //         props.setErrorCount(0);
  //         setMax(false);
  //       }
  //       if (blob.size > 250000) {
  //         props.setCitizenshipBackFile("");
  //         props.setCitizenshipBack("");
  //         props.setErrorCount(1);
  //         setMax(true);
  //       }
  //     });
  // }

  // async function onCrop(e) {
  //   try {
  //     const croppedImageUrl = await getCroppedImg(image, croppedArea, rotation);
  //     console.log("croppedimageurl", croppedImageUrl);
  //     setImage(croppedImageUrl);
  //     setCurrentImage(null);
  //     fetch(croppedImageUrl)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         console.log(blob);
  //         let extension = blob.type.split("/");
  //         if (blob.size < 250000) {
  //           props.setCitizenshipBackFile(
  //             new File([blob], `image.${extension[1]}`, {
  //               type: blob.type,
  //             })
  //           );
  //           props.setCitizenshipBack(imageName + "." + `${extension[1]}`);
  //           props.setErrorCount(0);
  //           setMax(false);
  //         }
  //         if (blob.size > 250000) {
  //           props.setCitizenshipBackFile("");
  //           props.setCitizenshipBack("");
  //           props.setErrorCount(1);
  //           setMax(true);
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e, "there was an error while getting the cropped image");
  //     props.setError(true);
  //   }
  // }

  return (
    <div className=" documents">
      <input
        type="file"
        id="document-pp_size_photo"
        accept="image/*"
        className="form-control"
        ref={inputRef}
        onChange={onSelectFile}
      />
      <div className="image-container image-person">
        <img
          src={
            image
              ? currentImage
              : `${process.env.REACT_APP_BASE_URL}/img/` +
                props.email +
                "/" +
                props.displayImg
          }
          alt=""
        />
      </div>

      {/* <div className="container-cropper">
        <Cropper image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
        <Slider />
      </div> */}
      {props.setErrors && <p className="error">{props.setErrors}</p>}
      {max && <p className="error">The file size should not exceed 250KB</p>}

      <div className="container-buttons">
        <Button variant="contained" color="primary" onClick={triggerPopup}>
          choose
        </Button>
      </div>

      {/* {currentImage ? (
        <div className="container-cropper">
          <div className="overlay">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1.5 / 1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              rotation={rotation}
              onRotationChange={setRotation}
            />
          </div>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation)}
          />
          <div className="btn-container">
            <span className="btn btn-success" onClick={onCrop}>
              crop
            </span>
            <span className="btn btn-danger" onClick={cancelSelection}>
              cancel
            </span>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default CitizenshipBack;
