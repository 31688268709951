import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import DatePicker from "react-datepicker";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import adbs from "ad-bs-converter";

import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import FormDispatch from "../../../CustomerForm/FormDispatch";
import FormState from "../../../CustomerForm/FormState";

import districts from "../../../../Assets/district-list-nepal";
import country from "../../../../Assets/country";
import provinceData from "../../../../Assets/province-district.json";
import CitizenshipBack from "../../../Handler/Documents/CitizenshipBack";
import CitizenshipFront from "../../../Handler/Documents/CitizenshipFront";
import Signature from "../../../Handler/Documents/Signature";
import DisplayPicture from "../../../Handler/Documents/DisplayPicture";

const Nominee = () => {
  const formState = useContext(FormState);
  const formDispatch = useContext(FormDispatch);
  const [errorCount, setErrorCount] = useState(0);

  const initialState = {
    errorCount: 0,
    sendCount: 0,
    formData: {
      nameOfGuardian: {
        name: "nameOfGuardian",
        value: "",
        hasErrors: false,
        message: "",
      },
      relationshipWithApplicant: {
        name: "relationshipWithApplicant",
        value: "",
        hasErrors: false,
        message: "",
      },
      fathersName: {
        name: "fathersName",
        value: "",
        hasErrors: false,
        message: "",
      },
      grandFathersName: {
        name: "grandFathersName",
        value: "",
        hasErrors: false,
        message: "",
      },
      mobile: {
        name: "mobile",
        value: "",
        hasErrors: false,
        message: "",
      },
      email: {
        name: "email",
        value: "",
        hasErrors: false,
        message: "",
      },
      typeOfIdCard: {
        name: "typeOfIdCard",
        value: "",
        hasErrors: false,
        message: "",
      },
      idNo: {
        name: "idNo",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDistrict: {
        name: "idIssueDistrict",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDateBs: {
        name: "idIssueDateBs",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDate: {
        name: "idIssueDate",
        value: "",
        hasErrors: false,
        message: "",
      },
      pan: {
        name: "pan",
        value: "",
        hasErrors: false,
        message: "",
        touched: false,
        checkCount: 0,
        checkingNow: false,
      },

      addresses: {
        temporary: {
          country: {
            name: "country",
            value: "",
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
        permanent: {
          country: {
            name: "country",
            value: "",
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: "",
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: "",
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
      },
      documents: {
        displayPicture: {
          value: "",
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipBack: {
          value: "",
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipFront: {
          value: "",
          hasErrors: false,
          message: "",
          required: true,
        },
        signature: {
          value: "",
          hasErrors: false,
          message: "",
          required: true,
        },
      },
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft.formData[key].hasErrors = false;
            draft.formData[key].touched = true;
            draft.formData[key].value = action.value;
            if (draft.formData[key].name == "mobile") {
              if (action.value.length > 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message =
                  "Mobile number cannot exceed 10 digits";
              }
            }
            // if (draft.formData[key].name == "pan") {
            //   if (action.value.length > 9) {
            //     draft.formData[key].hasErrors = true;
            //     draft.formData[key].message =
            //       "Pan number cannot exceed 9 digits";
            //   }
            // }
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field is required`;
              return;
            }

            draft.formData[key].value = action.value;
            if (draft.formData[key].name == "email") {
              if (
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  draft.formData.email.value
                )
              ) {
                draft.formData.email.hasErrors = true;
                draft.formData.email.message =
                  "You must provide a valid email address";
                return;
              }
            }
            if (draft.formData[key].name == "mobile") {
              if (action.value.length != 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message = "Mobile number must be 10 digits";
              }
            }
            // if (draft.formData[key].name == "pan") {
            //   console.log("this is a pan number");
            //   if (action.value.length != 9) {
            //     draft.formData[key].hasErrors = true;
            //     draft.formData[key].message = "Pan number must be 9 digits";
            //   }
            // }
          }
        }
        return;
      case "idIssueDateChange":
        console.log("id issue date change reducer hit");
        draft.formData.idIssueDate.touched = true;
        draft.formData.idIssueDate.hasErrors = false;
        draft.formData.idIssueDate.value = action.value;
        draft.formData.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "renderProvinces":
        console.log("this reducer was hit at the render proinces");
        if (!draft.formData.addresses.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.formData.addresses.temporary.province.list.push(key);
            draft.formData.addresses.permanent.province.list.push(key);
          }
        }
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          provinceData[key].map((item, i) => {
            draft.formData.addresses.temporary.district.list.push(
              item.district
            );
            draft.formData.addresses.permanent.district.list.push(
              item.district
            );
          });
        }
        draft.formData.addresses.temporary.district.listToShow =
          draft.formData.addresses.temporary.district.list;
        draft.formData.addresses.permanent.district.listToShow =
          draft.formData.addresses.permanent.district.list;
        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.formData.addresses.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.formData.addresses.temporary.municipality.list.push(item);
              draft.formData.addresses.permanent.municipality.list.push(item);
            });
          });
        }
        draft.formData.addresses.temporary.municipality.listToShow =
          draft.formData.addresses.temporary.municipality.list;
        draft.formData.addresses.permanent.municipality.listToShow =
          draft.formData.addresses.permanent.municipality.list;
        return;
      case "temporaryInputChange":
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].name == action.field) {
            draft.formData.addresses.temporary[key].hasErrors = false;
            draft.formData.addresses.temporary[key].value = action.value;
            if (draft.formData.addresses.temporary[key].name == "province") {
              draft.formData.addresses.temporary.district.listToShow = [];
              draft.formData.addresses.temporary.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.temporary.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.temporary[key].name == "district") {
              draft.formData.addresses.temporary.municipality.listToShow = [];
              draft.formData.addresses.temporary.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.temporary.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.temporary.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "permanentInputChange":
        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].name == action.field) {
            draft.formData.addresses.permanent[key].hasErrors = false;
            draft.formData.addresses.permanent[key].value = action.value;
            if (draft.formData.addresses.permanent[key].name == "province") {
              draft.formData.addresses.permanent.district.listToShow = [];
              draft.formData.addresses.permanent.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.permanent.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.permanent[key].name == "district") {
              draft.formData.addresses.permanent.municipality.listToShow = [];
              draft.formData.addresses.permanent.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.permanent.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.permanent.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "panChange":
        draft.formData.pan.touched = true;
        draft.formData.pan.hasErrors = false;
        draft.formData.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9 && action.value.length > 0) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.length === 0) {
          draft.formData.pan.hasErrors = false;
          draft.formData.pan.message = "";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message = "PAN number invalid";
          return;
        } else if (action.value.length > 9) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be more than 9 digits";
          return;
        }
        draft.formData.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.formData.pan.checkingNow = true;
        draft.formData.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.formData.pan.message = "PAN number available";
        draft.formData.pan.hasErrors = false;
        return;
      case "panUnavailable":
        draft.formData.pan.message = "PAN number already in use";
        draft.formData.pan.hasErrors = true;
        return;
      case "setDisplayPicture":
        draft.formData.documents.displayPicture.hasErrors = false;
        draft.formData.documents.displayPicture.value = action.value;
        return;
      case "setDisplayPictureFile":
        draft.formData.documents.displayPicture.file = action.value;
        return;
      case "setCitizenshipBack":
        draft.formData.documents.citizenshipBack.hasErrors = false;
        draft.formData.documents.citizenshipBack.value = action.value;
        return;
      case "setCitizenshipBackFile":
        draft.formData.documents.citizenshipBack.file = action.value;
        return;
      case "setCitizenshipFront":
        draft.formData.documents.citizenshipFront.hasErrors = false;
        draft.formData.documents.citizenshipFront.value = action.value;
        return;
      case "setCitizenshipFrontFile":
        draft.formData.documents.citizenshipFront.file = action.value;
        return;
      case "setSignature":
        draft.formData.documents.signature.hasErrors = false;
        draft.formData.documents.signature.value = action.value;
        return;
      case "setSignatureFile":
        draft.formData.documents.signature.file = action.value;
        return;
      case "sameAsCurrent":
        draft.formData.addresses.permanent.country.value =
          draft.formData.addresses.temporary.country.value;
        draft.formData.addresses.permanent.country.touched = true;
        draft.formData.addresses.permanent.province.value =
          draft.formData.addresses.temporary.province.value;
        draft.formData.addresses.permanent.province.touched = true;
        draft.formData.addresses.permanent.district.value =
          draft.formData.addresses.temporary.district.value;
        draft.formData.addresses.permanent.district.touched = true;
        draft.formData.addresses.permanent.municipality.value =
          draft.formData.addresses.temporary.municipality.value;
        draft.formData.addresses.permanent.municipality.touched = true;
        draft.formData.addresses.permanent.city.value =
          draft.formData.addresses.temporary.city.value;
        draft.formData.addresses.permanent.city.touched = true;
        draft.formData.addresses.permanent.locality.value =
          draft.formData.addresses.temporary.locality.value;
        draft.formData.addresses.permanent.locality.touched = true;
        draft.formData.addresses.permanent.ward_number.value =
          draft.formData.addresses.temporary.ward_number.value;
        draft.formData.addresses.permanent.ward_number.touched = true;
        draft.formData.addresses.permanent.block_number.value =
          draft.formData.addresses.temporary.block_number.value;
        draft.formData.addresses.permanent.block_number.touched = true;
        draft.formData.addresses.permanent.phone_number.value =
          draft.formData.addresses.temporary.phone_number.value;
        draft.formData.addresses.permanent.phone_number.touched = true;
        draft.formData.addresses.permanent.country.hasErrors = false;
        draft.formData.addresses.permanent.province.hasErrors = false;
        draft.formData.addresses.permanent.district.hasErrors = false;
        draft.formData.addresses.permanent.municipality.hasErrors = false;
        draft.formData.addresses.permanent.city.hasErrors = false;
        draft.formData.addresses.permanent.locality.hasErrors = false;
        draft.formData.addresses.permanent.ward_number.hasErrors = false;
        draft.formData.addresses.permanent.block_number.hasErrors = false;
        draft.formData.addresses.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.formData.addresses.permanent.country.value = "";
        draft.formData.addresses.permanent.province.value = "";
        draft.formData.addresses.permanent.district.value = "";
        draft.formData.addresses.permanent.municipality.value = "";
        draft.formData.addresses.permanent.city.value = "";
        draft.formData.addresses.permanent.locality.value = "";
        draft.formData.addresses.permanent.ward_number.value = "";
        draft.formData.addresses.permanent.block_number.value = "";
        draft.formData.addresses.permanent.phone_number.value = "";
        draft.formData.addresses.permanent.country.touched = false;
        draft.formData.addresses.permanent.province.touched = false;
        draft.formData.addresses.permanent.district.touched = false;
        draft.formData.addresses.permanent.municipality.touched = false;
        draft.formData.addresses.permanent.city.touched = false;
        draft.formData.addresses.permanent.locality.touched = false;
        draft.formData.addresses.permanent.ward_number.touched = false;
        draft.formData.addresses.permanent.block_number.touched = false;
        draft.formData.addresses.permanent.phone_number.touched = false;
        return;
      case "validateForm":
        //check for errors
        console.log("handle form reducer thit at gurdfains");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (draft.formData[key].value == "") {
            console.log("there is an errror");
            draft.errorCount++;
            console.log(draft.formData[key].name);
            console.log(draft.errorCount);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
        }
        //check for errors in address section
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].value == "") {
            console.log("there is an errror in temporary address section");
            draft.errorCount++;
            console.log(draft.formData.addresses.temporary[key].name);
            console.log(draft.errorCount);
            draft.formData.addresses.temporary[key].hasErrors = true;
            draft.formData.addresses.temporary[key].message =
              "This field is required";
          }
        }
        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].value == "") {
            console.log("there is an errror in permanent address section");
            draft.errorCount++;
            console.log(draft.formData.addresses.permanent[key].name);
            console.log(draft.errorCount);
            draft.formData.addresses.permanent[key].hasErrors = true;
            draft.formData.addresses.permanent[key].message =
              "This field is required";
          }
        }
        //check for errors in the documents sectoin
        for (const key in draft.formData.documents) {
          console.log(
            "the loop now runs for the documents seciton foo the guardian"
          );
          if (draft.formData.documents[key].value == "") {
            draft.errorCount++;
            draft.formData.documents[key].hasErrors = true;
            draft.formData.documents[key].message = "This document is required";
          }
        }
        // Exception
        if (draft.formData.addresses.permanent.city.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.city.hasErrors = false;
        }
        if (draft.formData.addresses.permanent.block_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.block_number.hasErrors = false;
        }
        if (draft.formData.addresses.permanent.phone_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.permanent.phone_number.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.city.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.city.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.block_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.block_number.hasErrors = false;
        }
        if (draft.formData.addresses.temporary.phone_number.value === "") {
          draft.errorCount--;
          draft.formData.addresses.temporary.phone_number.hasErrors = false;
        }
        if (draft.formData.pan.value === "") {
          draft.errorCount--;
          draft.formData.pan.hasErrors = false;
        }

        //dispatch the state if error count is o0
        //check which elemnt has Error
        if (!draft.errorCount) {
          draft.sendCount++;
        }

        return;
      case "saveSubmitCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  function handleCheck(e) {
    console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({
      type: "inputChange",
      value: bsDate,
      field: "idIssueDateBs",
    });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    dispatch({
      type: "idIssueDateChange",
      value: date,
      field: "idIssueDate",
    });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }
  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  // PAN Check
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.formData.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.formData.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          console.log(response.data);
          if (response.data.success === true) {
            dispatch({ type: "panAvailable" });
          } else {
            dispatch({ type: "panUnavailable" });
          }
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }

    return () => ourRequest.cancel();
  }, [state.formData.pan.checkCount]);

  useEffect(() => {
    if (state.sendCount) {
      console.log("form dispatch form the nominee info here");
      formDispatch({
        type: "saveNominee",
        value: {
          type: "2",
          full_name: state.formData.nameOfGuardian.value,
          email: state.formData.email.value,
          relation: state.formData.relationshipWithApplicant.value,
          father_name: state.formData.fathersName.value,
          grand_father_name: state.formData.grandFathersName.value,
          mobile: state.formData.mobile.value,
          addresses: [
            {
              type: "1",
              block_number:
                state.formData.addresses.temporary.block_number.value,
              phone_number:
                state.formData.addresses.temporary.phone_number.value,
              ward_number: state.formData.addresses.temporary.ward_number.value,
              locality: state.formData.addresses.temporary.locality.value,
              municipality:
                state.formData.addresses.temporary.municipality.value,
              district: state.formData.addresses.temporary.district.value,
              province: state.formData.addresses.temporary.province.value,
              country: state.formData.addresses.temporary.country.value,
              city: state.formData.addresses.temporary.city.value,
            },
            {
              type: "2",
              block_number:
                state.formData.addresses.permanent.block_number.value,
              phone_number:
                state.formData.addresses.permanent.phone_number.value,
              ward_number: state.formData.addresses.permanent.ward_number.value,
              locality: state.formData.addresses.permanent.locality.value,
              municipality:
                state.formData.addresses.permanent.municipality.value,
              district: state.formData.addresses.permanent.district.value,
              province: state.formData.addresses.permanent.province.value,
              country: state.formData.addresses.permanent.country.value,
              city: state.formData.addresses.permanent.city.value,
            },
          ],
          personal_informations: {
            identity_card_type: state.formData.typeOfIdCard.value,
            identity_card_number: state.formData.idNo.value,
            identity_card_issue_district: state.formData.idIssueDistrict.value,
            identity_card_issue_date: state.formData.idIssueDate.dateFormatted,
            pan_number: "",
          },
          documents: {
            photo: state.formData.documents.displayPicture.value,
            signature: state.formData.documents.signature.value,
            gov_issued_id_front: state.formData.documents.citizenshipBack.value,
            gov_issued_id_back: state.formData.documents.citizenshipFront.value,
          },
        },
      });
      formDispatch({
        type: "saveNomineeDocumentsFile",
        value: {
          nominee_photo_file: state.formData.documents.displayPicture.file,
          nominee_gov_issued_id_front_file:
            state.formData.documents.citizenshipFront.file,
          nominee_gov_issued_id_back_file:
            state.formData.documents.citizenshipBack.file,
          nominee_signature_file: state.formData.documents.signature.file,
        },
      });
    }
  }, [state.sendCount]);

  function setDisplayPicture(e) {
    dispatch({ type: "setDisplayPicture", value: e });
  }
  function setDisplayPictureFile(e) {
    dispatch({ type: "setDisplayPictureFile", value: e });
  }
  function setCitizenshipBack(e) {
    dispatch({ type: "setCitizenshipBack", value: e });
  }
  function setCitizenshipBackFile(e) {
    console.log(e);
    dispatch({ type: "setCitizenshipBackFile", value: e });
  }
  function setCitizenshipFront(e) {
    dispatch({ type: "setCitizenshipFront", value: e });
  }
  function setCitizenshipFrontFile(e) {
    dispatch({ type: "setCitizenshipFrontFile", value: e });
  }
  function setSignature(e) {
    dispatch({ type: "setSignature", value: e });
  }
  function setSignatureFile(e) {
    dispatch({ type: "setSignatureFile", value: e });
  }

  useEffect(() => {
    if (formState.validationCheck > 0) {
      dispatch({ type: "validateForm" });
    }
  }, [formState.validationCheck]);

  useEffect(() => {
    dispatch({ type: "saveSubmitCount" });
  }, [state.formData]);

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-name_of_guardian"
            >
              Name of Nominee
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.nameOfGuardian.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-name_of_guardian"
              type="text"
              placeholder={` ${
                state.formData.nameOfGuardian.hasErrors
                  ? state.formData.nameOfGuardian.message
                  : "Nominee's Full Name"
              } `}
              required
              value={state.formData.nameOfGuardian.value}
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "nameOfGuardian",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "nameOfGuardian",
                })
              }
            />
            {/* {state.formData.nameOfGuardian.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.nameOfGuardian.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guaridan-relationship_with_applicant"
            >
              Relationship with applicant
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.relationshipWithApplicant.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guaridan-relationship_with_applicant"
              placeholder={` ${
                state.formData.relationshipWithApplicant.hasErrors
                  ? state.formData.relationshipWithApplicant.message
                  : "Relationship with applicant"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "relationshipWithApplicant",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "relationshipWithApplicant",
                })
              }
              value={state.formData.relationshipWithApplicant.value}
            />
            {/* {state.formData.relationshipWithApplicant.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.relationshipWithApplicant.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-fathers_name"
            >
              Father's Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.fathersName.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-fathers_name"
              type="text"
              placeholder={` ${
                state.formData.fathersName.hasErrors
                  ? state.formData.fathersName.message
                  : "Father's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "fathersName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "fathersName",
                })
              }
              value={state.formData.fathersName.value}
            />
            {/* {state.formData.fathersName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.fathersName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guaridan-grandFathers_name"
            >
              GrandFather's Name
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.grandFathersName.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guaridan-grandFathers_name"
              placeholder={` ${
                state.formData.grandFathersName.hasErrors
                  ? state.formData.grandFathersName.message
                  : "GrandFather's Name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "grandFathersName",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "grandFathersName",
                })
              }
              value={state.formData.grandFathersName.value}
            />
            {/* {state.formData.grandFathersName.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.grandFathersName.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-information-mobile"
            >
              Mobile
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.mobile.hasErrors && "is-invalid border-danger"
              }`}
              id="guardian-information-mobile"
              type="number"
              placeholder={` ${
                state.formData.mobile.hasErrors
                  ? state.formData.mobile.message
                  : "Nominee's 10 digit phone number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "mobile",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "mobile",
                })
              }
              value={state.formData.mobile.value}
            />
            {state.formData.mobile.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.mobile.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guaridan-personal_information-email"
            >
              Email
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.email.hasErrors && "is-invalid border-danger"
              }`}
              type="email"
              id="guaridan-personal_information-email"
              placeholder={` ${
                state.formData.email.hasErrors
                  ? state.formData.email.message
                  : "Nominee's email address"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "email",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "email",
                })
              }
              value={state.formData.email.value}
            />
            {state.formData.email.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.email.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="personal_information-type_of_identity_card"
            >
              Type of Identitiy card
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.typeOfIdCard.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="personal_information-type_of_identity_card"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "typeOfIdCard",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "typeOfIdCard",
                })
              }
              value={state.formData.typeOfIdCard.value}
            >
              <option value="" className="">
                Choose Nominee's type of ID
              </option>
              <option value="1">Citizenship</option>
            </select>
            {/* {state.formData.typeOfIdCard.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.typeOfIdCard.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="personal_information-identification_number"
            >
              Identification Number
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.idNo.hasErrors && "is-invalid border-danger"
              }`}
              id="personal_information-identification_number"
              placeholder={` ${
                state.formData.idNo.hasErrors
                  ? state.formData.idNo.message
                  : "Enter Identification Number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "idNo",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "idNo",
                })
              }
              value={state.formData.idNo.value}
            />
            {/* {state.formData.idNo.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.idNo.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="personal_information-id_issued_district"
            >
              ID Issued District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.idIssueDistrict.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="personal_information-id_issued_district"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "inputChange",
                  value: e.target.value,
                  field: "idIssueDistrict",
                })
              }
              onBlur={(e) =>
                dispatch({
                  type: "inputBlur",
                  value: e.target.value,
                  field: "idIssueDistrict",
                })
              }
              value={state.formData.idIssueDistrict.value}
            >
              {districts.map((item, i) => {
                return (
                  <option value={item.name} key={i}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.formData.idIssueDistrict.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.idIssueDistrict.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-id_issue_date_bs"
            >
              ID issue Date(B.S)
            </label>
            <span className="text-danger">*</span>
            <section className="diy-calendar-section">
              <input
                value={state.formData.idIssueDateBs.value}
                type="text"
                className={`form-control-alternative calendar ${
                  state.formData.idIssueDateBs.hasErrors &&
                  "is-invalid border-danger"
                }`}
              />
              <div className="diy-calendar">
                <Calendar
                  className={`form-control-alternative calendar ${
                    state.formData.idIssueDateBs.hasErrors &&
                    "is-invalid border-danger"
                  }`}
                  theme="deepdark"
                  onChange={handleIdIssueDateBs}
                  value={state.formData.idIssueDateBs.value}
                />
              </div>
            </section>
            {/* {state.formData.idIssueDateBs.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.idIssueDateBs.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-id_issued_date_ad"
            >
              ID Issue Date(A.D)
            </label>
            <span className="text-danger">*</span>
            {/* <Input
              className={`form-control-alternative calendar`}
              id="guardian-id_issued_date_ad"
              type="text"
              onChange={handleIdIssueDate}
              value={state.formData.idIssueDate.value}
              disabled
            /> */}
            <DatePicker
              className={`form-control-alternative calendar`}
              onChange={handleIdIssueDate}
              selected={state.formData.idIssueDate.value}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {state.formData.idIssueDate.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.idIssueDate.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="guardian-pan">
              Permanent Account Number(PAN)
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.pan.hasErrors && "is-invalid border-danger"
              }`}
              id="guardian-pan"
              type="text"
              placeholder={` ${
                state.formData.pan.hasErrors
                  ? state.formData.pan.message
                  : "Enter Nominee's Account Number"
              } `}
              onBlur={(e) =>
                dispatch({
                  type: "validatePAN",
                  value: e.target.value,
                })
              }
              onChange={(e) =>
                dispatch({
                  type: "panChange",
                  value: e.target.value,
                  field: "pan",
                })
              }
              value={state.formData.pan.value}
            />
            {state.formData.pan.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.pan.message}
              </Alert>
            )}
            {state.formData.pan.checkingNow && (
              <Alert
                className={`mt-4 ${
                  state.formData.pan.hasErrors
                    ? "alert-danger"
                    : "alert-success"
                }`}
              >
                {state.formData.pan.message}
              </Alert>
            )}
          </FormGroup>
        </Col>
      </Row>
      <h3 className="text-primary">Nominee's Current Address</h3>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="guardian-country">
              Country
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.temporary.country.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-country"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "country",
                })
              }
              value={state.formData.addresses.temporary.country.value}
            >
              {country.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.formData.addresses.temporary.country.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.country.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="guardian-province">
              Province
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.temporary.province.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-province"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "province",
                })
              }
              value={state.formData.addresses.temporary.province.value}
            >
              {state.formData.addresses.temporary.province.list.map(
                (item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
            {/* {state.formData.addresses.temporary.province.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.province.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-temporary_district"
            >
              District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.temporary.district.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-temporary_district"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "district",
                })
              }
              value={state.formData.addresses.temporary.district.value}
            >
              {state.formData.addresses.temporary.district.listToShow.map(
                (item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="guardian-city">
              City
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.temporary.city.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-city"
              type="text"
              placeholder={` ${
                state.formData.addresses.temporary.city.hasErrors
                  ? state.formData.addresses.temporary.city.message
                  : "Nominee's City Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "city",
                })
              }
              value={state.formData.addresses.temporary.city.value}
            />
            {/* {state.formData.addresses.temporary.city.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.city.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-temporary_municipality"
            >
              Municipality
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.temporary.municipality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-temporary_municipality"
              type="text"
              placeholder={` ${
                state.formData.addresses.temporary.municipality.hasErrors
                  ? state.formData.addresses.temporary.municipality.message
                  : "Nominee's Municipality / VDC name"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "municipality",
                })
              }
              value={state.formData.addresses.temporary.municipality.value}
            >
              {state.formData.addresses.temporary.municipality.listToShow.map(
                (item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
            {/* {state.formData.addresses.temporary.municipality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.municipality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-locality_tole"
            >
              Locality/Tole
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.temporary.locality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-locality_tole"
              type="text"
              placeholder={` ${
                state.formData.addresses.temporary.locality.hasErrors
                  ? state.formData.addresses.temporary.locality.message
                  : "Enter Nominee's street address"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "locality",
                })
              }
              value={state.formData.addresses.temporary.locality.value}
            />
            {/* {state.formData.addresses.temporary.locality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.locality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-temporary_ward_no"
            >
              Ward No
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.temporary.ward_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-temporary_ward_no"
              type="text"
              placeholder={` ${
                state.formData.addresses.temporary.ward_number.hasErrors
                  ? state.formData.addresses.temporary.ward_number.message
                  : "Enter Nominee's ward number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "ward_number",
                })
              }
              value={state.formData.addresses.temporary.ward_number.value}
            />
            {/* {state.formData.addresses.temporary.ward_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.ward_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-temporary_block_no"
            >
              Block No
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.temporary.block_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-temporary_block_no"
              type="text"
              placeholder={` ${
                state.formData.addresses.temporary.block_number.hasErrors
                  ? state.formData.addresses.temporary.block_number.message
                  : "Enter Nominee's block number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: "block_number",
                })
              }
              value={state.formData.addresses.temporary.block_number.value}
            />
            {/* {state.formData.addresses.temporary.block_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.block_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-temporary_telephone_number"
            >
              Telephone Number
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.temporary.phone_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-temporary_telephone_number"
              type="number"
              placeholder={` ${
                state.formData.addresses.temporary.phone_number.hasErrors
                  ? state.formData.addresses.temporary.phone_number.message
                  : "Enter Nominee's phones number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "temporaryInputChange",
                  value: e.target.value,
                  field: " phone_number",
                })
              }
              value={state.formData.addresses.temporary.phone_number.value}
            />
            {/* {state.formData.addresses.temporary.phone_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.temporary.phone_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <h3 className="text-primary">Nominee's Permanent Address</h3>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Input
              className="form-control-alternative alert-primary ml-1"
              type="checkbox"
              id="nominee-permanent-address"
              onClick={handleCheck}
            />
            <label
              className="form-control-label"
              htmlFor="nominee-permanent-address"
              style={{ marginLeft: "3rem" }}
            >
              Same as Current Address
            </label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_country"
            >
              Country
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.permanent.country.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_country"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "country",
                })
              }
              value={state.formData.addresses.permanent.country.value}
            >
              {country.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {/* {state.formData.addresses.permanent.country.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.country.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_province"
            >
              Province
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.permanent.province.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_province"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "province",
                })
              }
              value={state.formData.addresses.permanent.province.value}
            >
              {state.formData.addresses.permanent.province.list.map(
                (item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
            {/* {state.formData.addresses.permanent.province.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.province.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_district"
            >
              District
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.permanent.district.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_district"
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "district",
                })
              }
              value={state.formData.addresses.permanent.district.value}
            >
              {state.formData.addresses.permanent.district.listToShow.map(
                (item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_city"
            >
              City
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.permanent.city.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_city"
              type="text"
              placeholder={` ${
                state.formData.addresses.permanent.city.hasErrors
                  ? state.formData.addresses.permanent.city.message
                  : "Nominee's City Name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "city",
                })
              }
              value={state.formData.addresses.permanent.city.value}
            />
            {/* {state.formData.addresses.permanent.city.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.city.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_municipality"
            >
              Municipality
            </label>
            <span className="text-danger">*</span>
            <select
              className={`form-control-alternative calendar ${
                state.formData.addresses.permanent.municipality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_municipality"
              type="text"
              placeholder={` ${
                state.formData.addresses.permanent.municipality.hasErrors
                  ? state.formData.addresses.permanent.municipality.message
                  : "Nominee's Municipality / VDC name"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "municipality",
                })
              }
              value={state.formData.addresses.permanent.municipality.value}
            >
              {state.formData.addresses.permanent.municipality.listToShow.map(
                (item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                }
              )}
            </select>
            {/* {state.formData.addresses.permanent.municipality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.municipality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_locality_tole"
            >
              Locality/Tole
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.permanent.locality.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_locality_tole"
              type="text"
              placeholder={` ${
                state.formData.addresses.permanent.locality.hasErrors
                  ? state.formData.addresses.permanent.locality.message
                  : "Enter Nominee's street address"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "locality",
                })
              }
              value={state.formData.addresses.permanent.locality.value}
            />
            {/* {state.formData.addresses.permanent.locality.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.locality.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_ward_no"
            >
              Ward No
            </label>
            <span className="text-danger">*</span>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.permanent.ward_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_ward_no"
              type="text"
              placeholder={` ${
                state.formData.addresses.permanent.ward_number.hasErrors
                  ? state.formData.addresses.permanent.ward_number.message
                  : "Enter Nominee's ward number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "ward_number",
                })
              }
              value={state.formData.addresses.permanent.ward_number.value}
            />
            {/* {state.formData.addresses.permanent.ward_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.ward_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_block_no"
            >
              Block No
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.permanent.block_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_block_no"
              type="text"
              placeholder={` ${
                state.formData.addresses.permanent.block_number.hasErrors
                  ? state.formData.addresses.permanent.block_number.message
                  : "Enter Nominee's block number"
              } `}
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: "block_number",
                })
              }
              value={state.formData.addresses.permanent.block_number.value}
            />
            {/* {state.formData.addresses.permanent.block_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.block_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="guardian-permanent_telephone_number"
            >
              Telephone Number
            </label>
            <Input
              className={`form-control-alternative ${
                state.formData.addresses.permanent.phone_number.hasErrors &&
                "is-invalid border-danger"
              }`}
              id="guardian-permanent_telephone_number"
              type="number"
              placeholder={` ${
                state.formData.addresses.permanent.phone_number.hasErrors
                  ? state.formData.addresses.permanent.phone_number.message
                  : "Enter Nominee's phones number"
              } `}
              required
              onChange={(e) =>
                dispatch({
                  type: "permanentInputChange",
                  value: e.target.value,
                  field: " phone_number",
                })
              }
              value={state.formData.addresses.permanent.phone_number.value}
            />
            {/* {state.formData.addresses.permanent.phone_number.hasErrors && (
              <Alert className="alert-danger mt-4">
                {state.formData.addresses.permanent.phone_number.message}
              </Alert>
            )} */}
          </FormGroup>
        </Col>
      </Row>
      <h3 className="text-primary">
        Upload Nominee's documents <span className="text-danger">*</span>
      </h3>
      <Row className="mb-5">
        <Col lg="6">
          <Card>
            <CardHeader>
              <p className="lead">Nominee's PP size photo</p>
            </CardHeader>
            <CardBody>
              <DisplayPicture
                setDisplayPicture={setDisplayPicture}
                setDisplayPictureFile={setDisplayPictureFile}
                setError={state.formData.documents.displayPicture.hasErrors}
                setErrorCount={setErrorCount}
              />
              {state.formData.documents.displayPicture.hasErrors && (
                <p className="text-danger">
                  {state.formData.documents.displayPicture.message}
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <CardHeader>
              <p className="lead">Nominee's Citizenship card front</p>
            </CardHeader>
            <CardBody>
              <CitizenshipFront
                setCitizenshipFront={setCitizenshipFront}
                setCitizenshipFrontFile={setCitizenshipFrontFile}
                setError={state.formData.documents.citizenshipFront.hasErrors}
                setErrorCount={setErrorCount}
              />
              {state.formData.documents.citizenshipFront.hasErrors && (
                <p className="text-danger">
                  {state.formData.documents.citizenshipFront.message}
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg="6">
          <div className="card">
            <div className="card-header">
              <p className="lead">Nominee's Citizenship card back</p>
            </div>
            <div className="card-body">
              <CitizenshipBack
                setCitizenshipBack={setCitizenshipBack}
                setCitizenshipBackFile={setCitizenshipBackFile}
                setError={state.formData.documents.citizenshipBack.hasErrors}
                setErrorCount={setErrorCount}
              />
              {state.formData.documents.citizenshipBack.hasErrors && (
                <p className="text-danger">
                  {state.formData.documents.citizenshipBack.message}
                </p>
              )}
            </div>
          </div>
        </Col>
        <Col lg="6">
          <Card>
            <CardHeader>
              <p className="lead">Nominee's Signature</p>
            </CardHeader>
            <CardBody>
              <Signature
                setSignature={setSignature}
                setSignatureFile={setSignatureFile}
                setError={state.formData.documents.signature.hasErrors}
                setErrorCount={setErrorCount}
              />
              {state.formData.documents.signature.hasErrors && (
                <p className="text-danger">
                  {state.formData.documents.signature.message}
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Nominee;
