import React, { useState, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import DematMap from "../../../../../Handler/DematMap";

const Page3 = ({ data, nomineeData, nominee }) => {
  const [personalInformations, setPersonalInformations] = useState({});
  const [permanentAddress, setPermanentAddress] = useState({});
  const [temporaryAddress, setTemporaryAddress] = useState({});
  const [documents, setDocuments] = useState({});

  const [age, setAge] = useState(null);

  const initialState = {
    longitude: 85.3420486,
    latitude: 27.6915196,
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "setLong":
        draft.longitude = action.value;
        return;
      case "setLat":
        draft.latitude = action.value;
        return;
      case "default":
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    nomineeData?.personal_informations?.map((item) => {
      setPersonalInformations(item);
    });
    data?.documents?.map((item) => {
      setDocuments(item);
    });
    nomineeData?.addresses?.map((item) => {
      if (item.type === "permanent") {
        setPermanentAddress(item);
      }
      if (item.type === "temporary") {
        setTemporaryAddress(item);
      }
    });
  }, [nomineeData]);

  useEffect(() => {
    const date = new Date(personalInformations.dob);
    var today = new Date();
    var age_now = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age_now--;
    }
    setAge(age_now);
  }, [data]);

  useEffect(() => {
    dispatch({ type: "setLong", value: documents.long });
    dispatch({ type: "setLat", value: documents.lat });
  }, [documents]);

  return (
    <div className="page">
      <div className="form-row">
        <div className="col">
          <h4>बैंक खाता विवरण (Bank Account Details)</h4>
        </div>
      </div>
      <div className="form-row">
        <div className="col no-border">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <label htmlFor="bankAcType">
                <p>बैंक खाताको किसिम (Type of Bank Account)</p>
              </label>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="no-border">
                <input
                  type="radio"
                  name="bank-ac-type"
                  id="bank-ac-type-saving"
                  checked={
                    data?.accounts[0]?.type === "saving" ? "checked" : ""
                  }
                />
                <label htmlFor="bank-ac-type-yes">
                  <p> बचत खाता (Saving Account)</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="no-border">
                <input
                  type="radio"
                  name="bank-ac-type"
                  id="bank-ac-type-current"
                  checked={
                    data?.accounts[0]?.type === "current" ? "checked" : ""
                  }
                />
                <label htmlFor="bank-ac-type-yes">
                  <p>चल्ती खाता (Current Account)</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-4 no-border">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="bankAcNumber">
                  <p> बैंक खाता नंम्बर (Bank Account Number)</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="bankAcNumber"
                readOnly
                defaultValue={data?.accounts[0]?.number}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-4 no-border">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="bankName">
                  <p>खाता रहेको बैंकको नाम (Name of Bank) </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="bankName"
                readOnly
                defaultValue={data?.accounts[0]?.bank_name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mb-3">
        <div className="col col-4 no-border">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="branchName">
                  <p>बैंक शाखाको नाम (Name of Branch)</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="branchName"
                readOnly
                defaultValue={data?.accounts[0]?.branch_name}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <h4>ईछ्याको व्यक्ति सम्बन्धि विवरण (Nominee's Detials):</h4>
          <h5>
            मेरो मृत्यु भएको अवस्था वा मैले नसकेको अवस्थामा देहायको व्यक्तिले
            मेरो नाम मा भएको सम्पूर्ण धितोपत्र हकदाबी गर्न पाउने छ। In the event
            of my death or incapacity the following named nominee shall be
            entitled to the balance of my demat account.
          </h5>
        </div>
      </div>
      <div className="form-row">
        <div className="col-2 col">
          <div className="border-wrapper ">
            <div className="padding-wrapper">
              <p>हकदाबी गर्नेको नाम</p>
              <p>Name of Nominee</p>
            </div>
          </div>
        </div>
        <div className="col-2 col">
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <span className="d-flex">
                <span className="left w-40 pr-2">
                  <p>नेपालीमा </p>
                </span>
                <div className="right w-60 bl-1 pl-2">
                  <p>
                    श्री /श्रीमती
                    <br />
                    सुश्री /नाबालक
                  </p>
                </div>
              </span>
            </div>
          </div>
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <span className="d-flex">
                <span className="left w-40 pr-2">
                  <p>In English</p>
                </span>
                <div className="right w-60 bl-1 pl-2">
                  <p>
                    Mr/Mrs <br /> Miss/Minor
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                name="nominee-name-nepali"
                id="nominee-name-nepali"
                className="form-input w-100"
                readOnly
              />
            </div>
          </div>
          <div className="border-wrapper h-50">
            <div className="padding-wrapper">
              <input
                type="text"
                name="nominee-name-english"
                id="nominee-name-english"
                className="form-input w-100"
                defaultValue={nomineeData?.full_name}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>निवेदक संगको सम्बन्ध </p>
              <p>Relationship with Applicant</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="relWithApplicant"
                  readOnly
                  defaultValue={nomineeData?.relation}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>नागरिकता /राहदानी /जन्म दर्ता नं </p>
              <p>Citizenship/Passport/Birth certificate No.</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="number"
                  className="form-input w-100"
                  id="NomineeCertificateNo"
                  readOnly
                  defaultValue={personalInformations?.identity_card_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>जारि ठाउँ </p>
              <p>Place of Issue.</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineePlaceOfIssue"
                  readOnly
                  defaultValue={
                    personalInformations?.identity_card_issue_district
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>उमेर </p>
              <p>Age</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="number"
                  className="form-input w-100"
                  id="nomineeAge"
                  readOnly
                  // defaultValue={age && age}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>बुबाको नाम</p>
              <p>Father's Name</p>
            </div>
          </div>
        </div>
        <div className="col col-4">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeFather"
                  readOnly
                  defaultValue={nomineeData?.father_name}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>बाजेको नाम</p>
              <p>Grandather's Name</p>
            </div>
          </div>
        </div>
        <div className="col col-4">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeGrandFather"
                  readOnly
                  defaultValue={nomineeData?.grand_father_name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>राष्ट्र </p>
              <p>Nation</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeNation"
                  readOnly
                  defaultValue={nomineeData?.father_name}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>अञ्चल </p>
              <p>Zone</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeZone"
                  readOnly
                  defaultValue={permanentAddress?.country}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>जिल्ला </p>
              <p>District</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeDistrict"
                  readOnly
                  defaultValue={permanentAddress?.district}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>गा.बि.स/न.पा/म.न.पा</p>
              <p>VDC/Muncipality/Meropolitan</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeVDC/Muncipality/Meropolitan"
                  readOnly
                  defaultValue={permanentAddress?.municipality}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>टोल </p>
              <p>Locality</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeLocality"
                  readOnly
                  defaultValue={permanentAddress?.locality}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>वडा नं </p>
              <p>Ward No.</p>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="number"
                  className="form-input w-100"
                  id="nomineeWardNo"
                  readOnly
                  defaultValue={permanentAddress?.ward_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>ब्लक नं</p>
              <p>Block No.</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-input w-100"
                  id="nomineeBlockNo"
                  readOnly
                  defaultValue={permanentAddress?.block_number}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>पत्राचार ठेगाना</p>
              <p>Correspondance Address.</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="nomineeCorrespondanceAdd"
                readOnly
                defaultValue={temporaryAddress?.district}
              />
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>फोन नं</p>
              <p>Telephone No.</p>
            </div>
          </div>
        </div>
        <div className="col col-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="nomineeTelephone"
                readOnly
                defaultValue={permanentAddress?.phone_number}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>फ्याक्स नं </p>
              <p>Fax No.</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="nomineeFax"
              />
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>मोबाइल नं</p>
              <p>Mobile No.</p>
            </div>
          </div>
        </div>
        <div className="col col-2">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="nomineeMobile"
                readOnly
                defaultValue={nomineeData?.mobile}
              />
            </div>
          </div>
        </div>
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>ईमेल </p>
              <p>Email ID.</p>
            </div>
          </div>
        </div>
        <div className="col col-5">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="text"
                className="form-input w-100"
                id="nomineeEmail"
                readOnly
                defaultValue={nomineeData?.email}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-1">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <p>स्थायी लेखा नं</p>
              <p>PAN NO.</p>
            </div>
          </div>
        </div>
        <div className="col ">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input
                type="number"
                className="form-input w-100"
                id="nomineePAN"
                readOnly
                defaultValue={personalInformations?.pan}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <div className="image-container signature-container bb-1">
            <img
              src={
                `${process.env.REACT_APP_BASE_URL}/img/` +
                data.email +
                "/nominee/" +
                nominee.signature_name
              }
              alt=""
              style={{ height: "100px", width: "300px" }}
            />
          </div>
          <div className="text-box text-center mb-3">
            <p>Signature of Nominee</p>
          </div>
          <div className="thumb-print my-3 mt-3">
            <p>औठा छाप / Thumb Print</p>
            <div className="thumb-print-container">
              <div className="left">
                <div className="caption">
                  <p>दाया / Right</p>
                </div>
                <div className="image-container">
                  <img src="" alt="" />
                </div>
              </div>
              <div className="right">
                <div className="caption">
                  <p>बाया / left</p>
                </div>
                <div className="image-container">
                  <img src="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-9 my-3">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="text-box text-box-top text-center">
                <p>Site Map of the Account Holder's Residence</p>
              </div>
              <div className="map-container">
                {/* <img src="" alt="" /> */}
                <DematMap setLng={state.longitude} setLat={state.latitude} />
              </div>
              <div className="text-box text-box-bottom text-center w-80 mx-auto my-3">
                <p>
                  From main road street <span>________</span> the distance of
                  residence is
                  <span>________</span> meters(approx.).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-break"></div>
      <div className="form-row">
        <div className="col col-3">
          <h4>Benefical Owner Copy</h4>
        </div>
        <div className="col col-9">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <div className="input-group">
                <label htmlFor="beneficalACNo">
                  <p>हितग्राहिको खाता नं</p>
                  <p>Beneficial Account No.</p>
                </label>
                <input type="text" className="form-input" id="beneficalACNo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row my-3">
        <div className="col col-1">
          <p>नाम</p>
          <p>Name:</p>
        </div>
        <div className="col col-11">
          <div className="border-wrapper">
            <div className="padding-wrapper">
              <input type="text" className="form-input w-100" readonly />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col col-3">
          <p>निछेप सदस्य - गोर्खाज फाईन्यान्स लि :</p>
        </div>
        <div className="col col-9">
          <div className="padding-wrapper">
            <input type="text" className="form-input w-100 bb-1" readonly />
          </div>
        </div>
      </div>
      <div className="form-row justify-content-center align-items-center">
        <div className="col-2">
          <div className="padding-wrapper">
            <p>दस्तखत:</p>
          </div>
        </div>
        <div className="col-3">
          <div className="signature-container">
            <img src="" alt="" />
          </div>
        </div>
        <div className="col-2">
          <div className="padding-wrapper">
            <p>कम्पनी छाप:</p>
          </div>
        </div>
        <div className="col-3">
          <div className="signature-container">
            <img src="" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page3;
