import React, { useState, useContext } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import DispatchContext from "../../../DispatchContext";
import StateContext from "../../../StateContext";
import logo from "../../../Assets/images/logo.png";
import Avatar from "../../../Assets/images/avatar-small.jpg";
import { Monitor, Search } from "../../Layout/Icons";

function Sidebar(props) {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const [collapseOpen, setCollapseOpen] = useState();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  function handleLogout(e) {
    e.preventDefault();
    appDispatch({ type: "clientLogout" });
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0">
            <img alt="logo" className="navbar-brand-img" src={logo} />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={Avatar} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem
                to={`/dashboard/customers/profile/${props.id}`}
                tag={Link}
              >
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem
                to={`/dashboard/customers/settings/${props.id}`}
                tag={Link}
              >
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo && (
                    <Link to="/admin/dashboard">
                      <img alt="Gurkhas Finance" src={logo} />
                    </Link>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Search className="" icon />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>
            <NavItem>
              <NavLink
                to="/dashboard"
                onClick={closeCollapse}
                tag={NavLinkRRD}
                activeClassName="active"
              >
                <Monitor />
                Dashboard
              </NavLink>
            </NavItem>
          </Nav>
          {/* Divider */}
          <hr className="my-3" />
          <Card className="shadow py-3 my-4">
            <CardHeader className="border-0">
              <div className="d-flex justify-content-center align-items-center">
                <span class="avatar avatar-xl rounded-circle">
                  <img
                    alt="Gurkhas Finance Ltd."
                    src={
                      props.guardian !== false
                        ? Avatar
                        : `${process.env.REACT_APP_BASE_URL}/img/` +
                          props.email +
                          "/" +
                          props.data?.documents[0]?.photo_name
                    }
                  />
                </span>
              </div>
            </CardHeader>
            <CardBody>
              <Media className="d-block text-center">
                <span className="mb-0 text-sm font-weight-bold">
                  {appState.client.data.user?.name}
                </span>
                <br />
                <span className="text-sm text-light font-weight-bold">
                  Your DEMAT number:
                </span>
                <h3 className="text-primary">
                  {props.boid ? props.boid : "........ ........"}
                </h3>
              </Media>
            </CardBody>
          </Card>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Sidebar;
