import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import OTPInput from "otp-input-react";

//CONTEXTS
import FormState from "../../FormState";
import FormDispatch from "../../FormDispatch";
import { Refresh } from "../../../Layout/Icons";

const Verification = (props) => {
  const [OTP, setOTP] = useState("");
  const formState = useContext(FormState);
  const [checkCount, setCheckCount] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const formDispatch = useContext(FormDispatch);
  const [pending, setPending] = useState(false);

  function submitHandler(e) {
    e.preventDefault();
    //check if the otp is 6 digits
    if (OTP.toString().length === 6) {
      //increase checkcount
      setCheckCount(checkCount + 1);
      setError(true);
    }
  }

  useEffect(() => {
    console.log("uniqueid", formState.uniqueId);
    console.log(checkCount, "from the useEffect");
    const ourRequest = axios.CancelToken.source();
    if (checkCount >= 1) {
      setPending(true);
      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/validateOTP`,
            { uniqueId: formState.uniqueId, otp: OTP },
            { cancelToken: ourRequest.token }
          );
          console.log(response.data.message);
          if (response.data.success) {
            // setCheckCount(checkCount + 1);
            // props.history.push("/customers/create/createPassword");
            formDispatch({ type: "activeTab", value: "createPassword" });
          }
        } catch (e) {
          console.log(e.response, "there was an error");
          setPending(false);
          setError(true);
          setErrorMsg(e.response?.data?.message);
        }
      }
      register();
    }

    return () => ourRequest.cancel();
  }, [checkCount]);

  return (
    <div className="section verification section-box mx-auto">
      <h3>Enter Verification Code</h3>
      <p>
        A message with verification code has been sent to your email address and
        phone number given below
      </p>
      <div className="text-box">
        <p>
          +977 -
          <span className="number phone_number">
            {formState?.formData.customers.mobile}
          </span>
        </p>
        <p>
          <span className="number phone_number">
            {formState?.formData.customers.email}
          </span>
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <span className="left">
          <p>Verification Code</p>
          {errorMsg && <span className="error mb-2">{errorMsg}</span>}
        </span>
        <span className="right d-flex resend-otp">
          <span className="resend-icon mr-2">
            <Refresh className="icon" />
          </span>
          <span>
            <p>Resend Code </p>
          </span>
        </span>
      </div>
      <form className="otp digit-group" data-group-name="digits">
        <div className="input-group">
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus={1}
            OTPLength={6}
            otpType="number"
            disabled={false}
            className="form-input"
            // secure
          />
        </div>
        <button
          className="btn-blue my-4 zoom-in"
          onClick={submitHandler}
          disabled={pending || OTP === ""}
          style={{ width: "auto" }}
        >
          {pending ? "Verifying . . ." : "Verify"}
          {/* <span> →</span> */}
        </button>
      </form>
      <p className="signIn">
        Already Have an account?
        <Link to="/login" className="text-danger">
          Sign in
        </Link>
      </p>
    </div>
  );
};

export default withRouter(Verification);
