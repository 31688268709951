import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import DispatchContext from "../../DispatchContext";
import ClientLoggedOut from "../ClientLoggedOut";
import Loading from "../Layout/Loading";
import logo from "../../Assets/images/logo.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Eye, Mail, Unlock } from "../Layout/Icons";
import Footer from "../Layout/Footer";
import StateContext from "../../StateContext";

const Verify = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [userData, setUserData] = useState();
  const history = useHistory();
  const location = useLocation();
  const splits = location.search.split("=");
  const id = splits[1];
  console.log(id);

  const initialState = {
    pageData: "",
    isPending: false,
    error: null,
    submitCount: 0,
    password: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
      type: "password",
    },
    confirmPassword: {
      value: "",
      hasErrors: false,
      message: "",
      required: true,
      type: "password",
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "passwordChange":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (draft.password.value.length > 50) {
          draft.password.hasErrors = true;
          draft.password.message = "Password cannot exceed 50 characters";
        }
        return;
      case "passwordAfterDelay":
        if (draft.password.value.length < 1) {
          draft.password.hasErrors = true;
          draft.password.message = "You must provide a password";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/.test(
            draft.password.value
          )
        ) {
          draft.password.hasErrors = true;
          draft.password.message =
            "Password must be more than 8 characters and must contain uppercase, lowercase, special character and number";
        }
        return;
      case "confirmPasswordChange":
        draft.confirmPassword.hasErrors = false;
        draft.confirmPassword.value = action.value;
        return;
      case "confirmPasswordAfterDelay":
        if (draft.password.value !== draft.confirmPassword.value) {
          draft.confirmPassword.hasErrors = true;
          draft.confirmPassword.message = "Passwords do not match";
        }
        return;
      case "togglePassword":
        if (draft.password.type === "password") {
          draft.password.type = "text";
        } else {
          draft.password.type = "password";
        }
        return;
      case "toggleConfirmPassword":
        if (draft.confirmPassword.type === "password") {
          draft.confirmPassword.type = "text";
        } else {
          draft.confirmPassword.type = "password";
        }
        return;
      case "submitForm":
        if (!draft.password.hasErrors & !draft.confirmPassword.hasErrors) {
          draft.submitCount++;
        }
        return;
      case "setError":
        draft.error = action.value;
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      default:
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.password.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "passwordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.password.value]);

  useEffect(() => {
    if (state.confirmPassword.value) {
      const delay = setTimeout(() => {
        dispatch({ type: "confirmPasswordAfterDelay" });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.confirmPassword.value]);

  useEffect(() => {
    if (state.submitCount) {
      const ourRequest = axios.CancelToken.source();
      async function verify() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/password/reset`,
            {
              token: id,
              password: state.password.value,
              password_confirmation: state.confirmPassword.value,
            }
          );
          if (response.data) {
            setUserData(response.data);
            console.log(response.data);
            // appDispatch({ type: "verify", value: response.data.data.email });
            if (response.data.success === true) {
              history.push("/login");
            }
          }
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "setIsPending", value: false });
        }
      }
      verify();
      return () => ourRequest.cancel();
    }
  }, [state.submitCount]);

  function togglePassword() {
    dispatch({ type: "togglePassword" });
  }

  function toggleConfirmPassword() {
    dispatch({ type: "toggleConfirmPassword" });
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "passwordChange", value: state.password.value });
    dispatch({ type: "passwordAfterDelay", value: state.password.value });
    dispatch({
      type: "confirmPasswordChange",
      value: state.confirmPassword.value,
    });
    dispatch({
      type: "confirmPasswordAfterDelay",
      value: state.confirmPassword.value,
    });
    dispatch({ type: "submitForm" });
  }

  return (
    <div className="admin">
      <div className="bg-default">
        <div className="main-content">
          <div className="header py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7"></div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent">
                    <div className="text-center px-5 py-3">
                      <img
                        alt="Gurkhas Finance Ltd"
                        src={logo}
                        style={{
                          height: "80px",
                          width: "260px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </CardHeader>

                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Reset password</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="position-relative">
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <Unlock className="icon" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Enter new password"
                            type={state.password.type}
                            onChange={(e) =>
                              dispatch({
                                type: "passwordChange",
                                value: e.target.value,
                              })
                            }
                            value={state.password.value}
                          />
                          <InputGroupAddon
                            addonType="imepend"
                            role="button"
                            onClick={togglePassword}
                          >
                            <InputGroupText>
                              <Eye className="icon" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {state.password.hasErrors && (
                          <p className="text-danger">
                            {state.password.message}
                          </p>
                        )}
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <Unlock className="icon" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Retype your password"
                            type={state.confirmPassword.type}
                            onChange={(e) =>
                              dispatch({
                                type: "confirmPasswordChange",
                                value: e.target.value,
                              })
                            }
                            value={state.confirmPassword.value}
                          />
                          <InputGroupAddon
                            addonType="imepend"
                            role="button"
                            onClick={toggleConfirmPassword}
                          >
                            <InputGroupText>
                              <Eye className="icon" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {state.confirmPassword.hasErrors && (
                          <p className="text-danger">
                            {state.confirmPassword.message}
                          </p>
                        )}
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={handleSubmit}
                        >
                          {state.isPending ? "Submitting . . ." : "Submit"}
                        </Button>
                        {state.userData && (
                          <Alert className="alert-danger alert-dismissible mt-4">
                            {state.userData.message}
                          </Alert>
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link to="/login" className="text-light">
                      <small>Sign in</small>
                    </Link>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link to="/" className="text-light">
                      <small>Create new account</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Verify;
