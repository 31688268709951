import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";

import {
  Alert,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import provinceData from "../../../Assets/province-district.json";
import FormDispatch from "../FormDispatch";
import country from "../../../Assets/country";

const Address = ({ data }) => {
  const formDispatch = useContext(FormDispatch);
  const intitalState = {
    temporary: {
      block_number: {
        value: data?.addresses[1]?.block_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: data?.addresses[0]?.phone_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: data?.addresses[0]?.ward_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: data?.addresses[0]?.locality,
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: data?.addresses[0]?.municipality,
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: data?.addresses[0]?.city,
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        value: data?.addresses[0]?.district,
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      province: {
        value: data?.addresses[0]?.province,
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: data?.addresses[0]?.country,
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
    permanent: {
      // type: "2",
      block_number: {
        value: data?.addresses[1]?.block_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      phone_number: {
        value: data?.addresses[1]?.phone_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      ward_number: {
        value: data?.addresses[1]?.ward_number,
        hasErrors: false,
        message: "",
        touched: false,
      },
      locality: {
        value: data?.addresses[1]?.locality,
        hasErrors: false,
        message: "",
        touched: false,
      },
      municipality: {
        value: data?.addresses[1]?.municipality,
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      city: {
        value: data?.addresses[1]?.city,
        hasErrors: false,
        message: "",
        touched: false,
      },
      district: {
        value: data?.addresses[1]?.district,
        hasErrors: false,
        message: "",
        list: [],
        listToShow: [],
        touched: false,
      },
      province: {
        value: data?.addresses[1]?.province,
        hasErrors: false,
        message: "",
        list: [],
        touched: false,
      },
      country: {
        value: data?.addresses[1]?.country,
        hasErrors: false,
        message: "",
        touched: false,
      },
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "temporaryCountryChange":
        draft.temporary.country.hasErrors = false;
        draft.temporary.country.value = action.value;
        draft.temporary.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.temporary.province.list.push(key);
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "temporaryProvincechange":
        draft.temporary.province.hasErrors = false;
        draft.temporary.province.value = action.value;
        draft.temporary.province.touched = true;
        //empty distrct listToShow
        draft.temporary.district.listToShow = [];
        //empty distrct value
        draft.temporary.district.value = "";
        //filter the array
        let selectedProvince;
        for (const key in provinceData) {
          selectedProvince = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince.map((item, i) => {
          draft.temporary.district.listToShow.push(item.district);
        });
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          draft.temporary.district.list.push();
          provinceData[key].map((item, i) => {
            // console.log("from the array", item.district)
            draft.temporary.district.list.push(item.district);
            draft.permanent.district.list.push(item.district);
          });
        }
        draft.temporary.district.listToShow = draft.temporary.district.list;
        draft.permanent.district.listToShow = draft.permanent.district.list;
        return;
      case "temporaryDistrictChange":
        draft.temporary.district.hasErrors = false;
        draft.temporary.district.value = action.value;
        draft.temporary.district.touched = true;
        //empty municipality listToShow
        draft.temporary.municipality.listToShow = [];
        //empty municipality value
        draft.temporary.municipality.value = "";
        //filter the array
        let selectedDistrict;
        for (const key in provinceData) {
          selectedDistrict = provinceData[draft.temporary.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.temporary.municipality.list.push(item);
              draft.permanent.municipality.list.push(item);
            });
          });
        }
        draft.temporary.municipality.listToShow =
          draft.temporary.municipality.list;
        draft.permanent.municipality.listToShow =
          draft.permanent.municipality.list;
        return;
      case "temporaryMunicipalityChange":
        draft.temporary.municipality.hasErrors = false;
        draft.temporary.municipality.value = action.value;
        draft.temporary.municipality.touched = true;
        return;
      case "temporaryCityChange":
        draft.temporary.city.hasErrors = false;
        draft.temporary.city.value = action.value;
        draft.temporary.city.touched = true;
        return;
      case "temporaryLocalityChange":
        draft.temporary.locality.hasErrors = false;
        draft.temporary.locality.value = action.value;
        draft.temporary.locality.touched = true;
        return;
      case "temporaryWardChange":
        draft.temporary.ward_number.hasErrors = false;
        draft.temporary.ward_number.value = action.value;
        draft.temporary.ward_number.touched = true;
        return;
      case "temporaryBlockChange":
        draft.temporary.block_number.hasErrors = false;
        draft.temporary.block_number.value = action.value;
        draft.temporary.block_number.touched = true;
        return;
      case "temporaryPhoneChange":
        draft.temporary.phone_number.hasErrors = false;
        draft.temporary.phone_number.value = action.value;
        draft.temporary.phone_number.touched = true;
        return;
      //PERMANENT
      case "permanentCountryChange":
        draft.permanent.country.hasErrors = false;
        draft.permanent.country.value = action.value;
        draft.permanent.country.touched = true;
        return;
      case "renderProvinces":
        console.log("render province reducer hits");
        if (!draft.permanent.province.list.length) {
          for (const key in provinceData) {
            draft.permanent.province.list.push(key);
          }
        }

        return;
      case "permanentProvincechange":
        draft.permanent.province.hasErrors = false;
        draft.permanent.province.value = action.value;
        draft.permanent.province.touched = true;
        //empty distrct listToShow

        draft.permanent.district.listToShow = [];
        //empty distrct value
        draft.permanent.district.value = "";
        //filter the array
        let selectedProvince2;
        for (const key in provinceData) {
          selectedProvince2 = provinceData[action.value];
        }
        //repopulate the listToShow array in the district
        selectedProvince2.map((item, i) => {
          draft.permanent.district.listToShow.push(item.district);
        });
        return;
      case "permanentDistrictChange":
        draft.permanent.district.hasErrors = false;
        draft.permanent.district.value = action.value;
        draft.permanent.district.touched = true;
        //empty municipality listToShow
        draft.permanent.municipality.listToShow = [];
        //empty municipality value
        draft.permanent.municipality.value = "";
        //filter the array
        let selectedDistrict2;
        for (const key in provinceData) {
          selectedDistrict2 = provinceData[draft.permanent.province.value];
        }
        //repopulate the listToShow array in the municipality
        selectedDistrict2.map((item, i) => {
          if (item.district === action.value) {
            item.municipality.map((item, i) => {
              draft.permanent.municipality.listToShow.push(item);
            });
          }
        });
        return;
      case "permanentMunicipalityChange":
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.municipality.value = action.value;
        draft.permanent.municipality.touched = true;
        return;
      case "permanentCityChange":
        draft.permanent.city.hasErrors = false;
        draft.permanent.city.value = action.value;
        draft.permanent.city.touched = true;
        return;
      case "permanentLocalityChange":
        draft.permanent.locality.hasErrors = false;
        draft.permanent.locality.value = action.value;
        draft.permanent.locality.touched = true;
        return;
      case "permanentWardChange":
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.ward_number.value = action.value;
        draft.permanent.ward_number.touched = true;
        return;
      case "permanentBlockChange":
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.block_number.value = action.value;
        draft.permanent.block_number.touched = true;
        return;
      case "permanentPhoneChange":
        draft.permanent.phone_number.hasErrors = false;
        draft.permanent.phone_number.value = action.value;
        draft.permanent.phone_number.touched = true;
        return;
      case "sameAsCurrent":
        draft.permanent.country.value = draft.temporary.country.value;
        draft.permanent.country.touched = true;
        draft.permanent.province.value = draft.temporary.province.value;
        draft.permanent.province.touched = true;
        draft.permanent.district.value = draft.temporary.district.value;
        draft.permanent.district.touched = true;
        draft.permanent.municipality.value = draft.temporary.municipality.value;
        draft.permanent.municipality.touched = true;
        draft.permanent.city.value = draft.temporary.city.value;
        draft.permanent.city.touched = true;
        draft.permanent.locality.value = draft.temporary.locality.value;
        draft.permanent.locality.touched = true;
        draft.permanent.ward_number.value = draft.temporary.ward_number.value;
        draft.permanent.ward_number.touched = true;
        draft.permanent.block_number.value = draft.temporary.block_number.value;
        draft.permanent.block_number.touched = true;
        draft.permanent.phone_number.value = draft.temporary.phone_number.value;
        draft.permanent.phone_number.touched = true;
        draft.permanent.country.hasErrors = false;
        draft.permanent.province.hasErrors = false;
        draft.permanent.district.hasErrors = false;
        draft.permanent.municipality.hasErrors = false;
        draft.permanent.city.hasErrors = false;
        draft.permanent.locality.hasErrors = false;
        draft.permanent.ward_number.hasErrors = false;
        draft.permanent.block_number.hasErrors = false;
        draft.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.permanent.country.value = "";
        draft.permanent.province.value = "";
        draft.permanent.district.value = "";
        draft.permanent.municipality.value = "";
        draft.permanent.city.value = "";
        draft.permanent.locality.value = "";
        draft.permanent.ward_number.value = "";
        draft.permanent.block_number.value = "";
        draft.permanent.phone_number.value = "";
        draft.permanent.country.touched = false;
        draft.permanent.province.touched = false;
        draft.permanent.district.touched = false;
        draft.permanent.municipality.touched = false;
        draft.permanent.city.touched = false;
        draft.permanent.locality.touched = false;
        draft.permanent.ward_number.touched = false;
        draft.permanent.block_number.touched = false;
        draft.permanent.phone_number.touched = false;
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, intitalState);

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  useEffect(() => {
    console.log("save count form the useeffect", state.submitCount.counter);
    // console.log("save the the state and navigate to another page");
    if (state.submitCount.counter >= 1) {
      console.log("there is a submitCount");
      formDispatch({
        type: "saveAddress",
        value: [
          {
            type: "1",
            id: data.addresses[0]?.id,
            block_number: state.temporary.block_number.value,
            phone_number: state.temporary.phone_number.value,
            ward_number: state.temporary.ward_number.value,
            locality: state.temporary.locality.value,
            municipality: state.temporary.municipality.value,
            district: state.temporary.district.value,
            province: state.temporary.province.value,
            country: state.temporary.country.value,
            city: state.temporary.city.value,
          },
          {
            type: "2",
            id: data.addresses[1]?.id,
            block_number: state.permanent.block_number.value,
            phone_number: state.permanent.phone_number.value,
            ward_number: state.permanent.ward_number.value,
            locality: state.permanent.locality.value,
            municipality: state.permanent.municipality.value,
            district: state.permanent.district.value,
            province: state.permanent.province.value,
            country: state.permanent.country.value,
            city: state.permanent.city.value,
          },
        ],
      });
    }
  }, [state.submitCount.counter]);

  function handleCheck(e) {
    console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  useEffect(() => {
    dispatch({ type: "checkCount" });
  }, [state.temporary, state.permanent]);

  const [tempCountryReject, setTempCountryReject] = useState(false);
  const [tempProvinceReject, setTempProvinceReject] = useState(false);
  const [tempDistrictReject, setTempDistrictReject] = useState(false);
  const [tempCityReject, setTempCityReject] = useState(false);
  const [tempMunicipalityReject, setTempMunicipalityReject] = useState(false);
  const [tempLocalityReject, setTempLocalityReject] = useState(false);
  const [tempWardNoReject, setTempWardNoReject] = useState(false);
  const [tempBlockNoReject, setTempBlockNoReject] = useState(false);
  const [tempPhoneNoReject, setTempPhoneNoReject] = useState(false);
  const [countryReject, setCountryReject] = useState(false);
  const [provinceReject, setProvinceReject] = useState(false);
  const [districtReject, setDistrictReject] = useState(false);
  const [cityReject, setCityReject] = useState(false);
  const [municipalityReject, setMunicipalityReject] = useState(false);
  const [localityReject, setLocalityReject] = useState(false);
  const [wardNoReject, setWardNoReject] = useState(false);
  const [blockNoReject, setBlockNoReject] = useState(false);
  const [phoneNoReject, setPhoneNoReject] = useState(false);

  useEffect(() => {
    data.reasons.map((item, i) => {
      if (item.type === "address") {
        if (item.title === "temporary_country") {
          setTempCountryReject(true);
        }
        if (item.title === "temporary_province") {
          setTempProvinceReject(true);
        }
        if (item.title === "temporary_district") {
          setTempDistrictReject(true);
        }
        if (item.title === "temporary_city") {
          setTempCityReject(true);
        }
        if (item.title === "temporary_municipality") {
          setTempMunicipalityReject(true);
        }
        if (item.title === "temporary_locality") {
          setTempLocalityReject(true);
        }
        if (item.title === "temporary_ward_no") {
          setTempWardNoReject(true);
        }
        if (item.title === "temporary_block_no") {
          setTempBlockNoReject(true);
        }
        if (item.title === "temporary_phone_no") {
          setTempPhoneNoReject(true);
        }
        if (item.title === "temporary_country") {
          setCountryReject(true);
        }
        if (item.title === "permanent_province") {
          setProvinceReject(true);
        }
        if (item.title === "permanent_district") {
          setDistrictReject(true);
        }
        if (item.title === "permanent_city") {
          setCityReject(true);
        }
        if (item.title === "permanent_municipality") {
          setMunicipalityReject(true);
        }
        if (item.title === "permanent_locality") {
          setLocalityReject(true);
        }
        if (item.title === "permanent_ward_no") {
          setWardNoReject(true);
        }
        if (item.title === "permanent_block_no") {
          setBlockNoReject(true);
        }
        if (item.title === "permanent_phone_no") {
          setPhoneNoReject(true);
        }
      }
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <h3 className="text-primary">Current Address</h3>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-country">
                Country
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  tempCountryReject && "border-danger is-invalid"
                }`}
                id="input-country"
                type="text"
                value={state.temporary.country.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryCountryChange",
                    value: e.target.value,
                  })
                }
              >
                {country.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {state.temporary.country.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.country.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-province">
                Province
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  tempProvinceReject && "border-danger is-invalid"
                }`}
                id="input-province"
                value={state.temporary.province.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryProvincechange",
                    value: e.target.value,
                  })
                }
              >
                {state.temporary.province.list.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {state.temporary.province.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.province.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-district">
                District
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  tempDistrictReject && "border-danger is-invalid"
                }`}
                id="input-district"
                type="text"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryDistrictChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.district.value}
              >
                {state.temporary.district.listToShow.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {state.temporary.district.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.district.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-city">
                City
              </label>
              <Input
                className={`form-control-alternative ${
                  tempCityReject && "border-danger is-invalid"
                }`}
                id="input-city"
                placeholder="Your City Name"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryCityChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.city.value}
              />
              {state.temporary.city.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.city.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-municipality"
              >
                Municipality
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  tempMunicipalityReject && "border-danger is-invalid"
                }`}
                id="input-municipality"
                type="text"
                placeholder="Your Municipality / VDC name"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryMunicipalityChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.municipality.value}
              >
                {state.temporary?.municipality?.listToShow?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
              {state.temporary.municipality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.municipality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-locality_tole"
              >
                Locality/Tole
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  tempLocalityReject && "border-danger is-invalid"
                }`}
                id="input-locality_tole"
                placeholder="Temporary locality"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryLocalityChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.locality.value}
              />
              {state.temporary.locality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.locality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-ward_no">
                Ward No
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  tempWardNoReject && "border-danger is-invalid"
                }`}
                id="input-ward_no"
                type="text"
                placeholder="Temporary ward no"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryWardChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.ward_number.value}
              />
              {state.temporary.ward_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.ward_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-block_no">
                Block No
              </label>
              <Input
                type="number"
                className={`form-control-alternative ${
                  tempBlockNoReject && "border-danger is-invalid"
                }`}
                id="input-block_no"
                placeholder="Temporary block no"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryBlockChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.block_number.value}
              />
              {state.temporary.block_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.block_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-telephone_number"
              >
                Telephone Number
              </label>
              <Input
                className={`form-control-alternative ${
                  tempPhoneNoReject && "border-danger is-invalid"
                }`}
                id="input-telephone_number"
                type="number"
                placeholder="Temporary Telephone number"
                onChange={(e) =>
                  dispatch({
                    type: "temporaryPhoneChange",
                    value: e.target.value,
                  })
                }
                value={state.temporary.phone_number.value}
              />
              {state.temporary.phone_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.temporary.phone_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <h3 className="text-primary">Permanent Address</h3>
        <FormGroup>
          <Row className="py-3">
            <Col lg="6">
              <Input
                type="checkbox"
                className="form-control-alternative alert-primary ml-1"
                id="permanent-address"
                onClick={handleCheck}
              />
              <label
                className="form-control-label"
                htmlFor="permanent-address"
                style={{ marginLeft: "3rem" }}
              >
                Same as Current Address
              </label>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Country
                </label>
                <span className="text-danger">*</span>
                <select
                  className={`form-control-alternative calendar ${
                    countryReject && "border-danger is-invalid"
                  }`}
                  id="input-country"
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentCountryChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.country.value}
                >
                  {country.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.country.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.country.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-province">
                  Province
                </label>
                <span className="text-danger">*</span>
                <select
                  className={`form-control-alternative calendar ${
                    provinceReject && "border-danger is-invalid"
                  }`}
                  id="input-province"
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentProvincechange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.province.value}
                >
                  {state.permanent.province.list.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.province.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.province.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-district">
                  District
                </label>
                <span className="text-danger">*</span>
                <select
                  className={`form-control-alternative calendar ${
                    districtReject && "border-danger is-invalid"
                  }`}
                  id="input-district"
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentDistrictChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.district.value}
                >
                  {state.permanent.district.listToShow.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.district.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.district.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  City
                </label>
                <Input
                  className={`form-control-alternative ${
                    cityReject && "border-danger is-invalid"
                  }`}
                  id="input-city"
                  type="text"
                  placeholder="Your City Name"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentCityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.city.value}
                />
                {state.temporary.city.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.city.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-municipality"
                >
                  Municipality
                </label>
                <span className="text-danger">*</span>
                <select
                  className={`form-control-alternative calendar ${
                    municipalityReject && "border-danger is-invalid"
                  }`}
                  id="input-municipality"
                  type="text"
                  placeholder="Your Municipality / VDC name"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentMunicipalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.municipality.value}
                >
                  {state.permanent?.municipality?.listToShow?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {state.temporary.municipality.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.municipality.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-locality_tole"
                >
                  Locality/Tole
                </label>
                <span className="text-danger">*</span>
                <Input
                  className={`form-control-alternative ${
                    localityReject && "border-danger is-invalid"
                  }`}
                  id="input-locality_tole"
                  type="text"
                  placeholder="Permanent locality"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentLocalityChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.locality.value}
                />
                {state.temporary.locality.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.locality.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-ward_no">
                  Ward No
                </label>
                <span className="text-danger">*</span>
                <Input
                  className={`form-control-alternative ${
                    wardNoReject && "border-danger is-invalid"
                  }`}
                  id="input-ward_no"
                  type="text"
                  placeholder="Permanent ward no"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentWardChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.ward_number.value}
                />
                {state.temporary.ward_number.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.ward_number.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-block_no">
                  Block No
                </label>
                <Input
                  className={`form-control-alternative ${
                    blockNoReject && "border-danger is-invalid"
                  }`}
                  id="input-block_no"
                  type="number"
                  placeholder="Permanent block no"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentBlockChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.block_number.value}
                />
                {state.temporary.block_number.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.block_number.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-telephone_number"
                >
                  Telephone Number
                </label>
                <Input
                  className={`form-control-alternative ${
                    phoneNoReject && "border-danger is-invalid"
                  }`}
                  id="input-telephone_number"
                  type="number"
                  placeholder="Permanent Telephone number"
                  onChange={(e) =>
                    dispatch({
                      type: "permanentPhoneChange",
                      value: e.target.value,
                    })
                  }
                  value={state.permanent.phone_number.value}
                />
                {state.temporary.phone_number.hasErrors && (
                  <Alert className="alert-danger mt-4">
                    {state.temporary.phone_number.message}
                  </Alert>
                )}
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
      </CardBody>
    </Container>
  );
};

export default Address;
