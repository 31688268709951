import React, { useState, useEffect, useContext } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import Switch from "react-switch";

import StateContext from "../../../../StateContext";
import Loading from "../../../Layout/Loading";

const UserEdit = () => {
  const history = useHistory();
  const [checked, setChecked] = useState();
  const appState = useContext(StateContext);
  const { id } = useParams();

  const initialState = {
    formData: {
      fullName: {
        value: "",
        hasErrors: false,
        message: "",
        name: "name",
      },
      email: {
        value: "",
        hasErrors: false,
        message: "",
        name: "email",
      },
      roles: {
        value: "",
        hasErrors: false,
        message: "",
        name: "roles",
      },
      active: {
        value: "yes",
        hasErrors: false,
        message: "",
        name: "active",
      },
    },
    userData: {},
    roles: [],
    userLoaded: false,
    submitCount: 0,
    isPending: false,
    hasError: false,
    message: "",
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "saveUserData":
        draft.userData = action.value;
        draft.userLoaded = true;
        return;
      case "userRoles":
        draft.roles = action.value;
        return;
      case "saveFullName":
        draft.formData.fullName.value = action.value;
        return;
      case "saveEmail":
        draft.formData.email.value = action.value;
        return;
      case "saveActive":
        draft.formData.active.value = action.value;
        return;
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name === action.field) {
            draft.formData[key].hasErrors = false;
            draft.formData[key].value = action.value;
            if (
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                draft.formData.email.value
              )
            ) {
              draft.formData.email.hasErrors = true;
              draft.formData.email.message =
                "You must provide a valid email address";
              return;
            }
            if (action.value === "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field cannot be blank`;
              return;
            }
          }
        }
        return;
      case "rolesInputChange":
        draft.formData.roles.hasErrors = false;
        draft.formData.roles.value = action.value;
        return;
      case "activeInputChange":
        draft.formData.active.hasErrors = false;
        if (draft.formData.active.value === "yes") {
          draft.formData.active.value = "no";
        } else {
          draft.formData.active.value = "yes";
        }
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "setErrorMsg":
        draft.hasError = true;
        draft.message = action.value;
        return;
      case "setSuccessMsg":
        draft.hasError = false;
        draft.message = action.value;
        return;
      case "checkForErrors":
        console.log("checking for errors");
        var errorCount = 0;
        for (const key in draft.formData) {
          if (draft.formData[key].value == "") {
            console.log(draft.formData[key].value);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
          if (draft.formData[key].hasErrors) {
            errorCount++;
          }
        }
        console.log("ready to submit", errorCount);
        if (errorCount < 1) {
          draft.submitCount++;
        }
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function handleChange(nextChecked, e) {
    e.preventDefault();
    setChecked(nextChecked);
    dispatch({ type: "activeInputChange" });
  }

  // UserData
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
          {
            cancelToken: ourRequest.token,
          }
        );
        // setUser(response.data.data);
        dispatch({ type: "saveUserData", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching user");
        dispatch({ type: "setErrorMsg", value: "There was an error!" });
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);
  // Roles
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/roles`,
          {
            cancelToken: ourRequest.token,
          }
        );
        console.log(response.data);
        dispatch({ type: "userRoles", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching users");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);
  // Post
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.submitCount) {
      dispatch({ type: "setIsPending", value: true });

      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.user.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      async function register() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/users/${id}`,
            {
              name: state.formData.fullName.value,
              email: state.formData.email.value,
              roles: state.formData.roles.value,
              active: state.formData.active.value,
            },
            { cancelToken: ourRequest.token }
          );
          // console.log(response);
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "setSuccessMsg", value: response?.data?.message });
          window.location.reload();
        } catch (e) {
          dispatch({ type: "setErrorMsg", value: e.response?.data?.message });
          console.log(e.response, "there was an error");
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "setIsPending", value: false });
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.submitCount]);

  useEffect(() => {
    if (state.userLoaded) {
      state.userData.roles.map((item, i) => {
        dispatch({ type: "rolesInputChange", value: item.id });
      });
      if (state.userData.active === "yes") {
        setChecked(true);
      }
      if (state.userData.active === "no") {
        setChecked(false);
      }
      dispatch({ type: "saveFullName", value: state.userData.name });
      dispatch({ type: "saveEmail", value: state.userData.email });
      dispatch({ type: "saveActive", value: state.userData.active });
    }
  }, [state.userLoaded]);

  const handleSubmit = () => {
    dispatch({ type: "checkForErrors" });
  };

  if (!state.userLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <CardHeader className="border-0">
        <h3 className="mb-0">Edit User</h3>
      </CardHeader>

      <Card>
        <CardBody>
          <Form>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Full Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue={state.userData.name}
                    id="input-username"
                    type="text"
                    onChange={(e) =>
                      dispatch({
                        type: "inputChange",
                        value: e.target.value,
                        field: "name",
                      })
                    }
                  />
                  {state.formData.fullName.hasErrors && (
                    <Alert className="alert-danger mt-4">
                      {state.formData.fullName.message}
                    </Alert>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue={state.userData.email}
                    id="input-email"
                    type="email"
                    onChange={(e) =>
                      dispatch({
                        type: "inputChange",
                        value: e.target.value,
                        field: "email",
                      })
                    }
                  />
                  {state.formData.email.hasErrors && (
                    <Alert className="alert-danger mt-4">
                      {state.formData.email.message}
                    </Alert>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {state.formData.roles.value !== 5 && (
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="roles">
                      Roles
                    </label>
                    <Input
                      className="form-control-alternative"
                      name="roles"
                      type={"select"}
                      value={state.formData.roles.value}
                      onChange={(e) =>
                        dispatch({
                          type: "rolesInputChange",
                          value: e.target.value,
                          field: "roles",
                        })
                      }
                    >
                      <option value=""></option>
                      {state.roles.map((item, i) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="active">
                    Active
                  </label>
                  <br />
                  <Switch
                    onChange={handleChange}
                    checked={state.formData.active.value === 1 ? "" : checked}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="7" md="10">
                <Button color="info" onClick={handleSubmit}>
                  Edit
                </Button>
                <Button color="danger" onClick={(e) => history.goBack()}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {state.hasError && (
                  <Alert className="alert-danger mt-4">{state.message}</Alert>
                )}
                {!state.hasError && state.submitCount > 0 && state.message && (
                  <Alert className="alert-success mt-4">{state.message}</Alert>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default UserEdit;
