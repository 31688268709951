//occupation list

const occupation = [
  {
    id: 0,
    name: "Select your occupation",
    occupation_id: "0",
  },
  {
    id: 1,
    name: "Business",
    occupation_id: "1",
  },
  {
    id: 2,
    name: "Farmer ",
    occupation_id: "2",
  },
  {
    id: 3,
    name: "Government Service",
    occupation_id: "3",
  },
  {
    id: 4,
    name: "Housewife",
    occupation_id: "4",
  },
  {
    id: 5,
    name: "Others ",
    occupation_id: "5",
  },
  {
    id: 6,
    name: "Professional",
    occupation_id: "6",
  },
  {
    id: 7,
    name: "Public Sector ",
    occupation_id: "7",
  },
  {
    id: 8,
    name: "Private Sector",
    occupation_id: "8",
  },
  {
    id: 9,
    name: "Retired",
    occupation_id: "9",
  },
  {
    id: 10,
    name: "Service ",
    occupation_id: "10",
  },
  {
    id: 11,
    name: "Student ",
    occupation_id: "11",
  },
];

export default occupation;
