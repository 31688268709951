//country list

const country = [
  {
    id: 0,
    name: "",
    country_id: "0",
  },
  {
    id: 1,
    name: "Nepal",
    country_id: "1",
  },
];

export default country;
