import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { Info } from "../../Layout/Icons";
import FormDispatch from "./../FormDispatch";
import FormState from "./../FormState";

function FamilyInformation(props) {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);
  const [saveCount, setSaveCount] = useState(0);
  const initialState = {
    backCount: 0,
    fathersName: {
      name: "fathersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    grandFathersName: {
      name: "grandFathersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    mothersName: {
      name: "mothersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    spousesName: {
      name: "spousesName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughterInLawsName: {
      name: "daughterInLawsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    fatherInLawsName: {
      name: "fatherInLawsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    sonsName: {
      name: "sonsName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
    daughtersName: {
      name: "daughtersName",
      value: "",
      hasErrors: false,
      message: "",
      touched: false,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft) {
          if (draft[key].name == action.field) {
            draft[key].hasErrors = false;
            draft[key].touched = true;
            draft[key].value = action.value;
          }
        }
        return;
      case "validateForm":
        let errorCount = 0;
        if (!draft.fathersName.value.length) {
          draft.fathersName.hasErrors = true;
          draft.fathersName.message = "This field is required";
        }
        if (!draft.grandFathersName.value.length) {
          draft.grandFathersName.hasErrors = true;
          draft.grandFathersName.message = "This field is required";
        }
        if (!draft.mothersName.value.length) {
          draft.mothersName.hasErrors = true;
          draft.mothersName.message = "This field is required";
        }
        for (const key in draft) {
          if (draft[key].hasErrors) {
            errorCount++;
          }
        }
        // console.log("error count is ", errorCount)
        if (!errorCount) {
          setSaveCount(saveCount + 1);
        }
        return;
      case "setBackCount":
        draft.backCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (saveCount) {
      formDispatch({
        type: "saveFamily",
        value: {
          father_name: state.fathersName.value,
          grand_father_name: state.grandFathersName.value,
          mother_name: state.mothersName.value,
          spouse: state.spousesName.value,
          son_name: state.sonsName.value,
          daughter_name: state.daughtersName.value,
          father_in_law_name: state.fatherInLawsName.value,
          daughter_in_law_name: state.daughterInLawsName.value,
        },
      });
      if (formState.showNominee) {
        // formDispatch({ type: "activeNominee" });
        formDispatch({ type: "activeTabs", value: "nominee" });

        // props.history.push("/customers/create/nominee");
        return;
      }
      // props.history.push("/customers/create/account");
      // formDispatch({ type: "activeAccount" });
      formDispatch({ type: "activeTabs", value: "account" });
    }
  }, [saveCount]);

  function handleSubmit() {
    dispatch({ type: "validateForm" });
  }
  function handleCheck(e) {
    if (e.target.checked) {
      formDispatch({ type: "nomineeTrue" });
    } else {
      formDispatch({ type: "nomineeFalse" });
    }
  }

  function handleBackCount(e) {
    e.preventDefault();
    dispatch({ type: "setBackCount" });
  }

  useEffect(() => {
    if (state.backCount > 0) {
      formDispatch({ type: "activeTabs", value: "address" });
    }
  }, [state.backCount]);

  return (
    <form action="" className="form position-relative">
      <div className="form-container personal-info">
        <div className="text-container">
          <h2>Family member details</h2>
          <span
            className="position-absolute end-0 top-0"
            onClick={() => {
              formDispatch({ type: "setInfo" });
            }}
          >
            <Info />
          </span>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.fathersName.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="family-father_name">
                Father's Name / बुबाको नाम
              </label>
              <span className="text-danger">*</span>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "fathersName",
                  })
                }
                value={state.fathersName.value}
                type="text"
                id="family-father_name"
                className="form-input"
                placeholder="Enter your Father's Name"
                required
              />
              {state.fathersName.hasErrors && (
                <span className="error">{state.fathersName.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.grandFathersName.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="family-grandfather_name">
                Grandfather's Name / हजुर बुबाको नाम
              </label>
              <span className="text-danger">*</span>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "grandFathersName",
                  })
                }
                value={state.grandFathersName.value}
                type="text"
                id="family-grandfather_name"
                className="form-input"
                placeholder="Enter your Grandfather's Name"
                required
              />
              {state.grandFathersName.hasErrors && (
                <span className="error">{state.grandFathersName.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div
              className={`input-group ${
                state.mothersName.hasErrors && "form-error"
              }`}
            >
              <label htmlFor="family-mother_name">
                Mother's Name / आमाको नाम
              </label>
              <span className="text-danger">*</span>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "mothersName",
                  })
                }
                value={state.mothersName.value}
                type="text"
                id="family-mother_name"
                className="form-input"
                placeholder="Enter your Mother's Name"
                required
              />
              {state.mothersName.hasErrors && (
                <span className="error">{state.mothersName.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group">
              <label htmlFor="family-spouse_name">
                Spouse Name / पति/पत्नीको नाम
              </label>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "spousesName",
                  })
                }
                value={state.spousesName.value}
                type="text"
                id="family-spouse_name"
                className="form-input"
                placeholder="Enter your Spouse's Name"
              />
              {state.spousesName.hasErrors && (
                <span className="error">{state.spousesName.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-group">
              <label htmlFor="family-daughter_in_law_name">
                Daughter in Law's Name / बुहारीको नाम
              </label>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "daughterInLawsName",
                  })
                }
                value={state.daughterInLawsName.value}
                type="text"
                id="family-daughter_in_law_name"
                className="form-input"
                placeholder="Enter your Daughter in Law's Name"
              />
              {state.daughterInLawsName.hasErrors && (
                <span className="error">
                  {state.daughterInLawsName.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-group">
              <label htmlFor="family-father_in_law_name">
                Father's in Law's Name / ससुरा को नाम
              </label>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "fatherInLawsName",
                  })
                }
                value={state.fatherInLawsName.value}
                type="text"
                id="family-father_in_law_name"
                className="form-input"
                placeholder="Enter your Father in Law's Name"
              />
              {state.fatherInLawsName.hasErrors && (
                <span className="error">{state.fatherInLawsName.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input-group">
              <label htmlFor="family-son_name">
                Son's Name / छोराहरुको नाम
              </label>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "sonsName",
                  })
                }
                value={state.sonsName.value}
                type="text"
                id="family-son_name"
                className="form-input"
                placeholder="Enter your Son's Name"
              />
              {state.sonsName.hasErrors && (
                <span className="error">{state.sonsName.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-group">
              <label htmlFor="family-daughter_name">
                Daughter's Name / छोरीहरुको नाम
              </label>
              <input
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "daughtersName",
                  })
                }
                value={state.daughtersName.value}
                type="text"
                id="family-daughter_name"
                className="form-input"
                placeholder="Enter your Daughter's Name"
              />
              {state.daughtersName.hasErrors && (
                <span className="error">{state.daughtersName.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="input-wrapper">
              <div className="input-group">
                <input
                  onChange={handleCheck}
                  type="checkbox"
                  className="form-input"
                  id="family-add_nominee"
                  style={{ width: "14px" }}
                />
                <label htmlFor="family-add_nominee" className="mb-0 mx-2">
                  Do you want to add nominee?
                </label>
              </div>
            </div>
          </div>
        </div>

        <span className="btn-transparent mr-2 " onClick={handleBackCount}>
          <span>← </span>
          Previous Page
        </span>
        <span onClick={handleSubmit} className="btn-blue">
          Next Page
          <span> →</span>
        </span>
      </div>
    </form>
  );
}

export default withRouter(FamilyInformation);
