import React, { useContext, useEffect } from "react";
import { withRouter, Switch, Route } from "react-router";

//COMPONENTS

import Sidebar from "./Admin/Layout/Sidebar";
import Header from "./Admin/Layout/Header";
import Footer from "./Layout/Footer";
import Dashboard from "./Admin/Dashboard";
import Users from "./Admin/Users/Users";
import Profile from "./Admin/Profile";
import Roles from "./Admin/Roles/Roles";
import Customers from "./Admin/Customer/Customers";
import Settings from "./Handler/Settings";

import StateContext from "../StateContext";

function AdminDashboard(props) {
  const appState = useContext(StateContext);
  const id = appState.user?.data?.user?.id;

  useEffect(() => {
    if (!appState.adminLoggedIn) {
      props.history.push("/admin");
    }
  }, [appState.adminLoggedIn]);

  return (
    <div className="admin">
      <Sidebar id={id} />
      <div className="main-content">
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Header id={id} />
          <Switch>
            <Route path="/admin/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/admin/dashboard/users">
              <Users />
            </Route>
            <Route path="/admin/dashboard/roles">
              <Roles />
            </Route>
            <Route path="/admin/dashboard/customers">
              <Customers />
            </Route>
            <Route path="/admin/dashboard/profile/:id" exact>
              <Profile />
            </Route>
            <Route path="/admin/dashboard/settings/:id" exact>
              <Settings />
            </Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminDashboard);
