import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Alert,
} from "reactstrap";
import StateContext from "../../../../StateContext";
import FormState from "../../../Client/FormState";
import FormDispatch from "../../../Client/FormDispatch";
import Loading from "../../../Layout/Loading";
import PersonalInfo from "../../../Client/CustomerView/PersonalInfo";
import Guardian from "../../../Client/CustomerView/Guardian";
import Account from "../../../Client/CustomerView/Account";
import Occupation from "../../../Client/CustomerView/Occupation";
import Address from "../../../Client/CustomerView/Address";
import FamilyInformation from "../../../Client/CustomerView/FamilyInformation";
import Documents from "../../../Client/CustomerView/Documents";
import Nominee from "../../../Client/CustomerView/Nominee";

const CustomerEdit = () => {
  const appState = useContext(StateContext);
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
    hasNominee: false,
    nomineeData: {},
    hasGuardian: false,
    guardianData: {},
    uniqueId: "",
    saveCount: 0,
    formLevel: 0,
    showGuardian: false,
    showNominee: false,
    resMsg: "",
    validationCheck: 0,
    rejectCount: {
      personal_informations: 0,
      addresses: 0,
      families: 0,
      occupations: 0,
      accounts: 0,
      documents: 0,
      guardian: 0,
      nominee: 0,
      payment_status: 0,
    },
    formData: {
      customers: {
        demat_account_type: "1",
        full_name: "",
        email: "",
        mobile: "",
        username: "",
        // password: "",
        is_minor: "1",
        nominee: "1",
      },
      personal_informations: {
        dob: "",
        gender: "",
        marital_status: "",
        nationality: "",
        pan_number: null,
        identity_card_type: "",
        identity_card_number: "",
        identity_card_issue_district: "",
        identity_card_issue_date: "",
      },
      addresses: {
        type: "",
        block_number: "",
        phone_number: "",
        ward_number: "",
        locality: "",
        municipality: "",
        district: "",
        province: "",
        country: "",
        city: "",
      },
      families: {
        father_name: "",
        grand_father_name: "",
        mother_name: "",
        spouse: "",
        son_name: "",
        daughter_name: "",
        father_in_law_name: "",
        daughter_in_law_name: "",
      },
      occupations: {
        title: "",
        type: "",
        organization_name: "",
        address: "",
        designation: "",
        financial_details: "",
      },
      accounts: {
        bank_name: "",
        bank_code: "",
        branch_name: "",
        number: "",
        type: "",
      },
      documents: {},
    },
    relationships: {},
    relationshipsGuardian: {},
    relationshipsNominee: {},
    documents_file: {},
    guardian_documents_file: {},
    nominee_documents_file: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasNominee":
        draft.hasNominee = true;
        draft.nomineeData = action.value;
        return;
      case "hasGuardian":
        draft.hasGuardian = true;
        draft.guardianData = action.value;
        return;
      case "infoUpdate":
        draft.rejectCount.personal_informations++;
        return;
      case "addressUpdate":
        draft.rejectCount.addresses++;
        return;
      case "familyUpdate":
        draft.rejectCount.families++;
        return;
      case "nomineeUpdate":
        draft.rejectCount.nominee++;
        return;
      case "guardianUpdate":
        draft.rejectCount.guardian++;
        return;
      case "accountUpdate":
        draft.rejectCount.accounts++;
        return;
      case "occupationUpdate":
        draft.rejectCount.occupations++;
        return;
      case "documentsUpdate":
        draft.rejectCount.documents++;
        return;
      case "payment_statusUpdate":
        draft.rejectCount.payment_status++;
        return;
      case "customerUpdate":
        console.log("this reducer was hit on customer form main");
        console.log(action.value.email, "action.value");
        draft.formData.customers.email = action.value.email;
        draft.formData.customers.mobile = action.value.mobile;
        draft.formData.customers.id = action.value.id;
        draft.formData.customers.registration_number =
          action.value.registration_number;
        draft.uniqueId = action.value.uniqueId;
        return;
      case "saveUsername":
        draft.formData.customers.username = action.value;
        return;
      case "savePassword":
        draft.formData.customers.password = action.value;
        return;
      case "savePersonalInfo":
        draft.formData.personal_informations = action.value;
        return;
      case "saveGuardian":
        draft.relationshipsGuardian = action.value;
        return;
      case "minorTrue":
        draft.formData.customers.is_minor = "2";
        draft.showGuardian = true;
        // draft.formData.documents = action.value;
        return;
      case "minorFalse":
        draft.formData.customers.is_minor = "1";
        draft.showGuardian = false;
        // draft.formData.documents = action.value;
        return;
      case "nomineeTrue":
        draft.formData.customers.nominee = "2";
        draft.showNominee = true;
        return;
      case "saveNominee":
        draft.relationshipsNominee = action.value;
        return;
      case "nomineeFalse":
        draft.formData.customers.nominee = "1";
        draft.showNominee = false;
        return;

      case "saveFullName":
        draft.formData.customers.full_name = action.value;
        return;
      case "saveAddress":
        draft.formData.addresses = action.value;
        return;
      case "saveFamily":
        draft.formData.families = action.value;
        return;
      case "saveOccupation":
        draft.formData.occupations = action.value;
        return;
      case "saveAccount":
        draft.formData.accounts = action.value;
        return;
      case "saveDocuments":
        draft.formData.documents = action.value;
        return;
      case "saveDocumentsFile":
        draft.documents_file = action.value;
        return;
      case "saveGuardianDocumentsFile":
        draft.guardian_documents_file = action.value;
        return;
      case "saveNomineeDocumentsFile":
        draft.nominee_documents_file = action.value;
        return;
      case "saveResMsg":
        draft.resMsg = action.value;
        return;
      case "saveValidation":
        draft.validationCheck++;
        return;
      case "saveForm":
        draft.saveCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.userLoaded) {
      if (state.userData.relationships && state.userData.relationships.length) {
        state.userData.relationships.map((item, i) => {
          if (item.type === "nominee") {
            dispatch({ type: "hasNominee", value: item });
            // dispatch({ type: "nomineeTrue" });
          }
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardian", value: item });
            // dispatch({ type: "minorTrue" });
          }
        });
      }
      if (state.userData.status == "rejected") {
        console.log("this form was rejected");
        dispatch({ type: "disableReject" });
      }
    }
  }, [state.userLoaded]);

  // Is minor ?
  useEffect(() => {
    if (state.formData.documents === "false") {
      if (state.userData.is_minor == "yes") {
        dispatch({
          type: "minorTrue",
          value: {
            birth_certificate_name:
              state.userData.documents[0]?.birth_certificate_name,
            birth_certificate_id:
              state.userData.documents[0]?.birth_certificate_id,
            lat: state.userData.documents[0]?.lat,
            long: state.userData.documents[0]?.long,
          },
        });
      }
      if (state.userData.is_minor == "no") {
        dispatch({
          type: "minorFalse",
          value: {
            id: state.userData.documents[0]?.id,
            photo: state.userData.documents[0]?.photo_name,
            photo_id: state.userData.documents[0]?.photo_id,
            gov_issued_id_front:
              state.userData.documents[0]?.gov_issued_id_front_name,
            gov_issued_id_front_id:
              state.userData.documents[0]?.gov_issued_id_front_id,
            gov_issued_id_back:
              state.userData.documents[0]?.gov_issued_id_back_name,
            gov_issued_id_back_id:
              state.userData.documents[0]?.gov_issued_id_back_id,
            thumb_left: state.userData.documents[0]?.thumb_left_name,
            thumb_left_id: state.userData.documents[0]?.thumb_left_id,
            thumb_right: state.userData.documents[0]?.thumb_right_name,
            thumb_right_id: state.userData.documents[0]?.thumb_right_id,
            signature: state.userData.documents[0]?.signature_name,
            signature_id: state.userData.documents[0]?.signature_id,
            lat: state.userData.documents[0]?.lat,
            long: state.userData.documents[0]?.long,
          },
        });
      }
    }
  }, [state.formData.documents]);

  // Is nominee ?
  useEffect(() => {
    if (state.userData) {
      if (state.userData.nominee == "yes") {
        dispatch({ type: "nomineeTrue" });
      }
    }
  }, [state.userLoaded]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          { cancelToken: ourRequest.token }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    dispatch({ type: "saveUsername", value: state.userData.username });
  }, [state.userData]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      let dataToSend = {};

      if (state.userData.is_minor == "yes" && state.userData.nominee == "no") {
        console.log("the user is a minor from the customer form");
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsGuardian],
        };
      } else if (
        state.userData.is_minor == "no" &&
        state.userData.nominee == "yes"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsNominee],
        };
      } else if (
        state.userData.is_minor == "yes" &&
        state.userData.nominee == "yes"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [
            state.relationshipsGuardian,
            state.relationshipsNominee,
          ],
        };
      } else {
        dataToSend = state.formData;
      }
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.client.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      async function register() {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
            dataToSend,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
          dispatch({
            type: "saveResMsg",
            value: response?.data?.success,
          });
        } catch (e) {
          console.log(e.response, "there was an error");
          dispatch({
            type: "saveResMsg",
            value: e.response?.data?.success,
          });
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      // let customers = [];
      const formData = new FormData();
      formData.append("email", state.formData.customers.email);
      if (
        state.userData.is_minor === "yes" &&
        state.userData.nominee === "no"
      ) {
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
      } else if (
        state.userData.is_minor === "no" &&
        state.userData.nominee === "yes"
      ) {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else if (
        state.userData.is_minor == "yes" &&
        state.userData.nominee == "yes"
      ) {
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
      }
      axios.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${appState.client.data.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/upload_image`,
            formData,
            // customers,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
        } catch (e) {
          console.log(e.response, "there was an error");
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);

  function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "saveForm" });
  }

  if (!state.userLoaded) {
    return (
      <div className="admin">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <FormState.Provider value={state}>
        <FormDispatch.Provider value={dispatch}>
          <Card className="">
            <CardHeader className="border-0 pb-1">
              <h3 className="mb-0">Edit Existing Customer</h3>
              <p className="text-sm">Edit the following details</p>
            </CardHeader>
            <CardBody className="shadow py-5">
              <Row>
                <Col xl="6" lg="12">
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Personal Information</h3>
                    </CardHeader>
                    <CardBody>
                      <PersonalInfo data={state.userData} />
                    </CardBody>
                  </Card>
                  {state.hasGuardian && (
                    <Card className="shadow mb-5">
                      <CardHeader>
                        <h3 className="mb-0">Guardian</h3>
                      </CardHeader>
                      <CardBody>
                        <Guardian
                          data={state.hasGuardian ? state.guardianData : ""}
                          userData={state.userData}
                        />
                      </CardBody>
                    </Card>
                  )}
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Account</h3>
                    </CardHeader>
                    <CardBody>
                      <Account data={state.userData} />
                    </CardBody>
                  </Card>
                  <Card className="shadow mb-5">
                    <CardHeader>
                      <h3 className="mb-0">Occupation</h3>
                    </CardHeader>
                    <CardBody>
                      <Occupation data={state.userData} />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6" lg="12">
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Address Information</h3>
                      <h4>(Current Address)</h4>
                    </CardHeader>
                    <CardBody>
                      <Address data={state.userData} />
                    </CardBody>
                  </Card>
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Family Information</h3>
                    </CardHeader>
                    <CardBody>
                      <FamilyInformation data={state.userData} />
                    </CardBody>
                  </Card>
                  {state.hasNominee && (
                    <Card className="shadow mb-5">
                      <CardHeader className="d-flex">
                        <h3 className="mb-0">Nominee</h3>
                      </CardHeader>
                      <CardBody>
                        <Nominee
                          data={state.hasNominee ? state.nomineeData : ""}
                          userData={state.userData}
                        />
                      </CardBody>
                    </Card>
                  )}
                  <Card className="shadow mb-5">
                    <CardHeader className="d-flex">
                      <h3 className="mb-0">Documents</h3>
                    </CardHeader>
                    <CardBody>
                      <Documents data={state.userData} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {state.resMsg === false && (
                <Row className="mt-4">
                  <Col lg="12">
                    <Alert className="alert-danger mt-4 text-center">
                      There was an error !!!
                    </Alert>
                  </Col>
                </Row>
              )}

              {state.resMsg === true && (
                <Row className="mt-4">
                  <Col lg="12">
                    <Alert className="alert-success mt-4 text-center">
                      Customer Updated Successfully
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row className="mt-4">
                <Col lg="10"></Col>
                <Col lg="2" className="d-flex justify-content-end">
                  <Button onClick={handleSubmit} className="btn-default">
                    {state.resMsg === true ? "Submitted" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </FormDispatch.Provider>
      </FormState.Provider>
    </>
  );
};

export default CustomerEdit;
