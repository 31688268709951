import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";

//CONTEXTS
import FormState from "./FormState";
import FormDispatch from "./FormDispatch";

//Components
import Header from "../Layout/Header";
import DispatchContext from "../../DispatchContext";
import CustomerRegister from "./CustomerView/CustomerRegister/CustomerRegister";
import CustomerForm from "./CustomerView/CustomerForm";

const CustomerCreate = (props) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const [error, setError] = useState();

  const initialState = {
    tab: "register",
    tabs: "personalInfo",
    percentage: "0",
    uniqueId: "",
    saveCount: 0,
    formLevel: 0,
    showGuardian: false,
    showNominee: false,
    sendCount: 0,
    isPending: false,
    imageUpload: false,
    errorMsg: {},
    info: true,
    formData: {
      customers: {
        demat_account_type: "1",
        full_name: "",
        email: "",
        mobile: "",
        username: "",
        password: "",
        is_minor: "1",
        nominee: "1",
      },
      personal_informations: {
        dob: "",
        gender: "",
        marital_status: "",
        nationality: "",
        pan_number: null,
        identity_card_type: "",
        identity_card_number: "",
        identity_card_issue_district: "",
        identity_card_issue_date: "",
      },
      addresses: [
        {
          type: "",
          block_number: "",
          phone_number: "",
          ward_number: "",
          locality: "",
          municipality: "",
          district: "",
          province: "",
          country: "",
          city: "",
        },
        {
          type: "",
          block_number: "",
          phone_number: "",
          ward_number: "",
          locality: "",
          municipality: "",
          district: "",
          province: "",
          country: "",
          city: "",
        },
      ],
      families: {
        father_name: "",
        grand_father_name: "",
        mother_name: "",
        spouse: "",
        son_name: "",
        daughter_name: "",
        father_in_law_name: "",
        daughter_in_law_name: "",
      },
      occupations: {
        title: "",
        type: "",
        organization_name: "",
        address: "",
        designation: "",
        financial_details: "",
      },
      accounts: {
        bank_name: "",
        bank_code: "",
        branch_name: "",
        number: "",
        type: "",
        demat: 0,
        meroShare: 0,
      },
      documents: {
        photo: "",
        gov_issued_id_front: "",
        gov_issued_id_back: "",
        thumb_left: "",
        thumb_right: "",
        signature: "",
        lat: "27.6915196",
        long: "85.3420486",
      },
    },
    relationships: {},
    relationshipsGuardian: {},
    relationshipsNominee: {},
    documents_file: {},
    guardian_documents_file: {},
    nominee_documents_file: {},
    signature: null,
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "customerUpdate":
        console.log("this reducer was hit on customer form main");
        console.log(action.value.email, "action.value");
        draft.formData.customers.email = action.value.email;
        draft.formData.customers.mobile = action.value.mobile;
        draft.uniqueId = action.value.uniqueId;
        return;
      case "saveUsername":
        draft.formData.customers.username = action.value;
        return;
      case "savePassword":
        draft.formData.customers.password = action.value;
        return;
      case "savePersonalInfo":
        draft.formData.personal_informations = action.value;
        return;
      case "saveGuardian":
        draft.relationshipsGuardian = action.value;
        return;
      case "minorTrue":
        draft.formData.customers.is_minor = "2";
        draft.showGuardian = true;
        draft.formData.documents = {
          birth_certificate: "",
        };
        return;
      case "minorFalse":
        draft.formData.customers.is_minor = "1";
        draft.showGuardian = false;
        draft.formData.documents = {
          photo: "",
          gov_issued_id_front: "",
          gov_issued_id_back: "",
          thumb_left: "",
          thumb_right: "",
          signature: "",
          lat: "27.6915196",
          long: "85.3420486",
        };
        return;
      case "nomineeTrue":
        draft.formData.customers.nominee = "2";
        draft.showNominee = true;
        return;
      case "saveNominee":
        draft.relationshipsNominee = action.value;
        return;
      case "nomineeFalse":
        draft.formData.customers.nominee = "1";
        draft.showNominee = false;
        return;
      case "saveFullName":
        draft.formData.customers.full_name = action.value;
        return;
      case "saveAddress":
        draft.formData.addresses = action.value;
        return;
      case "saveFamily":
        draft.formData.families = action.value;
        return;
      case "saveOccupation":
        draft.formData.occupations = action.value;
        return;
      case "saveAccount":
        draft.formData.accounts = action.value;
        return;
      case "saveDocuments":
        draft.formData.documents = action.value;
        return;
      case "saveDocumentsFile":
        draft.documents_file = action.value;
        return;
      case "saveGuardianDocumentsFile":
        draft.guardian_documents_file = action.value;
        return;
      case "saveNomineeDocumentsFile":
        draft.nominee_documents_file = action.value;
        return;
      case "activeTab":
        draft.tab = action.value;
        return;
      case "activeTabs":
        draft.tabs = action.value;
        return;
      case "signatureImg":
        draft.signature = action.value;
        return;
      case "setImageUpload":
        draft.imageUpload = true;
        return;
      case "savePercent":
        draft.percentage = action.value;
        return;
      case "setInfo":
        draft.info = !draft.info;
        return;
      case "setErrorMsg":
        draft.errorMsg = action.value;
        return;
      case "setRegisterError":
        draft.errorMsg = action.value;
        return;
      case "setIsPending":
        draft.isPending = action.value;
        return;
      case "saveForm":
        draft.saveCount++;
        return;
      case "changeCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function handleSubmit() {
    console.log("form can be submitted");
  }

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.saveCount) {
      dispatch({ type: "setIsPending", value: true });
      let dataToSend = {};
      if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "1"
      ) {
        console.log("the user is a minor from the customer form");
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsGuardian],
        };
      } else if (
        state.formData.customers.is_minor == "1" &&
        state.formData.customers.nominee == "2"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [state.relationshipsNominee],
        };
      } else if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "2"
      ) {
        dataToSend = {
          ...state.formData,
          relationships: [
            state.relationshipsGuardian,
            state.relationshipsNominee,
          ],
        };
      } else {
        dataToSend = state.formData;
      }
      console.log("data to save ", dataToSend);
      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/register_customer`,
            dataToSend,
            { cancelToken: ourRequest.token }
          );
          console.log("registration form submitted");
          console.log(response);
          dispatch({ type: "changeCount" });
          dispatch({ type: "savePercent", value: "70" });
          dispatch({ type: "setRegistarError", value: false });
        } catch (e) {
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "setErrorMsg", value: e.response?.data?.message });
          dispatch({ type: "setRegistarError", value: true });
          dispatch({ type: "savePercent", value: "0" });
          console.log(e.response, "there was an error");
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.saveCount]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.sendCount) {
      const formData = new FormData();
      formData.append("email", state.formData.customers.email);
      if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "1"
      ) {
        // candidate

        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
      } else if (
        state.formData.customers.is_minor == "1" &&
        state.formData.customers.nominee == "2"
      ) {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else if (
        state.formData.customers.is_minor == "2" &&
        state.formData.customers.nominee == "2"
      ) {
        // candidate
        formData.append(
          "birth_certificate_file",
          state.documents_file.birth_certificate_file
        );
        // guardian
        formData.append(
          "guardian_photo_file",
          state.guardian_documents_file.guardian_photo_file
        );
        formData.append(
          "guardian_gov_issued_id_front_file",
          state.guardian_documents_file.guardian_gov_issued_id_front_file
        );
        formData.append(
          "guardian_gov_issued_id_back_file",
          state.guardian_documents_file.guardian_gov_issued_id_back_file
        );
        formData.append(
          "guardian_signature_file",
          state.guardian_documents_file.guardian_signature_file
        );
        // nominee
        formData.append(
          "nominee_photo_file",
          state.nominee_documents_file.nominee_photo_file
        );
        formData.append(
          "nominee_gov_issued_id_front_file",
          state.nominee_documents_file.nominee_gov_issued_id_front_file
        );
        formData.append(
          "nominee_gov_issued_id_back_file",
          state.nominee_documents_file.nominee_gov_issued_id_back_file
        );
        formData.append(
          "nominee_signature_file",
          state.nominee_documents_file.nominee_signature_file
        );
      } else {
        formData.append("photo_file", state.documents_file.photo_file);
        formData.append(
          "gov_issued_id_front_file",
          state.documents_file.gov_issued_id_front_file
        );
        formData.append(
          "gov_issued_id_back_file",
          state.documents_file.gov_issued_id_back_file
        );
        formData.append(
          "thumb_left_file",
          state.documents_file.thumb_left_file
        );
        formData.append(
          "thumb_right_file",
          state.documents_file.thumb_right_file
        );
        formData.append("signature_file", state.documents_file.signature_file);
      }

      async function register() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/upload_image`,
            formData,
            { cancelToken: ourRequest.token }
          );
          console.log("image form submitted");
          console.log(response);
          dispatch({ type: "setImageUpload" });
          dispatch({ type: "setIsPending", value: false });
          dispatch({ type: "savePercent", value: "90" });
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "savePercent", value: "0" });
        }
      }
      register();
    }
    return () => ourRequest.cancel();
  }, [state.sendCount]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.sendCount > 0 && state.imageUpload === true) {
      async function login() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login`,
            {
              email: state.formData.customers.email,
              password: state.formData.customers.password,
            }
          );
          console.log(
            "from the client logged out page",
            response.data.data.user.roles[0].name
          );
          if (
            response.data.data.user.roles[0] &&
            response.data.data.user.roles[0].name
          ) {
            appDispatch({ type: "clientLogin", value: response.data });
            dispatch({ type: "savePercent", value: "100" });
            history.push("/dashboard");
          }
        } catch (e) {
          console.log(e, "there was an error");
          setError(e.response?.data?.data?.error);
          dispatch({ type: "savePercent", value: "0" });
        }
      }
      login();
    }
    return () => ourRequest.cancel();
  }, [state.sendCount, state.imageUpload]);

  return (
    <>
      <div className="client">
        <Header />
        <FormState.Provider value={state}>
          <FormDispatch.Provider value={dispatch}>
            <Switch>
              <Route path="/customers" exact>
                <CustomerRegister />
              </Route>
              <Route path="/customers/create" exact>
                <CustomerForm />
              </Route>
            </Switch>
          </FormDispatch.Provider>
        </FormState.Provider>
      </div>
    </>
  );
};

export default CustomerCreate;
