import { Container, Row, Col, NavItem, NavLink, Nav } from "reactstrap";

const Footer = () => {
  return (
    <footer className="py-5">
      <Container fluid>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6"></Col>
          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink href="" target="_blank" className="text-primary">
                  <small>
                    Powered By : <b>Unitech</b>
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
