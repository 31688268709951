import React, { useContext, useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
import DispatchContext from "../DispatchContext";
import logo from "../Assets/images/logo.png";

import Footer from "./Layout/Footer";
import { Mail, Unlock } from "./Layout/Icons";

function ClientLoggedOut(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [visible, setVisible] = useState(false);
  const [sendCount, setSendCount] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const appDispatch = useContext(DispatchContext);

  function submitHandler(e) {
    e.preventDefault();
    console.log("form submit handles from here");
    setSendCount(sendCount + 1);
  }
  console.log(props);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (sendCount) {
      setIsPending(true);
      async function login() {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login`,
            {
              email,
              password,
            }
          );
          console.log(
            "from the client logged out page",
            response.data.data.user.roles[0].name
          );
          if (
            response.data.data.user.roles[0] &&
            response.data.data.user.roles[0].name
          ) {
            appDispatch({ type: "clientLogin", value: response.data });
            setIsPending(false);
            props.history.push("/dashboard");
          }
          setVisible(true);
          setIsPending(false);
          setError("Authentication Error !");
        } catch (e) {
          console.log(e, "there was an error");
          setVisible(true);
          setIsPending(false);
          setError(e.response?.data?.data?.error);
        }
      }
      login();
    }
    return () => ourRequest.cancel();
  }, [sendCount]);

  useEffect(() => {
    if (!error) {
      setVisible(false);
      return;
    }
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  return (
    <>
      <div className="admin">
        <div className="bg-default">
          <div className="main-content">
            <div
              className="header py-7 py-lg-8"
              style={{ backgroundColor: "#FF0000" }}
            >
              <Container>
                <div className="header-body text-center mb-7"></div>
              </Container>
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-default"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </div>
            {/* Page content */}
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="6" md="8">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent">
                      <div className="text-center px-5 py-3">
                        <img
                          alt="Gurkhas Finance Ltd"
                          src={logo}
                          style={{
                            height: "80px",
                            width: "260px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sign up with credentials</small>
                      </div>
                      {visible && (
                        <Alert className="alert-danger alert-dismissible">
                          {error}
                        </Alert>
                      )}
                      <Form role="form">
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <Mail className="icon" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              autoComplete="new-email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <Unlock className="icon" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="new-password"
                              onChange={(e) => setPassword(e.target.value)}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                          </InputGroup>
                        </FormGroup>

                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="primary"
                            type="button"
                            onClick={submitHandler}
                          >
                            {isPending ? "Loading..." : "Sign in"}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <Link to="/forgotPassword" className="text-light">
                        <small>Forgot password?</small>
                      </Link>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link to="/customers" className="text-light">
                        <small>Create new account</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ClientLoggedOut);
