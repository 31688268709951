import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DisplayPicture from "../../Handler/Documents/DisplayPicture";
import ThumbPrintLeft from "../../Handler/Documents/ThumbprintLeft";
import ThumbprintRight from "../../Handler/Documents/ThumbprintRight";
import CitizenshipBack from "../../Handler/Documents/CitizenshipBack";
import CitizenshipFront from "../../Handler/Documents/CitizenshipFront";
import Signature from "../../Handler/Documents/Signature";
import BirthCertificate from "../../Handler/Documents/BirthCertificate";
import FormDispatch from "../FormDispatch";
import FormState from "../FormState";
import MapI from "../../Handler/MapSearch";
import { Info } from "../../Layout/Icons";

function Documents(props) {
  const formDispatch = useContext(FormDispatch);
  const formState = useContext(FormState);
  const [backCount, setBackCount] = useState(0);
  const [displayPicture, setDisplayPicture] = useState(null);
  const [displayPictureError, setDisplayPictureError] = useState("");
  const [displayPictureFile, setDisplayPictureFile] = useState(null);

  const [thumbrpintLeft, setThumbprintLeft] = useState(null);
  const [thumbrpintLeftError, setThumbprintLeftError] = useState("");
  const [thumbrpintLeftFile, setThumbprintLeftFile] = useState(null);

  const [thumbrpintRight, setThumbprintRight] = useState(null);
  const [thumbrpintRightError, setThumbprintRightError] = useState("");
  const [thumbrpintRightFile, setThumbprintRightFile] = useState(null);

  const [citizenshipBack, setCitizenshipBack] = useState(null);
  const [citizenshipBackError, setCitizenshipBackError] = useState("");
  const [citizenshipBackFile, setCitizenshipBackFile] = useState(null);

  const [citizenshipFront, setCitizenshipFront] = useState(null);
  const [citizenshipFrontError, setCitizenshipFrontError] = useState("");
  const [citizenshipFrontFile, setCitizenshipFrontFile] = useState(null);

  const [signature, setSignature] = useState(null);
  const [signatureError, setSignatureError] = useState("");
  const [signatureFile, setSignatureFile] = useState(null);

  const [birthCertificate, setBirthCertificate] = useState(null);
  const [birthCertificateError, setBirthCertificateError] = useState("");
  const [birthCertificateFile, setBirthCertificateFile] = useState(null);

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [docCount, setDocCount] = useState(0);

  const handleSubmit = () => {
    if (errorCount < 1) {
      setSubmitCount(submitCount + 1);
    }
  };

  // useEffect(() => {
  //   if (
  //     !displayPicture ||
  //     !thumbrpintLeft ||
  //     !thumbrpintRight ||
  //     !citizenshipBack ||
  //     !citizenshipFront ||
  //     !signature
  //   ) {
  //     setErrorCount(errorCount + 1);
  //     setErrors("This document is required");
  //   }
  // }, []);

  useEffect(() => {
    if (submitCount > 0) {
      if (!formState.showGuardian) {
        if (!displayPicture) {
          setErrorCount(errorCount + 1);
          setDisplayPictureError("This document is required");
          setDocCount(0);
        }
        if (!thumbrpintLeft) {
          setErrorCount(errorCount + 1);
          setThumbprintLeftError("This document is required");
          setDocCount(0);
        }
        if (!thumbrpintRight) {
          setErrorCount(errorCount + 1);
          setThumbprintRightError("This document is required");
          setDocCount(0);
        }
        if (!citizenshipBack) {
          setErrorCount(errorCount + 1);
          setCitizenshipBackError("This document is required");
          setDocCount(0);
        }
        if (!citizenshipFront) {
          setErrorCount(errorCount + 1);
          setCitizenshipFrontError("This document is required");
          setDocCount(0);
        }
        if (!signature) {
          setErrorCount(errorCount + 1);
          setSignatureError("This document is required");
          setDocCount(0);
        }
        if (
          displayPicture &&
          thumbrpintLeft &&
          thumbrpintRight &&
          citizenshipBack &&
          citizenshipFront &&
          signature
        ) {
          setErrorCount(0);
          setDocCount(docCount + 1);
          setDisplayPictureError("");
          setThumbprintLeftError("");
          setThumbprintRightError("");
          setCitizenshipBackError("");
          setCitizenshipFrontError("");
          setSignatureError("");
        }
      }
      if (formState.showGuardian) {
        if (!birthCertificate) {
          setErrorCount(errorCount + 1);
          setBirthCertificateError("This document is required");
          setDocCount(0);
        }
        if (birthCertificate) {
          setErrorCount(0);
          setBirthCertificateError("");
          setDocCount(docCount + 1);
        }
      }
    }
  }, [submitCount]);

  useEffect(() => {
    if (submitCount > 0 && errorCount < 1 && docCount > 0) {
      if (!formState.showGuardian) {
        formDispatch({
          type: "saveDocuments",
          value: {
            photo: displayPicture,
            gov_issued_id_front: citizenshipFront,
            gov_issued_id_back: citizenshipBack,
            thumb_left: thumbrpintLeft,
            thumb_right: thumbrpintRight,
            signature: signature,
            lat: latitude,
            long: longitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            photo_file: displayPictureFile,
            gov_issued_id_front_file: citizenshipFrontFile,
            gov_issued_id_back_file: citizenshipBackFile,
            thumb_left_file: thumbrpintLeftFile,
            thumb_right_file: thumbrpintRightFile,
            signature_file: signatureFile,
          },
        });
      } else {
        formDispatch({
          type: "saveDocuments",
          value: {
            birth_certificate: birthCertificate,
            lat: latitude,
            long: longitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            birth_certificate_file: birthCertificateFile,
          },
        });
      }
      formDispatch({ type: "activeTabs", value: "agreement" });
      console.log("documents saved from the documents component");
      console.log("documentCountInside", submitCount);
    }
  }, [docCount]);

  function lng(e) {
    setLongitude(e);
  }
  function lat(e) {
    setLatitude(e);
  }

  function handleBackCount(e) {
    e.preventDefault();
    setBackCount(backCount + 1);
  }
  // console.log(errors);
  useEffect(() => {
    if (backCount > 0) {
      formDispatch({ type: "activeTabs", value: "occupation" });
    }
  }, [backCount]);

  return (
    <form
      action=""
      className="form position-relative"
      encType="multipart/form-data"
    >
      <div className="form-container personal-info">
        <div className="text-container">
          <h2>Upload your documents</h2>
          <span
            className="position-absolute end-0 top-0"
            onClick={() => {
              formDispatch({ type: "setInfo" });
            }}
          >
            <Info />
          </span>
        </div>
        {!formState.showGuardian && (
          <>
            <div className="row mb-5">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Profile Picture</p>
                  </div>
                  <div className="card-body">
                    <DisplayPicture
                      setDisplayPicture={setDisplayPicture}
                      setDisplayPictureFile={setDisplayPictureFile}
                      setErrors={displayPictureError ? displayPictureError : ""}
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Thumbprint Left</p>
                  </div>
                  <div className="card-body">
                    <ThumbPrintLeft
                      setThumbprintLeft={setThumbprintLeft}
                      setThumbprintLeftFile={setThumbprintLeftFile}
                      setErrors={thumbrpintLeftError ? thumbrpintLeftError : ""}
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Thumbprint Right</p>
                  </div>
                  <div className="card-body">
                    <ThumbprintRight
                      setThumbprintRight={setThumbprintRight}
                      setThumbprintRightFile={setThumbprintRightFile}
                      setErrors={
                        thumbrpintRightError ? thumbrpintRightError : ""
                      }
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Citizenship card front</p>
                  </div>
                  <div className="card-body">
                    <CitizenshipFront
                      setCitizenshipFront={setCitizenshipFront}
                      setCitizenshipFrontFile={setCitizenshipFrontFile}
                      setErrors={
                        citizenshipFrontError ? citizenshipFrontError : ""
                      }
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Citizenship card back</p>
                  </div>
                  <div className="card-body">
                    <CitizenshipBack
                      setCitizenshipBack={setCitizenshipBack}
                      setCitizenshipBackFile={setCitizenshipBackFile}
                      setErrors={
                        citizenshipBackError ? citizenshipBackError : ""
                      }
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <p className="lead">Your Signature</p>
                  </div>
                  <div className="card-body">
                    <Signature
                      setSignature={setSignature}
                      setSignatureFile={setSignatureFile}
                      setErrors={signatureError ? signatureError : ""}
                      setErrorCount={setErrorCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {formState.showGuardian && (
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <p className="lead">Your Birth Certificate</p>
                </div>
                <div className="card-body">
                  <BirthCertificate
                    setBirthCertificate={setBirthCertificate}
                    setBirthCertificateFile={setBirthCertificateFile}
                    setErrors={
                      birthCertificateError ? birthCertificateError : ""
                    }
                    setErrorCount={setErrorCount}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-md-12">
            <div
              className="mapGl"
              style={{ height: "100%", width: "100%", position: "relative" }}
            >
              {/* <Maps setLng={lng} setLat={lat} /> */}
              <MapI setLng={lng} setLat={lat} height={"100vh"} />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-12">
          <span
            className="btn-transparent mr-2 "
            // to="/customers/create/occupation"
            onClick={handleBackCount}
          >
            <span>← </span>
            Previous Page
          </span>
          <span onClick={handleSubmit} className="btn-blue">
            Next Page
            <span> →</span>
          </span>
        </div>
      </div>
    </form>
  );
}

export default withRouter(Documents);
