import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import StateContext from "../../../../../StateContext";
import Loading from "../../../../Layout/Loading";

const MeroShare = () => {
  const appState = useContext(StateContext);
  const { id } = useParams();

  const initialState = {
    userLoaded: false,
    userData: {},
    hasGuardianDoc: false,
    guardianDoc: {},
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUser":
        draft.userData = action.value;
        return;
      case "userLoaded":
        draft.userLoaded = true;
        return;
      case "hasGuardianDoc":
        draft.hasGuardianDoc = true;
        draft.guardianDoc = action.value;
        return;
      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`,
          { cancelToken: ourRequest.token }
        );
        // console.log(response.data.data);
        dispatch({ type: "loadUser", value: response.data.data });
        dispatch({ type: "userLoaded" });
      } catch (e) {
        console.log(e, "there was an error fetching customers");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    if (state.userLoaded) {
      if (state.userData.relationships && state.userData.relationships.length) {
        state.userData.relationships.map((item, i) => {
          if (item.type === "guardian") {
            dispatch({ type: "hasGuardianDoc", value: item.documents[0] });
          }
        });
      }
    }
  }, [state.userLoaded]);

  if (!state.userLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="demat">
      <div className="mero-share">
        <div className="mero-share-form">
          <div className="container">
            <div className="page-header">
              <div className="left">
                <h1>
                  Online <br /> Mero Share <br /> Application Form
                </h1>
              </div>
              <div className="right">
                <div className="img-container">
                  <img src="../gurkhas-red.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <p>
                  To, <br />
                  The Manger <br />
                  <span className="text-input"> Head Office</span>
                  <br />
                  <span className="">Branch,</span>
                </p>
              </div>
              <div className="col-md-4">
                <p>
                  Date:{" "}
                  <span className="text-input">
                    {state.userData.created_at}
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className=" col my-3 text-center">
                <p className="text-underline">To Access Meroshare Online</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Dear Sir/Madam, <br />I hearby apply for the Gurkhas Finance
                  Ltd. Demat online facility to enable me to avail user access
                  to my account with you.
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <div className="text-box text-center">
                  <p>
                    My demat account details is: <br />
                    <span className="input-group single-digit">
                      <span>1</span>
                      <span>3</span>
                      <span>0</span>
                      <span>1</span>
                      <span>8</span>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  <span className="text-placeholder">Name:</span>
                  <span className="text-input">{state.userData.full_name}</span>
                </p>
                <p>
                  <span className="text-placeholder">Mobile No:</span>
                  <span className="text-input">{state.userData.mobile}</span>
                </p>
                <p>
                  <span className="text-placeholder">Email ID:</span>
                  <span className="text-input">{state.userData.email}</span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="signature-container">
                  <div className="image-container">
                    {state.hasGuardianDoc ? (
                      <>
                        <img
                          src={
                            `${process.env.REACT_APP_BASE_URL}/img/` +
                            state.userData.email +
                            "/guardian/" +
                            state.guardianDoc?.signature_name
                          }
                          alt=""
                          style={{ height: "50px", width: "200px" }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            `${process.env.REACT_APP_BASE_URL}/img/` +
                            state.userData.email +
                            "/" +
                            state.userData.documents[0]?.signature_name
                          }
                          alt=""
                          style={{ height: "50px", width: "200px" }}
                        />
                      </>
                    )}
                  </div>
                  <span className="text-input"></span>
                  <p className="text-center">Signature</p>
                </div>
                <br />
                <p>*Charge for Meroshare Login is Rs.50</p>
                <p>
                  Note: The username and Password of Meroshare will be sent to
                  the E-mail address provided in this application form
                </p>
                <br />
              </div>
            </div>

            {/* <style>
              .nepaliFont p
              {{
                fontSize: "13px",
              }}
            </style> */}
            <div className="row">
              <div className="col-md-12 nepaliFont">
                <p className="text-center">
                  <u>मेरो शेयरको सेवा सम्बन्धी नियम तथा शर्तहरु</u>
                </p>
                <p>
                  १. मेरो शेयर सेवा केवल व्यक्तगत प्रयोजनको लागि मात्र हो । यो
                  अन्य कसैलाई हस्तान्तरण गरिने छैन ।
                </p>
                <p>
                  २. ग्राहकले मेरो शेयर सेवासँग सम्बन्धित विद्युतीइ कारोबारको
                  लागि दिईएका सुरक्षित गोप्य नाम, पासवर्ड, पिन आदि जानकारीको
                  गोपनियता कायम गर्नेछ र यी जानकारीहरु कुनै पनि अवस्थमा कसैलाई
                  कहिले पनि दिने छैन ।
                </p>
                <p>
                  ३. यदि आफ्ना गोप्य पासवर्ड नम्बर पिन हराएको वा सो को जानकारी
                  कही कतै असम्बन्धित व्यक्तिको जानकारीमा आएको थाहा भएमा तुरुन्त
                  आफ्नो निक्षेप सदस्यलाई जानकारी गरार्य रद्द गराउनुपर्नेछ र
                  परिवर्तन गराउनु पर्नेछ ।
                </p>
                <p>
                  ४. मेरो शेयर मार्फत् गरिएका सम्पूर्ण कारोबारको जिम्मेवारी
                  ग्राहक स्वयंको हुनेछ ।
                </p>
                <p>
                  ५. मेरो शेयरको प्रयोग सधै सिडिएससिको प्रचलित नियम र विनियमका
                  अधिनमा रहने छ ।
                </p>
                <p>
                  ६. प्राविधिक कारणले सेवा अवरुद्ध हुन गई ग्राहकलाई हुन गएको
                  असुविधा, क्षति वा हानी नोक्सानीको लागि निक्षेष् सदस्य/सिडिएससि
                  कुनै पनि किसिमले जिम्मेवार हुने छैन ।
                </p>
                <p>
                  ७. यो निवेदनमा निक्षेप सदस्य/सिडिएससिको स्वीकृति आवश्यक पर्नेछ
                  । निक्षेप सदस्य/सिडिएससिसँग कुनै कारण नखुलाई आफ्नो स्वविवेकमा
                  निवेदन अस्वीकृत गर्ने अधिकार रहने छ ।
                </p>
                <p>
                  ८. ग्राहकलाई सुचित गरी वा नगरी यो सुविधा नवीकरण गर्ने, रद्द
                  गर्ने वा फिर्ता लिने अधिकार निक्षेप सदस्य/सिडिएसिलाई रहने छ ।
                </p>

                <br />
                <p>उद्घोषणः</p>

                <p>
                  म/हामी माथि उल्लेखित सम्पूर्ण विवरण साँचो रहेको घोषणा
                  गर्दछु/गर्दछौं । यदि उल्लेखित विवरणहरु कुनै कारणवश गलत भएको
                  अथवा गलत प्रमाणित भएको अवस्थामा त्यसबाट सिर्जना हुने सम्पूर्ण
                  परिस्थितिको जिम्मेवार म/हामी स्वयं हुनेछु/छौं । साथै माथि
                  उल्लेखित मोबाईल नम्बर तथा इमेल मेरो/हाम्रो व्यक्तिगत प्रयोजनका
                  लागि प्रयोग हुनेछ तथा मेरो शेयरको सेवाका लागि सिडिएससिबाट
                  प्रदान गरिने Login ID तथा Password हरु उल्लेखित मोबाईल नम्बर
                  वा इमेल ठेगानामा प्राप्त गर्न मेरो/हाम्रो मन्जुरी छ । मेरो
                  शेयरको सेवा उपभोग गर्ने सम्बन्धमा सिडिएससिद्वारा जारी गरिएका
                  यस सेवासँग सम्बन्धित माथि उल्लेख गरिएका सम्पूर्ण नियम, शर्तहरु
                  राम्ररी पढी बुझि स्वीकार गर्दछु/गर्दछौं ।
                </p>

                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="signature-container">
                  <div className="image-container">
                    {state.hasGuardianDoc ? (
                      <>
                        <img
                          src={
                            `${process.env.REACT_APP_BASE_URL}/img/` +
                            state.userData.email +
                            "/guardian/" +
                            state.guardianDoc?.signature_name
                          }
                          alt=""
                          style={{ height: "50px", width: "200px" }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            `${process.env.REACT_APP_BASE_URL}/img/` +
                            state.userData.email +
                            "/" +
                            state.userData.documents[0]?.signature_name
                          }
                          alt=""
                          style={{ height: "50px", width: "200px" }}
                        />
                      </>
                    )}
                  </div>
                  <span className="text-input"></span>
                  <p className="text-center">हस्ताक्षर</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeroShare;
