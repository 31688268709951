import React, { useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import StateContext from "../../../StateContext";

// reactstrap components
import { Container, Card } from "reactstrap";
import UserList from "./UserView/UserListView/UserList";
import Loading from "../../Layout/Loading";
import UserEdit from "./UserView/UserEdit";
import UserCreate from "./UserView/UserCreate";

function Users() {
  const appState = useContext(StateContext);
  const initialState = {
    users: [],
    roles: [],
    customers: [],
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "loadUsers":
        draft.users = action.value;
        return;
      case "sortUsers":
        draft.roles = draft.users.filter(
          (item) => item.roles[0]?.name !== "customer"
        );
        draft.customers = draft.users.filter(
          (item) => item.roles[0]?.name === "customer"
        );
        return;

      case "default":
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${appState.user.data.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const ourRequest = axios.CancelToken.source();
    async function fetchCustomers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/users`,
          {
            cancelToken: ourRequest.token,
          }
        );
        dispatch({ type: "loadUsers", value: response.data.data });
      } catch (e) {
        console.log(e, "there was an error fetching users");
      }
    }
    fetchCustomers();
    return () => ourRequest.cancel();
  }, []);

  useEffect(() => {
    dispatch({ type: "sortUsers" });
  }, [state.users]);

  // console.log(state.adminRoles);

  if (!state.users?.length) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <Container className="mt-8" fluid>
        <Card className="shadow">
          <Switch>
            <Route path="/admin/dashboard/users" exact>
              <UserList data={state.roles} />
            </Route>
            <Route path="/admin/dashboard/users/customer" exact>
              <UserList data={state.customers} />
            </Route>
            <Route path="/admin/dashboard/users/create" exact>
              <UserCreate data={state.adminRoles} />
            </Route>
            <Route path="/admin/dashboard/users/:id">
              <UserEdit />
            </Route>
          </Switch>
        </Card>
      </Container>
    </>
  );
}

export default Users;
