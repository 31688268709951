import React, { useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import axios from "axios";
import DatePicker from "react-datepicker";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import adbs from "ad-bs-converter";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import FormDispatch from "../FormDispatch";
import provinceData from "../../../Assets/province-district.json";
import districts from "../../../Assets/district-list-nepal";

import CitizenshipFront from "../../Handler/Documents/CitizenshipFront";
import CitizenshipBack from "../../Handler/Documents/CitizenshipBack";
import Signature from "../../Handler/Documents/Signature";
import DisplayPicture from "../../Handler/Documents/DisplayPicture";

const Nominee = ({ data, userData }) => {
  const formDispatch = useContext(FormDispatch);
  const [errorCount, setErrorCount] = useState(0);

  const initialState = {
    errorCount: 0,
    sendCount: 0,
    formData: {
      nameOfGuardian: {
        name: "nameOfGuardian",
        value: data?.full_name,
        hasErrors: false,
        message: "",
      },
      relationshipWithApplicant: {
        name: "relationshipWithApplicant",
        value: data?.relation,
        hasErrors: false,
        message: "",
      },
      fathersName: {
        name: "fathersName",
        value: data?.father_name,
        hasErrors: false,
        message: "",
      },
      grandFathersName: {
        name: "grandFathersName",
        value: data?.grand_father_name,
        hasErrors: false,
        message: "",
      },
      mobile: {
        name: "mobile",
        value: data?.mobile,
        hasErrors: false,
        message: "",
      },
      email: {
        name: "email",
        value: data?.email,
        hasErrors: false,
        message: "",
      },
      typeOfIdCard: {
        name: "typeOfIdCard",
        value: data?.personal_informations[0]?.identity_card_type,
        hasErrors: false,
        message: "",
      },
      idNo: {
        name: "idNo",
        value: data?.personal_informations[0]?.identity_card_number,
        hasErrors: false,
        message: "",
      },
      idIssueDistrict: {
        name: "idIssueDistrict",
        value: data?.personal_informations[0]?.identity_card_issue_district,
        hasErrors: false,
        message: "",
      },
      idIssueDateBs: {
        name: "idIssueDateBs",
        value: "",
        hasErrors: false,
        message: "",
      },
      idIssueDate: {
        name: "idIssueDate",
        value: data?.personal_informations[0]?.identity_card_issue_date,
        hasErrors: false,
        message: "",
      },
      pan: {
        name: "pan",
        value: data?.personal_informations[0]?.pan_number,
        hasErrors: false,
        message: "",
        touched: true,
        checkCount: 0,
        checkingNow: false,
      },

      addresses: {
        temporary: {
          country: {
            name: "country",
            value: data?.addresses[0]?.country,
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: data?.addresses[0]?.province,
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: data?.addresses[0]?.block_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: data?.addresses[0]?.phone_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: data?.addresses[0]?.ward_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: data?.addresses[0]?.locality,
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: data?.addresses[0]?.municipality,
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: data?.addresses[0]?.city,
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: data?.addresses[0]?.district,
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
        permanent: {
          country: {
            name: "country",
            value: data?.addresses[1]?.country,
            hasErrors: false,
            message: "",
          },
          province: {
            name: "province",
            value: data?.addresses[1]?.province,
            hasErrors: false,
            message: "",
            list: [],
          },
          block_number: {
            name: "block_number",
            value: data?.addresses[1]?.block_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          phone_number: {
            name: " phone_number",
            value: data?.addresses[1]?.phone_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          ward_number: {
            name: "ward_number",
            value: data?.addresses[1]?.ward_number,
            hasErrors: false,
            message: "",
            touched: false,
          },
          locality: {
            name: "locality",
            value: data?.addresses[1]?.locality,
            hasErrors: false,
            message: "",
            touched: false,
          },
          municipality: {
            name: "municipality",
            value: data?.addresses[1]?.municipality,
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
          city: {
            name: "city",
            value: data?.addresses[1]?.city,
            hasErrors: false,
            message: "",
            touched: false,
          },
          district: {
            name: "district",
            value: data?.addresses[1]?.district,
            hasErrors: false,
            message: "",
            list: [],
            listToShow: [],
            touched: false,
          },
        },
      },
      documents: {
        displayPicture: {
          value: data?.documents[0]?.photo_name,
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipBack: {
          value: data?.documents[0]?.gov_issued_id_back_name,
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        citizenshipFront: {
          value: data?.documents[0]?.gov_issued_id_front_name,
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
        signature: {
          value: data?.documents[0]?.signature_name,
          file: null,
          hasErrors: false,
          message: "",
          required: true,
        },
      },
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "inputChange":
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            // console.log(action.field, "fullname")
            draft.formData[key].hasErrors = false;
            draft.formData[key].touched = true;
            draft.formData[key].value = action.value;
            if (draft.formData[key].name == "mobile") {
              if (action.value.length > 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message =
                  "mobile number cannot exceed 10 digits";
              }
            }
            if (draft.formData[key].name == "pan") {
              if (action.value.length > 9) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message =
                  "pan number cannot exceed 9 digits";
              }
            }
          }
        }
        return;
      case "inputBlur":
        console.log("this reducer hit");
        for (const key in draft.formData) {
          if (draft.formData[key].name == action.field) {
            console.log("action.value", action.value);
            if (action.value.trim() == "") {
              draft.formData[key].hasErrors = true;
              draft.formData[key].message = `This field is required`;
              return;
            }

            draft.formData[key].value = action.value;
            if (draft.formData[key].name == "email") {
              if (
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  draft.formData.email.value
                )
              ) {
                draft.formData.email.hasErrors = true;
                draft.formData.email.message =
                  "You must provide a valid email address";
                return;
              }
            }
            if (draft.formData[key].name == "mobile") {
              if (action.value.length != 10) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message = "mobile number must be 10 digits";
              }
            }
            if (draft.formData[key].name == "pan") {
              console.log("this is a pan number");
              if (action.value.length != 9) {
                draft.formData[key].hasErrors = true;
                draft.formData[key].message = "pan number must be 9 digits";
              }
            }
          }
        }
        return;
      case "idIssueDateChange":
        console.log("id issue date change reducer hit");
        draft.formData.idIssueDate.touched = true;
        draft.formData.idIssueDate.hasErrors = false;
        draft.formData.idIssueDate.value = action.value;
        draft.formData.idIssueDate.dateFormatted = formatDate(action.value);
        return;
      case "renderProvinces":
        console.log("this reducer was hit at the render proinces");
        if (!draft.formData.addresses.temporary.province.list.length) {
          for (const key in provinceData) {
            draft.formData.addresses.temporary.province.list.push(key);
            draft.formData.addresses.permanent.province.list.push(key);
          }
        }
        return;
      case "renderDistricts":
        for (const key in provinceData) {
          provinceData[key].map((item, i) => {
            draft.formData.addresses.temporary.district.list.push(
              item.district
            );
            draft.formData.addresses.permanent.district.list.push(
              item.district
            );
          });
        }
        draft.formData.addresses.temporary.district.listToShow =
          draft.formData.addresses.temporary.district.list;
        draft.formData.addresses.permanent.district.listToShow =
          draft.formData.addresses.permanent.district.list;
        return;
      case "renderMunicipality":
        for (const key in provinceData) {
          draft.formData.addresses.temporary.municipality.list.push();
          provinceData[key].map((item, i) => {
            item.municipality.map((item, i) => {
              draft.formData.addresses.temporary.municipality.list.push(item);
              draft.formData.addresses.permanent.municipality.list.push(item);
            });
          });
        }
        draft.formData.addresses.temporary.municipality.listToShow =
          draft.formData.addresses.temporary.municipality.list;
        draft.formData.addresses.permanent.municipality.listToShow =
          draft.formData.addresses.permanent.municipality.list;
        return;
      case "temporaryInputChange":
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].name == action.field) {
            draft.formData.addresses.temporary[key].hasErrors = false;
            draft.formData.addresses.temporary[key].value = action.value;
            if (draft.formData.addresses.temporary[key].name == "province") {
              draft.formData.addresses.temporary.district.listToShow = [];
              draft.formData.addresses.temporary.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.temporary.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.temporary[key].name == "district") {
              draft.formData.addresses.temporary.municipality.listToShow = [];
              draft.formData.addresses.temporary.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.temporary.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.temporary.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "permanentInputChange":
        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].name == action.field) {
            draft.formData.addresses.permanent[key].hasErrors = false;
            draft.formData.addresses.permanent[key].value = action.value;
            if (draft.formData.addresses.permanent[key].name == "province") {
              draft.formData.addresses.permanent.district.listToShow = [];
              draft.formData.addresses.permanent.district.value = "";
              let selectedProvince;
              for (const key in provinceData) {
                selectedProvince = provinceData[action.value];
              }
              selectedProvince.map((item, i) => {
                draft.formData.addresses.permanent.district.listToShow.push(
                  item.district
                );
              });
            }
            if (draft.formData.addresses.permanent[key].name == "district") {
              draft.formData.addresses.permanent.municipality.listToShow = [];
              draft.formData.addresses.permanent.municipality.value = "";
              let selectedDistrict;
              for (const key in provinceData) {
                selectedDistrict =
                  provinceData[
                    draft.formData.addresses.permanent.province.value
                  ];
              }
              selectedDistrict.map((item, i) => {
                if (item.district === action.value) {
                  item.municipality.map((item, i) => {
                    draft.formData.addresses.permanent.municipality.listToShow.push(
                      item
                    );
                  });
                }
              });
            }
          }
        }
        return;
      case "setDisplayPicture":
        draft.formData.documents.displayPicture.hasErrors = false;
        draft.formData.documents.displayPicture.value = action.value;
        return;
      case "setDisplayPictureFile":
        draft.formData.documents.displayPicture.file = action.value;
        return;
      case "setCitizenshipBack":
        draft.formData.documents.citizenshipBack.hasErrors = false;
        draft.formData.documents.citizenshipBack.value = action.value;
        return;
      case "setCitizenshipBackFile":
        draft.formData.documents.citizenshipBack.file = action.value;
        return;
      case "setCitizenshipFront":
        draft.formData.documents.citizenshipFront.hasErrors = false;
        draft.formData.documents.citizenshipFront.value = action.value;
        return;
      case "setCitizenshipFrontFile":
        draft.formData.documents.citizenshipFront.file = action.value;
        return;
      case "setSignature":
        draft.formData.documents.signature.hasErrors = false;
        draft.formData.documents.signature.value = action.value;
        return;
      case "setSignatureFile":
        draft.formData.documents.signature.file = action.value;
        return;
      case "setBirthCertificate":
        draft.formData.documents.birthCertificate.hasErrors = false;
        draft.formData.documents.birthCertificate.value = action.value;
        return;
      case "setBirthCertificateFile":
        draft.formData.documents.birthCertificate.file = action.value;
        return;
      case "sameAsCurrent":
        draft.formData.addresses.permanent.country.value =
          draft.formData.addresses.temporary.country.value;
        draft.formData.addresses.permanent.country.touched = true;
        draft.formData.addresses.permanent.province.value =
          draft.formData.addresses.temporary.province.value;
        draft.formData.addresses.permanent.province.touched = true;
        draft.formData.addresses.permanent.district.value =
          draft.formData.addresses.temporary.district.value;
        draft.formData.addresses.permanent.district.touched = true;
        draft.formData.addresses.permanent.municipality.value =
          draft.formData.addresses.temporary.municipality.value;
        draft.formData.addresses.permanent.municipality.touched = true;
        draft.formData.addresses.permanent.city.value =
          draft.formData.addresses.temporary.city.value;
        draft.formData.addresses.permanent.city.touched = true;
        draft.formData.addresses.permanent.locality.value =
          draft.formData.addresses.temporary.locality.value;
        draft.formData.addresses.permanent.locality.touched = true;
        draft.formData.addresses.permanent.ward_number.value =
          draft.formData.addresses.temporary.ward_number.value;
        draft.formData.addresses.permanent.ward_number.touched = true;
        draft.formData.addresses.permanent.block_number.value =
          draft.formData.addresses.temporary.block_number.value;
        draft.formData.addresses.permanent.block_number.touched = true;
        draft.formData.addresses.permanent.phone_number.value =
          draft.formData.addresses.temporary.phone_number.value;
        draft.formData.addresses.permanent.phone_number.touched = true;
        draft.formData.addresses.permanent.country.hasErrors = false;
        draft.formData.addresses.permanent.province.hasErrors = false;
        draft.formData.addresses.permanent.district.hasErrors = false;
        draft.formData.addresses.permanent.municipality.hasErrors = false;
        draft.formData.addresses.permanent.city.hasErrors = false;
        draft.formData.addresses.permanent.locality.hasErrors = false;
        draft.formData.addresses.permanent.ward_number.hasErrors = false;
        draft.formData.addresses.permanent.block_number.hasErrors = false;
        draft.formData.addresses.permanent.phone_number.hasErrors = false;
        return;
      case "differentFromCurrent":
        draft.formData.addresses.permanent.country.value = "";
        draft.formData.addresses.permanent.province.value = "";
        draft.formData.addresses.permanent.district.value = "";
        draft.formData.addresses.permanent.municipality.value = "";
        draft.formData.addresses.permanent.city.value = "";
        draft.formData.addresses.permanent.locality.value = "";
        draft.formData.addresses.permanent.ward_number.value = "";
        draft.formData.addresses.permanent.block_number.value = "";
        draft.formData.addresses.permanent.phone_number.value = "";
        draft.formData.addresses.permanent.country.touched = false;
        draft.formData.addresses.permanent.province.touched = false;
        draft.formData.addresses.permanent.district.touched = false;
        draft.formData.addresses.permanent.municipality.touched = false;
        draft.formData.addresses.permanent.city.touched = false;
        draft.formData.addresses.permanent.locality.touched = false;
        draft.formData.addresses.permanent.ward_number.touched = false;
        draft.formData.addresses.permanent.block_number.touched = false;
        draft.formData.addresses.permanent.phone_number.touched = false;
        return;
      case "validateForm":
        //check for errors
        console.log("handle form reducer thit at gurdfains");
        draft.errorCount = 0;
        for (const key in draft.formData) {
          if (draft.formData[key].value == "") {
            console.log("there is an errror");
            draft.errorCount++;
            // console.log(draft.formData[key].name);
            // console.log(draft.errorCount);
            draft.formData[key].hasErrors = true;
            draft.formData[key].message = "This field is required";
          }
        }
        //check for errors in address section
        for (const key in draft.formData.addresses.temporary) {
          if (draft.formData.addresses.temporary[key].value == "") {
            console.log("there is an errror in temporary address section");
            draft.errorCount++;
            // console.log(draft.formData.addresses.temporary[key].name);
            // console.log(draft.errorCount);
            draft.formData.addresses.temporary[key].hasErrors = true;
            draft.formData.addresses.temporary[key].message =
              "This field is required";
          }
        }
        for (const key in draft.formData.addresses.permanent) {
          if (draft.formData.addresses.permanent[key].value == "") {
            console.log("there is an errror in permanent address section");
            draft.errorCount++;
            // console.log(draft.formData.addresses.permanent[key].name);
            // console.log(draft.errorCount);
            draft.formData.addresses.permanent[key].hasErrors = true;
            draft.formData.addresses.permanent[key].message =
              "This field is required";
          }
        }
        //check for errors in the documents sectoin
        for (const key in draft.formData.documents) {
          console.log(
            "the loop now runs for the documents seciton foo the guardian"
          );
          if (draft.formData.documents[key].value == "") {
            draft.errorCount++;
            draft.formData.documents[key].hasErrors = true;
            draft.formData.documents[key].message = "This document is required";
          }
        }

        //dispatch the state if error count is o0
        //check which elemnt has Error
        if (!draft.errorCount) {
          draft.sendCount++;
        }
        return;
      case "panChange":
        draft.formData.pan.touched = true;
        draft.formData.pan.hasErrors = false;
        draft.formData.pan.value = action.value;
        return;
      case "validatePAN":
        if (action.value.length < 9 && action.value.length > 0) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be less than 9 digits";
          return;
        }
        if (action.value.charAt(0) === "0") {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message = "PAN number invalid";
          return;
        }
        if (action.value.length === 0) {
          draft.formData.pan.hasErrors = false;
          draft.formData.pan.message = "";
          return;
        } else if (action.value.length > 9) {
          draft.formData.pan.hasErrors = true;
          draft.formData.pan.message =
            "PAN number must not be more than 9 digits";
          return;
        }
        draft.formData.pan.checkCount++;
        return;
      case "checkingPAN":
        draft.formData.pan.checkingNow = true;
        draft.formData.pan.message = "Checking....";
        return;
      case "panAvailable":
        draft.formData.pan.message = "PAN number available";
        draft.formData.pan.hasErrors = false;
        return;
      case "panUnavailable":
        draft.formData.pan.message = "PAN number already in use";
        draft.formData.pan.hasErrors = true;
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    dispatch({ type: "renderProvinces" });
    dispatch({ type: "renderDistricts" });
    dispatch({ type: "renderMunicipality" });
  }, []);

  function handleCheck(e) {
    // console.log("value of the checkbox", e.target.checked);
    if (e.target.checked) {
      dispatch({ type: "sameAsCurrent" });
    } else {
      dispatch({ type: "differentFromCurrent" });
    }
  }

  const handleIdIssueDateBs = ({ bsDate, adDate }) => {
    dispatch({ type: "inputChange", value: bsDate, field: "idIssueDateBs" });
    dispatch({
      type: "idIssueDateChange",
      value: new Date(adDate),
      field: "idIssueDate",
    });
  };

  function handleIdIssueDate(date) {
    // console.log(date);
    dispatch({
      type: "idIssueDateChange",
      value: date,
      field: "idIssueDate",
    });
    const convertedNepaliDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    ).ne;
    const formattedNepaliDate = `${convertedNepaliDate.year}-${convertedNepaliDate.month}-${convertedNepaliDate.day}`;
    dispatch({
      type: "inputChange",
      value: formattedNepaliDate,
      field: "idIssueDateBs",
    });
  }
  // console.log("de", state.formData.idIssueDate.value);
  function formatDate(dateAd) {
    let preFormattedAte = new Date(dateAd);
    let year = preFormattedAte.getFullYear();
    let month = "";
    let date = "";
    console.log(preFormattedAte.getMonth() + 1);
    if (parseInt(preFormattedAte.getMonth() + 1) < 10) {
      month = `0${preFormattedAte.getMonth() + 1}`;
    } else {
      month = preFormattedAte.getMonth() + 1;
    }
    if (parseInt(preFormattedAte.getDate()) < 10) {
      date = `0${preFormattedAte.getDate()}`;
    } else {
      date = preFormattedAte.getDate();
    }
    return `${year}-${month}-${date}`;
  }

  function handleSubmit() {
    console.log("handle submit function");
    dispatch({ type: "validateForm" });
  }

  function setDisplayPicture(e) {
    dispatch({ type: "setDisplayPicture", value: e });
  }
  function setDisplayPictureFile(e) {
    dispatch({ type: "setDisplayPictureFile", value: e });
  }
  function setCitizenshipBack(e) {
    dispatch({ type: "setCitizenshipBack", value: e });
  }
  function setCitizenshipBackFile(e) {
    dispatch({ type: "setCitizenshipBackFile", value: e });
  }
  function setCitizenshipFront(e) {
    dispatch({ type: "setCitizenshipFront", value: e });
  }
  function setCitizenshipFrontFile(e) {
    dispatch({ type: "setCitizenshipFrontFile", value: e });
  }
  function setSignature(e) {
    dispatch({ type: "setSignature", value: e });
  }
  function setSignatureFile(e) {
    dispatch({ type: "setSignatureFile", value: e });
  }

  useEffect(() => {
    if (state.submitCount.counter >= 1) {
      console.log("form dispatch form the nominee info here");
      formDispatch({
        type: "saveNominee",
        value: {
          type: "2",
          id: data?.id,
          full_name: state.formData.nameOfGuardian.value,
          email: state.formData.email.value,
          relation: state.formData.relationshipWithApplicant.value,
          father_name: state.formData.fathersName.value,
          grand_father_name: state.formData.grandFathersName.value,
          mobile: state.formData.mobile.value,
          addresses: [
            {
              type: "1",
              id: data?.addresses[0]?.id,
              block_number:
                state.formData.addresses.temporary.block_number.value,
              phone_number:
                state.formData.addresses.temporary.phone_number.value,
              ward_number: state.formData.addresses.temporary.ward_number.value,
              locality: state.formData.addresses.temporary.locality.value,
              municipality:
                state.formData.addresses.temporary.municipality.value,
              district: state.formData.addresses.temporary.district.value,
              province: state.formData.addresses.temporary.province.value,
              country: state.formData.addresses.temporary.country.value,
              city: state.formData.addresses.temporary.city.value,
            },
            {
              type: "2",
              id: data?.addresses[1]?.id,
              block_number:
                state.formData.addresses.permanent.block_number.value,
              phone_number:
                state.formData.addresses.permanent.phone_number.value,
              ward_number: state.formData.addresses.permanent.ward_number.value,
              locality: state.formData.addresses.permanent.locality.value,
              municipality:
                state.formData.addresses.permanent.municipality.value,
              district: state.formData.addresses.permanent.district.value,
              province: state.formData.addresses.permanent.province.value,
              country: state.formData.addresses.permanent.country.value,
              city: state.formData.addresses.permanent.city.value,
            },
          ],
          personal_informations: {
            id: data?.personal_informations[0]?.id,
            identity_card_type: state.formData.typeOfIdCard.value,
            identity_card_number: state.formData.idNo.value,
            identity_card_issue_district: state.formData.idIssueDistrict.value,
            identity_card_issue_date: state.formData.idIssueDate.dateFormatted,
            pan_number: state.formData.pan.value,
          },
          documents: {
            id: data?.documents[0]?.id,
            photo: state.formData.documents.displayPicture.value,
            photo_id: data?.documents[0]?.photo_id,
            signature: state.formData.documents.signature.value,
            signature_id: data?.documents[0]?.signature_id,
            gov_issued_id_front: state.formData.documents.citizenshipBack.value,
            gov_issued_id_front_id: data?.documents[0]?.gov_issued_id_front_id,
            gov_issued_id_back: state.formData.documents.citizenshipFront.value,
            gov_issued_id_back_id: data?.documents[0]?.gov_issued_id_back_id,
            lat: "27.6915196",
            long: "85.3420486",
          },
        },
      });
      formDispatch({
        type: "saveNomineeDocumentsFile",
        value: {
          nominee_photo_file: state.formData.documents.displayPicture.file,
          nominee_gov_issued_id_front_file:
            state.formData.documents.citizenshipFront.file,
          nominee_gov_issued_id_back_file:
            state.formData.documents.citizenshipBack.file,
          nominee_signature_file: state.formData.documents.signature.file,
        },
      });
    }
  }, [state.submitCount.counter]);

  useEffect(() => {
    dispatch({ type: "checkCount" });
  }, [state.formData]);

  const [nomineeNameReject, setNomineeNameReject] = useState(false);
  const [relationshipReject, setRelationshipReject] = useState(false);
  const [fatherReject, setFatherReject] = useState(false);
  const [grandFatherReject, setGrandFatherReject] = useState(false);
  const [mobileReject, setMobileReject] = useState(false);
  const [emailReject, setEmailReject] = useState(false);
  const [cardTypeReject, setCardTypeReject] = useState(false);
  const [cardNumberReject, setCardNumberReject] = useState(false);
  const [cardIssueDistrictReject, setCardIssueDistrictReject] = useState(false);
  const [cardIssueDateReject, setCardIssueDateReject] = useState(false);
  const [panReject, setPANReject] = useState(false);
  const [tempCountryReject, setTempCountryReject] = useState(false);
  const [tempProvinceReject, setTempProvinceReject] = useState(false);
  const [tempDistrictReject, setTempDistrictReject] = useState(false);
  const [tempCityReject, setTempCityReject] = useState(false);
  const [tempMunicipalityReject, setTempMunicipalityReject] = useState(false);
  const [tempLocalityReject, setTempLocalityReject] = useState(false);
  const [tempWardNoReject, setTempWardNoReject] = useState(false);
  const [tempBlockNoReject, setTempBlockNoReject] = useState(false);
  const [tempPhoneNoReject, setTempPhoneNoReject] = useState(false);
  const [countryReject, setCountryReject] = useState(false);
  const [provinceReject, setProvinceReject] = useState(false);
  const [districtReject, setDistrictReject] = useState(false);
  const [cityReject, setCityReject] = useState(false);
  const [municipalityReject, setMunicipalityReject] = useState(false);
  const [localityReject, setLocalityReject] = useState(false);
  const [wardNoReject, setWardNoReject] = useState(false);
  const [blockNoReject, setBlockNoReject] = useState(false);
  const [phoneNoReject, setPhoneNoReject] = useState(false);

  useEffect(() => {
    userData.reasons.map((item, i) => {
      if (item.type === "nominee") {
        if (item.title === "name_of_nominee") {
          setNomineeNameReject(true);
        }
        if (item.title === "relationship_with_applicant") {
          setRelationshipReject(true);
        }
        if (item.title === "fathers_name") {
          setFatherReject(true);
        }
        if (item.title === "grandfathers_name") {
          setGrandFatherReject(true);
        }
        if (item.title === "mobile") {
          setMobileReject(true);
        }
        if (item.title === "email") {
          setEmailReject(true);
        }
        if (item.title === "id_card_type") {
          setCardTypeReject(true);
        }
        if (item.title === "id_number") {
          setCardNumberReject(true);
        }
        if (item.title === "id_issue_district") {
          setCardIssueDistrictReject(true);
        }
        if (item.title === "id_issue_date") {
          setCardIssueDateReject(true);
        }
        if (item.title === "pan") {
          setPANReject(true);
        }
        if (item.title === "temporary_country") {
          setTempCountryReject(true);
        }
        if (item.title === "temporary_province") {
          setTempProvinceReject(true);
        }
        if (item.title === "temporary_district") {
          setTempDistrictReject(true);
        }
        if (item.title === "temporary_city") {
          setTempCityReject(true);
        }
        if (item.title === "temporary_municipality") {
          setTempMunicipalityReject(true);
        }
        if (item.title === "temporary_locality") {
          setTempLocalityReject(true);
        }
        if (item.title === "temporary_ward_no") {
          setTempWardNoReject(true);
        }
        if (item.title === "temporary_block_no") {
          setTempBlockNoReject(true);
        }
        if (item.title === "temporary_phone_no") {
          setTempPhoneNoReject(true);
        }
        if (item.title === "temporary_country") {
          setCountryReject(true);
        }
        if (item.title === "permanent_province") {
          setProvinceReject(true);
        }
        if (item.title === "permanent_district") {
          setDistrictReject(true);
        }
        if (item.title === "permanent_city") {
          setCityReject(true);
        }
        if (item.title === "permanent_municipality") {
          setMunicipalityReject(true);
        }
        if (item.title === "permanent_locality") {
          setLocalityReject(true);
        }
        if (item.title === "permanent_ward_no") {
          setWardNoReject(true);
        }
        if (item.title === "permanent_block_no") {
          setBlockNoReject(true);
        }
        if (item.title === "permanent_phone_no") {
          setPhoneNoReject(true);
        }
      }
    });
  }, [userData]);

  // PAN Check
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    if (state.formData.pan.checkCount) {
      dispatch({ type: "checkingPAN" });
      async function checkPan() {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/verify_pan_number/${state.formData.pan.value}`,
            { cancelToken: ourRequest.token }
          );

          // console.log(response.data);
          if (response.data.success === true) {
            dispatch({ type: "panAvailable" });
          } else {
            dispatch({ type: "panUnavailable" });
          }
        } catch (e) {
          console.log(e, "there was an error");
          dispatch({ type: "panUnavailable" });
        }
      }
      checkPan();
    }
    return () => ourRequest.cancel();
  }, [state.formData.pan.checkCount]);

  return (
    <Container>
      <CardBody>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-name_of_guardian"
              >
                Name of Nominee
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  nomineeNameReject || state.formData.nameOfGuardian.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-name_of_guardian"
                type="text"
                placeholder="Nominee's Full Name"
                value={state.formData.nameOfGuardian.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "nameOfGuardian",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "nameOfGuardian",
                  })
                }
              />
              {state.formData.nameOfGuardian.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.nameOfGuardian.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guaridan-relationship_with_applicant"
              >
                Relationship with applicant
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  relationshipReject ||
                  state.formData.relationshipWithApplicant.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guaridan-relationship_with_applicant"
                placeholder="Relationship with applicant"
                value={state.formData.relationshipWithApplicant.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "relationshipWithApplicant",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "relationshipWithApplicant",
                  })
                }
              />
              {state.formData.relationshipWithApplicant.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.relationshipWithApplicant.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-fathers_name"
              >
                Father's Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  fatherReject || state.formData.fathersName.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-fathers_name"
                type="text"
                placeholder="Father's Name"
                value={state.formData.fathersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "fathersName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "fathersName",
                  })
                }
              />
              {state.formData.fathersName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.fathersName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guaridan-grandFathers_name"
              >
                GrandFather's Name
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  grandFatherReject || state.formData.grandFathersName.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guaridan-grandFathers_name"
                placeholder="GrandFather's Name"
                value={state.formData.grandFathersName.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "grandFathersName",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "grandFathersName",
                  })
                }
              />
              {state.formData.grandFathersName.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.grandFathersName.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-information-mobile"
              >
                Mobile
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  mobileReject || state.formData.mobile.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-information-mobile"
                type="text"
                placeholder="Your 10 digit phone number"
                value={state.formData.mobile.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "mobile",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "mobile",
                  })
                }
                readOnly
              />
              {state.formData.mobile.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.mobile.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guaridan-personal_information-email"
              >
                Email
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  emailReject || state.formData.email.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guaridan-personal_information-email"
                placeholder="Your email address"
                value={state.formData.email.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "email",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "email",
                  })
                }
                readOnly
              />
              {state.formData.email.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.email.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="personal_information-type_of_identity_card"
              >
                Type of Identitiy card
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  cardTypeReject || state.formData.typeOfIdCard.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="personal_information-type_of_identity_card"
                type="text"
                value={state.formData.typeOfIdCard.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "typeOfIdCard",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "typeOfIdCard",
                  })
                }
              >
                <option value="">Choose your type of ID</option>
                <option value="1">Citizenship</option>
              </select>
              {state.formData.typeOfIdCard.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.typeOfIdCard.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="personal_information-identification_number"
              >
                Identification Number
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative calendar form-control ${
                  cardNumberReject || state.formData.idNo.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="personal_information-identification_number"
                placeholder="Enter  Identification Number"
                value={state.formData.idNo.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "idNo",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "idNo",
                  })
                }
              />
              {state.formData.idNo.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.idNo.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="personal_information-id_issued_district"
              >
                ID Issued District
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  cardIssueDistrictReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.idIssueDistrict.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="personal_information-id_issued_district"
                type="text"
                value={state.formData.idIssueDistrict.value}
                onChange={(e) =>
                  dispatch({
                    type: "inputChange",
                    value: e.target.value,
                    field: "idIssueDistrict",
                  })
                }
                onBlur={(e) =>
                  dispatch({
                    type: "inputBlur",
                    value: e.target.value,
                    field: "idIssueDistrict",
                  })
                }
              >
                {districts.map((item, i) => {
                  return (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {state.formData.idIssueDistrict.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.idIssueDistrict.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-id_issue_date_bs"
              >
                ID issue Date(B.S)
              </label>
              <span className="text-danger">*</span>
              <section className="diy-calendar-section">
                <input
                  value={state.formData.idIssueDateBs.value}
                  type="text"
                  className="form-control-alternative calendar form-control"
                />
                <div className="diy-calendar">
                  <Calendar
                    className="form-control-alternative calendar form-control"
                    value={state.formData.idIssueDateBs.value}
                    onChange={handleIdIssueDateBs}
                    theme="deepdark"
                  />
                </div>
              </section>
              {state.formData.idIssueDateBs.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.idIssueDateBs.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-id_issued_date_ad"
              >
                ID Issue Date(A.D)
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative calendar form-control ${
                  cardIssueDateReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.idIssueDate.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                value={state.formData.idIssueDate.value}
                onChange={handleIdIssueDate}
                disabled
              />
              {/* <DatePicker
                className={`form-control-alternative calendar form-control ${
                  cardIssueDateReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.idIssueDate.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                selected={state.formData.idIssueDate.value}
                onChange={handleIdIssueDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              /> */}
              {state.formData.idIssueDate.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.idIssueDate.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="guardian-pan">
                Permanent Account Number(PAN)
              </label>
              <Input
                className={`form-control-alternative ${
                  panReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.pan.hasErrors ? "is-invalid border-danger" : ""
                }`}
                id="guardian-pan"
                type="text"
                placeholder="Enter PAN"
                value={state.formData.pan.value}
                onBlur={(e) =>
                  dispatch({
                    type: "validatePAN",
                    value: e.target.value,
                  })
                }
                onChange={(e) =>
                  dispatch({
                    type: "panChange",
                    value: e.target.value,
                    field: "pan",
                  })
                }
              />
              {state.formData.pan.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.pan.message}
                </Alert>
              )}
              {state.formData.pan.checkingNow && (
                <Alert className="alert-danger mt-4">
                  {state.formData.pan.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <h3 className="text-primary">Nominee's Current Address</h3>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="guardian-country">
                Country
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  tempCountryReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.temporary.country.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-country"
                type="text"
                value={state.formData.addresses.temporary.country.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "country",
                  })
                }
              >
                <option value=""></option>
                <option value="Nepal">Nepal</option>
              </select>
              {state.formData.addresses.temporary.country.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.country.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="guardian-province">
                Province
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  tempProvinceReject ? "is-invalid border-danger" : ""
                }  ${
                  state.formData.addresses.temporary.province.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-province"
                type="text"
                value={state.formData.addresses.temporary.province.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "province",
                  })
                }
              >
                {state.formData.addresses.temporary.province.list.map(
                  (item, i) => {
                    return (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
              {state.formData.addresses.temporary.province.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.province.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-temporary_district"
              >
                District
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  tempDistrictReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.temporary.district.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-temporary_district"
                type="text"
                value={state.formData.addresses.temporary.district.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "district",
                  })
                }
              >
                {state.formData.addresses.temporary.district.listToShow.map(
                  (item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
              {state.formData.addresses.temporary.district.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.district.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="guardian-city">
                City
              </label>
              <Input
                className={`form-control-alternative ${
                  tempCityReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.temporary.city.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-city"
                type="text"
                placeholder="Your City Name"
                value={state.formData.addresses.temporary.city.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "city",
                  })
                }
              />
              {state.formData.addresses.temporary.city.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.city.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-temporary_municipality"
              >
                Municipality
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  tempMunicipalityReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.temporary.municipality.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-temporary_municipality"
                type="text"
                placeholder="Your Municipality / VDC name"
                value={state.formData.addresses.temporary.municipality.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "municipality",
                  })
                }
              >
                {state.formData.addresses.temporary.municipality.listToShow.map(
                  (item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
              {state.formData.addresses.temporary.municipality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.municipality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-locality_tole"
              >
                Locality/Tole
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  tempLocalityReject ? "is-invalid border-danger" : ""
                }
                ${
                  state.formData.addresses.temporary.locality.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-locality_tole"
                type="text"
                placeholder="Enter your street address"
                value={state.formData.addresses.temporary.locality.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "locality",
                  })
                }
              />
              {state.formData.addresses.temporary.locality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.locality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-temporary_ward_no"
              >
                Ward No
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  tempWardNoReject ? "is-invalid border-danger" : ""
                }
                ${
                  state.formData.addresses.temporary.ward_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }
                `}
                id="guardian-temporary_ward_no"
                type="text"
                placeholder="Enter your ward number"
                value={state.formData.addresses.temporary.ward_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "ward_number",
                  })
                }
              />
              {state.formData.addresses.temporary.ward_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.ward_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-temporary_block_no"
              >
                Block No
              </label>
              <Input
                className={`form-control-alternative ${
                  tempBlockNoReject ? "is-invalid border-danger" : ""
                }
                ${
                  state.formData.addresses.temporary.block_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }
                `}
                id="guardian-temporary_block_no"
                type="text"
                placeholder="Enter your block number"
                value={state.formData.addresses.temporary.block_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: "block_number",
                  })
                }
              />
              {state.formData.addresses.temporary.block_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.block_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-temporary_telephone_number"
              >
                Telephone Number
              </label>
              <Input
                className={`form-control-alternative ${
                  tempPhoneNoReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.temporary.phone_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-temporary_telephone_number"
                type="number"
                placeholder="Enter your phones number"
                value={state.formData.addresses.temporary.phone_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "temporaryInputChange",
                    value: e.target.value,
                    field: " phone_number",
                  })
                }
              />
              {state.formData.addresses.temporary.phone_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.temporary.phone_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <h3 className="text-primary">Nominee's Permanent Address</h3>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Input
                className="form-control-alternative alert-primary ml-1"
                type="checkbox"
                id="nominee-permanent-address"
                onClick={handleCheck}
              />
              <label
                className="form-control-label"
                htmlFor="nominee-permanent-address"
                style={{ marginLeft: "3rem" }}
              >
                Same as Current Address
              </label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_country"
              >
                Country
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  countryReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.country.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_country"
                type="text"
                value={state.formData.addresses.permanent.country.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "country",
                  })
                }
              >
                <option value=""></option>
                <option value="Nepal">Nepal</option>
              </select>
              {state.formData.addresses.permanent.country.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.country.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_province"
              >
                Province
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  provinceReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.province.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_province"
                type="text"
                value={state.formData.addresses.permanent.province.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "province",
                  })
                }
              >
                {state.formData.addresses.permanent.province.list.map(
                  (item, i) => {
                    return (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
              {state.formData.addresses.permanent.province.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.province.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_district"
              >
                District
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar  ${
                  districtReject ? "is-invalid border-danger" : ""
                }`}
                id="guardian-permanent_district"
                type="text"
                value={state.formData.addresses.permanent.district.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "district",
                  })
                }
              >
                {state.formData.addresses.permanent.district.listToShow.map(
                  (item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_city"
              >
                City
              </label>
              <Input
                className={`form-control-alternative ${
                  cityReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.city.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_city"
                type="text"
                placeholder="Your City Name"
                value={state.formData.addresses.permanent.city.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "city",
                  })
                }
              />
              {state.formData.addresses.permanent.city.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.city.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_municipality"
              >
                Municipality
              </label>
              <span className="text-danger">*</span>
              <select
                className={`form-control-alternative calendar ${
                  municipalityReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.municipality.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_municipality"
                type="text"
                placeholder="Your Municipality / VDC name"
                value={state.formData.addresses.permanent.municipality.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "municipality",
                  })
                }
              >
                {state.formData.addresses.permanent.municipality.listToShow.map(
                  (item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  }
                )}
              </select>
              {state.formData.addresses.permanent.municipality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.municipality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_locality_tole"
              >
                Locality/Tole
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  localityReject ? "is-invalid border-danger" : ""
                }
                ${
                  state.formData.addresses.permanent.locality.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_locality_tole"
                type="text"
                placeholder="Enter your street address"
                value={state.formData.addresses.permanent.locality.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "locality",
                  })
                }
              />
              {state.formData.addresses.permanent.locality.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.locality.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_ward_no"
              >
                Ward No
              </label>
              <span className="text-danger">*</span>
              <Input
                className={`form-control-alternative ${
                  wardNoReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.ward_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_ward_no"
                type="text"
                placeholder="Enter your ward number"
                value={state.formData.addresses.permanent.ward_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "ward_number",
                  })
                }
              />
              {state.formData.addresses.permanent.ward_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.ward_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_block_no"
              >
                Block No
              </label>
              <Input
                className={`form-control-alternative ${
                  blockNoReject ? "is-invalid border-danger" : ""
                } ${
                  state.formData.addresses.permanent.block_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_block_no"
                type="text"
                placeholder="Enter your block number"
                value={state.formData.addresses.permanent.block_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: "block_number",
                  })
                }
              />
              {state.formData.addresses.permanent.block_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.block_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="guardian-permanent_telephone_number"
              >
                Telephone Number
              </label>
              <Input
                className={`form-control-alternative ${
                  phoneNoReject ? "is-invalid border-danger" : ""
                }${
                  state.formData.addresses.permanent.phone_number.hasErrors
                    ? "is-invalid border-danger"
                    : ""
                }`}
                id="guardian-permanent_telephone_number"
                type="number"
                placeholder="Enter your phones number"
                value={state.formData.addresses.permanent.phone_number.value}
                onChange={(e) =>
                  dispatch({
                    type: "permanentInputChange",
                    value: e.target.value,
                    field: " phone_number",
                  })
                }
              />
              {state.formData.addresses.permanent.phone_number.hasErrors && (
                <Alert className="alert-danger mt-4">
                  {state.formData.addresses.permanent.phone_number.message}
                </Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <h3 className="text-primary">
          Upload your documents <span className="text-danger">*</span>
        </h3>
        <Row className="mb-5">
          <Col lg="6">
            <Card>
              <CardHeader>
                <p className="lead">Your PP size photo</p>
              </CardHeader>
              <CardBody>
                <DisplayPicture
                  setDisplayPicture={setDisplayPicture}
                  setDisplayPictureFile={setDisplayPictureFile}
                  displayImg={`nominee/${data.documents[0]?.photo_name}`}
                  email={userData?.email}
                  setError={state.formData.documents.displayPicture.hasErrors}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.displayPicture.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.displayPicture.message}
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardHeader>
                <p className="lead">Your Citizenship card front</p>
              </CardHeader>
              <CardBody>
                <CitizenshipFront
                  setCitizenshipFront={setCitizenshipFront}
                  setCitizenshipFrontFile={setCitizenshipFrontFile}
                  displayImg={`nominee/${data.documents[0]?.gov_issued_id_front_name}`}
                  email={userData?.email}
                  setError={state.formData.documents.citizenshipFront.hasErrors}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.citizenshipFront.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.citizenshipFront.message}
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg="6">
            <div className="card">
              <div className="card-header">
                <p className="lead">Your Citizenship card back</p>
              </div>
              <div className="card-body">
                <CitizenshipBack
                  setCitizenshipBack={setCitizenshipBack}
                  setCitizenshipBackFile={setCitizenshipBackFile}
                  displayImg={`nominee/${data.documents[0]?.gov_issued_id_back_name}`}
                  email={userData?.email}
                  setError={state.formData.documents.citizenshipBack.hasErrors}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.citizenshipBack.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.citizenshipBack.message}
                  </p>
                )}
              </div>
            </div>
          </Col>
          <Col lg="6">
            <Card>
              <CardHeader>
                <p className="lead">Your Signature</p>
              </CardHeader>
              <CardBody>
                <Signature
                  setSignature={setSignature}
                  setSignatureFile={setSignatureFile}
                  displayImg={`nominee/${data.documents[0]?.signature_name}`}
                  email={userData?.email}
                  setError={state.formData.documents.signature.hasErrors}
                  setErrorCount={setErrorCount}
                />
                {state.formData.documents.signature.hasErrors && (
                  <p className="text-danger">
                    {state.formData.documents.signature.message}
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Container>
  );
};

export default Nominee;
