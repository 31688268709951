import React, { useEffect, useContext, useState } from "react";
import { CardBody, Container } from "reactstrap";
import { useImmerReducer } from "use-immer";

import FormDispatch from "../FormDispatch";
import FormState from "../FormState";

import DisplayPicture from "../../Handler/Documents/DisplayPicture";
import ThumbPrintLeft from "../../Handler/Documents/ThumbprintLeft";
import ThumbprintRight from "../../Handler/Documents/ThumbprintRight";
import CitizenshipBack from "../../Handler/Documents/CitizenshipBack";
import CitizenshipFront from "../../Handler/Documents/CitizenshipFront";
import Signature from "../../Handler/Documents/Signature";
import BirthCertificate from "../../Handler/Documents/BirthCertificate";

import MapI from "../../Handler/MapSearch";

const Documents = ({ data }) => {
  const formState = useContext(FormState);
  const formDispatch = useContext(FormDispatch);

  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [errorCount, setErrorCount] = useState(0);

  const initialState = {
    errorCount: 0,
    sendCount: 0,
    documents: {
      displayPicture: {
        value: data.documents[0]?.photo_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      thumbLeft: {
        value: data.documents[0]?.thumb_left_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      thumbRight: {
        value: data.documents[0]?.thumb_right_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      citizenshipBack: {
        value: data.documents[0]?.gov_issued_id_back_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      citizenshipFront: {
        value: data.documents[0]?.gov_issued_id_front_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      signature: {
        value: data.documents[0]?.signature_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
      birthCertificate: {
        value: data.documents[0]?.birth_certificate_name,
        file: null,
        hasErrors: false,
        message: "",
        required: true,
      },
    },
    submitCount: {
      counter: 0,
      hasErrors: false,
      touched: true,
    },
  };

  function ourReducer(draft, action) {
    switch (action.type) {
      case "setDisplayPicture":
        draft.documents.displayPicture.hasErrors = false;
        draft.documents.displayPicture.value = action.value;
        return;
      case "setDisplayPictureFile":
        draft.documents.displayPicture.file = action.value;
        return;
      case "setThumbprintLeft":
        draft.documents.thumbLeft.hasErrors = false;
        draft.documents.thumbLeft.value = action.value;
        return;
      case "setThumbprintLeftFile":
        draft.documents.thumbLeft.file = action.value;
        return;
      case "setThumbprintRight":
        draft.documents.thumbRight.hasErrors = false;
        draft.documents.thumbRight.value = action.value;
        return;
      case "setThumbprintRightFile":
        draft.documents.thumbRight.file = action.value;
        return;

      case "setCitizenshipBack":
        draft.documents.citizenshipBack.hasErrors = false;
        draft.documents.citizenshipBack.value = action.value;
        return;
      case "setCitizenshipBackFile":
        draft.documents.citizenshipBack.file = action.value;
        return;
      case "setCitizenshipFront":
        draft.documents.citizenshipFront.hasErrors = false;
        draft.documents.citizenshipFront.value = action.value;
        return;
      case "setCitizenshipFrontFile":
        draft.documents.citizenshipFront.file = action.value;
        return;
      case "setSignature":
        draft.documents.signature.hasErrors = false;
        draft.documents.signature.value = action.value;
        return;
      case "setSignatureFile":
        draft.documents.signature.file = action.value;
        return;
      case "setBirthCertificate":
        draft.documents.birthCertificate.hasErrors = false;
        draft.documents.birthCertificate.value = action.value;
        return;
      case "setBirthCertificateFile":
        draft.documents.birthCertificate.file = action.value;
        return;
      case "validateForm":
        //check for errors
        draft.errorCount = 0;
        for (const key in draft.documents) {
          if (draft.documents[key].value == "") {
            console.log("there is an errror");
            draft.errorCount++;
            console.log(draft.errorCount);
            draft.documents[key].hasErrors = true;
            draft.documents[key].message = "This field is required";
          }
          if (!draft.errorCount) {
            draft.sendCount++;
          }
        }
        return;
      case "checkCount":
        draft.submitCount.counter++;
        return;
      case "saveSubmitCount":
        draft.sendCount++;
        return;
      case "default":
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  function setDisplayPicture(e) {
    dispatch({ type: "setDisplayPicture", value: e });
  }
  function setDisplayPictureFile(e) {
    dispatch({ type: "setDisplayPictureFile", value: e });
  }
  function setThumbprintLeft(e) {
    dispatch({ type: "setThumbprintLeft", value: e });
  }
  function setThumbprintLeftFile(e) {
    dispatch({ type: "setThumbprintLeftFile", value: e });
  }
  function setThumbprintRight(e) {
    dispatch({ type: "setThumbprintRight", value: e });
  }
  function setThumbprintRightFile(e) {
    dispatch({ type: "setThumbprintRightFile", value: e });
  }
  function setCitizenshipBack(e) {
    dispatch({ type: "setCitizenshipBack", value: e });
  }
  function setCitizenshipBackFile(e) {
    dispatch({ type: "setCitizenshipBackFile", value: e });
  }
  function setCitizenshipFront(e) {
    dispatch({ type: "setCitizenshipFront", value: e });
  }
  function setCitizenshipFrontFile(e) {
    dispatch({ type: "setCitizenshipFrontFile", value: e });
  }
  function setSignature(e) {
    dispatch({ type: "setSignature", value: e });
  }
  function setSignatureFile(e) {
    dispatch({ type: "setSignatureFile", value: e });
  }
  function setBirthCertificate(e) {
    dispatch({ type: "setBirthCertificate", value: e });
  }
  function setBirthCertificateFile(e) {
    dispatch({ type: "setBirthCertificateFile", value: e });
  }
  function lng(e) {
    setLongitude(e);
  }
  function lat(e) {
    setLatitude(e);
  }
  const [errors, setErros] = useState("");

  useEffect(() => {
    if (state.sendCount) {
      if (data.is_minor === "no") {
        formDispatch({
          type: "saveDocuments",
          value: {
            id: data.documents[0]?.id,

            photo: state.documents.displayPicture.value,
            photo_id: data.documents[0]?.photo_id,

            gov_issued_id_front: state.documents.citizenshipFront.value,
            gov_issued_id_front_id: data.documents[0]?.gov_issued_id_front_id,

            gov_issued_id_back: state.documents.citizenshipBack.value,
            gov_issued_id_back_id: data.documents[0]?.gov_issued_id_back_id,

            thumb_left: state.documents.thumbLeft.value,
            thumb_left_id: data.documents[0]?.thumb_left_id,

            thumb_right: state.documents.thumbRight.value,
            thumb_right_id: data.documents[0]?.thumb_right_id,

            signature: state.documents.signature.value,
            signature_id: data.documents[0]?.signature_id,

            lat: longitude,
            long: latitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            photo_file: state.documents.displayPicture.file,
            gov_issued_id_front_file: state.documents.citizenshipFront.file,
            gov_issued_id_back_file: state.documents.citizenshipBack.file,
            thumb_left_file: state.documents.thumbLeft.file,
            thumb_right_file: state.documents.thumbRight.file,
            signature_file: state.documents.signature.file,
          },
        });
      } else {
        formDispatch({
          type: "saveDocuments",
          value: {
            id: data.documents[0]?.id,
            birth_certificate: state.documents.birthCertificate.value,
            birth_certificate_id: data.documents[0]?.birth_certificate_id,
            lat: latitude,
            long: longitude,
          },
        });
        formDispatch({
          type: "saveDocumentsFile",
          value: {
            birth_certificate_file: state.documents.birthCertificate.file,
          },
        });
      }
      // console.log("documents saved from the documents component");
    }
  }, [state.sendCount]);

  useEffect(() => {
    dispatch({ type: "validateForm" });
  }, [state.documents]);

  const [photoReject, setPhotoReject] = useState(false);
  const [signatureReject, setSignatureReject] = useState(false);
  const [thumbLefteReject, setThumbLefteReject] = useState(false);
  const [thumbRightReject, setThumbRightReject] = useState(false);
  const [govFrontReject, setGovFrontReject] = useState(false);
  const [govBackReject, setGovBackReject] = useState(false);
  const [birthCertificateReject, setBirthCertificateReject] = useState(false);

  useEffect(() => {
    data.reasons.map((item, i) => {
      if (item.type === "documents") {
        if (item.title === "photo") {
          setPhotoReject(true);
        }
        if (item.title === "signature") {
          setSignatureReject(true);
        }
        if (item.title === "thumb_left") {
          setThumbLefteReject(true);
        }
        if (item.title === "thumb_right") {
          setThumbRightReject(true);
        }
        if (item.title === "gov_issued_id_front") {
          setGovFrontReject(true);
        }
        if (item.title === "gov_issued_id_back") {
          setGovBackReject(true);
        }
        if (item.title === "birth_certificate") {
          setBirthCertificateReject(true);
        }
      }
    });
  }, [data]);

  return (
    <Container>
      <CardBody>
        <form action="" className="form">
          <div className="form-container personal-info">
            <div className="text-container">
              <h2 className="text-primary">
                Upload your documents <span className="text-danger">*</span>
              </h2>
            </div>
            {data.is_minor === "no" && (
              <>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your PP size photo </p>
                      </div>
                      <div
                        className={`card-body ${
                          photoReject && "border-danger"
                        }`}
                      >
                        <DisplayPicture
                          setDisplayPicture={setDisplayPicture}
                          setDisplayPictureFile={setDisplayPictureFile}
                          displayImg={data.documents[0]?.photo_name}
                          email={data?.email}
                          setError={state.documents.displayPicture.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.displayPicture.hasErrors && (
                          <p className="text-danger">
                            {state.documents.displayPicture.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your Thumbprint Left</p>
                      </div>
                      <div
                        className={`card-body ${
                          thumbLefteReject && "border-danger"
                        }`}
                      >
                        <ThumbPrintLeft
                          setThumbprintLeft={setThumbprintLeft}
                          setThumbprintLeftFile={setThumbprintLeftFile}
                          displayImg={data.documents[0]?.thumb_left_name}
                          email={data?.email}
                          setError={state.documents.thumbLeft.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.thumbLeft.hasErrors && (
                          <p className="text-danger">
                            {state.documents.thumbLeft.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your Thumbprint Right</p>
                      </div>
                      <div
                        className={`card-body ${
                          thumbRightReject && "border-danger"
                        }`}
                      >
                        <ThumbprintRight
                          setThumbprintRight={setThumbprintRight}
                          setThumbprintRightFile={setThumbprintRightFile}
                          displayImg={data.documents[0]?.thumb_right_name}
                          email={data?.email}
                          setError={state.documents.thumbRight.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.thumbRight.hasErrors && (
                          <p className="text-danger">
                            {state.documents.thumbRight.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your Citizenship card front</p>
                      </div>
                      <div
                        className={`card-body ${
                          govFrontReject && "border-danger"
                        }`}
                      >
                        <CitizenshipFront
                          setCitizenshipFront={setCitizenshipFront}
                          setCitizenshipFrontFile={setCitizenshipFrontFile}
                          displayImg={
                            data.documents[0]?.gov_issued_id_front_name
                          }
                          email={data?.email}
                          setError={state.documents.citizenshipFront.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.citizenshipFront.hasErrors && (
                          <p className="text-danger">
                            {state.documents.citizenshipFront.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your Citizenship card back</p>
                      </div>
                      <div
                        className={`card-body ${
                          govBackReject && "border-danger"
                        }`}
                      >
                        <CitizenshipBack
                          setCitizenshipBack={setCitizenshipBack}
                          setCitizenshipBackFile={setCitizenshipBackFile}
                          displayImg={
                            data.documents[0]?.gov_issued_id_back_name
                          }
                          email={data?.email}
                          setError={state.documents.citizenshipBack.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.citizenshipBack.hasErrors && (
                          <p className="text-danger">
                            {state.documents.citizenshipBack.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <p className="lead">Your Signature</p>
                      </div>
                      <div
                        className={`card-body ${
                          signatureReject && "border-danger"
                        }`}
                      >
                        <Signature
                          setSignature={setSignature}
                          setSignatureFile={setSignatureFile}
                          displayImg={data.documents[0]?.signature_name}
                          email={data?.email}
                          setError={state.documents.signature.hasErrors}
                          setErrorCount={setErrorCount}
                        />
                        {state.documents.signature.hasErrors && (
                          <p className="text-danger">
                            {state.documents.signature.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {data.is_minor === "yes" && (
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <p className="lead">Your Birth Certificate</p>
                    </div>
                    <div
                      className={`card-body ${
                        birthCertificateReject && "border-danger"
                      }`}
                    >
                      <BirthCertificate
                        setBirthCertificate={setBirthCertificate}
                        setBirthCertificateFile={setBirthCertificateFile}
                        displayImg={data.documents[0]?.birth_certificate_name}
                        email={data?.email}
                        setError={state.documents.birthCertificate.hasErrors}
                        setErrorCount={setErrorCount}
                      />
                      {state.documents.birthCertificate.hasErrors && (
                        <p className="text-danger">
                          {state.documents.birthCertificate.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
        <div
          className="mapGl"
          style={{
            height: "400px",
            width: "100%",
            position: "relative",
          }}
        >
          <MapI setLng={lng} setLat={lat} height={"400px"} />
        </div>
      </CardBody>
    </Container>
  );
};

export default Documents;
